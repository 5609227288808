import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Line} from "react-chartjs-2";
import {Tile} from "../Tile";
import {getLanguage} from "../../utils/LanguageUtility";

/**
 * The {@code GirthMeasurements} class represents the girth measurements tile.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class GirthMeasurements extends React.Component {

  /**
   * Renders the graph.
   *
   * @returns {*} the HTML representation of the graph
   */
  renderGraph = () => {
    const {measurements} = this.props;
    const {formatMessage} = this.props.intl;

    let labels = [];
    let chest = [];
    let hip = [];
    let thigh = [];
    let underBust = [];
    let upperArm = [];
    let waist = [];

    if (measurements !== null && measurements.length > 0) {
      measurements.forEach(function (measurement) {
        labels.push(new Date(measurement.date).toLocaleDateString(getLanguage()));
        chest.push(measurement.chest);
        hip.push(measurement.hip);
        thigh.push(measurement.thigh);
        underBust.push(measurement.underBust);
        upperArm.push(measurement.upperArm);
        waist.push(measurement.waist);
      });
    }

    const data = {
      datasets: [
        {
          data: chest,
          label: formatMessage({id: 'performance.page.tile.girth-measurements.label.chest'}),
          fill: false,
          borderWidth: 2,
          borderColor: '#4cc7e9',
          backgroundColor: '#4cc7e9',
          pointBorderColor: '#4cc7e9',
          pointBackgroundColor: '#4cc7e9',
          pointHoverBorderColor: '#00b1e0',
          pointHoverBackgroundColor: '#00b1e0',
        },
        {
          data: waist,
          label: formatMessage({id: 'performance.page.tile.girth-measurements.label.waist'}),
          fill: false,
          borderWidth: 2,
          borderColor: '#4cce73',
          backgroundColor: '#4cce73',
          pointBorderColor: '#4cce73',
          pointBackgroundColor: '#4cce73',
          pointHoverBorderColor: '#00ba38',
          pointHoverBackgroundColor: '#00ba38',
        },
        {
          data: hip,
          label: formatMessage({id: 'performance.page.tile.girth-measurements.label.hip'}),
          fill: false,
          borderWidth: 2,
          borderColor: '#ffc675',
          backgroundColor: '#ffc675',
          pointBorderColor: '#ffc675',
          pointBackgroundColor: '#ffc675',
          pointHoverBorderColor: '#f0ad4e',
          pointHoverBackgroundColor: '#f0ad4e',
        },
        {
          data: underBust,
          label: formatMessage({id: 'performance.page.tile.girth-measurements.label.underBust'}),
          fill: false,
          borderWidth: 2,
          borderColor: '#ff5439',
          backgroundColor: '#ff5439',
          pointBorderColor: '#ff5439',
          pointBackgroundColor: '#ff5439',
          pointHoverBorderColor: '#ff2300',
          pointHoverBackgroundColor: '#ff2300',
        },
        {
          data: upperArm,
          label: formatMessage({id: 'performance.page.tile.girth-measurements.label.upperArm'}),
          fill: false,
          borderWidth: 2,
          borderColor: '#007a9c',
          backgroundColor: '#007a9c',
          pointBorderColor: '#007a9c',
          pointBackgroundColor: '#007a9c',
          pointHoverBorderColor: '#004659',
          pointHoverBackgroundColor: '#004659',
        },
        {
          data: thigh,
          label: formatMessage({id: 'performance.page.tile.girth-measurements.label.thigh'}),
          fill: false,
          borderWidth: 2,
          borderColor: '#008227',
          backgroundColor: '#008227',
          pointBorderColor: '#008227',
          pointBackgroundColor: '#008227',
          pointHoverBorderColor: '#004a16',
          pointHoverBackgroundColor: '#004a16',
        }
      ],

      labels: labels
    };

    const options = {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            stacked: true
          }
        ],
        yAxes: [
          {
            type: 'linear',
            gridLines: {
              display: false
            },
            ticks: {
              min: 0,
              suggestedMax: 80,
              stepSize: 10
            }
          }
        ]
      }
    };

    return <Line data={data}
                 options={options}
                 legend={{position: 'bottom', labels: {boxWidth: 12}}}/>;
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <Tile xs={12} md={6}>
        <FormattedMessage id="performance.page.tile.girth-measurements.page.title" tagName="h5"/>
        {this.renderGraph()}
      </Tile>
    );
  }
}

export default injectIntl(GirthMeasurements);
