import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import {Content, FeatureBox, Icon} from "../../components/FeatureBox/index";
import {BounceIn} from "../../components/Animations/index";
import {Image, ParallaxImage} from "../../components/Image/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import Light from "../../components/Icons/Light";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code About} class represents the about us page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class About extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div id="about-us-page">
                <ParallaxImage lowRes={require('../../images/pages/about-us/page-header-tiny.jpeg')}
                               highRes={require('../../images/pages/about-us/page-header.jpeg')}/>
              <PageHeader>
                <PageHeaderTitle>
                  <FormattedMessage id="about.page.title" tagName="h3"/>
                  <FormattedMessage id="about.page.subtitle" tagName="p"/>
                </PageHeaderTitle>
              </PageHeader>
                <section>
                    <Grid>
                        <Row className="pillars">
                            <Col xs={12} md={4}>
                                <FeatureBox>
                                    <BounceIn delay="400">
                                        <Icon>
                                            <Light icon="running"/>
                                        </Icon>
                                    </BounceIn>
                                    <Content>
                                        <FormattedMessage id="home.feature.accompaniment.title" tagName="h4"/>
                                        <FormattedHTMLMessage id="home.feature.accompaniment.paragraph" tagName="p"/>
                                    </Content>
                                </FeatureBox>
                            </Col>
                            <Col xs={12} md={4}>
                                <FeatureBox>
                                    <BounceIn delay="600">
                                        <Icon>
                                            <Light icon="cauldron"/>
                                        </Icon>
                                    </BounceIn>
                                    <Content>
                                        <FormattedMessage id="home.feature.food.title" tagName="h4"/>
                                        <FormattedHTMLMessage id="home.feature.food.paragraph" tagName="p"/>
                                    </Content>
                                </FeatureBox>
                            </Col>
                            <Col xs={12} md={4}>
                                <FeatureBox>
                                    <BounceIn delay="800">
                                        <Icon>
                                            <Light icon="badge-check"/>
                                        </Icon>
                                    </BounceIn>
                                    <Content>
                                        <FormattedMessage id="home.feature.motivation.title" tagName="h4"/>
                                        <FormattedHTMLMessage id="home.feature.motivation.paragraph" tagName="p"/>
                                    </Content>
                                </FeatureBox>
                            </Col>
                        </Row>
                        <Row className="description">
                          <Col xsHidden sm={4}>
                            <Image lowRes={require('../../images/pages/about-us/image.svg')}
                                   highRes={require('../../images/pages/about-us/image.jpeg')}
                                   classNames="img-responsive"/>
                          </Col>
                          <Col xs={12} sm={8}>
                                <FormattedMessage id="about.paragraph.one" tagName="p"/>
                                <FormattedMessage id="about.paragraph.two" tagName="p"/>
                            <FormattedMessage id="about.paragraph.three" tagName="p"/>
                            <FormattedMessage id="about.paragraph.four" tagName="p"/>
                                <br/>
                            <LocalizedLink id={URIKeys.REGISTER} className="btn btn-info">
                              <FormattedMessage id="page.pricing.pricing-box.button.subscribe"/>
                            </LocalizedLink>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </div>
        );
    }
}

export default About;
