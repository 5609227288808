import React from "react";
import {Field, Form} from "react-final-form";
import {Button, Col, ControlLabel, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import FormControl from "./final-form/FormControl/FormControl";
import FormGroup from "./final-form/FormGroup/FormGroup";
import arrayMutators from 'final-form-arrays';
import {maxLength} from "../../utils/ValidationUtility";
import {patch} from "../../utils/FetchUtility";
import BackendURLConstants from "../../constants/BackendURLConstants";
import Select from "./final-form/Select/Select";
import {getFacilityAppointments} from "../../actions";
import {connect} from "react-redux";

/**
 * The {@code AppointmentForm} class represents the form to edit an appointment.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class AppointmentForm extends React.Component {

  /**
   * Creates a new instance of the {@link AppointmentForm} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      initialValues: {
        user: undefined,
        employee: undefined,
        cardio: undefined,
        module: undefined,
        submodules: undefined,
        comment: undefined
      },
      employee: undefined
    };
  }

  componentDidMount() {
    const {appointment} = this.props;
    const employee = this.getEmployee();

    this.setState({
      initialValues: {
        user: appointment.user.firstName + ' ' + appointment.user.lastName,
        employee: employee.firstName + ' ' + employee.lastName,
        cardio: appointment.cardio,
        module: appointment.module,
        submodules: appointment.submodules,
        comment: appointment.comment
      },
      employee: employee
    });
  }

  /**
   * Returns the employee for selected appointment.
   * <p>
   * If the employee is already set on the appointment, this one is used. If not, the employee that is currently logged
   * in is used.
   *
   * @returns {employee} the employee for the selected appointment
   */
  getEmployee() {
    const {appointment, employee} = this.props;

    let result;
    if (appointment.employee) {
      result = appointment.employee;
    } else {
      result = employee;
    }
    return result;
  }

  /**
   * Handles the form on submit.
   */
  onSubmit = async (values) => {
    const {appointment, dispatch, facilityAppointmentCollection} = this.props;

    const data = {
      ...values,
      date: appointment.date,
      user: BackendURLConstants.USER.replace('$id', appointment.user.username),
      employee: BackendURLConstants.EMPLOYEE.replace('$id', this.state.employee.username),
      pointOfService: BackendURLConstants.POINT_OF_SERVICE.replace('$id', appointment.pointOfService.id),
      facility: appointment.facility
    };

    await patch(BackendURLConstants.APPOINTMENT.replace('$id', appointment.id), JSON.stringify(data), 'application/json', true);

    dispatch(getFacilityAppointments(appointment.facility.id, new Date(facilityAppointmentCollection[0].date), undefined, true));
    this.props.toggleEditAppointmentModal();
  };

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {formatMessage} = this.props.intl;
    return (
      <div>
        <Form
          onSubmit={this.onSubmit}
          initialValues={this.state.initialValues}
          mutators={{
            ...arrayMutators
          }}
          render={({handleSubmit, pristine, invalid}) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="user">
                    <ControlLabel>
                      <FormattedMessage id="label.user"/>
                    </ControlLabel>
                    <Field
                      name="user"
                      component={FormControl}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="employee">
                    <ControlLabel>
                      <FormattedMessage id="label.employee"/>
                    </ControlLabel>
                    <Field
                      name="employee"
                      component={FormControl}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="module">
                    <ControlLabel>
                      <FormattedMessage id="label.module"/>
                    </ControlLabel>
                    <Field
                      name="module"
                      component={Select}
                      options={[
                        {
                          value: undefined,
                          label: formatMessage({id: 'label.select'})
                        },
                        {
                          value: 'A',
                          label: 'A'
                        },
                        {
                          value: 'B',
                          label: 'B'
                        },
                        {
                          value: 'C',
                          label: 'C'
                        },
                        {
                          value: 'D',
                          label: 'D'
                        }
                      ]}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="submodules">
                    <ControlLabel>
                      <FormattedMessage id="label.submodules"/>
                    </ControlLabel>
                    <label className="checkbox-inline">
                      <Field
                        name="submodules"
                        component="input"
                        type="checkbox"
                        value="X1"
                      />{' '}
                      <FormattedMessage id="submodule.X1"/>
                    </label>
                    <label className="checkbox-inline">
                      <Field
                        name="submodules"
                        component="input"
                        type="checkbox"
                        value="X2"
                      />{' '}
                      <FormattedMessage id="submodule.X2"/>
                    </label>
                    <label className="checkbox-inline">
                      <Field
                        name="submodules"
                        component="input"
                        type="checkbox"
                        value="X3"
                      />{' '}
                      <FormattedMessage id="submodule.X3"/>
                    </label>
                    <label className="checkbox-inline">
                      <Field
                        name="submodules"
                        component="input"
                        type="checkbox"
                        value="X4"
                      />{' '}
                      <FormattedMessage id="submodule.X4"/>
                    </label>
                    <label className="checkbox-inline">
                      <Field
                        name="submodules"
                        component="input"
                        type="checkbox"
                        value="X5"
                      />{' '}
                      <FormattedMessage id="submodule.X5"/>
                    </label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="cardio">
                    <ControlLabel>
                      <FormattedMessage id="label.cardio"/>
                    </ControlLabel>
                    <Field
                      name="cardio"
                      component={FormControl}
                      componentClass="textarea"
                      validate={maxLength(256)}
                      rows={5}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="comment">
                    <ControlLabel>
                      <FormattedMessage id="label.comment"/>
                    </ControlLabel>
                    <Field
                      name="comment"
                      component={FormControl}
                      componentClass="textarea"
                      validate={maxLength(256)}
                      rows={5}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button className="pull-right" bsStyle="danger" onClick={this.props.toggleEditAppointmentModal}>
                    <FormattedMessage id="common.button.cancel"/>
                  </Button>
                  <Button className="pull-right" bsStyle="primary" type="submit" disabled={pristine || invalid}>
                    <FormattedMessage id="common.button.confirm"/>
                  </Button>
                </Col>
              </Row>
            </form>
          )}/>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {getFacilityAppointments} = state;
  return {
    facilityAppointmentCollection: getFacilityAppointments.facilityAppointmentCollection,
  };
}

export default connect(mapStateToProps)(injectIntl(AppointmentForm));
