import React from "react";
import {Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

/**
 * The {@code OpeningDay} class represents an opening day.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class OpeningDay extends React.Component { // todo: move to functional component

  /**
   * Renders the component.
   *
   * @returns JSX.Element HTML representation of the component
   */
  render() {
    const {day, children} = this.props;

    const cssClass = (day) === new Date().getDay() ? ' current' : ''; // todo: add classNames package

    return (
      <Row className={"point-of-service-opening-schedule-entry" + cssClass}>
        <Col xs={5} className="point-of-service-opening-schedule-entry-day">
          <FormattedMessage id={`date.day.${(day === 0 ? 6 : day - 1)}`}/>
        </Col>
        <Col xs={7} className="point-of-service-opening-schedule-entry-hours text-right">
          {children}
        </Col>
      </Row>
    );
  }
}

export default OpeningDay;
