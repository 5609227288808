import React from "react";
import {Col, Row} from "react-bootstrap";

/**
 * The {@code BackLink} class represents the back link.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class BackLink extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    return (
      <Row>
        <Col xs={12}>
          <div className="page-header-back-link">
            {this.props.children}
          </div>
        </Col>
      </Row>
    )
  }
}

export default BackLink;
