import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import LocalizedLink from "../components/Intl/LocalizedLink";
import {Brand, Solid} from "../components/Icons/index";
import URIKeys from "../constants/URIKeys";

/**
 * The {@code Footer} class represents the page footer.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Footer extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <footer className="footer">
                <section className="footer-social">
                    <Grid>
                        <Row>
                            <Col xs={12} sm={6}>
                                <ul className="list-inline footer-social-media">
                                    <li>
                                        <a href="https://www.facebook.com/Bodyreset.be/" className="facebook" target="_blank" rel="noopener noreferrer">
                                            <Brand icon="facebook-f"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/bodyreset_be/" className="instagram" target="_blank" rel="noopener noreferrer">
                                            <Brand icon="instagram"/>
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={6}>
                                <LocalizedLink id={URIKeys.NEWSLETTER_SIGN_UP} className="footer-social-newsletter">
                                    <Solid icon="envelope"/>
                                    <Solid icon="envelope-open" classNames="hidden"/>
                                    <FormattedMessage id="footer.link.newsletter" tagName="span"/>
                                </LocalizedLink>
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <section className="footer-links">
                    <Grid>
                        <Row>
                            <Col xs={12} sm={3}>
                                <h4>Bodyreset</h4>
                                <ul className="list-unstyled footer-links-list">
                                    <li className="footer-links-list-item">
                                        <LocalizedLink id={URIKeys.HOME}>
                                            <FormattedMessage id="nav-bar.item.home"/>
                                        </LocalizedLink>
                                    </li>
                                    <li className="footer-links-list-item">
                                        <LocalizedLink id={URIKeys.SERVICES}>
                                            <FormattedMessage id="nav-bar.item.services"/>
                                        </LocalizedLink>
                                    </li>
                                    <li className="footer-links-list-item">
                                        <LocalizedLink id={URIKeys.STORE_LOCATOR}>
                                            <FormattedMessage id="nav-bar.item.store-locator"/>
                                        </LocalizedLink>
                                    </li>
                                    <li className="footer-links-list-item">
                                        <LocalizedLink id={URIKeys.PRICING}>
                                            <FormattedMessage id="nav-bar.item.pricing"/>
                                        </LocalizedLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={3}>
                                <FormattedMessage id="footer.title.about" tagName="h4"/>
                                <ul
                                    className="list-unstyled footer-links-list">
                                    <li className="footer-links-list-item">
                                        <LocalizedLink id={URIKeys.ABOUT_US}>
                                            <FormattedMessage id="nav-bar.item.about-us"/>
                                        </LocalizedLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={3}>
                                <FormattedMessage id="footer.title.help" tagName="h4"/>
                                <ul className="list-unstyled footer-links-list">
                                    <li className="footer-links-list-item">
                                        <LocalizedLink id={URIKeys.LOGIN}>
                                            <FormattedMessage id="nav-bar.item.login"/>
                                        </LocalizedLink>
                                    </li>
                                  <li className="footer-links-list-item">
                                    <LocalizedLink id={URIKeys.FAQ}>
                                      <FormattedMessage id="footer.link.faq"/>
                                    </LocalizedLink>
                                  </li>
                                    <li className="footer-links-list-item">
                                        <LocalizedLink id={URIKeys.CONTACT}>
                                            <FormattedMessage id="footer.link.contact-us"/>
                                        </LocalizedLink>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <section className="footer-copyright">
                    <Grid>
                        <Row>
                            <Col xs={12} sm={6} smPush={6}>
                                <ul className="list-inline footer-copyright-legal">
                                    <li>
                                        <LocalizedLink id={URIKeys.COOKIE_POLICY}>
                                            <FormattedMessage id="footer.link.cookie-policy"/>
                                        </LocalizedLink>
                                    </li>
                                    <li>
                                        <LocalizedLink id={URIKeys.PRIVACY_POLICY}>
                                            <FormattedMessage id="footer.link.privacy-policy"/>
                                        </LocalizedLink>
                                    </li>
                                    <li>
                                        <LocalizedLink id={URIKeys.TERMS_AND_CONDITIONS}>
                                            <FormattedMessage id="footer.link.terms-and-conditions"/>
                                        </LocalizedLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={6} smPull={6}>
                                <div className="footer-copyright-notice">
                                    <FormattedMessage id="footer.paragraph.copyright"/>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </footer>
        );
    }
}

export default Footer;
