import React from "react";

/**
 * The {@code Instruction} class represents an instruction.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Instruction extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <li className="instruction">{this.props.description}</li>
        );
    }
}

export default Instruction;
