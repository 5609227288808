import React from "react";
import {Redirect} from "react-router-dom";
import {injectIntl} from "react-intl";
import {Col, Grid, Row} from "react-bootstrap";
import {LoginForm} from "../../components/Forms/index";
import {hasRole, isLoggedIn} from "../../utils/AuthenticationUtility";
import URIKeys from "../../constants/URIKeys";
import USER_ROLES from "../../constants/UserRoles";

/**
 * The {@code Login} class represents the login page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Login extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {formatMessage} = this.props.intl;
    if (isLoggedIn()) {
      if (hasRole(USER_ROLES.USER)) {
        return <Redirect to={formatMessage({id: URIKeys.DASHBOARD_PERFORMANCE})}/>
      } else {
        return <Redirect to={formatMessage({id: URIKeys.DASHBOARD_OVERVIEW})}/>
      }
    }

    return (
      <div id="login-page">
        <Grid>
          <Row>
            <Col xs={10} xsOffset={1} md={4} mdOffset={4} className="col-login-form">
              <div className="login-form-wrapper">
                <LoginForm/>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default injectIntl(Login);
