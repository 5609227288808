import React from "react";
import {Field, Form} from "react-final-form";
import {Button, Col, ControlLabel, FormGroup, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import BUNDLES from "../../constants/Bundles";
import Select from "./final-form/Select/Select";
import BackendURLConstants from "../../constants/BackendURLConstants";
import {patch} from "../../utils/FetchUtility";
import {getUser} from "../../actions";
import {connect} from "react-redux";

/**
 * The {@code AddCreditsForm} class represents the form to add credits.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class AddCreditsForm extends React.Component {

  /**
   * Creates a new instance of the {@link AddCreditsForm} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    let bundle = BUNDLES.filter(function (bundle) {
      return 'small' === bundle.key;
    });

    this.state = {
      initialValues: {
        credits: bundle[0].credits
      }
    };
  }

  /**
   * Renders the submit button.
   *
   * @returns {*} the submit button
   */
  renderSubmitButton = () => {
    return (
      <Button className="pull-right" bsStyle="primary" type="submit">
        <FormattedMessage id="common.button.confirm"/>
      </Button>
    );
  };

  /**
   * Renders the cancel button.
   *
   * @returns {*} the cancel button
   */
  renderCancelButton = () => {
    return (
      <Button className="pull-right" bsStyle="danger" onClick={this.props.toggleAddCreditsModal}>
        <FormattedMessage id="common.button.cancel"/>
      </Button>
    );
  };

  /**
   * Handles the form on submit.
   */
  onSubmit = async (values) => {
    const {user, dispatch} = this.props;

    const data = {
      sku: values.credits
    };

    await patch(BackendURLConstants.USER_CREDITS.replace('$id', user.username), JSON.stringify(data), 'application/json', true);

    dispatch(getUser(user.username));
    this.props.toggleAddCreditsModal();
  };

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {formatMessage} = this.props.intl;

    return (
      <div>
        <Form
          onSubmit={this.onSubmit}
          initialValues={this.state.initialValues}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="credits">
                    <ControlLabel>
                      <FormattedMessage id="label.bundle"/>
                    </ControlLabel>
                    <Field
                      name="credits"
                      component={Select}
                      options={BUNDLES.map(bundle => ({
                        value: bundle.sku,
                        label: formatMessage({id: `bundle.${bundle.key}.name`})
                      }))}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {this.renderCancelButton()}
                  {this.renderSubmitButton()}
                </Col>
              </Row>
            </form>
          )}/>
      </div>
    );
  }
}

export default connect()(injectIntl(AddCreditsForm));
