import Actions from "../constants/Actions";

export function getUsers(state = {
  userCollection: null
}, action) {
  if (action.type === Actions.GET_USERS) {
    return Object.assign({}, state, {
      userCollection: action.result
    });
  } else {
    return state;
  }
}

export function getUser(state = {
  userObject: null
}, action) {
  if (action.type === Actions.GET_USER) {
    return Object.assign({}, state, {
      userObject: action.result
    });
  } else {
    return state;
  }
}

export function getUserAppointments(state = {
  userAppointmentCollection: null
}, action) {
  if (action.type === Actions.GET_USER_APPOINTMENTS) {
    return Object.assign({}, state, {
      userAppointmentCollection: action.result
    });
  } else {
    return state;
  }
}

export function getUserBodyCompositionMeasurements(state = {
  userBodyCompositionMeasurementCollection: null
}, action) {
  if (action.type === Actions.GET_USER_BODY_COMPOSITION_MEASUREMENTS) {
    return Object.assign({}, state, {
      userBodyCompositionMeasurementCollection: action.result
    });
  } else {
    return state;
  }
}

export function getUserGirthMeasurements(state = {
  userGirthMeasurementCollection: null
}, action) {
  if (action.type === Actions.GET_USER_GIRTH_MEASUREMENTS) {
    return Object.assign({}, state, {
      userGirthMeasurementCollection: action.result
    });
  } else {
    return state;
  }
}

export function getUserMealSchedules(state = {
  userMealScheduleCollection: null
}, action) {
  if (action.type === Actions.GET_USER_MEAL_SCHEDULES) {
    return Object.assign({}, state, {
      userMealScheduleCollection: action.result
    });
  } else {
    return state;
  }
}

export function getUserMealSchedule(state = {
  userMealScheduleObject: null
}, action) {
  if (action.type === Actions.GET_USER_MEAL_SCHEDULE) {
    return Object.assign({}, state, {
      userMealScheduleObject: action.result
    });
  } else {
    return state;
  }
}
