import React from "react";
import {getUsername, hasRole} from "../../../utils/AuthenticationUtility";
import {getEmployeeRecipes} from "../../../actions";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import URIKeys from "../../../constants/URIKeys";
import DashboardHeader from "../../../partials/DashboardHeader";
import {PageHeader, PageHeaderTitle} from "../../../components/PageHeader";
import Loading from "../../../components/Modals/Loading";
import {Button, Col, Grid, Row, Table} from "react-bootstrap";
import {Pagination} from "@react-bootstrap/pagination";
import {Light} from "../../../components/Icons";
import ManageRecipeModal from "../../../components/Modals/ManageRecipeModal/ManageRecipeModal";
import USER_ROLES from "../../../constants/UserRoles";

/**
 * The {@code Recipes} class represents the dashboard recipes listing page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Recipes extends React.Component {

  /**
   * Creates a new instance of the {@link Recipes} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      recipeManagement: {
        showModal: false,
        recipe: null
      }
    };
  }

  /**
   * Runs after the component output has been rendered to the DOM.
   * <p>
   * Retrieves all recipes for the current employee.
   */
  componentDidMount() {
    const {dispatch} = this.props;
    // Only fetch this information for the proper authorities, otherwise you'll receive a 403.
    if (hasRole(USER_ROLES.NUTRITIONIST) || hasRole(USER_ROLES.ADMIN)) {
      dispatch(getEmployeeRecipes(getUsername(), 10, 0));
    }
  }

  /**
   * Renders the component
   *
   * @returns {*} the HTML representation of the component.
   */
  render() {
    const {intl: {formatMessage}, error, loading, employeeRecipeCollection} = this.props;

    if (!hasRole(USER_ROLES.NUTRITIONIST) && !hasRole(USER_ROLES.ADMIN)) {
      return <Redirect to={formatMessage({id: URIKeys.PAGE_NOT_FOUND})}/>
    }

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || employeeRecipeCollection === null) {
      return (
        <div id="dashboard-recipe-management-page" className="dashboard-page">
          <DashboardHeader/>
          <PageHeader>
            <PageHeaderTitle>
              <FormattedMessage id="page.recipe-management.title" tagName="h3"/>
              <FormattedMessage id="page.recipe-management.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <section className="recipes">
            <Loading/>
          </section>
        </div>
      )
    }

    return (
      <div id="dashboard-recipe-management-page" className="dashboard-page">
        <DashboardHeader/>
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="page.recipe-management.title" tagName="h3"/>
            <FormattedMessage id="page.recipe-management.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="recipes">
          <Grid>
            <Row>
              <Col xs={12}>
                <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                        onClick={() => this.toggleManageRecipeModal(true)}>
                  <Light icon="plus-square"/>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Table striped bordered condensed hover responsive>
                  <thead>
                  <tr>
                    <th><FormattedMessage id="label.name"/></th>
                    <th><FormattedMessage id="label.meal-type"/></th>
                    <th><FormattedMessage id="label.preparation-time"/></th>
                    <th><FormattedMessage id="label.people"/></th>
                    <th><FormattedMessage id="label.allergens"/></th>
                    <th>&#160;</th>
                  </tr>
                  </thead>
                  {this.renderTableBody(employeeRecipeCollection.recipes)}
                </Table>
              </Col>
            </Row>
          </Grid>
        </section>
        {employeeRecipeCollection && employeeRecipeCollection.recipes.length > 0 && this.renderPagination(employeeRecipeCollection.page)}
        <ManageRecipeModal show={this.state.recipeManagement.showModal}
                           recipe={this.state.recipeManagement.recipe}
                           toggleManageRecipeModal={() => this.toggleManageRecipeModal(false)}/>
      </div>
    )
  }

  /**
   * Renders given {@code recipes} in the table body.
   *
   * @param recipes the recipes
   * @returns {*} the table body
   */
  renderTableBody(recipes) {
    const {intl: {formatMessage}} = this.props;
    let result;
    if (recipes.length === 0) {
      result = (<tbody>
      <tr>
        <td colSpan={6}>
          <FormattedMessage id="page.recipe-management.empty"/>
        </td>
      </tr>
      </tbody>)
    } else {
      result = (<tbody>
      {recipes.map((recipe) => (
        <tr key={recipe.id}>
          <td>{recipe.name}</td>
          <td>{formatMessage({id: 'recipe.type.' + recipe.meal})}</td>
          <td>{recipe.preparationTime}</td>
          <td>{recipe.people}</td>
          {this.renderAllergens(recipe)}
          <td className="text-center">
            <Button bsStyle="primary" bsSize="small" onClick={() => this.toggleManageRecipeModal(true, recipe)}>
              <Light icon="edit"/>
            </Button>
          </td>
        </tr>
      ))}
      </tbody>)
    }
    return result;
  }

  /**
   * Renders the {@code allergens}.
   *
   * @param recipe
   * @returns {*}
   */
  renderAllergens(recipe) {
    let result = <td>&nbsp;</td>;
    if (recipe.allergens) {
      result = (<td>
        <ul className="list-inline">
          {recipe.allergens.map((allergen, i) => (
            <li key={i}>
              <FormattedMessage id={'allergen.type.' + allergen}/>
              {(i + 1) < recipe.allergens.length ? ',' : ''}
            </li>
          ))}
        </ul>
      </td>);
    }
    return result;
  }

  /**
   * Renders the pagination links
   *
   * @param page the page information
   * @returns {undefined|*} the pagination links
   */
  renderPagination(page) {
    let result = undefined;
    if (page) {
      return (<section className="pagination-links">
        <Grid>
          <Row>
            <Col xs={12}>
              <Pagination bsSize="medium"
                          items={(page.totalPages)}
                          activePage={page.number + 1}
                          onSelect={this.togglePage}
                          prev
                          next
                          first
                          last
                          ellipsis
                          boundaryLinks
                          maxButtons={5}/>
            </Col>
          </Row>
        </Grid>
      </section>);
    }
    return result;
  }

  /**
   * Toggles a page in the paginated list.
   *
   * @param page the page
   */
  togglePage = (page) => {
    const {dispatch} = this.props;
    dispatch(getEmployeeRecipes(getUsername(), 10, --page));
  };

  /**
   * Toggles the manage recipe modal.
   *
   * @param value the value
   * @param recipe the recipe which needs to be edited
   */
  toggleManageRecipeModal = (value = true, recipe = null) => {
    this.setState({
      recipeManagement: {
        showModal: value,
        recipe: recipe
      }
    });
  };
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, employeeRecipes} = state;
  return {
    error: common.error,
    loading: common.loading,
    employeeRecipeCollection: employeeRecipes.employeeRecipeCollection
  };
}

export default connect(mapStateToProps)(injectIntl((Recipes)));
