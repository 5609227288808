import React, {useState} from "react";
import {Button, Col, Grid, Row} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from "react-intl";
import IntakeForm from "../Forms/IntakeForm/IntakeForm";
import {NUTRITIONIST_CONSULTANCY_STATUS} from "../../constants/NutritionistConsultancyStatus";
import ConfirmationModal from "../Modals/ConfirmationModal/ConfirmationModal";
import {InsufficientCreditsModal} from "../Modals";

const MealScheduleRequest = ({intl: {formatMessage}, onSubmit, user, facility}) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] = useState(false);
    const [submittedValues, setSubmittedValues] = useState(undefined);

    const submitInterceptor = (values) => {
      setSubmittedValues(values);

      if (facility.appointmentPrice >= user.credits) {
        setShowConfirmationModal(false);
        setShowInsufficientCreditsModal(true);
      } else {
        setShowInsufficientCreditsModal(false);
        setShowConfirmationModal(true);
      }
    };

    const cancelSubmit = () => {
      setShowConfirmationModal(false);
      setShowInsufficientCreditsModal(false);
    };

    const confirmSubmit = () => {
      onSubmit(submittedValues);
      setShowConfirmationModal(false);
      setShowInsufficientCreditsModal(false);
      setSubmittedValues(undefined);
    };

    const {nutritionistConsultancyStatus} = user;
  const {appointmentPrice} = facility;

    const questions = [{
      name: 'question1',
      label: formatMessage({id: 'meal-schedule.intake-form.question-1'}),
      answers: [
        formatMessage({id: 'meal-schedule.intake-form.question-1.answer-1'}),
        formatMessage({id: 'meal-schedule.intake-form.question-1.answer-2'}),
        formatMessage({id: 'meal-schedule.intake-form.question-1.answer-3'}),
        formatMessage({id: 'meal-schedule.intake-form.question-1.answer-4'}),
        formatMessage({id: 'meal-schedule.intake-form.question-1.answer-5'}),
        formatMessage({id: 'meal-schedule.intake-form.question-1.answer-6'}),
        formatMessage({id: 'meal-schedule.intake-form.question-1.answer-7'}),
        formatMessage({id: 'meal-schedule.intake-form.question-1.answer-8'}),
        formatMessage({id: 'meal-schedule.intake-form.question-1.answer-9'})
      ],
      commentLabel: formatMessage({id: 'meal-schedule.intake-form.comment'}),
    }, {
      name: 'question2',
      label: formatMessage({id: 'meal-schedule.intake-form.question-2'}),
      answers: [
        formatMessage({id: 'meal-schedule.intake-form.question-2.answer-1'}),
        formatMessage({id: 'meal-schedule.intake-form.question-2.answer-2'}),
        formatMessage({id: 'meal-schedule.intake-form.question-2.answer-3'}),
        formatMessage({id: 'meal-schedule.intake-form.question-2.answer-4'}),
        formatMessage({id: 'meal-schedule.intake-form.question-2.answer-5'}),
        formatMessage({id: 'meal-schedule.intake-form.question-2.answer-6'}),
        formatMessage({id: 'meal-schedule.intake-form.question-2.answer-7'}),
      ],
      commentLabel: formatMessage({id: 'meal-schedule.intake-form.comment'}),
    }, {
      name: 'question3',
      label: formatMessage({id: 'meal-schedule.intake-form.question-3'}),
      answers: [
        formatMessage({id: 'meal-schedule.intake-form.question-3.answer-1'}),
        formatMessage({id: 'meal-schedule.intake-form.question-3.answer-2'}),
        formatMessage({id: 'meal-schedule.intake-form.question-3.answer-3'}),
        formatMessage({id: 'meal-schedule.intake-form.question-3.answer-4'}),
        formatMessage({id: 'meal-schedule.intake-form.question-3.answer-5'}),
        formatMessage({id: 'meal-schedule.intake-form.question-3.answer-6'}),
        formatMessage({id: 'meal-schedule.intake-form.question-3.answer-7'}),
        formatMessage({id: 'meal-schedule.intake-form.question-3.answer-8'}),
      ],
      commentLabel: formatMessage({id: 'meal-schedule.intake-form.comment'}),
    }, {
      name: 'question4',
      label: formatMessage({id: 'meal-schedule.intake-form.question-4'}),
      answers: [
        formatMessage({id: 'meal-schedule.intake-form.question-4.answer-1'}),
        formatMessage({id: 'meal-schedule.intake-form.question-4.answer-2'}),
        formatMessage({id: 'meal-schedule.intake-form.question-4.answer-3'}),
        formatMessage({id: 'meal-schedule.intake-form.question-4.answer-4'}),
        formatMessage({id: 'meal-schedule.intake-form.question-4.answer-5'}),
        formatMessage({id: 'meal-schedule.intake-form.question-4.answer-6'}),
        formatMessage({id: 'meal-schedule.intake-form.question-4.answer-7'}),
      ],
      commentLabel: formatMessage({id: 'meal-schedule.intake-form.comment'}),
    }, {
      name: 'question5',
      label: formatMessage({id: 'meal-schedule.intake-form.question-5'}),
      answers: [
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-1'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-2'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-3'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-4'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-5'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-6'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-7'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-8'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-9'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-10'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-11'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-12'}),
        formatMessage({id: 'meal-schedule.intake-form.question-5.answer-13'})
      ],
      commentLabel: formatMessage({id: 'meal-schedule.intake-form.comment'}),
    }, {
      name: 'question6',
      label: formatMessage({id: 'meal-schedule.intake-form.question-6'}),
      answers: [
        formatMessage({id: 'meal-schedule.intake-form.question-6.answer-1'}),
        formatMessage({id: 'meal-schedule.intake-form.question-6.answer-2'}),
        formatMessage({id: 'meal-schedule.intake-form.question-6.answer-3'}),
        formatMessage({id: 'meal-schedule.intake-form.question-6.answer-4'}),
        formatMessage({id: 'meal-schedule.intake-form.question-6.answer-5'}),
        formatMessage({id: 'meal-schedule.intake-form.question-6.answer-6'}),
        formatMessage({id: 'meal-schedule.intake-form.question-6.answer-7'}),
      ],
      commentLabel: formatMessage({id: 'meal-schedule.intake-form.comment'}),
    }, {
      name: 'question7',
      label: formatMessage({id: 'meal-schedule.intake-form.question-7'}),
      answers: [
        formatMessage({id: 'meal-schedule.intake-form.question-7.answer-1'}),
        formatMessage({id: 'meal-schedule.intake-form.question-7.answer-2'}),
        formatMessage({id: 'meal-schedule.intake-form.question-7.answer-3'}),
        formatMessage({id: 'meal-schedule.intake-form.question-7.answer-4'}),
        formatMessage({id: 'meal-schedule.intake-form.question-7.answer-5'}),
        formatMessage({id: 'meal-schedule.intake-form.question-7.answer-6'}),
      ],
      commentLabel: formatMessage({id: 'meal-schedule.intake-form.comment'}),
    }];

    let componentDependingOnStatus;

    if (nutritionistConsultancyStatus === NUTRITIONIST_CONSULTANCY_STATUS.INITIAL_APPOINTMENT_PLANNED) {
      componentDependingOnStatus = (
        <Row>
          <FormattedMessage id="meal-schedule.initial-appointment-planned.text"/>
        </Row>
      )
    } else {
      componentDependingOnStatus = (
        <>
          <Row>
            <FormattedMessage id="meal-schedule.intake-form.title" tagName="h5"/>
          </Row>
          <Row>
            <FormattedMessage id="meal-schedule.intake-form.intro" tagName="p"/>
          </Row>
          <Row>
            <IntakeForm
              edit
              questions={questions}
              onSubmit={submitInterceptor}
            />
          </Row>
          <ConfirmationModal show={showConfirmationModal}>
            <FormattedMessage id="appointments.page.section.intake-form.subtitle" tagName="h4"/>
            <FormattedHTMLMessage id="appointments.page.section.intake-form.paragraph"
                                  values={{
                                    credits: appointmentPrice
                                  }}/>
            <Row>
              <Col xs={12}>
                <Button className="pull-right" bsStyle="danger" onClick={cancelSubmit}>
                  <FormattedMessage id="common.button.cancel"/>
                </Button>
                <Button className="pull-right" bsStyle="primary" onClick={confirmSubmit}>
                  <FormattedMessage id="common.button.confirm"/>
                </Button>
              </Col>
            </Row>
          </ConfirmationModal>
          <InsufficientCreditsModal show={showInsufficientCreditsModal}>
            <FormattedHTMLMessage id="modal.insufficient-credits.paragraph.meal-schedule"
                                  values={{
                                    balance: user.credits,
                                    price: appointmentPrice
                                  }}/>
            <Button bsStyle="danger" onClick={cancelSubmit}>
              <FormattedMessage id="common.button.cancel"/>
            </Button>
          </InsufficientCreditsModal>
        </>
      );
    }

    return (
      <Grid className="meal-schedule-wrapper">
        {componentDependingOnStatus}
      </Grid>
    );
  }
;

export default injectIntl(MealScheduleRequest);
