import React from "react";
import {Redirect} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {login, resetLoginForm} from "../../actions";
import RequiredAnnotation from "./RequiredAnnotation";
import {Loading} from "../../components/Modals";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import {getValidationState, validate} from "../../utils/ValidationUtility";
import ValidationTypes from "../../constants/ValidationTypes";
import URIKeys from "../../constants/URIKeys";
import {hasRole} from "../../utils/AuthenticationUtility";
import {getCookie} from "../../utils/CookieUtility";
import USER_ROLES from "../../constants/UserRoles";

/**
 * The {@code LoginForm} class represents a login form.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class LoginForm extends React.Component {

  /** 
   * The class constructor. 
   */
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: {
        value: '',
        state: undefined,
        message: undefined
      },
      password: {
        value: '',
        state: undefined,
        message: undefined
      },
      rememberMe: {
        value: getCookie('remember-me')
      }
    };
  }

  /**
   * Resets the form.
   */
  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch(resetLoginForm());
  }

  /**npm sta
   * Handle the change event for the email address input.
   *
   * @param e the event
   */
  handleEmailAddressChange = (e) => {
    this.validateEmailAddress(e.target.value);
  };

  /**
   * Validates the given {@code emailAddress}.
   *
   * @param emailAddress the email address
   */
  validateEmailAddress(emailAddress) {
    let message = validate(emailAddress, [ValidationTypes.REQUIRED, ValidationTypes.EMAIL_ADDRESS]);
    let state = getValidationState(message);
    this.setState({
      emailAddress: {
        value: emailAddress,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handle the change event for the subject input.
   *
   * @param e the event
   */
  handlePasswordChange = (e) => {
    this.validatePassword(e.target.value);
  };

  /**
   * Handle the change event for the remember me checkbox.
   */
  handleRememberMeClick = () => {
    this.setState(prevState => ({
      rememberMe: {
        value: !prevState.rememberMe.value
      }
    }));
  };

  /**
   * Validates the given {@code password}.
   *
   * @param password the password
   */
  validatePassword(password) {
    let message = validate(password, [ValidationTypes.REQUIRED]);
    let state = getValidationState(message);
    this.setState({
      password: {
        value: password,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handles the form on submit.
   */
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      const {dispatch} = this.props;

      const data = new URLSearchParams();
      data.append('grant_type', 'password');
      data.append('username', this.state.emailAddress.value);
      data.append('password', this.state.password.value);

      dispatch(login(data, this.state.rememberMe.value));
    }
  };

  /**
   * Checks whether the form is valid.
   *
   * @returns {boolean} {@code true} in case the form is valid, otherwise {@code false}
   */
  isValid() {
    this.validateEmailAddress(this.state.emailAddress.value);
    this.validatePassword(this.state.password.value);

    return this.state.emailAddress.state === null
      && this.state.password.state === null;
  }

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {error, loading, success, message} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    let loadingModal;
    if (loading > 0) {
      loadingModal = <Loading/>;
    }

    if (success) {
      if (hasRole(USER_ROLES.USER)) {
        return (
          <Redirect to={formatMessage({id: URIKeys.DASHBOARD_PERFORMANCE})}/>
        );
      } else {
        return (
          <Redirect to={formatMessage({id: URIKeys.DASHBOARD_OVERVIEW})}/>
        );
      }
    }

    let emailAddressState = this.state.emailAddress.state;

    let passwordState = this.state.password.state;
    let passwordMessage = this.state.password.message;

    if (message != null) {
      emailAddressState = 'error';

      passwordState = 'error';
      passwordMessage = formatMessage({id: message});
    }

    return (
      <form
        id="login-form"
        onSubmit={this.handleSubmit}
        noValidate="noValidate">
        <Row>
          <Col xs={12}>
            <h3>
              <FormattedMessage id="login.page.title"/>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormGroup controlId="emailAddress" validationState={emailAddressState}>
              <ControlLabel>
                <FormattedMessage id="label.email-address"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <FormControl
                type="text"
                value={this.state.emailAddress.value}
                onChange={this.handleEmailAddressChange}/>
              <HelpBlock>{this.state.emailAddress.message}
              </HelpBlock>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormGroup controlId="password" validationState={passwordState}>
              <ControlLabel>
                <FormattedMessage id="label.password"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <FormControl
                type="password"
                value={this.state.password.value}
                onChange={this.handlePasswordChange}/>
              <HelpBlock>{passwordMessage}
              </HelpBlock>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormGroup controlId="rememberMe">
              <Checkbox onClick={this.handleRememberMeClick}>
                <FormattedMessage
                  id="label.remember-me"/>
              </Checkbox>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button type="submit" bsStyle="primary">
              <FormattedMessage id="form.button.login"/>
            </Button>
            <LocalizedLink id={URIKeys.FORGOT_PASSWORD} className="pull-right">
              <FormattedMessage id="login.page.link.forgot-password"/>
            </LocalizedLink>
          </Col>
        </Row>
        {loadingModal}
      </form>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, login} = state;
  return {
    error: common.error,
    loading: common.loading,
    success: login.success,
    message: login.message,
  };
}

export default connect(mapStateToProps)(injectIntl(LoginForm));
