import React from "react";
import SVG from "../../images/icons/fa-solid.svg";

/**
 * The {@code Solid} class represents a Font Awesome icon.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Solid extends React.Component {

    /** 
     * The class constructor. 
     */
    constructor(props) {
        super(props);

        let classNames = 'icon';
        if (this.props.classNames !== undefined) {
            classNames += ' ' + this.props.classNames;
        }

        this.state = {
            classNames: classNames
        };
    }

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <svg className={this.state.classNames}>
                <use xlinkHref={SVG + '#' + this.props.icon}>&#160;</use>
            </svg>
        );
    }
}

export default Solid;
