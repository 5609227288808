import React from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Col, Grid, Row} from "react-bootstrap";
import ScrollableAnchor from "react-scrollable-anchor";
import {BackLink, PageHeader, PageHeaderTitle} from "../../components/PageHeader";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import {Light} from "../../components/Icons";
import {
  AllergenData,
  BasicInfo,
  BasicInfoEntry,
  MobileRecipeInfo,
  NutritionalValues,
  NutritionalValuesData,
  NutritionalValuesTitle,
  RecipeDetails,
  RecipeInfo,
  RecipeInfoWrapper
} from "../../components/Recipe";
import {ParallaxImage} from "../../components/Image";
import {AllergenList} from "../../components/AllergenList";
import {IngredientList} from "../../components/IngredientList";
import {InstructionList} from "../../components/InstructionList";
import {Loading} from "../../components/Modals";
import URIKeys from "../../constants/URIKeys";
import {getRecipe} from "../../actions";

/**
 * The {@code Recipe} class represents the dashboard recipe page.
 Recipes
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Recipe extends React.Component {

  /**
   * Gets the recipe.
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getRecipe(this.props.match.params.id));
  }

  renderPeopleLabel = () => {
    const {recipeObject} = this.props;
    const {formatMessage} = this.props.intl;

    let result;
    if (recipeObject.people === 1) {
      result = formatMessage({id: 'recipe.page.label.person'})
    } else {
      result = formatMessage({id: 'recipe.page.label.people'})
    }

    return result;
  };

  renderTip = () => {
    const {recipeObject} = this.props;
    return <p className="tip"><FormattedMessage id="recipe.page.section-two.tip"/>: {recipeObject.tip}
    </p>;
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, recipeObject} = this.props;
    const {formatMessage} = this.props.intl;

    if (error || (loading === 0 && recipeObject && !recipeObject.visible)) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !recipeObject) {
      return (
        <div id="dashboard-recipe-page">
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    return (
      <div id="dashboard-recipe-page">
        <PageHeader>
          <BackLink>
            <LocalizedLink id={URIKeys.FOOD_CONSULTANT}>
              <Light icon="chevron-left"/>&#160;
              <FormattedMessage id="services.section.food-consultant.title"/>
            </LocalizedLink>
          </BackLink>
          <PageHeaderTitle>
            <h3>{recipeObject.name}</h3>
            <p>{recipeObject.description}</p>
          </PageHeaderTitle>
        </PageHeader>
        <ParallaxImage lowRes={require('../../images/recipes/' + recipeObject.id + '-tiny.jpeg')}
                       highRes={require('../../images/recipes/' + recipeObject.id + '.jpeg')}>
          <RecipeInfoWrapper>
            <RecipeInfo>
              <BasicInfo>
                <BasicInfoEntry icon="cauldron">
                  <FormattedMessage id={'recipe.type.' + recipeObject.meal}/>
                </BasicInfoEntry>
                <BasicInfoEntry icon="user">
                  {recipeObject.people} {this.renderPeopleLabel()}
                </BasicInfoEntry>
                <BasicInfoEntry icon="utensils">
                  {recipeObject.nutritionalValue.calories} kcal <a
                  href="#nutritionalValues">voedingswaarden</a>
                </BasicInfoEntry>
                <BasicInfoEntry icon="clock">
                  {recipeObject.preparationTime} <FormattedMessage id="recipe.page.label.minutes"/>
                </BasicInfoEntry>
              </BasicInfo>
              <AllergenList allergens={recipeObject.allergens}/>
            </RecipeInfo>
          </RecipeInfoWrapper>
        </ParallaxImage>
        <MobileRecipeInfo>
          <BasicInfo>
            <BasicInfoEntry icon="cauldron">
              <FormattedMessage id={'recipe.type.' + recipeObject.meal}/>
            </BasicInfoEntry>
            <BasicInfoEntry icon="user">
              {recipeObject.people} {this.renderPeopleLabel()}
            </BasicInfoEntry>
            <BasicInfoEntry icon="utensils">
              {recipeObject.nutritionalValue.calories} kcal <a
              href="#nutritionalValues"><FormattedMessage id="recipe.page.link.nutritionalValues"/></a>
            </BasicInfoEntry>
            <BasicInfoEntry icon="clock">
              {recipeObject.preparationTime} <FormattedMessage id="recipe.page.label.minutes"/>
            </BasicInfoEntry>
          </BasicInfo>
          <AllergenList allergens={recipeObject.allergens}/>
        </MobileRecipeInfo>
        <RecipeDetails>
          <Col xs={12} sm={4} className="ingredients">
            <FormattedMessage id="recipe.page.section-one.title" tagName="h4"/>
            <IngredientList ingredients={recipeObject.ingredients}/>
          </Col>
          <Col xs={12} sm={8} className="instructions">
            <FormattedMessage id="recipe.page.section-two.title" tagName="h4"/>
            <InstructionList instructions={recipeObject.instructions}/>
            {this.renderTip()}
          </Col>
        </RecipeDetails>
        <ScrollableAnchor id={'nutritionalValues'}>
          <NutritionalValues>
            <NutritionalValuesTitle quantity={recipeObject.nutritionalValue.quantity}/>
            <Row>
              <NutritionalValuesData nutritionalValues={recipeObject.nutritionalValue}/>
              <AllergenData allergens={recipeObject.allergens}/>
            </Row>
          </NutritionalValues>
        </ScrollableAnchor>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getRecipe} = state;
  return {
    error: common.error,
    loading: common.loading,
    recipeObject: getRecipe.recipeObject,
  };
}

export default connect(mapStateToProps)(injectIntl(Recipe));
