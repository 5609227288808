import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {injectIntl} from "react-intl";
import {Loading} from "../../components/Modals/index";
import URIKeys from "../../constants/URIKeys";
import {logout} from "../../actions";

/**
 * The {@code Logout} class represents the logout page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Logout extends React.Component {

    /** 
     * Resets the form when the component is added to the DOM. 
     */
    componentDidMount() {
        const {dispatch} = this.props;
        dispatch(logout());
    }

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        const {loading, success} = this.props;
        const {formatMessage} = this.props.intl;

        if (loading > 0) {
            return (
                <div>
                    <Loading/>
                </div>
            )
        }

        if (success) {
          window.location.href = formatMessage({id: URIKeys.HOME});
        }

        return (
            <div>&#160;</div>
        );
    }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
    const {common, logout} = state;
    return {
        loading: common.loading,
        success: logout.success
    };
}

export default connect(mapStateToProps)(injectIntl(Logout));
