import React from "react";
import DashboardHeader from "../../../partials/DashboardHeader";
import {Button, Col, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {Redirect} from "react-router-dom";
import URIKeys from "../../../constants/URIKeys";
import {connect} from "react-redux";
import Loading from "../../../components/Modals/Loading";
import {getCart} from "../../../actions/Cart";
import {Image} from "../../../components/Image";
import {Light} from "../../../components/Icons";
import {patch, remove} from "../../../utils/FetchUtility";
import BackendURLConstants from "../../../constants/BackendURLConstants";
import {getLanguage} from "../../../utils/LanguageUtility";
import LocalizedLink from "../../../components/Intl/LocalizedLink";
import InvalidCartModal from "../../../components/Modals/InvalidCartModal/InvalidCartModal";

/**
 * The {@code ShoppingCart} class represents the dashboard shopping cart page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ShoppingCart extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      showWarningModal: false,
      warningMessage: undefined,
    };
  }

  componentDidMount() {
    const {dispatch} = this.props;

    dispatch(getCart());
  }

  deleteEntry = async (entry) => {
    const {dispatch} = this.props;
    await remove(BackendURLConstants.CART_ENTRY.replace('$id', entry.id), true);
    dispatch(getCart());
  };

  updateQuantity = async (entry, e) => {
    const {dispatch} = this.props;

    const data = {
      quantity: parseInt(e.target.value),
      deliveryDate: entry.deliveryDate,
      product: {
        id: entry.product.id,
        sku: entry.product.sku
      }
    };

    try {
      await patch(BackendURLConstants.CART_ENTRY.replace('$id', entry.id), JSON.stringify(data), 'application/json', true);
      dispatch(getCart());
    } catch (e) {
      let errorMessage = 'modal.invalid-cart.generic';
      if ('NO_STOCK' === e) {
        errorMessage = 'modal.invalid-cart.no-stock';
      } else if ('DELIVERY_DATE') {
        errorMessage = 'modal.invalid-cart.delivery-date'
      }

      this.setState({
        showWarningModal: true,
        warningMessage: errorMessage
      });
    }
  };

  updateDeliveryDate = async (entry, e) => {
    const {dispatch} = this.props;

    const data = {
      quantity: entry.quantity,
      deliveryDate: e.target.value,
      product: {
        id: entry.product.id,
        sku: entry.product.sku
      }
    };

    try {
      await patch(BackendURLConstants.CART_ENTRY.replace('$id', entry.id), JSON.stringify(data), 'application/json', true);
      dispatch(getCart());
    } catch (e) {
      let errorMessage = 'modal.invalid-cart.generic';
      if ('NO_STOCK' === e) {
        errorMessage = 'modal.invalid-cart.no-stock';
      } else if ('DELIVERY_DATE') {
        errorMessage = 'modal.invalid-cart.delivery-date'
      }

      this.setState({
        showWarningModal: true,
        warningMessage: errorMessage
      });
    }
  };

  cancel = () => {
    const {dispatch} = this.props;
    this.setState({
      showWarningModal: false,
      warningMessage: undefined
    });
    dispatch(getCart());
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, cartObject} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !cartObject) {
      return (
        <div id="dashboard-shopping-cart-page" className="dashboard-page">
          <DashboardHeader/>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    let tax = 0;
    for (const taxEntry of cartObject.taxes) {
      tax += taxEntry.value;
    }

    if (cartObject.entries.length === 0) {
      return (
        <div id="dashboard-shopping-cart-page" className="dashboard-page">
          <DashboardHeader/>
          <Grid>
            <section className="shopping-cart">
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="page.shopping-cart.title" tagName="h3"/>
                  <FormattedMessage id="page.shopping-cart.empty-paragraph" tagName="p"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <LocalizedLink id={URIKeys.DASHBOARD_SHOP} className="btn btn-primary">
                    <Light icon="chevron-left"/>
                    <FormattedMessage id="modal.add-to-cart.button.show-cart"/>
                  </LocalizedLink>
                </Col>
              </Row>
            </section>
          </Grid>
        </div>
      );
    }

    return (
      <div id="dashboard-shopping-cart-page" className="dashboard-page">
        <DashboardHeader/>
        <Grid>
          <section className="shopping-cart">
            <Row>
              <Col xs={12}>
                <FormattedMessage id="page.shopping-cart.title" tagName="h3"/>
              </Col>
            </Row>
            {cartObject.entries.map((entry) => (
              <Row key={entry.id} className="cart-entry">
                <Col xs={6} sm={5}>
                  <Row>
                    <Col xs={2}>
                      {this.renderImage(entry.product)}
                    </Col>
                    <Col xs={10}>
                      <span className="cart-entry-name">{entry.product.name}</span>
                      <span className="cart-entry-sku">SKU: {entry.product.sku}</span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} sm={7}>
                  <Row>
                    <Col xs={1}>
                      <Button bsStyle="primary" onClick={() => this.deleteEntry(entry)}>
                        <Light icon="trash-alt"/>
                      </Button>
                    </Col>
                    <Col xs={5}>
                      {'TAKE_AWAY' === entry.product.type && (
                        <FormGroup controlId="deliveryDate">
                          <FormControl componentClass="select"
                                       onChange={(e) => this.updateDeliveryDate(entry, e)}
                                       defaultValue={entry.deliveryDate}>
                            {entry.availableDeliveryDates.map((available) => (
                              <option value={available}
                                      key={available}>{new Date(available).toLocaleDateString(getLanguage(), {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              })}</option>
                            ))}
                          </FormControl>
                        </FormGroup>
                      )}
                    </Col>
                    <Col xs={2}>
                      <FormGroup controlId="quantity">
                        <FormControl componentClass="select"
                                     onChange={(e) => this.updateQuantity(entry, e)}
                                     defaultValue={entry.quantity}>
                          {quantities.map((quantity) => (
                            <option value={quantity} key={quantity}>{quantity}</option>
                          ))}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      {this.renderPrice(cartObject, entry)}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
            <div className="shopping-cart-totals">
              <Row className="shopping-cart-totals-subtotal">
                <Col xs={12}>
                  <Row>
                    <Col xs={6} sm={8}>
                      <FormattedMessage id="label.subtotal"/>
                    </Col>
                    <Col xs={6} sm={4}>
                      {this.renderSubtotal(cartObject)}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.renderTax(cartObject, tax)}
              {this.renderDeliveryCosts(cartObject)}
              <Row className="shopping-cart-totals-total">
                <Col xs={12}>
                  <Row>
                    <Col xs={6} sm={8}>
                      <FormattedMessage id="label.total"/>
                    </Col>
                    <Col xs={6} sm={4}>
                      {this.renderTotal(cartObject)}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Row>
              <Col xs={12}>
                <LocalizedLink id={URIKeys.DASHBOARD_CHECKOUT} className="btn btn-primary pull-right">
                  <Light icon="chevron-right"/>
                  <FormattedMessage id="modal.add-to-cart.button.checkout"/>
                </LocalizedLink>
              </Col>
            </Row>
          </section>
        </Grid>
        <InvalidCartModal show={this.state.showWarningModal}>
          <FormattedMessage id={this.state.warningMessage} tagName="p"/>
          <Button bsStyle="warning" onClick={() => this.cancel()}>
            <FormattedMessage id="common.button.cancel"/>
          </Button>
        </InvalidCartModal>
      </div>
    );
  }

  renderImage = (product) => {
    if (product.images && product.images[0]) {
      return (
        <img className="img-responsive" src={'data:image/jpg;base64,' + product.images[0].url}
             alt={product.images[0].name}/>
      );
    } else {
      return (
        <Image lowRes={require('../../../images/products/placeholder-tiny.jpeg')}
               highRes={require('../../../images/products/placeholder.jpeg')}
               classNames="img-responsive"/>
      );
    }
  }

  renderPrice = (cart, entry) => {
    if (cart.containsCreditBundles) {
      return (
        <span className="cart-entry-subtotal">
                        €{entry.subtotal.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
                      </span>
      );
    }

    return (
      <span className="cart-entry-subtotal">
        {entry.subtotal.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}&#160;credit(s)
      </span>
    );
  }

  renderSubtotal = (cart) => {
    if (cart.containsCreditBundles) {
      return (
        <div>
          €{cart.subtotal.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
        </div>
      )
    }

    return (
      <div>
        {cart.subtotal.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}&#160;credit(s)
      </div>
    )
  }

  renderTax = (cart, tax) => {
    if (cart.containsCreditBundles) {
      return (
        <Row className="shopping-cart-totals-tax">
          <Col xs={12}>
            <Row>
              <Col xs={6} sm={8}>
                <FormattedMessage id="label.tax"/>
              </Col>
              <Col xs={6} sm={4}>
                €{tax.toLocaleString(getLanguage(), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return undefined;
  }

  renderDeliveryCosts = (cart) => {
    if (cart.containsShipToProducts) {
      return (
        <Row className="shopping-cart-totals-delivery-costs">
          <Col xs={12}>
            <Row>
              <Col xs={6} sm={8}>
                <FormattedMessage id="label.delivery-costs"/>
              </Col>
              <Col xs={6} sm={4}>
                {cart.deliveryCosts.toLocaleString(getLanguage(), {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}&#160;credit(s)
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return undefined;
  }

  renderTotal = (cart) => {
    if (cart.containsCreditBundles) {
      return (
        <div>
          €{cart.total.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
        </div>
      );
    }

    return (
      <div>
        {cart.total.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}&#160;credit(s)
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {common, getCart} = state;
  return {
    error: common.error,
    loading: common.loading,
    cartObject: getCart.cartObject
  };
}

export default connect(mapStateToProps)(injectIntl(ShoppingCart));
