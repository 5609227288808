import React from "react";
import {FormattedMessage} from "react-intl";
import VALIDATION_TYPES from "../constants/ValidationTypes";
import {printInputDateInISODateTime} from "./DateTimeUtility";
import BackendURLConstants from "../constants/BackendURLConstants";
import {getOne} from "./FetchUtility";

/**
 * Checks if the given {@code value} meets the given {@code requirements}.
 *
 * @param value the value
 * @param requirements the requirements
 * @returns {null|string} a validation message in case the given {@code value} is invalid, otherwise {@code null}
 */
export function validate(value, requirements) {
  let result = null;
  for (let i = 0; i < requirements.length; i++) {
    switch (true) {
      case !(result) && requirements[i] === VALIDATION_TYPES.REQUIRED:
        result = validateRequired(value);
        continue;
      case !(result) && requirements[i] === VALIDATION_TYPES.NAME:
        result = validateName(value);
        continue;
      case !(result) && requirements[i] === VALIDATION_TYPES.EMAIL_ADDRESS:
        result = validateEmailAddress(value);
        continue;
      case !(result) && requirements[i] === VALIDATION_TYPES.PASSWORD:
        result = validatePassword(value);
        continue;
      case !(result) && requirements[i] === VALIDATION_TYPES.PHONE_NUMBER:
        result = validatePhoneNumber(value);
        continue;
      case !(result) && requirements[i] === VALIDATION_TYPES.TEXT:
        result = validateText(value);
        continue;
      case !(result) && requirements[i] === VALIDATION_TYPES.DATE:
        result = validateDate(value);
        continue;
      case !(result) && requirements[i] === VALIDATION_TYPES.TERMS_AND_CONDITIONS:
        result = validateTermsAndConditions(value);
        continue;
      default:
        break;
    }
  }
  return result;
}

/**
 * Checks if the given password {@code p1} and {@code p2} are equal.
 *
 * @param p1 the first password
 * @param p2 the second password
 * @returns {null|string} a validation message in case the given password {@code p1} and {@code p2} are not equal,
 * otherwise {@code null}
 */
export function validatePasswords(p1, p2) {
  return (p1.localeCompare(p2) === 0) ? null : <FormattedMessage id="form.validation-message.password-confirmation"/>
}

/**
 * Returns the validation state based on the content of the given {@code message}.
 *
 * @param message the message
 * @returns {null|string} the validation state
 */
export function getValidationState(message) {
  let state = null;
  if (message) {
    state = 'error';
  }
  return state;
}

/**
 * Checks if the given {@code value} is not null or empty.
 *
 * @param value the value
 * @returns {null|string} a validation message in case the given {@code value} is invalid, otherwise {@code null}
 */
function validateRequired(value) {
  return value
    ? null
    : <FormattedMessage id="form.validation-message.required"/>
}

/**
 * Checks if the given {@code value} is a valid email address.
 *
 * @param value the value
 * @returns {null|string} a validation message in case the given {@code value} is invalid, otherwise {@code null}
 */
function validateEmailAddress(value) {
  return value && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i.test(value)
    ? <FormattedMessage id="form.validation-message.email-address"/>
    : null;
}

/**
 * Checks if the given {@code value} is a valid password.
 *
 * @param value the value
 * @returns {null|string} a validation message in case the given {@code value} is invalid, otherwise {@code null}
 */
function validatePassword(value) {
  return value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d-_@$!%*#?&]{8,}$/i.test(value)
    ? <FormattedMessage id="form.validation-message.password"/>
    : null;
}

/**
 * Checks if the given {@code value} is a valid phone number.
 *
 * @param value the value
 * @returns {null|string} a validation message in case the given {@code value} is invalid, otherwise {@code null}
 */
function validatePhoneNumber(value) {
  return value && !/^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/i.test(value)
    ? <FormattedMessage id="form.validation-message.phone-number"/>
    : null;
}

/**
 * Checks if the given {@code value} is a valid name.
 *
 * @param value the value
 * @returns {null|string} a validation message in case the given {@code value} is invalid, otherwise {@code null}
 */
function validateName(value) {
  return value && !/^.[a-zA-Z][a-zA-Z '.-]+$/.test(value)
    ? <FormattedMessage id="form.validation-message.name"/>
    : null;
}

/**
 * Checks if the given {@code value} is valid text.
 *
 * @param value the value
 * @returns {null|string} a validation message in case the given {@code value} is invalid, otherwise {@code null}
 */
function validateText(value) {
  return value && !/^(?=.*[a-zA-Z0-9])[\w\n -+=:;.,!?"'/€]+$/i.test(value)
    ? <FormattedMessage id="form.validation-message.text"/>
    : null;
}

/**
 * Checks if the given {@code value} is a valid date formatted as "MM-DD-YYYY".
 *
 * @param value the value
 * @returns {null|string} a validation message in case the given {@code value} is invalid, otherwise {@code null}
 */
function validateDate(value) {
  let result = <FormattedMessage id="form.validation-message.date"/>;

  // First check for the pattern
  if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(value)) {
    return result;
  }

  // Parse the date parts to integers
  let parts = value.split("-");
  let day = parseInt(parts[0], 10);
  let month = parseInt(parts[1], 10);
  let year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return result;
  }

  let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  if (day > 0 && day <= monthLength[month - 1]) {
    result = null;
  }

  return result;
}

/**
 * Checks if the given {@code value} is true.
 *
 * @param value the value
 * @returns {null|string} a validation message in case the given {@code value} is invalid, otherwise {@code null}
 */
function validateTermsAndConditions(value) {
  return value
    ? null
    : <FormattedMessage id="form.validation-message.terms-and-conditions"/>
}

/* final-form validation functions */
export const compose = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value) => !value && (
  <FormattedMessage id="form.validation-message.required"/>
);

export const maxLength = (max) => (value) => value && value.length > max && (
  <FormattedMessage id="form.validation-message.max-length" values={{max}}/>
);

export const phoneNumber = (value) => value && !/^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/i.test(value) && (
  <FormattedMessage id="form.validation-message.phone-number"/>
);

export const email = (value) => value && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i.test(value) && (
  <FormattedMessage id="form.validation-message.email-address"/>
);

export const date = (value) => value && isNaN(Date.parse(printInputDateInISODateTime(value))) && (
  <FormattedMessage id="form.validation-message.date"/>
);

export const number = (value) => value && isNaN(value) && (
  <FormattedMessage id="form.validation-message.number"/>
);

export const gtOrEqZero = (value) => value && value < 0 && (
  <FormattedMessage id="form.validation-message.negative-number"/>
);

export const uniqueUsername = async (value) => value && await isNotUnique(value) && (
  <FormattedMessage id="form.validation-message.unique-username"/>
);

async function isNotUnique(value) {
  return await getOne(BackendURLConstants.USER_VALIDATE.replace("$id", value), false);
  ;
}
