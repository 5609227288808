import React from "react";
import {FormControl, HelpBlock} from 'react-bootstrap';

const Select = ({input, meta, render, options, ...otherProps}) => (
    <div>
      <FormControl
        {...input}
        {...otherProps}
        componentClass="select"
      >
        {options && options.map((option, i) => (
          <option key={i} value={option.value} disabled={option.disabled}>{option.label}</option>
        ))}
      </FormControl>

      {meta.touched && meta.error && (
        <HelpBlock>
          {meta.error}
        </HelpBlock>
      )}
    </div>
  )
;

export default Select;
