/**
 * The {@code VALIDATION_TYPES} constant class contains the different validation modes.
 *
 * @type {string}
 */
const VALIDATION_TYPES = {
    DATE: 'date',
    EMAIL_ADDRESS: 'email-address',
    NAME: 'name',
    PASSWORD: 'password',
    PHONE_NUMBER: 'phone-number',
    REQUIRED: 'required',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
    TEXT: 'text'
};

export default VALIDATION_TYPES;
