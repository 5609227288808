import React from "react";
import DashboardHeader from "../../../partials/DashboardHeader";
import {Col, Grid, Row} from "react-bootstrap";
import {Document, DocumentList} from "../../../components/DocumentList";
import {FormattedMessage, injectIntl} from "react-intl";
import {getUsername} from "../../../utils/AuthenticationUtility";
import {getUser} from "../../../actions";
import {Redirect} from "react-router-dom";
import URIKeys from "../../../constants/URIKeys";
import {connect} from "react-redux";
import InactiveUserPage from "../../../components/InactiveUserPage/InactiveUserPage";
import Loading from "../../../components/Modals/Loading";

/**
 * The {@code Documents} class represents the dashboard Documents page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Documents extends React.Component {

  componentDidMount() {
    const {dispatch} = this.props;

    let username = getUsername();

    dispatch(getUser(username));
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, userObject} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !userObject) {
      return (
        <div id="dashboard-documents-page" className="dashboard-page">
          <DashboardHeader/>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    if (!userObject.active) {
      return (
        <div id="dashboard-documents-page" className="dashboard-page">
          <DashboardHeader/>
          <InactiveUserPage/>
        </div>
      );
    }

    return (
      <div id="dashboard-documents-page" className="dashboard-page">
        <DashboardHeader/>
        <Grid>
          <section className="common">
            <Row>
              <Col xs={12}>
                <FormattedMessage id="documents.page.title" tagName="h3"/>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormattedMessage id="documents.page.section.common.title" tagName="h5"/>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormattedMessage id="documents.page.section.common.paragraph" tagName="p"/>
                <DocumentList>
                  <Document fileName="huisreglement.pdf" label={formatMessage({id: "document.house-rules.label"})}/>
                </DocumentList>
              </Col>
            </Row>
          </section>
          <section className="health-insurance">
            <Row>
              <Col xs={12}>
                <FormattedMessage id="documents.page.section.health-insurance.title" tagName="h5"/>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormattedMessage id="documents.page.section.health-insurance.paragraph" tagName="p"/>
                <DocumentList>
                  <Document fileName="sportpremie_cm.pdf"
                            label={formatMessage({id: "document.health-insurance.cm.label"})}/>
                  <Document fileName="sportpremie_euromut.pdf"
                            label={formatMessage({id: "document.health-insurance.euromut.label"})}/>
                  <Document fileName="sportpremie_lm.pdf"
                            label={formatMessage({id: "document.health-insurance.lm.label"})}/>
                  <Document fileName="sportpremie_partena.pdf"
                            label={formatMessage({id: "document.health-insurance.partena.label"})}/>
                  <Document fileName="sportpremie_voorzorg.pdf"
                            label={formatMessage({id: "document.health-insurance.voorzorg.label"})}/>
                </DocumentList>
              </Col>
            </Row>
          </section>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {common, getUser} = state;
  return {
    error: common.error,
    loading: common.loading,
    userObject: getUser.userObject
  };
}


export default connect(mapStateToProps)(injectIntl(Documents));
