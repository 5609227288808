import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {Image, ParallaxImage} from "../../components/Image/index";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import {FadeInLeft, FadeInRight, FadeInUp} from "../../components/Animations/index";
import URIKeys from "../../constants/URIKeys";
import LocalizedLink from "../../components/Intl/LocalizedLink";

/**
 * The {@code Careers} class represents the careers page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Careers extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div id="careers-page">
        <ParallaxImage lowRes={require('../../images/pages/careers/page-header-tiny.jpeg')}
                       highRes={require('../../images/pages/careers/page-header.jpeg')}/>
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="page.careers.title" tagName="h3"/>
            <FormattedMessage id="page.careers.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="intro">
          <Grid>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <FormattedMessage id="page.careers.intro.parapagraph" tagName="p"/>
              </Col>
            </Row>
          </Grid>
        </section>
        <section className="images">
          <Grid>
            <Row>
              <Col sm={12} md={8}>
                <FadeInLeft delay="400">
                  <Image lowRes={require('../../images/pages/careers/careers-one-tiny.jpeg')}
                         highRes={require('../../images/pages/careers/careers-one.jpeg')}
                         classNames="img-responsive"/>
                </FadeInLeft>
              </Col>
              <Col sm={12} md={4}>
                <FadeInRight delay="600">
                  <Image lowRes={require('../../images/pages/careers/careers-two.svg')}
                         highRes={require('../../images/pages/careers/careers-two.jpg')}
                         classNames="img-responsive"/>
                </FadeInRight>
                <FadeInUp delay="800">
                  <Image lowRes={require('../../images/pages/careers/careers-three.svg')}
                         highRes={require('../../images/pages/careers/careers-three.jpg')}
                         classNames="img-responsive"/>
                </FadeInUp>
              </Col>
            </Row>
          </Grid>
        </section>
        <section className="applications">
          <Grid>
            <Row>
              <Col xs={12} md={5} mdOffset={1}>
                <FormattedMessage id="page.careers.entrepreneur.title" tagName="h6"/>
                <FormattedMessage id="page.careers.entrepreneur.paragraph.one" tagName="p"/>
                <FormattedMessage id="page.careers.entrepreneur.paragraph.two" tagName="p"/>
              </Col>
              <Col xs={12} md={5}>
                <FormattedMessage id="page.careers.personal-trainer.title" tagName="h6"/>
                <FormattedMessage id="page.careers.personal-trainer.paragraph.one" tagName="p"/>
                <FormattedMessage id="page.careers.personal-trainer.paragraph.two" tagName="p"/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={5} mdOffset={1}>
                <FormattedMessage id="page.careers.movement-coach.title" tagName="h6"/>
                <FormattedMessage id="page.careers.movement-coach.paragraph.one" tagName="p"/>
                <FormattedMessage id="page.careers.movement-coach.paragraph.two" tagName="p"/>
              </Col>
              <Col xs={12} md={5}>
                <FormattedMessage id="page.careers.academy.title" tagName="h6"/>
                <FormattedMessage id="page.careers.academy.paragraph.one" tagName="p"/>
                <FormattedHTMLMessage id="page.careers.academy.paragraph.two"/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={10} mdOffset={1} className="text-center">
                <br/>
                <LocalizedLink id={URIKeys.APPLICATION} className="btn btn-info">
                  <FormattedMessage id="page.careers.button.apply"/>
                </LocalizedLink>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

export default Careers;
