import React from "react";
import {Col} from "react-bootstrap";

/**
 * The {@code RecipeInfo} class represents the recipe info.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class RecipeInfo extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <Col xs={12} sm={5} md={3} className="recipe-info">
                {this.props.children}
            </Col>
        );
    }
}

export default RecipeInfo;
