import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {injectIntl} from "react-intl";
import URIKeys from "../../constants/URIKeys";
import {
  Appointments,
  Documents,
  ManageRecipes,
  MealSchedule,
  MealSchedules,
  Overview,
  Performance,
  PointOfService,
  PointsOfService,
  User,
  Users
} from "../index";
import Newsletter from "./employee/Newsletter";
import TechnicalEmail from "./employee/TechnicalEmail";
import IntakeForms from "./employee/IntakeForms";
import IntakeForm from "./employee/IntakeForm";
import FoodTipsAndTricks from "./user/FoodTipsAndTricks";
import Shop from "./user/Shop";
import ProductDetailPage from "./user/ProductDetailPage";
import ShoppingCart from "./user/ShoppingCart";
import Checkout from "./user/Checkout";

/**
 * The {@code DashboardPage} class represents the dashboard page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class DashboardPage extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {formatMessage} = this.props.intl;

    // TODO: add extra security on routes based on user type / authority

    return (
      <Switch>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_APPOINTMENTS})} component={Appointments}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_PERFORMANCE})} component={Performance}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_MEAL_SCHEDULES})} component={MealSchedules}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_MEAL_SCHEDULE})} component={MealSchedule}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_INTAKE_FORMS})} component={IntakeForms}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_INTAKE_FORM})} component={IntakeForm}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_DOCUMENTS})} component={Documents}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_FOOD_TIPS_AND_TRICKS})} component={FoodTipsAndTricks}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_OVERVIEW})} component={Overview}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_USERS})} component={Users}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_USER})} component={User}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_MANAGE_RECIPES})} component={ManageRecipes}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_POINTS_OF_SERVICE})} component={PointsOfService}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_POINT_OF_SERVICE})} component={PointOfService}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_NEWSLETTER})} component={Newsletter}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_SHOP})} component={Shop}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_CART})} component={ShoppingCart}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_CHECKOUT})} component={Checkout}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_PRODUCT_DETAIL_PAGE})} component={ProductDetailPage}/>
        <Route exact path={formatMessage({id: URIKeys.DASHBOARD_TECHNICAL_EMAIL})} component={TechnicalEmail}/>
        <Redirect from="*" to={formatMessage({id: URIKeys.PAGE_NOT_FOUND})}/>
      </Switch>
    );
  }
}

export default injectIntl(DashboardPage);
