import React from "react";
import {Redirect, Route} from "react-router-dom";
import {injectIntl} from "react-intl";
import {isLoggedIn} from "../../utils/AuthenticationUtility";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code SecuredRoute} class is used to secure a route.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class SecuredRoute extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        const {formatMessage} = this.props.intl;
        const {component, path} = this.props;

        if (!isLoggedIn()) {
            return <Redirect to={formatMessage({id: URIKeys.LOGIN})}/>
        }

        return (
            <Route path={path} component={component}/>
        );
    }
}

export default injectIntl(SecuredRoute);
