import React from "react";
import {Modal} from "react-bootstrap";
import Light from "../../Icons/Light";

/**
 * The {@code CreateUserModal} class represents the modal to delete an entity.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class DeleteEntityModal extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {show} = this.props;
    return (
      <Modal show={show} backdrop={true} className="danger-modal text-center">
        <Modal.Header>
          <Light icon="trash-alt"/>
        </Modal.Header>
        <Modal.Body>
          {this.props.children}
        </Modal.Body>
      </Modal>
    );
  }
}

export default DeleteEntityModal;
