import React from "react";
import {Col} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

/**
 * The {@code NutritionalValuesTitle} class represents the nutritional values data.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class NutritionalValuesData extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        const {nutritionalValues} = this.props;

        return (
            <Col xs={12} md={6}>
                <ul className="nutritional-values-data">
                    <li className="odd">
                        <FormattedMessage id="nutritional-values.energy"/>&#160;
                        <span className="value">{nutritionalValues.calories} kcal</span>
                    </li>
                    <li className="odd">
                        <FormattedMessage id="nutritional-values.carbohydrates"/>&#160;
                        <span className="value">{nutritionalValues.carbohydrates} g</span>
                        <br/>
                        <FormattedMessage id="nutritional-values.sugar"/>&#160;
                        <span className="value">{nutritionalValues.sugar} g</span>
                    </li>
                    <li className="odd">
                        <FormattedMessage id="nutritional-values.salt"/>&#160;
                        <span className="value">{nutritionalValues.salt} mg</span>
                    </li>
                    <li className="even">
                        <FormattedMessage id="nutritional-values.proteins"/>&#160;
                        <span className="value">{nutritionalValues.proteins} g</span>
                    </li>
                    <li className="even">
                        <FormattedMessage id="nutritional-values.fat"/>&#160;
                        <span className="value">{nutritionalValues.fat} g</span>
                        <br/>
                        <FormattedMessage id="nutritional-values.saturated-fat"/>&#160;
                        <span className="value">{nutritionalValues.saturatedFat} g</span>
                    </li>
                    <li className="even">
                        <FormattedMessage id="nutritional-values.fibers"/>&#160;
                        <span className="value">{nutritionalValues.fibers} g</span>
                    </li>
                </ul>
            </Col>
        );
    }
}

export default NutritionalValuesData;
