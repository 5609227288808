import React from "react";
import {Collapse} from "react-bootstrap";

/**
 * The {@code OpeningSchedule} class represents an opening schedule.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class OpeningSchedule extends React.Component { // todo: define as functional component

  /**
   * Renders the component.
   *
   * @returns JSX.Element HTML representation of the component
   */
  render() {
    return (
      <Collapse in={this.props.visible}>
        <div className="point-of-service-opening-schedule">
          {this.props.children}
        </div>
      </Collapse>
    );
  }
}

export default OpeningSchedule;
