import React from "react";

/**
 * The {@code Image} class represents an image.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Image extends React.Component {

    /**
     * The class constructor.
     */
    constructor() {
        super();

        this.state = {
            loaded: false
        };
    }

    onImageLoaded() {
        this.setState({
            loaded: true
        });
    }

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        let lowResClass = ' ';
        let highResClass = ' image-hidden ';

        if (this.state.loaded) {
            lowResClass = ' image-hidden ';
            highResClass = ' ';
        }

        return (
            <div className="image">
                <img className={"image-low-res" + lowResClass + this.props.classNames}
                     src={this.props.lowRes}
                     alt={this.props.alt}/>
                <img className={"image-high-res" + highResClass + this.props.classNames}
                     src={this.props.highRes}
                     alt={this.props.alt}
                     onLoad={this.onImageLoaded.bind(this)}/>
            </div>
        );
    }
}

export default Image;
