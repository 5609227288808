import React from "react";
import {
  Button,
  Col,
  ControlLabel,
  DropdownButton,
  FormControl,
  FormGroup,
  HelpBlock,
  InputGroup,
  MenuItem,
  Row
} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {resetEmail, sendEmail} from "../../actions";
import RequiredAnnotation from "./RequiredAnnotation";
import {Loading, Success} from "../../components/Modals";
import {Flag} from "../Icons";
import {getValidationState, validate} from "../../utils/ValidationUtility";
import ValidationTypes from "../../constants/ValidationTypes";
import BackendURLConstants from "../../constants/BackendURLConstants";
import URIKeys from "../../constants/URIKeys";
import LocalizedLink from "../Intl/LocalizedLink";

const COUNTRIES = [
  {
    code: 'be',
    prefix: '+32'
  },
  {
    code: 'nl',
    prefix: '+31'
  }
];

/**
 * The {@code ContactForm} class represents a contact form.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ContactForm extends React.Component {

  /** 
   * The class constructor. 
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      name: {
        value: '',
        state: undefined,
        message: undefined
      },
      emailAddress: {
        value: '',
        state: undefined,
        message: undefined
      },
      country: {
        value: '+32'
      },
      phoneNumber: {
        value: '',
        state: undefined,
        message: undefined
      },
      pointOfService: {
        value: 'initial',
        state: undefined,
        message: undefined
      },
      subject: {
        value: '',
        state: undefined,
        message: undefined
      },
      message: {
        value: '',
        state: undefined,
        message: undefined
      }
    };
  }

  /** 
   * Resets the form when the component is added to the DOM. 
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(resetEmail());
  }

  /**
   * Handle the change event for the name input.
   *
   * @param e the event
   */
  handleNameChange = (e) => {
    this.validateName(e.target.value);
  };

  /**
   * Validates the given {@code firstName}.
   *
   * @param name the name
   */
  validateName(name) {
    let message = validate(name, [ValidationTypes.REQUIRED, ValidationTypes.NAME]);
    let state = getValidationState(message);
    this.setState({
      name: {
        value: name,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handle the change event for the email address input.
   *
   * @param e the event
   */
  handleEmailAddressChange = (e) => {
    this.validateEmailAddress(e.target.value);
  };

  /**
   * Validates the given {@code emailAddress}.
   *
   * @param emailAddress the email address
   */
  validateEmailAddress(emailAddress) {
    let message = validate(emailAddress, [ValidationTypes.REQUIRED, ValidationTypes.EMAIL_ADDRESS]);
    let state = getValidationState(message);
    this.setState({
      emailAddress: {
        value: emailAddress,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handles the country drop down selection.
   *
   * @param prefix the prefix for the telephone number
   * @param e the click event
   */
  handleCountrySelection(prefix, e) {
    e.preventDefault();

    this.setState({
      country: {
        value: prefix
      }
    });
  }

  /**
   * Handle the change event for the email address input.
   *
   * @param e the event
   */
  handlePhoneNumberChange = (e) => {
    this.validatePhoneNumber(e.target.value);
  };

  /**
   * Validates the given {@code emailAddress}.
   *
   * @param phoneNumber the phone number
   */
  validatePhoneNumber(phoneNumber) {
    // Strip leading zeros
    phoneNumber = phoneNumber.replace(/^0+/, '');

    // Remove spaces
    phoneNumber = phoneNumber.replace('/\s+/g', '');

    // Add the country prefix for validation
    let formattedPhoneNumber = this.state.country.value + phoneNumber;

    let message = validate(formattedPhoneNumber, [ValidationTypes.REQUIRED, ValidationTypes.PHONE_NUMBER]);
    let state = getValidationState(message);
    this.setState({
      phoneNumber: {
        value: phoneNumber,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handle the change event for the subject input.
   *
   * @param e the event
   */
  handleSubjectChange = (e) => {
    this.validateSubject(e.target.value);
  };

  /**
   * Validates the given {@code subject}.
   *
   * @param subject the subject
   */
  validateSubject(subject) {
    let message = validate(subject, [ValidationTypes.REQUIRED, ValidationTypes.TEXT]);
    let state = getValidationState(message);
    this.setState({
      subject: {
        value: subject,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handle the change event for the message input.
   *
   * @param e the event
   */
  handlePointOfServiceChange = (e) => {
    this.validatePointOfService(e.target.value);
  };

  /**
   * Validates the given {@code id}.
   *
   * @param id the ID of the point of service
   */
  validatePointOfService(id) {
    let value = id;
    if (id === 'initial') {
      value = null;
    }

    let message = validate(value, [ValidationTypes.REQUIRED]);
    let state = getValidationState(message);
    this.setState({
      pointOfService: {
        value: id,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handle the change event for the message input.
   *
   * @param e the event
   */
  handleMessageChange = (e) => {
    this.validateMessage(e.target.value);
  };

  /**
   * Validates the given {@code text}.
   *
   * @param text the text
   */
  validateMessage(text) {
    let message = validate(text, [ValidationTypes.REQUIRED, ValidationTypes.TEXT]);
    let state = getValidationState(message);
    this.setState({
      message: {
        value: text,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handles the form on submit.
   */
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      const {dispatch} = this.props;
      const data = {
        'pointOfServiceId': this.state.pointOfService.value,
        'name': this.state.name.value,
        'emailAddress': this.state.emailAddress.value,
        'phoneNumber': this.state.country.value + this.state.phoneNumber.value,
        'subject': this.state.subject.value,
        'message': this.state.message.value
      };

      dispatch(sendEmail(BackendURLConstants.CONTACT_EMAIL, data));
    }
  };

  /**
   * Checks whether the form is valid.
   *
   * @returns {boolean} {@code true} in case the form is valid, otherwise {@code false}
   */
  isValid() {
    this.validateName(this.state.name.value);
    this.validateEmailAddress(this.state.emailAddress.value);
    this.validatePhoneNumber(this.state.phoneNumber.value);
    this.validateSubject(this.state.subject.value);
    this.validatePointOfService(this.state.pointOfService.value);
    this.validateMessage(this.state.message.value);

    return this.state.emailAddress.state === null
      && this.state.phoneNumber.state === null
      && this.state.subject.state === null
      && this.state.pointOfService.state === null
      && this.state.message.state === null;
  }

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {formatMessage} = this.props.intl;
    const {pointsOfService, isSendingEmail, succeededSendingEmail} = this.props;

    let loadingModal;
    if (isSendingEmail) {
      loadingModal = <Loading/>;
    }

    let successModal;
    if (succeededSendingEmail) {
      successModal = (
        <Success show={true}>
          <FormattedMessage id="success-message.email.post" tagName="p"/>
          <LocalizedLink id={URIKeys.HOME} className="btn btn-success">
            <FormattedMessage id="modal.success.button.home"/>
          </LocalizedLink>
        </Success>
      );
    }

    return (
      <form
        id="contact-form"
        onSubmit={this.handleSubmit}
        noValidate="noValidate">
        {loadingModal}
        {successModal}
        <Row>
          <Col xs={12} md={4}>
            <FormGroup controlId="name" validationState={this.state.name.state}>
              <ControlLabel>
                <FormattedMessage id="label.name"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <FormControl
                type="text"
                value={this.state.name.value}
                onChange={this.handleNameChange}/>
              <HelpBlock>{this.state.name.message}
              </HelpBlock>
            </FormGroup>
          </Col>
          <Col xs={12} md={4}>
            <FormGroup controlId="emailAddress" validationState={this.state.emailAddress.state}>
              <ControlLabel>
                <FormattedMessage id="label.email-address"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <FormControl
                type="text"
                value={this.state.emailAddress.value}
                onChange={this.handleEmailAddressChange}/>
              <HelpBlock>{this.state.emailAddress.message}
              </HelpBlock>
            </FormGroup>
          </Col>
          <Col xs={12} md={4}>
            <FormGroup controlId="phoneNumber" validationState={this.state.phoneNumber.state}>
              <ControlLabel>
                <FormattedMessage id="label.phone-number"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <InputGroup>
                <DropdownButton
                  componentClass={InputGroup.Button}
                  id="country-select"
                  title={this.state.country.value}>
                  {COUNTRIES.map(country => (
                    <MenuItem data={country} key={country.code}
                              onClick={(e) => this.handleCountrySelection(country.prefix, e)}>
                      <Flag country={country.code}/>
                      <FormattedMessage id={'country.' + country.code}/>
                      <span className="prefix">{country.prefix}</span>
                    </MenuItem>
                  ))}
                </DropdownButton>
                <FormControl
                  type="tel"
                  value={this.state.phoneNumber.value}
                  onChange={this.handlePhoneNumberChange}/>
              </InputGroup>
              <HelpBlock>{this.state.phoneNumber.message}
              </HelpBlock>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <FormGroup controlId="subject" validationState={this.state.subject.state}>
              <ControlLabel>
                <FormattedMessage id="label.subject"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <FormControl
                type="text"
                value={this.state.subject.value}
                onChange={this.handleSubjectChange}/>
              <HelpBlock>{this.state.subject.message}
              </HelpBlock>
            </FormGroup>
          </Col>
          <Col xs={12} md={4}>
            <FormGroup controlId="pointOfService" validationState={this.state.pointOfService.state}>
              <ControlLabel>
                <FormattedMessage id="label.point-of-service"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <FormControl componentClass="select"
                           value={this.state.pointOfService.value}
                           onChange={this.handlePointOfServiceChange}>
                <option value="initial" disabled>
                  {formatMessage({id: 'form.placeholder.select'})}
                </option>
                {pointsOfService.map(pointOfService => (
                  <option value={pointOfService.id} key={pointOfService.id}>
                    {pointOfService.name}
                  </option>
                ))}
              </FormControl>
              <HelpBlock>{this.state.pointOfService.message}
              </HelpBlock>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormGroup controlId="message" validationState={this.state.message.state}>
              <ControlLabel>
                <FormattedMessage id="label.message"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <FormControl
                componentClass="textarea" rows={5}
                value={this.state.message.value}
                onChange={this.handleMessageChange}/>
              <HelpBlock>{this.state.message.message}
              </HelpBlock>
            </FormGroup>
          </Col>
        </Row>
        <hr/>
        <Button type="submit" bsStyle="primary">
          <FormattedMessage id="form.button.send-message"/>
        </Button>
      </form>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {postEmail} = state;

  const {
    isSendingEmail,
    failedSendingEmail,
    succeededSendingEmail
  } = postEmail || {
    failedSendingEmail: false,
    hasErrorEmail: false,
    succeededSendingEmail: false
  };

  return {
    isSendingEmail,
    failedSendingEmail,
    succeededSendingEmail
  };
}

export default connect(mapStateToProps)(injectIntl(ContactForm));
