import React from "react";
import {connect} from "react-redux";
import {Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {createSubscription, resetSubscription} from "../../actions";
import RequiredAnnotation from "./RequiredAnnotation";
import {Loading, Success} from "../../components/Modals";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import {getValidationState, validate} from "../../utils/ValidationUtility";
import URIKeys from "../../constants/URIKeys";
import ValidationTypes from "../../constants/ValidationTypes";
import BackendURLConstants from "../../constants/BackendURLConstants";

/**
 * The {@code NewsletterSignUpForm} class represents a newsletter sign up form.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class NewsletterSignUpForm extends React.Component {

  /** 
   * The class constructor. 
   */
  constructor(props) {
    super(props);

    this.state = {
      firstName: {
        value: '',
        state: undefined,
        message: undefined
      },
      lastName: {
        value: '',
        state: undefined,
        message: undefined
      },
      emailAddress: {
        value: '',
        state: undefined,
        message: undefined
      },
      termsAndConditions: {
        value: false,
        state: undefined,
        message: undefined
      }
    };
  }

  /** 
   * Resets the form when the component is added to the DOM. 
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(resetSubscription());
  }

  /**
   * Handle the change event for the first name input.
   *
   * @param e the event
   */
  handleFirstNameChange = (e) => {
    this.validateFirstName(e.target.value);
  };

  /**
   * Validates the given {@code firstName}.
   *
   * @param firstName the first name
   */
  validateFirstName(firstName) {
    let message = validate(firstName, [ValidationTypes.REQUIRED, ValidationTypes.NAME]);
    let state = getValidationState(message);
    this.setState({
      firstName: {
        value: firstName,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handle the change event for the last name input.
   *
   * @param e the event
   */
  handleLastNameChange = (e) => {
    this.validateLastName(e.target.value);
  };

  /**
   * Validates the given {@code lastName}.
   *
   * @param lastName the last name
   */
  validateLastName(lastName) {
    let message = validate(lastName, [ValidationTypes.REQUIRED, ValidationTypes.NAME]);
    let state = getValidationState(message);
    this.setState({
      lastName: {
        value: lastName,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handle the change event for the email address input.
   *
   * @param e the event
   */
  handleEmailAddressChange = (e) => {
    this.validateEmailAddress(e.target.value);
  };

  /**
   * Validates the given {@code emailAddress}.
   *
   * @param emailAddress the email address
   */
  validateEmailAddress(emailAddress) {
    let message = validate(emailAddress, [ValidationTypes.REQUIRED, ValidationTypes.EMAIL_ADDRESS]);
    let state = getValidationState(message);
    this.setState({
      emailAddress: {
        value: emailAddress,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handle the change event for the terms and conditions checkbox.
   */
  handleTermsAndConditionsClick = () => {
    this.validateTermsAndConditions(!this.state.termsAndConditions.value);
  };

  /**
   * Validates the given {@code termsAndConditions}.
   *
   * @param termsAndConditions the terms and conditions flag
   */
  validateTermsAndConditions(termsAndConditions) {
    let message = validate(termsAndConditions, [ValidationTypes.TERMS_AND_CONDITIONS]);
    let state = getValidationState(message);
    this.setState({
      termsAndConditions: {
        value: termsAndConditions,
        state: state,
        message: message,
      }
    });
  };

  /**
   * Handles the form on submit.
   */
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      const {dispatch} = this.props;
      const data = {
        'firstName': this.state.firstName.value,
        'lastName': this.state.lastName.value,
        'emailAddress': this.state.emailAddress.value,
        'type': 'NEWSLETTER'
      };

      dispatch(createSubscription(BackendURLConstants.SUBSCRIPTIONS, data));
    }
  };

  /**
   * Checks whether the form is valid.
   *
   * @returns {boolean} {@code true} in case the form is valid, otherwise {@code false}
   */
  isValid() {
    this.validateFirstName(this.state.firstName.value);
    this.validateLastName(this.state.lastName.value);
    this.validateEmailAddress(this.state.emailAddress.value);
    this.validateTermsAndConditions(this.state.termsAndConditions.value);

    return this.state.firstName.state === null
      && this.state.lastName.state === null
      && this.state.emailAddress.state === null
      && this.state.termsAndConditions.state === null;
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {isPostingSubscription, succeededPostingSubscription} = this.props;

    let loadingModal;
    if (isPostingSubscription) {
      loadingModal = <Loading/>;
    }

    let successModal;
    if (succeededPostingSubscription) {
      successModal = (
        <Success show={true}>
          <FormattedMessage id="success-message.subscription.post" tagName="p"/>
          <LocalizedLink id={URIKeys.HOME} className="btn btn-success">
            <FormattedMessage id="modal.success.button.home"/>
          </LocalizedLink>
        </Success>
      );
    }

    return (
      <form
        id="newsletter-sign-up-form"
        onSubmit={this.handleSubmit}
        noValidate="noValidate">
        {loadingModal}
        {successModal}
        <Row>
          <Col xs={12} md={6}>
            <FormGroup controlId="firstName" validationState={this.state.firstName.state}>
              <ControlLabel>
                <FormattedMessage id="label.first-name"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <FormControl
                type="text"
                value={this.state.firstName.value}
                onChange={this.handleFirstNameChange}/>
              <HelpBlock>{this.state.firstName.message}</HelpBlock>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup controlId="lastName" validationState={this.state.lastName.state}>
              <ControlLabel>
                <FormattedMessage id="label.last-name"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <FormControl
                type="text"
                value={this.state.lastName.value}
                onChange={this.handleLastNameChange}/>
              <HelpBlock>{this.state.lastName.message}</HelpBlock>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup controlId="emailAddress" validationState={this.state.emailAddress.state}>
              <ControlLabel>
                <FormattedMessage id="label.email-address"/>
                <RequiredAnnotation/>
              </ControlLabel>
              <FormControl
                type="email"
                value={this.state.emailAddress.value}
                onChange={this.handleEmailAddressChange}/>
              <HelpBlock>{this.state.emailAddress.message}</HelpBlock>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormGroup controlId="termsAndConditions" validationState={this.state.termsAndConditions.state}>
              <Checkbox onClick={this.handleTermsAndConditionsClick}>
                <FormattedMessage
                  id="label.agree-with-terms-and-conditions"
                  values={{
                    termsAndConditions:
                      <LocalizedLink id={URIKeys.TERMS_AND_CONDITIONS}>
                        <FormattedMessage id="footer.link.terms-and-conditions"/>
                      </LocalizedLink>
                  }}/>
              </Checkbox>
              <HelpBlock>{this.state.termsAndConditions.message}</HelpBlock>
            </FormGroup>
          </Col>
        </Row>
        <hr/>
        <Button type="submit" bsStyle="primary">
          <FormattedMessage id="form.button.subscribe"/>
        </Button>
      </form>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {postSubscription} = state;

  const {
    isPostingSubscription,
    failedPostingSubscription,
    succeededPostingSubscription
  } = postSubscription || {
    isPostingSubscription: false,
    failedPostingSubscription: false,
    succeededPostingSubscription: false
  };

  return {
    isPostingSubscription,
    failedPostingSubscription,
    succeededPostingSubscription
  };
}

export default connect(mapStateToProps)(NewsletterSignUpForm);
