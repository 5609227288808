import React from "react";
import {Link} from "react-router-dom";

/**
 * The {@code Name} class represents an image box name.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Name extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    return (
      <h5 className="image-box-name">
        <Link to={this.props.url}>
          {this.props.children}
        </Link>
      </h5>
    );
  }
}

export default Name;
