import React from "react";
import Solid from "../Icons/Solid";

/**
 * The {@code ProgressBar} class represents a progress bar.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Step extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div
        className={'step-progress-bar-step' + (this.props.active ? ' active' : '') + (this.props.complete ? ' complete' : '')}>
        <Solid icon="dot-circle"/><br/>
        {this.props.children}
      </div>
    );
  }
}

export default Step;
