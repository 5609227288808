/**
 * The {@code SOCIALMEDIA_REFERENCES} constant class contains the supported social media references.
 *
 * @type {object}
 */
const SOCIALMEDIA_REFERENCES = [
    {
      key: 'label.select',
      code: 'NONE'
    },
    {
      key: 'reference.FRIENDS',
      code: 'FRIENDS'
    },
    {
      key: 'reference.FACEBOOK',
      code: 'FACEBOOK'
    },
    {
      key: 'reference.INSTAGRAM',
      code: 'INSTAGRAM'
    },
    {
      key: 'reference.INTERNET',
      code: 'INTERNET'
    },
    {
      key: 'reference.OTHER',
      code: 'OTHER'
    }
];


export default SOCIALMEDIA_REFERENCES;

