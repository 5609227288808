import Actions from "../constants/Actions";

export function getPointsOfService(state = {
  pointOfServiceCollection: null
}, action) {
  if (action.type === Actions.GET_POINTS_OF_SERVICE) {
    return Object.assign({}, state, {
      pointOfServiceCollection: action.result
    });
  } else {
    return state;
  }
}

export function getPointOfService(state = {
  pointOfServiceObject: null
}, action) {
  if (action.type === Actions.GET_POINT_OF_SERVICE) {
    return Object.assign({}, state, {
      pointOfServiceObject: action.result
    });
  } else {
    return state;
  }
}

export function storeLocator(state = {
  pointsOfService: null
}, action) {
  if (action.type === Actions.GET_POINTS_OF_SERVICE_STORE_LOCATOR) {
    return Object.assign({}, state, {
      pointsOfService: action.result
    });
  } else {
    return state;
  }
}
