import React from "react";
import {Col} from "react-bootstrap";
import {AllergenList} from "../AllergenList";
import {FormattedMessage} from "react-intl";

/**
 * The {@code AllergenData} class represents the allergen data.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class AllergenData extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <Col xsHidden sm={12} md={6} className="allergen-data">
                <FormattedMessage id="recipe.page.section-four.title" tagName="h5"/>
                <AllergenList allergens={this.props.allergens}/>
            </Col>
        );
    }
}

export default AllergenData;
