import React from "react";
import {Field, Form, FormSpy} from "react-final-form";
import {Alert, Button, Col, ControlLabel, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import FormControl from "./final-form/FormControl/FormControl";
import Select from "./final-form/Select/Select";
import FormGroup from "./final-form/FormGroup/FormGroup";
import {compose, date, email, maxLength, phoneNumber, required, uniqueUsername} from "../../utils/ValidationUtility";
import {
  convertISODateToDateInput,
  convertJSDateToDateInput,
  printInputDateInISODateTime
} from "../../utils/DateTimeUtility";
import {isUserSubscribed, updateSubscription} from "../../utils/SubscriptionsUtility";
import COUNTRIES from "../../constants/Countries";
import SOCIALMEDIA_REFERENCES from "../../constants/SocialMediaReference";
import YES_NO from "../../constants/YesNo";
import DIABETES_TYPE from "../../constants/DiabetesType";
import LANGUAGES from "../../constants/Languages";
import {hasRole, isLoggedIn} from "../../utils/AuthenticationUtility";
import {patch, post} from "../../utils/FetchUtility";
import BackendURLConstants from "../../constants/BackendURLConstants";
import {connect} from "react-redux";
import RequiredAnnotation from "./RequiredAnnotation";
import USER_ROLES from "../../constants/UserRoles";
import URIKeys from "../../constants/URIKeys";
import {withRouter} from "react-router";

/**
 * The {@code UserGeneralInformationForm} class represents the form to edit general user information.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class UserGeneralInformationForm extends React.Component {

  /**
   * Creates a new instance of the {@link UserGeneralInformationForm} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      error: undefined,
      initialValues: {
        username: undefined,
        firstName: undefined,
        lastName: undefined,
        language: 'nl',
        dayOfBirth: convertJSDateToDateInput(new Date()),
        creditsExpiryDate: convertJSDateToDateInput(new Date()),
        credits: 0,
        goal: undefined,
        medicalOperations: undefined,
        medication: undefined,
        physicalComplaints: undefined,
        physicalExercise: undefined,
        profession: undefined,
        activeProfession: undefined,
        shifts: undefined,
        active: false,
        intakeCompleted: false,
        address: {
          city: undefined,
          country: 'BE',
          phoneNumber: undefined,
          postalCode: undefined,
          state: 'BE-VLI',
          streetName: undefined,
          streetNumber: undefined
        },
        userDetails: {
          stomachOrIntestinalComplaints: undefined,
          kidneyOrLiverProblems: undefined,
          backShoulderOrJointComplaints: undefined,
          medicinesOrSupplements: undefined,
          diabetes: undefined,
          gout: undefined,
          epilepsy: undefined,
          cancer: undefined,
          hypokalemie: undefined,
          prosthesesOrPaceMakerOrImplants: undefined,
          electroCardiographOrMedicalInstruments: undefined,
          varicoseVeins: undefined,
          cardiacArrhythmias: undefined,
          skinDiseasesOrOtherSkinConditions: undefined,
          recentMyocardialInfarction: undefined,
          regularMuscleCramps: undefined,
          antiDepressants: undefined
        },
        mandatoryForeFitDetail: undefined,
        referenceType: undefined,
        womenUserDetails: {
          hormonalComplaints: undefined,
          breastfeeding: undefined,
          pregnant: undefined
        },
        pointOfService: 1,
        godparent: 'tom.ector@bodyreset.be',
        newsletter: false,
        motivation: false
      }
    };
  }

  /**
   * runs after the component output has been rendered to the DOM.
   * <p>
   * Updates the initial values if the user is being edited.
   */
  componentDidMount() {
    const {create, user} = this.props;

    if (!create) {
      this.setState({
        initialValues: {
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          language: user.language,
          dayOfBirth: convertISODateToDateInput(user.dayOfBirth),
          creditsExpiryDate: user.creditsExpiryDate == null ? '01-01-2019' : convertISODateToDateInput(user.creditsExpiryDate),
          credits: user.credits,
          goal: user.goal,
          medicalOperations: user.medicalOperations,
          medication: user.medication,
          physicalComplaints: user.physicalComplaints,
          physicalExercise: user.physicalExercise,
          profession: user.profession,
          activeProfession: user.activeProfession,
          shifts: user.shifts,
          active: user.active,
          intakeCompleted: user.intakeCompleted,
          address: user.address,
          userDetails: user.userDetails,
          mandatoryForeFitDetail: user.mandatoryForeFitDetail,
          referenceType: user.referenceType,
          womenUserDetails: user.womenUserDetails,
          pointOfService: user.pointOfService && user.pointOfService.id,
          godparent: user.godparent && user.godparent.username,
          newsletter: isUserSubscribed(user, 'NEWSLETTER'),
          motivation: isUserSubscribed(user, 'MOTIVATION')
        }
      });
    }
  }

  /**
   * Renders the submit button.
   *
   * @returns {*} the submit button
   */
  renderSubmitButton = (pristine, invalid) => {
    return (
      <Button className="pull-right" bsStyle="primary" type="submit" disabled={pristine || invalid}>
        <FormattedMessage id="common.button.confirm"/>
      </Button>
    );
  };

  /**
   * Renders the cancel button.
   *
   * @returns {*} the cancel button
   */
  renderCancelButton = () => {
    return (
      <Button className="pull-right" bsStyle="danger" onClick={() => this.onCancel()}>
        <FormattedMessage id="common.button.cancel"/>
      </Button>
    );
  };

  /**
   * Renders the buttons.
   *
   * @returns {*} the buttons
   */
  renderButtons = (pristine, invalid) => {
    const {edit} = this.props;

    let result;

    if (edit) {
      result = (<Row>
        <Col xs={12}>
          {this.renderCancelButton()}
          {this.renderSubmitButton(pristine, invalid)}
        </Col>
      </Row>);
    }

    return result;
  };

  /**
   * Cancels the edit.
   */
  onCancel = () => {
    window.location.reload();
  };

  /**
   * Handles the form on submit.
   */
  onSubmit = async (values) => {
    const {intl: {formatMessage}, history, create} = this.props;

    const data = {
      ...values,
      dayOfBirth: printInputDateInISODateTime(values.dayOfBirth),
      creditsExpiryDate: printInputDateInISODateTime(values.creditsExpiryDate),
      godparent: values.godparent === '' ? null : BackendURLConstants.EMPLOYEE.replace('$id', values.godparent),
      pointOfService: BackendURLConstants.POINT_OF_SERVICE.replace('$id', values.pointOfService)
    };

    try {
      if (create) {
        await post(BackendURLConstants.USERS, JSON.stringify(data), 'application/json', false);
      } else {
        await patch(BackendURLConstants.USER.replace('$id', values.username), JSON.stringify(data), 'application/json', true);
      }

      // Update the newsletter and subscriptions based on the user selection on the checkbox
      const {user} = this.props;
      if (values.newsletter !== this.state.initialValues.newsletter) {
        await updateSubscription(user, values.newsletter, 'NEWSLETTER');
      }

      if (values.motivation !== this.state.initialValues.motivation) {
        await updateSubscription(user, values.motivation, 'MOTIVATION');
      }
      if (create) {
        window.location.href=formatMessage({id: URIKeys.REGISTER_REDIRECT});
      } else {
        history.go();
      }
    } catch (error) {
      this.setState({
        error: error.message
      });
    }
  };

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}, create, edit, pointOfServiceCollection} = this.props;
    const {error} = this.state;

    return (
      <div>
        {error && (
          <Alert bsStyle="danger">
            {error}
          </Alert>
        )}

        <Form
          onSubmit={this.onSubmit}
          initialValues={this.state.initialValues}
          render={({handleSubmit, pristine, invalid}) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="form.user.general-information.section.common" tagName="h4"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="username">
                    <ControlLabel>
                      <FormattedMessage id="label.email-address"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="username"
                      component={FormControl}
                      validate={(create && compose(required, email, maxLength(256), uniqueUsername)) || compose(required, email, maxLength(256))}
                      type="username"
                      disabled={!create}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="firstName">
                    <ControlLabel>
                      <FormattedMessage id="label.first-name"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="firstName"
                      component={FormControl}
                      validate={compose(required, maxLength(256))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="lastName">
                    <ControlLabel>
                      <FormattedMessage id="label.last-name"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="lastName"
                      component={FormControl}
                      validate={compose(required, maxLength(256))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="dayOfBirth">
                    <ControlLabel>
                      <FormattedMessage id="label.date-of-birth"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="dayOfBirth"
                      component={FormControl}
                      validate={compose(required, date, maxLength(256))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="language">
                    <ControlLabel>
                      <FormattedMessage id="label.language"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="language"
                      component={Select}
                      disabled={true}
                      value="nl"
                      options={LANGUAGES.map(language => ({
                        value: language.key,
                        label: formatMessage({id: 'common.language.' + language.key})
                      }))}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="address.phoneNumber">
                    <ControlLabel>
                      <FormattedMessage id="label.phone-number"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="address.phoneNumber"
                      component={FormControl}
                      validate={compose(phoneNumber, maxLength(256))}
                      placeholder="+32123456789"
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="pointOfService">
                    <ControlLabel>
                      <FormattedMessage id="label.point-of-service"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="pointOfService"
                      component={Select}
                      disabled={!(create || (edit && (hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN))))}
                      validate={required}
                      options={pointOfServiceCollection && pointOfServiceCollection.pointsOfService.map(pointOfService => ({
                        value: pointOfService.id,
                        label: pointOfService.name
                      }))}
                    />
                  </FormGroup>
                </Col>
                {!create && (
                <div>
                <Col xs={12} sm={6}>
                  <FormSpy
                    subscription={{values: true}}
                    render={({values: {pointOfService}}) => {
                      const pointOfServiceObj = pointOfServiceCollection && pointOfServiceCollection.pointsOfService
                        .find(pos => pos.id == pointOfService); // string with number comparison!

                      const employees = [
                        {
                          key: '',
                          value: formatMessage({id: 'placeholder.select-godparent'})
                        }
                      ];

                      if (pointOfServiceObj && pointOfServiceObj.employees) {
                        for (const employee of pointOfServiceObj.employees) {
                          employees.push({
                            key: employee.username,
                            value: employee.firstName + ' ' + employee.lastName
                          })
                        }
                      }

                      return (
                        <FormGroup controlId="godparent">
                          <ControlLabel>
                            <FormattedMessage id="label.godparent"/>
                          </ControlLabel>
                          <Field
                            name="godparent"
                            component={Select}
                            disabled={!((create && (hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN))) || (edit && (hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN))))}
                            options={employees.map(employee => ({
                              value: employee.key,
                              label: employee.value
                            }))}
                          />
                        </FormGroup>
                      );
                    }}
                  />
                </Col>
                </div>)}
              </Row>
              {!create && (
              <div>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="credits">
                    <ControlLabel>
                      <FormattedMessage id="label.credits"/>
                    </ControlLabel>
                    <Field
                      name="credits"
                      component={FormControl}
                      type="number"
                      step="0.1"
                      min="0"
                      disabled={!(!create && edit && (hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN)))}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="creditsExpiryDate">
                    <ControlLabel>
                      <FormattedMessage id="label.credits-expiry-date"/>
                    </ControlLabel>
                    <Field
                      name="creditsExpiryDate"
                      component={FormControl}
                      disabled={!(!create && edit && (hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN)))}
                    />
                  </FormGroup>
                </Col>
              </Row>
              </div>)}
              <Row>
              {!create && (
                <div>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="active">
                    <ControlLabel>
                      <FormattedMessage id="label.active"/>
                    </ControlLabel>
                    <Field
                      name="active"
                      component={Select}
                      disabled={!(!create && edit && hasRole(USER_ROLES.ADMIN))}
                      options={[
                        {
                          value: undefined,
                          label: formatMessage({id: 'label.select'}),
                          disabled: true
                        },
                        {
                          value: true,
                          label: formatMessage({id: 'common.yes'})
                        },
                        {
                          value: false,
                          label: formatMessage({id: 'common.no'})
                        }
                      ]}
                    />
                  </FormGroup>
                </Col>
                </div>)}
                <Col xs={12} sm={6}>
                  <FormGroup controlId="goal">
                    <ControlLabel>
                      <FormattedMessage id="label.goal"/>
                    </ControlLabel>
                    <Field
                      name="goal"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {!create && (
              <div>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="intakeCompleted">
                    <ControlLabel>
                      <FormattedMessage id="label.intake-completed"/>
                    </ControlLabel>
                    <Field
                      name="intakeCompleted"
                      component={Select}
                      disabled={!(!create && edit && (hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN) || hasRole(USER_ROLES.TRAINER)))}
                      options={[
                        {
                          value: undefined,
                          label: formatMessage({id: 'label.select'}),
                          disabled: true
                        },
                        {
                          value: true,
                          label: formatMessage({id: 'common.yes'})
                        },
                        {
                          value: false,
                          label: formatMessage({id: 'common.no'})
                        }
                      ]}
                    />
                  </FormGroup>
                </Col>
              </Row>
               {/* Profession Details */}
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="form.user.general-information.section.profession" tagName="h4"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="profession">
                    <ControlLabel>
                      <FormattedMessage id="label.profession"/>
                    </ControlLabel>
                    <Field
                      name="profession"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="shifts">
                    <ControlLabel>
                      <FormattedMessage id="label.shifts"/>
                    </ControlLabel>
                    <Field
                      name="shifts"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="activeProfession">
                    <ControlLabel>
                      <FormattedMessage id="label.active-profession"/>
                    </ControlLabel>
                    <Field
                      name="activeProfession"
                      component={Select}
                      disabled={!edit}
                      options={[
                        {
                          value: undefined,
                          label: formatMessage({id: 'label.select'}),
                          disabled: true
                        },
                        {
                          value: true,
                          label: formatMessage({id: 'common.yes'})
                        },
                        {
                          value: false,
                          label: formatMessage({id: 'common.no'})
                        }
                      ]}
                    />
                  </FormGroup>
                </Col>
              </Row>
              </div>)}
              {/* Health Details */}
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="form.user.general-information.section.health" tagName="h4"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="medicalOperations">
                    <ControlLabel>
                      <FormattedMessage id="label.medical-operations"/>
                    </ControlLabel>
                    <Field
                      name="medicalOperations"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="medication">
                    <ControlLabel>
                      <FormattedMessage id="label.medication"/>
                    </ControlLabel>
                    <Field
                      name="medication"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="physicalComplaints">
                    <ControlLabel>
                      <FormattedMessage id="label.physical-complaints"/>
                    </ControlLabel>
                    <Field
                      name="physicalComplaints"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                {!create && (
                <div>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="physicalExercise">
                    <ControlLabel>
                      <FormattedMessage id="label.physical-exercise"/>
                    </ControlLabel>
                    <Field
                      name="physicalExercise"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!(!create && edit && (hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN) || hasRole(USER_ROLES.TRAINER)))}
                    />
                  </FormGroup>
                </Col>
                </div>)}
              </Row>
              {/* Address Details */}
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="form.user.general-information.section.address" tagName="h4"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="address.streetName">
                    <ControlLabel>
                      <FormattedMessage id="label.street-name"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="address.streetName"
                      component={FormControl}
                      validate={compose(required, maxLength(256))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="address.streetNumber">
                    <ControlLabel>
                      <FormattedMessage id="label.street-number"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="address.streetNumber"
                      component={FormControl}
                      validate={compose(required, maxLength(256))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="address.postalCode">
                    <ControlLabel>
                      <FormattedMessage id="label.postal-code"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="address.postalCode"
                      component={FormControl}
                      validate={compose(required, maxLength(256))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="address.city">
                    <ControlLabel>
                      <FormattedMessage id="label.city"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="address.city"
                      component={FormControl}
                      validate={compose(required, maxLength(256))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="address.country">
                    <ControlLabel>
                      <FormattedMessage id="label.country"/>
                      <RequiredAnnotation/>
                    </ControlLabel>
                    <Field
                      name="address.country"
                      component={Select}
                      disabled={!edit}
                      validate={required}
                      options={COUNTRIES.map(country => ({
                        value: country.isoCode,
                        label: formatMessage({id: country.key})
                      }))}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormSpy
                    subscription={{values: true}}
                    render={({values: {address: {country} = {}}}) => {
                      const countryObj = country && COUNTRIES
                        .find(countryConstant => countryConstant.isoCode === country);

                      return (
                        <FormGroup controlId="address.state">
                          <ControlLabel>
                            <FormattedMessage id="label.state"/>
                            <RequiredAnnotation/>
                          </ControlLabel>
                          <Field
                            name="address.state"
                            component={Select}
                            disabled={!edit}
                            validate={required}
                            options={countryObj && countryObj.states.map(state => ({
                              value: state.isoCode,
                              label: formatMessage({id: state.key})
                            }))}
                          />
                        </FormGroup>
                      );
                    }}
                  />
                </Col>
              </Row>
              {!create && (
                <div>
              {/* Personal History */}
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="form.user.general-information.section.userDetails" tagName="h4"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.stomachOrIntestinalComplaints">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-stomachOrIntestinalComplaints"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.stomachOrIntestinalComplaints"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.kidneyOrLiverProblems">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-kidneyOrLiverProblems"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.kidneyOrLiverProblems"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.backShoulderOrJointComplaints">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-backShoulderOrJointComplaints"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.backShoulderOrJointComplaints"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.medicinesOrSupplements">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-medicinesOrSupplements"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.medicinesOrSupplements"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.diabetes">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-diabetes"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.diabetes"
                      component={Select}
                      options={DIABETES_TYPE.map(types => ({
                        value: types.value,
                        label: formatMessage({id: types.key})
                      }))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.gout">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-gout"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.gout"
                      component={Select}
                      options={YES_NO.map(yesNo => ({
                        value: yesNo.value,
                        label: formatMessage({id: yesNo.key})
                      }))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.epilepsy">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-epilepsy"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.epilepsy"
                      component={Select}
                      options={YES_NO.map(yesNo => ({
                        value: yesNo.value,
                        label: formatMessage({id: yesNo.key})
                      }))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.cancer">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-cancer"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.cancer"
                      component={Select}
                      options={YES_NO.map(yesNo => ({
                        value: yesNo.value,
                        label: formatMessage({id: yesNo.key})
                      }))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.hypokalemie">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-hypokalemie"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.hypokalemie"
                      component={Select}
                      options={YES_NO.map(yesNo => ({
                        value: yesNo.value,
                        label: formatMessage({id: yesNo.key})
                      }))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.prosthesesOrPaceMakerOrImplants">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-prosthesesOrPaceMakerOrImplants"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.prosthesesOrPaceMakerOrImplants"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.electroCardiographOrMedicalInstruments">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-electroCardiographOrMedicalInstruments"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.electroCardiographOrMedicalInstruments"
                      component={Select}
                      options={YES_NO.map(yesNo => ({
                        value: yesNo.value,
                        label: formatMessage({id: yesNo.key})
                      }))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.varicoseVeins">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-varicoseVeins"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.varicoseVeins"
                      component={Select}
                      options={YES_NO.map(yesNo => ({
                        value: yesNo.value,
                        label: formatMessage({id: yesNo.key})
                      }))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.cardiacArrhythmias">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-cardiacArrhythmias"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.cardiacArrhythmias"
                      component={Select}
                      options={YES_NO.map(yesNo => ({
                        value: yesNo.value,
                        label: formatMessage({id: yesNo.key})
                      }))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.skinDiseasesOrOtherSkinConditions">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-skinDiseasesOrOtherSkinConditions"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.skinDiseasesOrOtherSkinConditions"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.recentMyocardialInfarction">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-recentMyocardialInfarction"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.recentMyocardialInfarction"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.regularMuscleCramps">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-regularMuscleCramps"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.regularMuscleCramps"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="userDetails.antiDepressants">
                    <ControlLabel>
                      <FormattedMessage id="label.userDetails-antiDepressants"/>
                    </ControlLabel>
                    <Field
                      name="userDetails.antiDepressants"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* womenUserDetails */}
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="form.user.general-information.section.women" tagName="h4"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="womenUserDetails.hormonalComplaints">
                    <ControlLabel>
                      <FormattedMessage id="label.womenUserDetails-hormonalComplaints"/>
                    </ControlLabel>
                    <Field
                      name="womenUserDetails.hormonalComplaints"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="womenUserDetails.breastfeeding">
                    <ControlLabel>
                      <FormattedMessage id="label.womenUserDetails-breastfeeding"/>
                    </ControlLabel>
                    <Field
                      name="womenUserDetails.breastfeeding"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="womenUserDetails.pregnant">
                    <ControlLabel>
                      <FormattedMessage id="label.womenUserDetails-pregnant"/>
                    </ControlLabel>
                    <Field
                      name="womenUserDetails.pregnant"
                      component={FormControl}
                      validate={maxLength(256)}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* Useful Infomation */}
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="form.user.general-information.section.usefulInfo" tagName="h4"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="referenceType">
                    <ControlLabel>
                      <FormattedMessage id="label.reference.title"/>
                    </ControlLabel>
                    <Field
                      name="referenceType"
                      component={Select}
                      options={SOCIALMEDIA_REFERENCES.map(referenceType => ({
                        value: referenceType.code,
                        label: formatMessage({id: referenceType.key})
                      }))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* Mandatory For eFit */}
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="form.user.general-information.section.mandatoryForeFit" tagName="h4"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="mandatoryForeFitDetail">
                    <ControlLabel>
                      <FormattedMessage id="label.mandatoryForeFit-detail"/>
                    </ControlLabel>
                    <Field
                      name="mandatoryForeFitDetail"
                      component={FormControl}
                      validate={compose(maxLength(256))}
                      disabled={!edit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {/* UserProfile Eula Information */}
                  <Row>
                    <Col xs={12}>
                      <FormGroup controlId="newsletter">
                      <ControlLabel>
                        <FormattedMessage id="label.subscribe.title"/>
                      </ControlLabel>
                        <label className="checkbox-inline">
                          <Field
                            name="newsletter"
                            component="input"
                            type="checkbox"
                            disabled={!edit}
                          />{' '}
                          <FormattedMessage id="label.subscribe.newsletter"/>
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup controlId="motivation">
                        <label className="checkbox-inline">
                          <Field
                            name="motivation"
                            component="input"
                            type="checkbox"
                            disabled={!edit}
                          />{' '}
                          <FormattedMessage id="label.subscribe.motivation"/>
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup controlId="unsubsctibeInfo">
                        <label>
                          {' '}
                          <FormattedMessage id="label.unsubscribe.info"/>
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              )}
              {/* Registration Eula Information */}
              {create && (
                <div>
                  <Row>
                    <Col xs={12}>
                      <FormGroup controlId="registrationEula">
                        <label>
                          {' '}
                          <FormattedMessage id="label.registration.eula"/>
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              )}
              {this.renderButtons(pristine, invalid)}
            </form>
          )}/>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getPointsOfService} = state;
  return {
    error: common.error,
    loading: common.loading,
    pointOfServiceCollection: getPointsOfService.pointOfServiceCollection,
  };
}

export default connect(mapStateToProps)(injectIntl(withRouter(UserGeneralInformationForm)));
