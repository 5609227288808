import React from "react";

/**
 * The {@code Allergen} class represents an allergen.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Allergen extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <li className="allergen">
                <span className="badge">{this.props.children}</span>
            </li>
        );
    }
}

export default Allergen;
