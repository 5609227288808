import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {BackLink, PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import {ParallaxImage} from "../../components/Image/index";
import {Light} from "../../components/Icons/index";
import URIKeys from "../../constants/URIKeys";
import {getRecipes} from "../../actions";
import {connect} from "react-redux";
import {Redirect} from "react-router";
import {Loading} from "../../components/Modals";
import {RecipeList} from "../../components/RecipeList";

/**
 * The {@code FoodConsultant} class represents the food consultant service page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class FoodConsultant extends React.Component {

  /**
   * Gets the recipes. 
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getRecipes());
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, recipeCollection} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || recipeCollection === null) {
      return (
        <div id="food-consultant-page">
          <ParallaxImage lowRes={require('../../images/pages/food-consultant/page-header.svg')}
                         highRes={require('../../images/pages/food-consultant/page-header.jpg')}/>
          <PageHeader>
            <BackLink>
              <LocalizedLink id={URIKeys.SERVICES}>
                <Light icon="chevron-left"/>&#160;
                <FormattedMessage id="nav-bar.item.services"/>
              </LocalizedLink>
            </BackLink>
            <PageHeaderTitle>
              <FormattedMessage id="services.section.food-consultant.title" tagName="h3"/>
              <FormattedMessage id="food-consultant.page.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <section>
            <Grid>
              <Row>
                <Loading/>
              </Row>
            </Grid>
          </section>
        </div>
      )
    }

    return (
      <div id="food-consultant-page">
        <ParallaxImage lowRes={require('../../images/pages/food-consultant/page-header.svg')}
                       highRes={require('../../images/pages/food-consultant/page-header.jpg')}/>
        <PageHeader>
          <BackLink>
            <LocalizedLink id={URIKeys.SERVICES}>
              <Light icon="chevron-left"/>&#160;
              <FormattedMessage id="nav-bar.item.services"/>
            </LocalizedLink>
          </BackLink>
          <PageHeaderTitle>
            <FormattedMessage id="services.section.food-consultant.title" tagName="h3"/>
            <FormattedMessage id="food-consultant.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section>
          <Grid>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <FormattedMessage id="food-consultant.paragraph.one" tagName="p"/>
                <FormattedMessage id="food-consultant.paragraph.two" tagName="p"/>
                <FormattedMessage id="food-consultant.paragraph.three" tagName="p"/>
              </Col>
            </Row>
          </Grid>
        </section>
        <RecipeList recipeCollection={recipeCollection}/>
        <section>
          <Grid>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <LocalizedLink id={URIKeys.SERVICES} className="btn btn-sm btn-info">
                  <Light icon="chevron-left"/>&#160;
                  <FormattedMessage id="common.button.back"/>
                </LocalizedLink>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    )
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getRecipes} = state;
  return {
    error: common.error,
    loading: common.loading,
    recipeCollection: getRecipes.recipeCollection
  };
}

export default connect(mapStateToProps)(injectIntl(FoodConsultant));
