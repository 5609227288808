/**
 * The {@code LANGUAGES} constant class contains the supported languages.
 * todo: move to endpoint
 *
 * @type {object}
 */
const LANGUAGES = [
  {
    key: 'nl'
  }
];

export default LANGUAGES;

