import React from "react";
import {Col, Row} from "react-bootstrap";

/**
 * The {@code PointOfService} class represents a points of service list item.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PointOfService extends React.Component {

    /**
     * Renders the component.
     *
     * @returns JSX.Element HTML representation of the component
     */
    render() {
        const pointOfService = this.props.data;

        return (
            <li key={pointOfService.id}>
                <Row className="point-of-service">
                    <Col xs={12} lg={10} lgOffset={1}>
                        {this.props.children}
                    </Col>
                </Row>
            </li>
        );
    }
}

export default PointOfService;
