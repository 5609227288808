import React from "react";
import {GoogleApiWrapper, Map} from 'google-maps-react';
import {getLanguage} from "../../utils/LanguageUtility";

/**
 * The {@code MapContainer} class represents the wrapper for the map.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class MapContainer extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        const styles = [
            {
                "featureType": "administrative.locality",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "administrative.province",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "hue": "#b0ff00"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#7bdffb"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            }
        ];

        return (
            <Map
                google={this.props.google}
                className="google-maps-container"
                mapTypeControl={false}
                gestureHandling="cooperative"
                styles={styles}
                zoom={this.props.zoom}
                center={this.props.center}
                initialCenter={this.props.initialCenter}>
                {this.props.children}
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDn27Np4sDUc9wiX8QXwnSEpfaqyiK2GhU',
  language: getLanguage()
})(MapContainer);
