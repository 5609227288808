import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Doughnut} from "react-chartjs-2";
import {Tile} from "../Tile";

/**
 * The {@code BodyMass} class represents the body composition graph.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class BodyMass extends React.Component {

    /**
     * Renders the graph.
     *
     * @returns {*} the HTML representation of the graph
     */
    renderGraph = () => {
        const {measurement} = this.props;
        const {formatMessage} = this.props.intl;

        let bodyMass = [0, 0, 0];
        let color = '#4cce73';
        let hoverColor = '#00ba38';

        if (measurement !== null) {
            if (measurement.fatPercentage > 25) {
                color = '#ff5439';
                hoverColor = '#ff2300';
            } else if (measurement.fatPercentage > 20) {
                color = '#ffc675';
                hoverColor = '#f0ad4e';
            }

            bodyMass = [
                (measurement.weight / 100 * measurement.fatPercentage).toFixed(2),
                measurement.boneMass,
                measurement.muscleMass
            ];
        }

        let data = {
            datasets: [{
                data: bodyMass,
                bolderColor: [color, '#f5f5f5', '#4cc7e9'],
                backgroundColor: [color, '#f5f5f5', '#4cc7e9'],
                hoverBorderColor: [hoverColor, '#ababab', '#00b1e0'],
                hoverBackgroundColor: [hoverColor, '#ababab', '#00b1e0']
            }],

            labels: [
                formatMessage({id: 'performance.page.tile.body-mass.label.fat-mass'}),
                formatMessage({id: 'performance.page.tile.body-mass.label.bone-mass'}),
                formatMessage({id: 'performance.page.tile.body-mass.label.muscular-mass'})
            ]
        };

        return <Doughnut data={data}
                         legend={{position: 'bottom', labels: {boxWidth: 12}}}
                         options={{circumference: Math.PI, rotation: Math.PI}}/>;
    };

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <Tile xs={12} md={4}>
                <FormattedMessage id="performance.page.tile.body-mass.title" tagName="h5"/>
                {this.renderGraph()}
            </Tile>
        );
    }
}

export default injectIntl(BodyMass);
