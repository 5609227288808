import React from "react";
import {FormattedMessage} from "react-intl";
import {Caption, Tile} from "../Tile";
import {Light} from "../Icons";

/**
 * The {@code VisceralFat} class represents the visceral fat tile.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class VisceralFat extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        const {measurement} = this.props;

      let icon = 'icon-gray';
        let visceralFat = '-';

        if (measurement !== null) {
          icon = 'icon-success';
            visceralFat = measurement.visceralFat;
            if (visceralFat > 12) {
              icon = 'icon-danger';
            }
        }

        return (
            <Tile xs={12} md={4}>
                <FormattedMessage id="performance.page.tile.visceral-fat.title" tagName="h5"/>
              <Light icon="heartbeat" classNames={icon}/>
                <Caption>
                    <h5>{visceralFat}</h5>
                </Caption>
            </Tile>
        );
    }
}

export default VisceralFat;
