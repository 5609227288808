import React from "react";
import {FormattedMessage} from "react-intl";
import {Alert, Col, Grid, Row} from "react-bootstrap";
import DashboardHeader from "../../../partials/DashboardHeader";
import {BackLink, PageHeaderTitle} from "../../../components/PageHeader";
import {Light} from "../../../components/Icons";
import PageHeader from "../../../components/PageHeader/PageHeader";
import TechnicalEmailForm from "../../../components/Forms/TechnicalEmailForm";

/**
 * The {@code TechnicalEmail} class represents the dashboard page to send technical emails.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class TechnicalEmail extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      show: true
    };
  }

  handleDismiss = () => {
    this.setState({show: false});
  };

  renderWarning = () => {
    let warning = undefined;
    if (this.state.show) {
      warning = (
        <Alert bsStyle="warning" onDismiss={() => this.handleDismiss()}>
          <FormattedMessage id="common.title.warning" tagName="h4"/>
          <FormattedMessage id="page.technical-email.paragraph.one" tagName="p"/>
        </Alert>
      );
    }
    return warning;
  };

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {history} = this.props;

    return (
      <div id="technical-email-page" className="dashboard-page">
        <DashboardHeader/>
        <PageHeader>
          <BackLink>
            <a onClick={history.goBack}>
              <Light icon="chevron-left"/>&#160;
              <FormattedMessage id="common.button.back-to-previous"/>
            </a>
          </BackLink>
          <PageHeaderTitle>
            <FormattedMessage id="page.technical-email.title" tagName="h3"/>
            <FormattedMessage id="page.technical-email.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="technical-email-form">
          <Grid>
            <Row>
              <Col xs={12}>
                {this.renderWarning()}
                <TechnicalEmailForm/>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

export default TechnicalEmail;
