import React from "react";

/**
 * The {@code OpeningHour} class represents an opening hour.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class OpeningHour extends React.Component {

    /**
     * Renders the component.
     *
     * @returns JSX.Element HTML representation of the component
     */
    render() {
        return (
            <span>
                {this.props.children}
            </span>
        );
    }
}

export default OpeningHour;
