import React from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, Grid, Nav, NavItem, Row, Table} from "react-bootstrap";
import {Pagination} from "@react-bootstrap/pagination";

import DashboardHeader from "../../../partials/DashboardHeader";
import Loading from "../../../components/Modals/Loading";
import URIKeys from "../../../constants/URIKeys";
import {BackLink, PageHeader, PageHeaderTitle} from "../../../components/PageHeader";
import {Light} from "../../../components/Icons";
import UserGeneralInformationForm from "../../../components/Forms/UserGeneralInformationForm";
import {
  getEmployeeRecipes,
  getPointsOfService,
  getUser,
  getUserAppointments,
  getUserBodyCompositionMeasurements,
  getUserGirthMeasurements,
  getUserMealSchedules,
  logout
} from "../../../actions";
import {getUsername, hasRole} from "../../../utils/AuthenticationUtility";
import AddEntityModal from "../../../components/Modals/AddEntityModal/AddEntityModal";
import BodyCompositionMeasurementForm from "../../../components/Forms/BodyCompositionMeasurementForm";
import {convertISODateTimeToJSDate, convertISODateToJSDate} from "../../../utils/DateTimeUtility";
import {remove} from "../../../utils/FetchUtility";
import GirthMeasurementForm from "../../../components/Forms/GirthMeasurementForm";
import AddCreditsModal from "../../../components/Modals/AddCreditsModal/AddCreditsModal";
import AddCreditsForm from "../../../components/Forms/AddCreditsForm";
import DeleteEntityModal from "../../../components/Modals/DeleteEntityModal/DeleteEntityModal";
import BackendURLConstants from "../../../constants/BackendURLConstants";
import ManageMealScheduleModal from "../../../components/Modals/ManageMealScheduleModal/ManageMealScheduleModal";
import {getLanguage} from "../../../utils/LanguageUtility";
import USER_ROLES from "../../../constants/UserRoles";

/**
 * The {@code User} class represents the dashboard user detail page.
 *
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class User extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      edit: !!this.props.create,
      selectedNavItem: 1,
      showDeleteUserModal: false,
      showCreateBodyCompositionMeasurementModal: false,
      showCreateGirthMeasurementModal: false,
      showAddCreditsModal: false,
      username: undefined,
      mealScheduleManagement: {
        showModal: false,
        mealSchedule: null
      }
    };
  }

  /**
   * Runs after the component output has been rendered to the DOM.
   * <p>
   * Retrieves all the user information.
   */
  componentDidMount() {
    const {dispatch} = this.props;

    const username = this.props.match.params.id;

    dispatch(getPointsOfService(undefined, undefined, undefined, true));
    dispatch(getUser(username));

    if (!hasRole(USER_ROLES.USER)) {
      dispatch(getUserAppointments(username, 10, 0));
      dispatch(getUserBodyCompositionMeasurements(username, undefined, undefined, undefined));
      dispatch(getUserGirthMeasurements(username, undefined, undefined, undefined));

      if (hasRole(USER_ROLES.ADMIN) || hasRole(USER_ROLES.NUTRITIONIST)) {
        dispatch(getEmployeeRecipes(getUsername(), 200, 0));
        dispatch(getUserMealSchedules(username, 10, 0));
      }
    }

    this.setState({
      username: username
    });
  }

  /**
   * Renders the edit button.
   *
   * @returns {*} the edit button
   */
  renderEditButton = () => {
    return (
      <Button className="pull-right" bsStyle="primary" onClick={() => this.toggleEdit(true)}>
        <Light icon="edit"/>
      </Button>
    );
  };

  /**
   * Renders the buttons.
   *
   * @returns {*} the buttons
   */
  renderButtons = () => {
    let result;
    if (!this.state.edit) {
      result = (<Row>
        <Col xs={12}>
          {this.renderEditButton()}
        </Col>
      </Row>);
    }
    return result;
  };

  /**
   * Toggles the edit mode.
   *
   * @param value the value
   */
  toggleEdit = (value) => {
    this.setState({
      edit: value
    });
  };

  /**
   * Toggles the selected navigation item.
   *
   * @param key the key
   */
  toggleNavItem = (key) => {
    this.setState({
      selectedNavItem: key
    });
  };

  /**
   * Toggles the create body composition measurement modal.
   *
   * @param value the value
   */
  toggleCreateBodyCompositionMeasurementModal = (value = true) => {
    this.setState({
      showCreateBodyCompositionMeasurementModal: value
    });
  };

  /**
   * Toggles the create girth measurement modal.
   *
   * @param value the value
   */
  toggleCreateGirthMeasurementModal = (value = true) => {
    this.setState({
      showCreateGirthMeasurementModal: value
    });
  };

  /**
   * Toggles the manage meal schedule modal.
   *
   * @param value the value
   * @param mealSchedule the meal schedule which needs to be edited
   */
  toggleManageMealScheduleModal = (value = true, mealSchedule = null) => {
    this.setState({
      mealScheduleManagement: {
        showModal: value,
        mealSchedule: mealSchedule
      }
    });
  };

  /**
   * Toggles the create girth measurement modal.
   *
   * @param value the value
   */
  toggleAddCreditsModal = (value = true) => {
    this.setState({
      showAddCreditsModal: value
    });
  };

  /**
   * Toggles the delete confirmation modal.
   *
   * @param user the user
   * @param value the value
   */
  toggleDeleteUserModal = (user, value = true) => {
    this.setState({
      showDeleteUserModal: value,
      user: user
    });
  };

  /**
   * Removes the user.
   */
  onDeleteUser = async () => {
    const {intl: {formatMessage}, history, dispatch, userObject} = this.props;

    await remove(BackendURLConstants.USER.replace('$id', userObject.username), true);
    dispatch(logout());
    this.toggleDeleteUserModal(null, false);
    window.location.href=formatMessage({id: URIKeys.HOME})
  };

  renderNavigation = () => {
    return (
      <Nav bsStyle="pills" stacked activeKey={this.state.selectedNavItem}>
        <NavItem eventKey={1} onClick={() => this.toggleNavItem(1)}>
          <FormattedMessage id="user.page.nav.personal-info"/>
        </NavItem>
        {hasRole(USER_ROLES.USER) && this.renderUserLinks()}
        {(hasRole(USER_ROLES.TRAINER) || hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN) || hasRole(USER_ROLES.NUTRITIONIST)) && this.renderTrainerLinks()}
        {(hasRole(USER_ROLES.NUTRITIONIST) || hasRole(USER_ROLES.ADMIN)) && this.renderNutritionistLinks()}
        {hasRole(USER_ROLES.ADMIN) && this.renderAdminLinks()}
      </Nav>
    );
  };

  renderUserLinks = () => {
    const {userObject} = this.props;
    return [
      <NavItem key={3} eventKey={3} onClick={() => this.toggleDeleteUserModal(userObject, true)}>
        <FormattedMessage id="user.page.nav.delete"/>
      </NavItem>
    ];
  };

  renderTrainerLinks = () => {
    return [
      <NavItem key={4} eventKey={4} onClick={() => this.toggleNavItem(4)}>
        <FormattedMessage id="user.page.nav.appointments"/>
      </NavItem>,
      <NavItem key={5} eventKey={5} onClick={() => this.toggleNavItem(5)}>
        <FormattedMessage id="user.page.nav.measurements"/>
      </NavItem>
    ];
  };

  renderNutritionistLinks = () => {
    return [
      <NavItem key={6} eventKey={6} onClick={() => this.toggleNavItem(6)}>
        <FormattedMessage id="user.page.nav.meal-schedules"/>
      </NavItem>
    ];
  };

  renderAdminLinks = () => {
    return [
      <NavItem key={7} eventKey={7} onClick={() => this.toggleAddCreditsModal(true)}>
        <FormattedMessage id="user.page.nav.credits"/>
      </NavItem>
    ];
  };

  renderContent = () => {
    const {userObject} = this.props;
    const {selectedNavItem} = this.state;

    let result;
    if (1 === selectedNavItem) {
      result = (
        <Col xs={12} sm={9}>
          {this.renderButtons()}
          <UserGeneralInformationForm create={false} edit={this.state.edit} user={userObject}/>
        </Col>
      );
    } else if (4 === selectedNavItem) {
      result = (
        <Col xs={12} sm={9}>
          <Row>
            <Col xs={12}>
              <FormattedMessage id="user.page.appointments.subtitle" tagName="h4"/>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.renderAppointments()}
            </Col>
          </Row>
        </Col>
      );
    } else if (5 === selectedNavItem) {
      result = (
        <Col xs={12} sm={9}>
          <Row>
            <Col xs={9}>
              <FormattedMessage id="user.page.measurements.body-composition.subtitle" tagName="h4"/>
            </Col>
            <Col xs={3}>
              {this.renderAddBodyCompositionMeasurementButton()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.renderBodyCompositionMeasurements()}
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <FormattedMessage id="user.page.measurements.girth.subtitle" tagName="h4"/>
            </Col>
            <Col xs={3}>
              {this.renderAddGirthMeasurementButton()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.renderGirthMeasurements()}
            </Col>
          </Row>
        </Col>
      );
    } else if (6 === selectedNavItem) {
      result = (
        <Col xs={12} sm={9}>
          <Row>
            <Col xs={9}>
              <FormattedMessage id="user.page.nav.meal-schedules" tagName="h4"/>
            </Col>
            <Col xs={3}>
              {this.renderAddMealScheduleButton()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.renderMealSchedules()}
            </Col>
          </Row>
        </Col>
      );
    }

    return result;
  };

  /**
   * Renders the button that is used to add body composition measurements.
   * <p>
   * This button is only visible for admins, managers and trainers.
   *
   * @returns the button
   */
  renderAddBodyCompositionMeasurementButton = () => {
    let button = null;
    if (hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN) || hasRole(USER_ROLES.TRAINER)) {
      button = (
        <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                onClick={() => this.toggleCreateBodyCompositionMeasurementModal(true)}>
          <Light icon="plus-square"/>
        </Button>
      );
    }
    return button;
  };

  /**
   * Renders the body composition measurements
   *
   * @returns {*} the body composition measurements
   */
  renderBodyCompositionMeasurements = () => {
    const {userBodyCompositionMeasurementCollection} = this.props;

    let result;
    if (userBodyCompositionMeasurementCollection.measurements.length) {
      result = (
        <Table striped bordered condensed hover responsive>
          <thead>
          <tr>
            <td><FormattedMessage id="common.date"/></td>
            <td><FormattedMessage id="body-composition-measurement.height"/></td>
            <td><FormattedMessage id="body-composition-measurement.weight"/></td>
            <td><FormattedMessage id="body-composition-measurement.bone-mass"/></td>
            <td><FormattedMessage id="body-composition-measurement.muscle-mass"/></td>
            <td><FormattedMessage id="body-composition-measurement.fat-percentage"/></td>
            <td><FormattedMessage id="body-composition-measurement.water-percentage"/></td>
            <td><FormattedMessage id="body-composition-measurement.metabolic-age"/></td>
            <td><FormattedMessage id="body-composition-measurement.visceral-fat"/></td>
          </tr>
          </thead>
          <tbody>
          {userBodyCompositionMeasurementCollection.measurements.map((measurement, i) => (
            <tr key={i}>
              <td>{convertISODateToJSDate(measurement.date).toLocaleDateString(getLanguage())}</td>
              <td>{measurement.height.toFixed(2)}</td>
              <td>{measurement.weight.toFixed(2)}</td>
              <td>{measurement.boneMass.toFixed(2)}</td>
              <td>{measurement.muscleMass.toFixed(2)}</td>
              <td>{measurement.fatPercentage.toFixed(2)}</td>
              <td>{measurement.waterPercentage.toFixed(2)}</td>
              <td>{measurement.metabolicAge.toFixed(2)}</td>
              <td>{measurement.visceralFat.toFixed(2)}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      );
    } else {
      result = <FormattedMessage id="common.measurement.none"/>;
    }

    return result;
  };

  /**
   * Renders the button that is used to add girth measurements.
   * <p>
   * This button is only visible for admins, managers and trainers.
   *
   * @returns the button
   */
  renderAddGirthMeasurementButton = () => {
    let button = null;
    if (hasRole(USER_ROLES.TRAINER) || hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN)) {
      button = (
        <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                onClick={() => this.toggleCreateGirthMeasurementModal(true)}>
          <Light icon="plus-square"/>
        </Button>
      );
    }
    return button;
  };

  /**
   * Renders the girth measurements
   *
   * @returns {*} the girth measurements
   */
  renderGirthMeasurements = () => {
    const {userGirthMeasurementCollection} = this.props;

    let result;
    if (userGirthMeasurementCollection.measurements.length) {
      result = (
        <Table striped bordered condensed hover responsive>
          <thead>
          <tr>
            <td><FormattedMessage id="common.date"/></td>
            <td><FormattedMessage id="performance.page.tile.girth-measurements.label.chest"/></td>
            <td><FormattedMessage id="performance.page.tile.girth-measurements.label.hip"/></td>
            <td><FormattedMessage id="performance.page.tile.girth-measurements.label.thigh"/></td>
            <td><FormattedMessage id="performance.page.tile.girth-measurements.label.underBust"/></td>
            <td><FormattedMessage id="performance.page.tile.girth-measurements.label.upperArm"/></td>
            <td><FormattedMessage id="performance.page.tile.girth-measurements.label.waist"/></td>
          </tr>
          </thead>
          <tbody>
          {userGirthMeasurementCollection.measurements.map((measurement, i) => (
            <tr key={i}>
              <td>{convertISODateToJSDate(measurement.date).toLocaleDateString(getLanguage())}</td>
              <td>{measurement.chest.toFixed(2)}</td>
              <td>{measurement.hip.toFixed(2)}</td>
              <td>{measurement.thigh.toFixed(2)}</td>
              <td>{measurement.underBust.toFixed(2)}</td>
              <td>{measurement.upperArm.toFixed(2)}</td>
              <td>{measurement.waist.toFixed(2)}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      );
    } else {
      result = <FormattedMessage id="common.measurement.none"/>;
    }

    return result;
  };

  /**
   * Renders the girth measurements
   *
   * @returns {*} the girth measurements
   */
  renderAppointments = () => {
    const {userAppointmentCollection} = this.props;

    let result;
    if (userAppointmentCollection.appointments.length) {
      result = (
        <div>
          <Table striped bordered condensed hover responsive>
            <thead>
            <tr>
              <td><FormattedMessage id="appointments.page.section.upcoming-appointments.table.header.date"/></td>
              <td><FormattedMessage id="appointments.page.section.upcoming-appointments.table.header.time"/></td>
              <td><FormattedMessage id="appointments.page.section.upcoming-appointments.table.header.facility"/></td>
              <td><FormattedMessage id="appointment.cardio"/></td>
              <td><FormattedMessage id="appointment.module"/></td>
              <td><FormattedMessage id="appointment.submodule"/></td>
              <td><FormattedMessage id="appointment.comment"/></td>
            </tr>
            </thead>
            <tbody>
            {userAppointmentCollection.appointments.map((appointment, i) => (
              <tr key={i}>
                <td>{convertISODateTimeToJSDate(appointment.date).toLocaleDateString(getLanguage())}</td>
                <td>{convertISODateTimeToJSDate(appointment.date).toLocaleTimeString(getLanguage(), {
                  hour: '2-digit',
                  minute: '2-digit'
                })}</td>
                <td><FormattedMessage id={'facilities.type.' + appointment.facility.type}/></td>
                <td>{appointment.cardio}</td>
                <td>{appointment.module}</td>
                <td>
                  {appointment.submodules.map((submodule, j) => (
                    <span key={j}>
                    <FormattedMessage id={'submodule.' + submodule}/>
                      {(j + 1) < appointment.submodules.length ? ', ' : ''}
                  </span>
                  ))}
                </td>
                <td>{appointment.comment}</td>
              </tr>
            ))}
            </tbody>
          </Table>
          <section className="pagination-links">
            <Row>
              <Col xs={12}>
                <Pagination bsSize="medium"
                            items={(userAppointmentCollection.page.totalPages)}
                            activePage={userAppointmentCollection.page.number + 1}
                            onSelect={this.toggleAppointmentsPage}
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={5}/>
              </Col>
            </Row>
          </section>
        </div>
      );
    } else {
      result = <FormattedMessage id="appointment.none"/>;
    }

    return result;
  };

  /**
   * Renders the button that is used to add body composition measurements.
   * <p>
   * This button is only visible for admins, managers and trainers.
   *
   * @returns the button
   */
  renderAddMealScheduleButton = () => {
    let button = null;
    if (hasRole(USER_ROLES.NUTRITIONIST) || hasRole(USER_ROLES.ADMIN)) {
      button = (
        <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                onClick={() => this.toggleManageMealScheduleModal(true)}>
          <Light icon="plus-square"/>
        </Button>
      );
    }
    return button;
  };

  /**
   * Renders the button that is used to add body composition measurements.
   * <p>
   * This button is only visible for admins, managers and trainers.
   *
   * @param mealSchedule the meal schedule
   * @returns the button
   */
  renderEditMealScheduleButton = (mealSchedule) => {
    let button = null;
    if (hasRole(USER_ROLES.NUTRITIONIST) || hasRole(USER_ROLES.ADMIN)) {
      button = (
        <Button bsStyle="primary" bsSize="small" onClick={() => this.toggleManageMealScheduleModal(true, mealSchedule)}>
          <Light icon="edit"/>
        </Button>
      );
    }
    return button;
  };

  /**
   * Renders the meal schedules.
   *
   * @returns {*} the meal schedules
   */
  renderMealSchedules = () => {
    const {userMealScheduleCollection} = this.props;

    let result;
    if (userMealScheduleCollection.mealSchedules.length) {
      result = (
        <div>
          <Table striped bordered condensed hover responsive>
            <thead>
            <tr>
              <td><FormattedMessage id="label.date"/></td>
              <td><FormattedMessage id="label.nutritionist"/></td>
              <td><FormattedMessage id="label.feedback"/></td>
              <td>&#160;</td>
            </tr>
            </thead>
            <tbody>
            {userMealScheduleCollection.mealSchedules.map((mealSchedule, i) => (
              <tr key={i}>
                <td>{mealSchedule.date.toLocaleDateString(getLanguage())}</td>
                <td>{mealSchedule.employee.firstName + ' ' + mealSchedule.employee.lastName}</td>
                <td className="truncate-column"><span>{mealSchedule.feedback}</span></td>
                <td className="text-center">
                  {this.renderEditMealScheduleButton(mealSchedule)}
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
          <section className="pagination-links">
            <Row>
              <Col xs={12}>
                <Pagination bsSize="medium"
                            items={(userMealScheduleCollection.page.totalPages)}
                            activePage={userMealScheduleCollection.page.number + 1}
                            onSelect={this.toggleMealSchedulesPage}
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={5}/>
              </Col>
            </Row>
          </section>
        </div>
      );
    } else {
      result = <FormattedMessage id="meal-schedules.none"/>;
    }

    return result;
  };

  /**
   * Toggles another page.
   *
   * @param page the apge to go to
   */
  toggleAppointmentsPage = (page) => {
    const {dispatch} = this.props;
    dispatch(getUserAppointments(this.state.username, 10, --page));
  };

  /**
   * Toggles another page.
   *
   * @param page the apge to go to
   */
  toggleMealSchedulesPage = (page) => {
    const {dispatch} = this.props;
    dispatch(getUserMealSchedules(this.state.username, 10, --page));
  };

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {error, loading, userObject, userAppointmentCollection, userBodyCompositionMeasurementCollection, userGirthMeasurementCollection, userMealScheduleCollection, pointOfServiceCollection, employeeRecipeCollection} = this.props;
    const {intl: {formatMessage}, history} = this.props;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if ((hasRole(USER_ROLES.USER) && (loading > 0 || !userObject || !pointOfServiceCollection))
      || (hasRole(USER_ROLES.NUTRITIONIST) && (loading > 0 || !userObject || !pointOfServiceCollection || !userAppointmentCollection || !userBodyCompositionMeasurementCollection || !userGirthMeasurementCollection || employeeRecipeCollection === null || !userMealScheduleCollection))
      || (!hasRole(USER_ROLES.USER) && (loading > 0 || !userObject || !pointOfServiceCollection || !userAppointmentCollection || !userBodyCompositionMeasurementCollection || !userGirthMeasurementCollection))) {
      return (
        <div id="dashboard-user-page" className="dashboard-page">
          <DashboardHeader/>
          <PageHeader>
            <BackLink>
              <a onClick={history.goBack}>
                <Light icon="chevron-left"/>&#160;
                <FormattedMessage id="common.button.back-to-previous"/>
              </a>
            </BackLink>
            <PageHeaderTitle>
              <FormattedMessage id="user.page.edit.title" tagName="h3"/>
              <FormattedMessage id="user.page.edit.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <section className="user-form">
            <Grid>
              <Row>
                <Loading/>
              </Row>
            </Grid>
          </section>
        </div>
      )
    }

    return (
      <div id="dashboard-user-page" className="dashboard-page">
        <DashboardHeader/>
        <PageHeader>
          <BackLink>
            <a onClick={history.goBack}>
              <Light icon="chevron-left"/>&#160;
              <FormattedMessage id="common.button.back-to-previous"/>
            </a>
          </BackLink>
          <PageHeaderTitle>
            <FormattedMessage id="user.page.edit.title" tagName="h3"/>
            <FormattedMessage id="user.page.edit.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="user-form">
          <Grid>
            <Row>
              <Col xs={12} sm={3}>
                {this.renderNavigation()}
              </Col>
              {this.renderContent()}
            </Row>
          </Grid>
        </section>
        <AddEntityModal show={this.state.showCreateBodyCompositionMeasurementModal}>
          <FormattedMessage id="modal.create-body-composition-measurement.title" tagName="h4"/>
          <BodyCompositionMeasurementForm user={userObject}
                                          toggleCreateBodyCompositionMeasurementModal={() => this.toggleCreateBodyCompositionMeasurementModal(false)}/>
        </AddEntityModal>
        <AddEntityModal show={this.state.showCreateGirthMeasurementModal}>
          <FormattedMessage id="modal.create-girth-measurement.title" tagName="h4"/>
          <GirthMeasurementForm user={userObject}
                                toggleCreateGirthMeasurementModal={() => this.toggleCreateGirthMeasurementModal(false)}/>
        </AddEntityModal>
        <ManageMealScheduleModal show={this.state.mealScheduleManagement.showModal}
                                 mealSchedule={this.state.mealScheduleManagement.mealSchedule}
                                 user={userObject}
                                 recipes={employeeRecipeCollection && employeeRecipeCollection.recipes}
                                 toggleManageMealScheduleModal={() => this.toggleManageMealScheduleModal(false)}/>
        <AddCreditsModal show={this.state.showAddCreditsModal}>
          <FormattedMessage id="user.page.credits.subtitle" tagName="h4"/>
          <AddCreditsForm user={userObject} toggleAddCreditsModal={() => this.toggleAddCreditsModal(false)}/>
        </AddCreditsModal>
        <DeleteEntityModal show={this.state.showDeleteUserModal}>
          <FormattedMessage id="user.page.modal.delete-confirmation.title" tagName="h4"/>
          <FormattedMessage id="user.page.modal.delete-confirmation.body" tagName="p"/>
          <Button bsStyle="primary" onClick={() => this.onDeleteUser()}>
            <FormattedMessage id="common.button.confirm"/>
          </Button>
          <Button bsStyle="danger" onClick={() => this.toggleDeleteUserModal(null, false)}>
            <FormattedMessage id="common.button.cancel"/>
          </Button>
        </DeleteEntityModal>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getUser, getUserAppointments, getUserBodyCompositionMeasurements, getUserGirthMeasurements, getUserMealSchedules, getPointsOfService, employeeRecipes} = state;
  return {
    error: common.error,
    loading: common.loading,
    userObject: getUser.userObject,
    userAppointmentCollection: getUserAppointments.userAppointmentCollection,
    userBodyCompositionMeasurementCollection: getUserBodyCompositionMeasurements.userBodyCompositionMeasurementCollection,
    userGirthMeasurementCollection: getUserGirthMeasurements.userGirthMeasurementCollection,
    userMealScheduleCollection: getUserMealSchedules.userMealScheduleCollection,
    pointOfServiceCollection: getPointsOfService.pointOfServiceCollection,
    employeeRecipeCollection: employeeRecipes.employeeRecipeCollection
  };
}

export default connect(mapStateToProps)(injectIntl(User));
