import React from "react";
import {Grid, Row} from "react-bootstrap";

/**
 * The {@code Content} class represents the content for the video background component.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Content extends React.Component {

    /**
     * The class constructor.
     */
    constructor() {
        super();

        this.state = {
            windowHeight: 0,
        };
    }

    /**
     * Adds the event listeners when the component is added to the DOM.
     */
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    /**
     * Removes the event listeners when the component is removed from the DOM.
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    /**
     * Determines the height of the browser window and updates the state accordingly.
     */
    updateDimensions = () => {
        const windowHeight = window.innerHeight;

        if (windowHeight !== this.state.windowHeight) {
            const translateValue = windowHeight / 2 - this.content.clientHeight / 2;

            this.content.style.transform = 'translate(0, ' + translateValue + 'px)';
            this.content.style.WebkitTransform = 'translate(0, ' + translateValue + 'px)';
            this.content.style.OTransform = 'translate(0, ' + translateValue + 'px)';
            this.content.style.MozTransform = 'translate(0, ' + translateValue + 'px)';

            this.setState({
                windowHeight: windowHeight
            });
        }
    };

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <Grid>
                <Row>
                    <div className="col-xs-12 col-lg-8 col-lg-offset-2"
                         ref={(content) => {
                             this.content = content;
                         }}>
                        {this.props.children}
                    </div>
                </Row>
            </Grid>
        );
    }
}

export default Content;
