import React from "react";
import {Col, Row} from "react-bootstrap";
import {Light} from "../../Icons";
import {FormattedMessage} from "react-intl";
import Answer from "./Answer";

/**
 * The {@code FaqItem} class represents a single faq item.
 *
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class FaqItem extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      collapsed: true
    };
  }

  toggleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {icon, headerKey} = this.props;
    const {collapsed} = this.state;

    return (
      <Row>
        <Col xs={12} className="faq-item">
          <div className="faq-item__header" onClick={this.toggleCollapse}>
            <Light icon={icon}/>
            <FormattedMessage id={headerKey} tagName="p"/>
          </div>
          {!collapsed && (
            this.props.children
          )}
          <hr/>
        </Col>
      </Row>
    );
  }
}

FaqItem.Answer = Answer;

export default FaqItem;

