import React from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import {ParallaxImage} from "../../components/Image/index";
import APPLICATIONS from "../../constants/Applications";
import ManagerApplication from "../../components/Forms/ManagerApplication";
import PersonalTrainerApplication from "../../components/Forms/PersonalTrainerApplication";
import MovementCoachApplication from "../../components/Forms/MovementCoachApplication";

/**
 * The {@code Application} class represents the application page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Application extends React.Component {

  /**
   * Creates a new instance of the {@link Application} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      application: 'initial'
    }
  }

  /**
   * Handles the onChange event for the application type selector.
   *
   * @param e the event
   */
  onSelectApplicationType = (e) => {
    this.setState({
        application: e.target.value
      }
    );
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div id="application-page">
        <ParallaxImage lowRes={require('../../images/pages/contact/page-header.svg')}
                       highRes={require('../../images/pages/contact/page-header.jpg')}/>
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="page.application.title" tagName="h3"/>
            <FormattedMessage id="page.application.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="function">
          <Grid>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                {this._renderApplicationTypeForm()}
              </Col>
            </Row>
          </Grid>
        </section>
        <section className="form">
          <Grid>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                {this._renderApplicationForm()}
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }

  /**
   * Renders the application type selection form.
   *
   * @returns {*} the application type selection form
   * @private
   */
  _renderApplicationTypeForm() {
    const {intl: {formatMessage}} = this.props;
    return (
      <form>
        <FormGroup controlId="application">
          <ControlLabel>
            <FormattedMessage id="label.application"/>
          </ControlLabel>
          <FormControl componentClass="select"
                       value={this.state.application}
                       onChange={this.onSelectApplicationType}>
            <option value="initial" disabled>
              {formatMessage({id: 'form.placeholder.select'})}
            </option>
            <option value={APPLICATIONS.MANAGER}>
              {formatMessage({id: 'label.entrepreneur'})}
            </option>
            <option value={APPLICATIONS.PERSONAL_TRAINER}>
              {formatMessage({id: 'label.' + APPLICATIONS.PERSONAL_TRAINER})}
            </option>
            <option value={APPLICATIONS.MOVEMENT_COACH}>
              {formatMessage({id: 'label.' + APPLICATIONS.MOVEMENT_COACH})}
            </option>
          </FormControl>
        </FormGroup>
      </form>
    );
  }

  /**
   * Renders the application form.
   *
   * @returns {*} the application form
   * @private
   */
  _renderApplicationForm() {
    let form;
    if (APPLICATIONS.MANAGER === this.state.application) {
      form = <ManagerApplication/>
    } else if (APPLICATIONS.PERSONAL_TRAINER === this.state.application) {
      form = <PersonalTrainerApplication/>
    } else if (APPLICATIONS.MOVEMENT_COACH === this.state.application) {
      form = <MovementCoachApplication/>
    }
    return form;
  }
}

export default injectIntl(Application);
