/**
 * The {@code Applications} class contains the application type constants.
 *
 */
class Applications {

  static get MANAGER() {
    return "manager";
  }

  static get PERSONAL_TRAINER() {
    return "personal-trainer";
  }

  static get MOVEMENT_COACH() {
    return "movement-coach";
  }
}

export default Applications;
