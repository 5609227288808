import React from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, Grid, Row, Table} from "react-bootstrap";
import DashboardHeader from "../../../partials/DashboardHeader";
import Loading from "../../../components/Modals/Loading";
import URIKeys from "../../../constants/URIKeys";
import {BackLink, PageHeaderTitle} from "../../../components/PageHeader";
import {Light} from "../../../components/Icons";
import {getPointOfService} from "../../../actions";
import PageHeader from "../../../components/PageHeader/PageHeader";
import OPENING_DAY_TYPES from "../../../constants/OpeningDayType";
import {
  convertInputTimeToISODateTime,
  convertISODateToJSDate,
  printInputDateInISODateTime
} from "../../../utils/DateTimeUtility";
import FacilityOpeningDayModal from "../../../components/Modals/FacilityOpeningDayModal/FacilityOpeningDayModal";
import {patch, post, remove} from "../../../utils/FetchUtility";
import BackendURLConstants from "../../../constants/BackendURLConstants";
import DeleteOpeningDayModal from "../../../components/Modals/DeleteOpeningDayModal/DeleteOpeningDayModal";
import {getLanguage} from "../../../utils/LanguageUtility";

/**
 * The {@code PointOfService} class represents the dashboard detail page for a single point of service
 *
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PointOfService extends React.Component {

  static WEEKDAYS = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];


  constructor(props, context) {
    super(props, context);

    this.state = {
      openingDay: undefined,
      openingSchedule: undefined,
      showFacilityOpeningDayModal: false,
      showDeleteOpeningDayModal: false,
    };
  }

  /**
   * Gets points of service by id. 
   */
  componentDidMount() {
    this.getPointOfService();
  }

  getPointOfService = () => {
    const {dispatch, match: {params: {id}}} = this.props;

    dispatch(getPointOfService(id));
  };

  showFacilityOpeningDayModal = (openingDay, openingSchedule) => {
    this.setState({
      showFacilityOpeningDayModal: true,
      openingDay,
      openingSchedule,
    });
  };

  hideFacilityOpeningDayModal = () => {
    this.setState({
      showFacilityOpeningDayModal: false,
      openingDay: undefined,
      openingSchedule: undefined
    });
  };

  onCreateOpeningDay = (openingSchedule) => {
    this.setState({
      showFacilityOpeningDayModal: true,
      openingDay: {
        type: OPENING_DAY_TYPES.SPECIAL
      },
      openingSchedule
    });
  };

  showDeleteOpeningDayModal = (openingDay) => {
    this.setState({
      showDeleteOpeningDayModal: true,
      openingSchedule: undefined,
      openingDay,
    });
  };

  hideDeleteOpeningDayModal = () => {
    this.setState({
      showDeleteOpeningDayModal: false,
      openingSchedule: undefined,
      openingDay: undefined
    });
  };

  onDeleteOpeningDay = async () => {
    const {openingDay} = this.state;

    await remove(BackendURLConstants.OPENING_DAY.replace('$id', openingDay.id), true);
    this.getPointOfService();

    this.hideDeleteOpeningDayModal();
  };

  // todo: split create and update
  onUpdateOpeningDay = async (values) => {
    const {pointOfService} = this.props;

    if (values.id) {
      const oldOpeningDay = pointOfService.facilities
        .flatMap(facility => facility.openingSchedule.openingDays)
        .find(openingDay => openingDay.id === values.id);

      const openingDayData = {
        id: values.id,
        closed: values.closed,
        type: values.type,
        date: values.type === OPENING_DAY_TYPES.SPECIAL ? printInputDateInISODateTime(values.date) : undefined,
        dayOfWeek: values.dayOfWeek,
        openingSchedule: {
          id: values.openingSchedule
        }
      };

      await patch(BackendURLConstants.OPENING_DAY.replace('$id', values.id), JSON.stringify(openingDayData), 'application/json', true);

      const openingHoursData = values.openingHours.map(openingHour => ({
        ...openingHour,
        startTime: convertInputTimeToISODateTime(openingHour.startTime),
        endTime: convertInputTimeToISODateTime(openingHour.endTime),
        appointmentsPerSlot: openingHour.appointmentsPerSlot ? openingHour.appointmentsPerSlot : null
      }));

      // updated
      const updatedOpeningHours = openingHoursData
        .filter(openingHour => openingHour.id);

      // created
      const createdOpeningHours = openingHoursData
        .filter(openingHour => !openingHour.id)
        .map(openingHour => ({
          ...openingHour,
          openingDay: oldOpeningDay._links.self.href
        }));

      // removed
      const removedOpeningHours = oldOpeningDay.openingHours
        .filter(oldOpeningHour => !openingHoursData
          .some(openingHour => openingHour.id === oldOpeningHour.id));

      let promises = [];

      if (updatedOpeningHours.length > 0) {
        promises = [
          ...updatedOpeningHours.map(openingHour => patch(BackendURLConstants.OPENING_HOUR.replace('$id', openingHour.id), JSON.stringify(openingHour), 'application/json', true))
        ];
      }

      if (createdOpeningHours.length > 0) {
        promises = [
          ...promises,
          ...createdOpeningHours.map(openingHour => post(BackendURLConstants.OPENING_HOURS, JSON.stringify(openingHour), 'application/json', true))
        ];
      }

      if (removedOpeningHours.length > 0) {
        promises = [
          ...promises,
          ...removedOpeningHours.map(openingHour => remove(BackendURLConstants.OPENING_HOUR.replace('$id', openingHour.id), true))
        ];
      }

      await Promise.all(promises);

    } else {
      const openingDayData = {
        id: values.id,
        closed: values.closed,
        type: values.type,
        date: values.type === OPENING_DAY_TYPES.SPECIAL ? printInputDateInISODateTime(values.date) : undefined,
        dayOfWeek: values.dayOfWeek,
        openingSchedule: {
          id: values.openingSchedule
        }
      };

      const openingDay = await post(BackendURLConstants.OPENING_DAYS, JSON.stringify(openingDayData), 'application/json', true);

      const openingHoursData = values.openingHours.map(openingHour => ({
        ...openingHour,
        startTime: convertInputTimeToISODateTime(openingHour.startTime),
        endTime: convertInputTimeToISODateTime(openingHour.endTime),
        openingDay: openingDay._links.self.href
      }));

      await Promise.all(
        openingHoursData.map(openingHour => post(BackendURLConstants.OPENING_HOURS, JSON.stringify(openingHour), 'application/json', true))
      );
    }

    this.hideFacilityOpeningDayModal();

    this.getPointOfService();
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}, history, error, loading, pointOfService} = this.props;
    const {showFacilityOpeningDayModal, openingDay, openingSchedule} = this.state;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !pointOfService) {
      return (
        <div id="dashboard-point-of-service-page" className="dashboard-page">
          <DashboardHeader/>
          <PageHeader>
            <BackLink>
              <a onClick={history.goBack}>
                <Light icon="chevron-left"/>&#160;
                <FormattedMessage id="common.button.back-to-previous"/>
              </a>
            </BackLink>
            <PageHeaderTitle>
              <h3>&#160;</h3>
              <FormattedMessage id="point-of-service.page.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    return (
      <div id="dashboard-point-of-service-page" className="dashboard-page">
        <DashboardHeader/>
        <PageHeader>
          <BackLink>
            <a onClick={history.goBack}>
              <Light icon="chevron-left"/>&#160;
              <FormattedMessage id="common.button.back-to-previous"/>
            </a>
          </BackLink>
          <PageHeaderTitle>
            <h3>{pointOfService.name}</h3>
            <FormattedMessage id="point-of-service.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="facilities">
          <Grid>
            <Row>
              <Col>
                {pointOfService.facilities && pointOfService.facilities.map((facility) => {
                  const {openingSchedule: {openingDays} = {}} = facility;

                  return (
                    <div id={facility.id} key={facility.id}>
                      <Row className="actions">
                        <Col xs={9}>
                          <h4>{formatMessage({id: `facilities.type.${facility.type}`})}</h4>
                        </Col>
                        <Col xs={3}>
                          <Button bsStyle="primary" bsSize="small" className="pull-right"
                                  onClick={() => this.onCreateOpeningDay(facility.openingSchedule)}>
                            <Light icon="plus-square"/>
                          </Button>
                        </Col>
                      </Row>

                      <Table responsive hover bordered striped condensed>
                        <thead>
                        <tr>
                          <th><FormattedMessage id="opening-day.day-of-week-or-date"/></th>
                          <th><FormattedMessage id="opening-day.type"/></th>
                          <th><FormattedMessage id="opening-day.closed"/></th>
                          <th><FormattedMessage id="opening-day.opening-hours"/></th>
                          <th><FormattedMessage id="opening-hour.appointments"/></th>
                          <th>&#160;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {openingDays.map((openingDay) => {
                          const isSpecial = openingDay.type === OPENING_DAY_TYPES.SPECIAL;

                          return (
                            <tr key={openingDay.id}>
                              <td>
                                {isSpecial ? (
                                  convertISODateToJSDate(openingDay.date).toLocaleDateString(getLanguage())
                                ) : (
                                  <FormattedMessage
                                    id={`date.day.${PointOfService.WEEKDAYS.indexOf(openingDay.dayOfWeek)}`}/>
                                )}
                              </td>
                              <td>
                                <FormattedMessage id={`opening-day.type.${openingDay.type.toLowerCase()}`}/>
                              </td>
                              <td>
                                {openingDay.closed ? (
                                  <FormattedMessage id="common.yes"/>
                                ) : (
                                  <FormattedMessage id="common.no"/>
                                )}
                              </td>
                              <td>
                                {openingDay.openingHours.map(openingHour => {
                                  const startTime = openingHour.startTime.toLocaleTimeString(getLanguage(), {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                  });
                                  const endTime = openingHour.endTime.toLocaleTimeString(getLanguage(), {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                  });
                                  return (
                                    <span key={openingHour.id} className="opening-hour">
                                      {`${startTime} - ${endTime}`}
                                    </span>
                                  );
                                })}
                              </td>
                              <td>
                                {openingDay.openingHours.map(openingHour => {
                                  const appointments = openingHour.appointmentsPerSlot
                                    ? openingHour.appointmentsPerSlot
                                    : facility.appointmentsPerSlot;
                                  return (
                                    <span key={openingHour.id} className="opening-hour">
                                      {appointments}
                                    </span>
                                  );
                                })}
                              </td>
                              <td className="table-actions text-center">
                                <Button bsStyle="primary" bsSize="small"
                                        onClick={() => this.showFacilityOpeningDayModal(openingDay, facility.openingSchedule)}>
                                  <Light icon="file-alt"/>&nbsp;
                                </Button>
                                {openingDay.type === OPENING_DAY_TYPES.SPECIAL && (
                                  <Button bsStyle="danger" bsSize="small"
                                          onClick={() => this.showDeleteOpeningDayModal(openingDay)}>
                                    <Light icon="trash-alt"/>&nbsp;
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </Table>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Grid>
        </section>


        <FacilityOpeningDayModal
          show={showFacilityOpeningDayModal}
          openingDay={openingDay}
          openingSchedule={openingSchedule}
          onSubmit={this.onUpdateOpeningDay}
          onCancel={this.hideFacilityOpeningDayModal}
        />

        <DeleteOpeningDayModal show={this.state.showDeleteOpeningDayModal}>
          <FormattedMessage id="point-of-service.page.modal.delete-confirmation.body" tagName="p"/>
          <Button bsStyle="primary" onClick={() => this.onDeleteOpeningDay()}>
            <FormattedMessage id="common.button.confirm"/>
          </Button>
          <Button bsStyle="danger" onClick={() => this.hideDeleteOpeningDayModal()}>
            <FormattedMessage id="common.button.cancel"/>
          </Button>
        </DeleteOpeningDayModal>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getPointOfService} = state;

  return {
    error: common.error,
    loading: common.loading,
    pointOfService: getPointOfService.pointOfServiceObject
  };
}

export default connect(mapStateToProps)(injectIntl(PointOfService));
