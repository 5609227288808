import React from "react";

/**
 * The {@code PricingBoxTitle} class represents a pricing box title.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PricingBoxTitle extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div className="pricing-box-title">
                {this.props.children}
            </div>
        );
    }
}

export default PricingBoxTitle;
