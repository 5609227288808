import Actions from "../constants/Actions";

function postEmail() {
    return {
        type: Actions.POST_EMAIL
    }
}

function postEmailFailed(status) {
    return {
        type: Actions.POST_EMAIL_FAILED,
        status: status
    }
}

function postEmailSuccess() {
    return {
        type: Actions.POST_EMAIL_SUCCESS
    }
}

function postEmailReset() {
    return {
        type: Actions.POST_EMAIL_RESET
    }
}

export function sendEmail(url, data) {
    return dispatch => {
        dispatch(postEmail());
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.status);
                }
            })
            .then(dispatch(postEmailSuccess()))
            .catch(error => dispatch(postEmailFailed(error.status)));
    }
}

export function resetEmail() {
    return dispatch => {
        dispatch(postEmailReset());
    }
}
