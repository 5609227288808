/**
 * The {@code ScreenSizes} constant class contains the different screen sizes.
 *
 * @type {int}
 */
const ScreenSizes = {
    XS_MIN: 480,
    XS_MAX: 767,
    SM_MIN: 768,
    SM_MAX: 991,
    MD_MIN: 992,
    MD_MAX: 1199,
    LG_MIN: 1200,
};

export default ScreenSizes;
