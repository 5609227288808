import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, ButtonGroup, Col, Grid, Row, Table} from "react-bootstrap";
import {getUsername, hasRole} from "../../../utils/AuthenticationUtility";
import {getEmployee, getFacilityAppointments} from "../../../actions";
import {Light} from "../../../components/Icons";
import {convertISODateTimeToJSDate} from "../../../utils/DateTimeUtility";
import {Redirect} from "react-router";
import DashboardHeader from "../../../partials/DashboardHeader";
import {PageHeader, PageHeaderTitle} from "../../../components/PageHeader";
import Loading from "../../../components/Modals/Loading";
import EditEntityModal from "../../../components/Modals/EditEntityModal/EditEntityModal";
import AppointmentForm from "../../../components/Forms/AppointmentForm";
import PointOfServiceSelectForm from "../../../components/Forms/PointOfServiceSelectForm";
import {connect} from "react-redux";
import URIKeys from "../../../constants/URIKeys";
import {Link} from "react-router-dom";
import {NUTRITIONIST_CONSULTANCY_STATUS} from "../../../constants/NutritionistConsultancyStatus";
import Solid from "../../../components/Icons/Solid";
import {getLanguage} from "../../../utils/LanguageUtility";
import USER_ROLES from "../../../constants/UserRoles";

/**
 * The {@code GodChildren} class represents the dashboard godchildren page.
 *
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Overview extends React.Component {

  /**
   * Creates a new instance of the {@link Overview} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      showEditAppointmentModal: false,
      appointment: undefined
    };
  }

  /**
   * Gets the user. 
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getEmployee(getUsername()));
  }

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}, error, loading, employee, facilityAppointmentCollection} = this.props;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !employee) {
      return (
        <div id="dashboard-overview-page" className="dashboard-page">
          <DashboardHeader/>
          <PageHeader>
            <PageHeaderTitle>
              <FormattedMessage id="overview.page.title" tagName="h3"/>
              <FormattedMessage id="overview.page.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    return (
      <div id="dashboard-overview-page" className="dashboard-page">
        <DashboardHeader/>
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="overview.page.title" tagName="h3"/>
            <FormattedMessage id="overview.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>

        {this.renderGodchildrenSection()}
        {this.renderAppointmentsSection()}

        <EditEntityModal show={this.state.showEditAppointmentModal}>
          <FormattedMessage id="form.appointment.title.edit" tagName="h4"/>
          <AppointmentForm appointment={this.state.appointment} employee={employee}
                           date={facilityAppointmentCollection && facilityAppointmentCollection[0] && facilityAppointmentCollection[0].date}
                           toggleEditAppointmentModal={() => this.toggleEditAppointmentModal(undefined, false)}/>
        </EditEntityModal>
      </div>
    );
  }

  /**
   * Renders the godchildren section.
   *
   * @returns {*} the godchildren section
   */
  renderGodchildrenSection = () => {
    const {intl: {formatMessage}, employee} = this.props;

    return (
      <section className="godchildren">
        <Grid>
          <Row>
            <Col xs={12}>
              <FormattedMessage id="overview.page.godchilderen.subtitle" tagName="h4"/>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Table striped bordered condensed hover responsive>
                <thead>
                <tr>
                  <th><FormattedMessage id="label.first-name"/></th>
                  <th><FormattedMessage id="label.last-name"/></th>
                  <th><FormattedMessage id="label.username"/></th>
                  <th><FormattedMessage id="label.phone-number"/></th>
                  <th><FormattedMessage id="label.credits"/></th>
                  <th><FormattedMessage id="label.credits-expiry-date"/></th>
                  <th><FormattedMessage id="label.active"/></th>
                  <th><FormattedMessage id="label.additional-info"/></th>
                  <th>&#160;</th>
                </tr>
                </thead>
                <tbody>
                {employee.godchildren.map((user) => (
                  <tr key={user.username}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.username}</td>
                    <td>{user.address.phoneNumber}</td>
                    <td>{user.credits}</td>
                    <td>{user.creditsExpiryDate}</td>
                    <td>{user.active ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'})}</td>
                    <td className="text-center">
                      {(user.nutritionistConsultancyStatus === NUTRITIONIST_CONSULTANCY_STATUS.INITIAL_APPOINTMENT_PLANNED
                        || user.nutritionistConsultancyStatus === NUTRITIONIST_CONSULTANCY_STATUS.FOLLOWUP_APPOINTMENT_OPEN) && (
                        <Solid icon="utensils-alt" classNames="icon-success"/>
                      )}
                    </td>
                    <td className="text-center">
                      <Button bsStyle="primary" bsSize="small" onClick={() => this.onEditUser(user.username)}>
                        <Light icon="edit"/>
                      </Button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Grid>
      </section>
    );
  };

  /**
   * Renders the appointments section.
   *
   * @returns {*} the appointments section
   */
  renderAppointmentsSection = () => {
    const {employee} = this.props;

    if (hasRole(USER_ROLES.TRAINER) || hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN)) {
      return (
        <section className="appointments">
          <Grid>
            <Row>
              <Col xs={12}>
                <FormattedMessage id="overview.page.appointments.subtitle" tagName="h4"/>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <PointOfServiceSelectForm pointsOfService={employee.pointsOfService}/>
              </Col>
            </Row>
            {this.renderAppointmentsOverview()}
          </Grid>
        </section>
      );
    }
  };

  /**
   * Renders the appointment's overview.
   *
   * @returns {*} the appointment's overview
   */
  renderAppointmentsOverview = () => {
    return (
      <div className="appointments-overview">
        {this.renderAppointmentsNavigation()}
        {this.renderAppointmentsTable()}
      </div>
    );
  };

  /**
   * Renders the appointment's overview navigation
   *
   * @returns {*}
   */
  renderAppointmentsNavigation = () => {
    return (
      <Row>
        <Col xs={6}>
          {this.renderNavigationDate()}
        </Col>
        <Col xs={6}>
          {this.renderNavigationButtons()}
        </Col>
      </Row>
    )
  };

  /**
   * Renders the month and year of the first of the selected week.
   *
   * @returns {*} the month and year
   */
  renderNavigationDate = () => {
    const {facilityAppointmentCollection, selectedFacility} = this.props;

    let result = undefined;
    if (selectedFacility !== 'initial' && facilityAppointmentCollection && facilityAppointmentCollection[0].appointments) {
      result = <h3>{facilityAppointmentCollection[0].date.toLocaleDateString(getLanguage())}</h3>;
    }
    return result;
  };

  /**
   * Renders the navigation buttons.
   *
   * @returns {*} the navigation buttons
   */
  renderNavigationButtons = () => {
    const {facilityAppointmentCollection, selectedFacility} = this.props;

    let result = undefined;
    if (selectedFacility !== 'initial' && facilityAppointmentCollection && facilityAppointmentCollection[0].appointments) {
      const selectedDate = facilityAppointmentCollection[0].date;

      const currentDate = new Date();
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);
      currentDate.setMilliseconds(0);

      let disabled = false;
      if (selectedDate.getTime() === currentDate.getTime()) {
        disabled = true;
      }

      result = (
        <ButtonGroup className="pull-right">
          <Button onClick={() => this.onPreviousDayClick()}>
            <Light icon="chevron-left"/>
          </Button>
          <Button disabled={disabled} onClick={() => this.onTodayClick(disabled)}>
            <FormattedMessage id="common.button.today"/>
          </Button>
          <Button onClick={() => this.onNextDayClick(disabled)}>
            <Light icon="chevron-right"/>
          </Button>
        </ButtonGroup>
      );
    }
    return result;
  };

  /**
   * Renders the appointment's table.
   *
   * @returns {*} the appointment's table
   */
  renderAppointmentsTable = () => {
    const {selectedFacility} = this.props;

    let result;

    if (selectedFacility === 'initial') {
      result = (
        <Row>
          <Col xs={12}>
            &#160;
          </Col>
        </Row>
      );
    } else {
      result = (
        <Row>
          <Col xs={12}>
            <Table striped bordered condensed hover responsive>
              <thead>
              <tr>
                <th><FormattedMessage id="opening-hour.start-time"/></th>
                <th><FormattedMessage id="opening-hour.end-time"/></th>
                <th><FormattedMessage id="label.user"/></th>
                <th><FormattedMessage id="label.employee"/></th>
                <th><FormattedMessage id="appointment.cardio"/></th>
                <th><FormattedMessage id="appointment.module"/></th>
                <th><FormattedMessage id="appointment.submodule"/></th>
                <th><FormattedMessage id="appointment.comment"/></th>
                <th>&#160;</th>
              </tr>
              </thead>
              {this.renderAppointmentsTableBody()}
            </Table>
          </Col>
        </Row>
      );
    }

    return result;
  };

  /**
   * Renders the body of the appointments table.
   *
   * @returns {*} the body of the appointments table
   */
  renderAppointmentsTableBody = () => {
    const {intl: {formatMessage}, facilityAppointmentCollection} = this.props;

    let result;

    if (facilityAppointmentCollection && facilityAppointmentCollection[0].appointments.length > 0) {
      result = (
        <tbody>
        {facilityAppointmentCollection[0].appointments.map(appointment => (
          <tr key={appointment.id}>
            <td>{convertISODateTimeToJSDate(appointment.date).toLocaleTimeString(getLanguage(), {
              hour: '2-digit',
              minute: '2-digit'
            })}</td>
            <td>{this.getEndDate(appointment)}</td>
            <td><Link
              to={formatMessage({id: URIKeys.DASHBOARD_USER}).replace(':id', appointment.user.username)}>{appointment.user.firstName} {appointment.user.lastName}</Link>
            </td>
            <td>{appointment.employee ? appointment.employee.firstName + ' ' + appointment.employee.lastName : ''}</td>
            <td>{appointment.cardio}</td>
            <td>{appointment.module}</td>
            <td>
              {appointment.submodules.map((submodule, j) => (
                <span key={j}>
                  <FormattedMessage id={'submodule.' + submodule}/>
                  {(j + 1) < appointment.submodules.length ? ', ' : ''}
                </span>
              ))}
            </td>
            <td>{appointment.comment}</td>
            <td className="text-center">
              <Button bsStyle="primary" bsSize="small"
                      onClick={() => this.toggleEditAppointmentModal(appointment, true)}>
                <Light icon="edit"/>
              </Button>
            </td>
          </tr>
        ))}
        </tbody>
      );
    } else {
      result = (
        <tbody>
        <tr>
          <td colSpan="9">
            <FormattedMessage id="overview.page.appointments.no-appointments"/>
          </td>
        </tr>
        </tbody>
      );
    }

    return result;
  };

  /**
   * Returns the end date of the appointment.
   *
   * @param appointment
   * @returns {string} the end date
   */
  getEndDate = (appointment) => {
    let result = convertISODateTimeToJSDate(appointment.date);
    result.setMinutes(result.getMinutes() + appointment.facility.appointmentDuration);
    return result.toLocaleTimeString(getLanguage(), {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  /**
   * Edits the user.
   */
  onEditUser = (id) => {
    const {intl: {formatMessage}, history} = this.props;
    history.push(formatMessage({id: URIKeys.DASHBOARD_USER}).replace(':id', id));
  };

  /**
   * Toggles the delete confirmation modal.
   *
   * @param appointment the appointment
   * @param value the value
   */
  toggleEditAppointmentModal = (appointment, value = true) => {
    this.setState({
      showEditAppointmentModal: value,
      appointment: appointment
    });
  };

  /**
   * Handles the click event for the next day button.
   */
  onNextDayClick = () => {
    const {dispatch, selectedFacility, facilityAppointmentCollection} = this.props;

    if (facilityAppointmentCollection && facilityAppointmentCollection[0].appointments) {
      const date = new Date(facilityAppointmentCollection[0].date);
      date.setDate(date.getDate() + 1);

      dispatch(getFacilityAppointments(selectedFacility, date, undefined, true));
    }
  };

  /**
   * Handles the click event for the next week button.
   *
   * @param disabled indicates whether the button is disabled, or not.
   */
  onPreviousDayClick = (disabled) => {
    if (!disabled) {
      const {dispatch, selectedFacility, facilityAppointmentCollection} = this.props;

      if (facilityAppointmentCollection && facilityAppointmentCollection[0].appointments) {
        const date = new Date(facilityAppointmentCollection[0].date);
        date.setDate(date.getDate() - 1);

        dispatch(getFacilityAppointments(selectedFacility, date, undefined, true));
      }
    }
  };

  /**
   * Handles the click event for the today button.
   *
   * @param disabled indicates whether the button is disabled, or not.
   */
  onTodayClick = (disabled) => {
    if (!disabled) {
      const {dispatch, selectedFacility} = this.props;

      dispatch(getFacilityAppointments(selectedFacility, new Date(), undefined, true));
    }
  };
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns {{facilityAppointmentCollection: *, selectedFacility: *, error: *, loading: *, employee: *}}
 */
function mapStateToProps(state) {
  const {common, employee, selectFacility, getFacilityAppointments} = state;
  return {
    error: common.error,
    loading: common.loading,
    employee: employee.employee,
    selectedFacility: selectFacility.selectedFacility,
    facilityAppointmentCollection: getFacilityAppointments.facilityAppointmentCollection,
  };
}

export default connect(mapStateToProps)(injectIntl(Overview));
