import React from "react";
import {Grid, Row} from "react-bootstrap";

/**
 * The {@code RecipeDetails} class represents the recipe details.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class RecipeDetails extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <section>
                <Grid>
                    <Row className="recipe-details">
                        {this.props.children}
                    </Row>
                </Grid>
            </section>
        );
    }
}

export default RecipeDetails;
