import React from "react";
import {Grid, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import URIKeys from "../../constants/URIKeys";
import {ImageBox} from "../../components/ImageBox";

/**
 * The {@code Services} class represents the services page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Services extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}} = this.props;

    return (
      <div id="services-page">
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="services.page.title" tagName="h3"/>
            <FormattedMessage id="services.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="services">
          <Grid>
            <Row>
              <ImageBox xs={12} sm={6} lg={4}>
                <ImageBox.ImageWrapper url={formatMessage({id: URIKeys.PERSONAL_TRAINING})}
                                       lowRes={require('../../images/pages/services/personal-training-tiny.jpeg')}
                                       highRes={require('../../images/pages/services/personal-training.jpeg')}>
                </ImageBox.ImageWrapper>
                <ImageBox.Name url={formatMessage({id: URIKeys.PERSONAL_TRAINING})}>
                  {formatMessage({id: 'services.section.personal-training.title'})}
                </ImageBox.Name>
              </ImageBox>
              <ImageBox xs={12} sm={6} lg={4}>
                <ImageBox.ImageWrapper url={formatMessage({id: URIKeys.PERSONAL_TRAINING_AT_HOME})}
                                       lowRes={require('../../images/pages/services/personal-training-at-home.svg')}
                                       highRes={require('../../images/pages/services/personal-training-at-home.jpg')}>
                </ImageBox.ImageWrapper>
                <ImageBox.Name url={formatMessage({id: URIKeys.PERSONAL_TRAINING_AT_HOME})}>
                  {formatMessage({id: 'services.section.personal-training-at-home.title'})}
                </ImageBox.Name>
              </ImageBox>
              <ImageBox xs={12} sm={6} lg={4}>
                <ImageBox.ImageWrapper url={formatMessage({id: URIKeys.SPINNING})}
                                       lowRes={require('../../images/pages/services/spinning-tiny.jpeg')}
                                       highRes={require('../../images/pages/services/spinning.jpeg')}>
                </ImageBox.ImageWrapper>
                <ImageBox.Name url={formatMessage({id: URIKeys.SPINNING})}>
                  {formatMessage({id: 'page.spinning.title'})}
                </ImageBox.Name>
              </ImageBox>
              <ImageBox xs={12} sm={6} lg={4}>
                <ImageBox.ImageWrapper url={formatMessage({id: URIKeys.SPORT_MASSAGE})}
                                       lowRes={require('../../images/pages/services/sport-massage.svg')}
                                       highRes={require('../../images/pages/services/sport-massage.jpg')}>
                </ImageBox.ImageWrapper>
                <ImageBox.Name url={formatMessage({id: URIKeys.SPORT_MASSAGE})}>
                  {formatMessage({id: 'services.section.sport-massage.title'})}
                </ImageBox.Name>
              </ImageBox>
              <ImageBox xs={12} sm={6} lg={4}>
                <ImageBox.ImageWrapper url={formatMessage({id: URIKeys.COMPANIES})}
                                       lowRes={require('../../images/pages/services/companies.svg')}
                                       highRes={require('../../images/pages/services/companies.jpg')}>
                </ImageBox.ImageWrapper>
                <ImageBox.Name url={formatMessage({id: URIKeys.COMPANIES})}>
                  {formatMessage({id: 'services.section.companies.title'})}
                </ImageBox.Name>
              </ImageBox>
              <ImageBox xs={12} sm={6} lg={4}>
                <ImageBox.ImageWrapper url={formatMessage({id: URIKeys.FOOD_CONSULTANT})}
                                       lowRes={require('../../images/pages/services/food-consultant.svg')}
                                       highRes={require('../../images/pages/services/food-consultant.jpg')}>
                </ImageBox.ImageWrapper>
                <ImageBox.Name url={formatMessage({id: URIKeys.FOOD_CONSULTANT})}>
                  {formatMessage({id: 'services.section.food-consultant.title'})}
                </ImageBox.Name>
              </ImageBox>
              <ImageBox xs={12} sm={6} lg={4}>
                <ImageBox.ImageWrapper url={formatMessage({id: URIKeys.ACADEMY})}
                                       lowRes={require('../../images/pages/services/academy.svg')}
                                       highRes={require('../../images/pages/services/academy.jpeg')}>
                </ImageBox.ImageWrapper>
                <ImageBox.Name url={formatMessage({id: URIKeys.ACADEMY})}>
                  {formatMessage({id: 'services.section.academy.title'})}
                </ImageBox.Name>
              </ImageBox>
              <ImageBox xs={12} sm={6} lg={4}>
                <ImageBox.ImageWrapper url={formatMessage({id: URIKeys.SCHOOL_ACTIVITIES})}
                                       lowRes={require('../../images/pages/services/school-activities.svg')}
                                       highRes={require('../../images/pages/services/school-activities.jpg')}>
                </ImageBox.ImageWrapper>
                <ImageBox.Name url={formatMessage({id: URIKeys.SCHOOL_ACTIVITIES})}>
                  {formatMessage({id: 'services.section.school-activities.title'})}
                </ImageBox.Name>
              </ImageBox>
              <ImageBox xs={12} sm={6} lg={4}>
                <ImageBox.ImageWrapper url={formatMessage({id: URIKeys.ENERGIZERS})}
                                       lowRes={require('../../images/pages/services/energizers.svg')}
                                       highRes={require('../../images/pages/services/energizers.jpg')}>
                </ImageBox.ImageWrapper>
                <ImageBox.Name url={formatMessage({id: URIKeys.ENERGIZERS})}>
                  {formatMessage({id: 'page.enegizers.title'})}
                </ImageBox.Name>
              </ImageBox>
              <ImageBox xs={12} sm={6} lg={4}>
                <ImageBox.ImageWrapper url={formatMessage({id: URIKeys.CAREERS})}
                                       lowRes={require('../../images/pages/services/careers-tiny.jpeg')}
                                       highRes={require('../../images/pages/services/careers.jpeg')}>
                </ImageBox.ImageWrapper>
                <ImageBox.Name url={formatMessage({id: URIKeys.CAREERS})}>
                  {formatMessage({id: 'page.careers.title'})}
                </ImageBox.Name>
              </ImageBox>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

export default injectIntl(Services);
