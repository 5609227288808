import React from "react";
import SVG from "../../images/icons/fa-brands.svg";

/**
 * The {@code Brand} class represents a Font Awesome icon from the brand icon sprite.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Brand extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <svg className="icon">
                <use xlinkHref={SVG + '#' + this.props.icon}>&#160;</use>
            </svg>
        );
    }
}

export default Brand;
