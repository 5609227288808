import React from "react";
import {FormattedMessage} from "react-intl";
import {Col, Grid, Row} from "react-bootstrap";
import URIKeys from "../../constants/URIKeys";
import LocalizedLink from "../Intl/LocalizedLink";
import {Light} from "../Icons";

/**
 * The {@code InactiveUserMessage} class represents an ingredient.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
const InactiveUserPage = () => (
  <div id="dashboard-inactive-page" className="error-page">
    <section>
      <Grid>
        <Row>
          <Col xsHidden sm={6} className="error-icon">
            <Light icon="exclamation-circle" classNames="icon-warning"/>
          </Col>
          <Col xs={12} sm={6} className="error-content">
            <FormattedMessage id='inactive.page.header' tagName="h4"/>
            <FormattedMessage id='inactive.page.paragraph' tagName="p"/>
            <LocalizedLink id={URIKeys.DASHBOARD_SHOP} className="btn btn-primary">
              <FormattedMessage id="inactive.page.button"/>
            </LocalizedLink>
          </Col>
        </Row>
      </Grid>
    </section>
  </div>
);

export default InactiveUserPage;
