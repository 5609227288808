import React from "react";

/**
 * The {@code PricingBoxPriceValue} class represents a pricing box price value.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PricingBoxPriceValue extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <span className="pricing-box-price-value">
                {this.props.children}
            </span>
        );
    }
}

export default PricingBoxPriceValue;
