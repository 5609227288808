import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

/**
 * The {@code FadeInRight} class represents a fade in right animation.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class FadeInRight extends React.Component {

    /** 
     * The class constructor. 
     */
    constructor(props) {
        super(props);

        let name = 'fade-in-right';
        if (this.props.delay !== undefined) {
            name += '-delay-' + this.props.delay;
        }

        this.state = {
            name: name
        };
    }

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
          <TransitionGroup component="span">
            <CSSTransition
              classNames={this.state.name}
              appear={true}
              enter={false}
              leave={false}
              timeout={{enter: 4000}}
            >
              {this.props.children}
            </CSSTransition>
          </TransitionGroup>
        );
    }
}

export default FadeInRight;
