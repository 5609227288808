import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, Grid, Row, Table} from "react-bootstrap";
import {Pagination} from "@react-bootstrap/pagination";
import {getUsername} from "../../../utils/AuthenticationUtility";
import {Light} from "../../../components/Icons";
import DashboardHeader from "../../../partials/DashboardHeader";
import {PageHeader, PageHeaderTitle} from "../../../components/PageHeader";
import Loading from "../../../components/Modals/Loading";
import {connect} from "react-redux";
import {getIntakeFormsForEmployee} from "../../../actions/IntakeForms";
import URIKeys from "../../../constants/URIKeys";
import {convertISODateTimeToJSDate} from "../../../utils/DateTimeUtility";
import {getLanguage} from "../../../utils/LanguageUtility";

class IntakeForms extends React.Component {

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getIntakeFormsForEmployee(getUsername(), undefined, 0));
  };

  onEditIntakeForm = (intakeFormId) => {
    const {intl: {formatMessage}, history} = this.props;

    history.push(formatMessage({id: URIKeys.DASHBOARD_INTAKE_FORM}).replace(':id', intakeFormId));
  };

  togglePage = (page) => {
    const {dispatch} = this.props;
    dispatch(getIntakeFormsForEmployee(getUsername(), undefined, --page));
  };

  render() {
    const {loading, intakeFormsCollection} = this.props;

    if (loading > 0) {
      return (
        <div id="dashboard-intake-forms-page" className="dashboard-page">
          <DashboardHeader/>
          <PageHeader>
            <PageHeaderTitle>
              <FormattedMessage id="intake-forms.page.title" tagName="h3"/>
              <FormattedMessage id="intake-forms.page.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    return (
      <div id="dashboard-intake-forms-page" className="dashboard-page">
        <DashboardHeader/>
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="intake-forms.page.title" tagName="h3"/>
            <FormattedMessage id="intake-forms.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>

        <section className="intake-forms">
          <Grid>
            <Row>
              <Col xs={12}>
                <Table striped bordered condensed hover responsive>
                  <thead>
                  <tr>
                    <th><FormattedMessage id="label.first-name"/></th>
                    <th><FormattedMessage id="label.last-name"/></th>
                    <th><FormattedMessage id="label.date"/></th>
                    <th><FormattedMessage id="label.completed"/></th>
                    <th>&#160;</th>
                  </tr>
                  </thead>
                  <tbody>
                  {intakeFormsCollection && intakeFormsCollection.intakeForms.length === 0 && (
                    <tr>
                      <td colSpan={5}>
                        <FormattedMessage id="intake-forms.page.empty"/>
                      </td>
                    </tr>
                  )}
                  {intakeFormsCollection && intakeFormsCollection.intakeForms.length > 0 && intakeFormsCollection.intakeForms.map((intakeForm) => (
                    <tr key={intakeForm.id}>
                      <td>{intakeForm.user.firstName}</td>
                      <td>{intakeForm.user.lastName}</td>
                      <td>{convertISODateTimeToJSDate(intakeForm.date).toLocaleDateString(getLanguage())}</td>
                      <td><FormattedMessage id={`common.${intakeForm.processed ? 'yes' : 'no'}`}/></td>
                      <td className="text-center">
                        <Button bsStyle="primary" bsSize="small" onClick={() => this.onEditIntakeForm(intakeForm.id)}>
                          <Light icon="edit"/>
                        </Button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Grid>
        </section>

        {intakeFormsCollection && intakeFormsCollection.intakeForms.length > 0 && (
          <section className="pagination-links">
            <Grid>
              <Row>
                <Col xs={12}>
                  <Pagination
                    bsSize="medium"
                    items={(intakeFormsCollection.page.totalPages)}
                    activePage={intakeFormsCollection.page.number + 1}
                    onSelect={this.togglePage}
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                  />
                </Col>
              </Row>
            </Grid>
          </section>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {common, intakeForms} = state;
  return {
    error: common.error,
    loading: common.loading,
    intakeFormsCollection: intakeForms.intakeFormsCollection,
  };
}

export default connect(mapStateToProps)(injectIntl(IntakeForms));
