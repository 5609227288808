/**
 * The {@code USER_ROLES} constant class contains the different roles (authorities) of a user.
 *
 * @type {string}
 */
const USER_ROLES = {
  ADMIN: 'ROLE_ADMIN',
  MANAGER: 'ROLE_MANAGER',
  TRAINER: 'ROLE_TRAINER',
  USER: 'ROLE_USER',
  NUTRITIONIST: 'ROLE_NUTRITIONIST'
};

export default USER_ROLES;
