import React from "react";
import { Modal } from "react-bootstrap";
import Light from "../../Icons/Light";

const ConfirmationModal = ({children, show}) => {
  return (
    <Modal show={show} backdrop={true} className="info-modal text-center">
      <Modal.Header>
        <Light icon="check-circle"/>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  )
};

export default ConfirmationModal;
