import React from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import {FormattedHTMLMessage, injectIntl} from "react-intl";
import {Grid, Row} from "react-bootstrap";
import {getUsername} from "../../../utils/AuthenticationUtility";
import DashboardHeader from "../../../partials/DashboardHeader";
import Loading from "../../../components/Modals/Loading";
import {
  Appointment,
  BodyComposition,
  BodyMass,
  Credits,
  GirthMeasurements,
  MetabolicAge,
  VisceralFat,
  Weight
} from "../../../components/Performance";
import {
  getAppointmentsUser,
  getUser,
  getUserBodyCompositionMeasurements,
  getUserGirthMeasurements
} from "../../../actions";
import URIKeys from "../../../constants/URIKeys";
import {convertISODateTimeToJSDate} from "../../../utils/DateTimeUtility";
import InactiveUserPage from "../../../components/InactiveUserPage/InactiveUserPage";

/**
 * The {@code Performance} class represents the dashboard performance page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Performance extends React.Component {

  /**
   * Gets the user. 
   */
  componentDidMount() {
    const {dispatch} = this.props;

    let username = getUsername();

    dispatch(getUser(username));
    dispatch(getAppointmentsUser(username));
    dispatch(getUserBodyCompositionMeasurements(username, undefined, undefined));
    dispatch(getUserGirthMeasurements(username, undefined, undefined));
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, userObject, userAppointmentCollection, userBodyCompositionMeasurementCollection, userGirthMeasurementCollection} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0
      || !userObject
      || userAppointmentCollection === null
      || userBodyCompositionMeasurementCollection === null
      || userGirthMeasurementCollection === null) {
      return (
        <div id="dashboard-performance-page" className="dashboard-page">
          <DashboardHeader/>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    if (!userObject.active) {
      return (
        <div id="dashboard-performance-page" className="dashboard-page">
          <DashboardHeader/>
          <InactiveUserPage/>
        </div>
      );
    }

    let lastBCMeasurement = null;
    let lastFiveBCMeasurements = null;
    if (userBodyCompositionMeasurementCollection.measurements.length > 0) {
      lastBCMeasurement = userBodyCompositionMeasurementCollection.measurements[userBodyCompositionMeasurementCollection.measurements.length - 1];
      if (userBodyCompositionMeasurementCollection.measurements <= 5) {
        lastFiveBCMeasurements = userBodyCompositionMeasurementCollection.measurements;
      } else {
        lastFiveBCMeasurements = userBodyCompositionMeasurementCollection.measurements.slice(Math.max(userBodyCompositionMeasurementCollection.measurements.length - 5, 1));
      }
    }

    let lastFiveGirthMeasurements = null;
    if (userGirthMeasurementCollection.measurements.length > 0) {
      if (userGirthMeasurementCollection.measurements.length <= 5) {
        lastFiveGirthMeasurements = userGirthMeasurementCollection.measurements;
      } else {
        lastFiveGirthMeasurements = userGirthMeasurementCollection.measurements.slice(Math.max(userGirthMeasurementCollection.measurements.length - 5, 1));
      }
    }

    let appointments = [];
    if (userAppointmentCollection.appointments.length > 0) {
      let currentDate = new Date();

      userAppointmentCollection.appointments.forEach(function (appointment) {
        if (convertISODateTimeToJSDate(appointment.date) > currentDate) {
          appointments.push(appointment);
        }
      });

      appointments.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
    }

    let nextAppointment = null;
    if (appointments.length > 0) {
      nextAppointment = appointments[0];
    }

    return (
      <div id="dashboard-performance-page" className="dashboard-page">
        <DashboardHeader/>

        <Grid>
          <section className="intro">
            <FormattedHTMLMessage id="performance.page.intro" values={{firstName: userObject.firstName}}/>
          </section>

          <section className="tiles">
            <Row>
              <Weight measurement={lastBCMeasurement}/>
              <Appointment appointment={nextAppointment} user={userObject}/>
              <Credits user={userObject}/>
            </Row>
            <Row>
              <BodyMass measurement={lastBCMeasurement}/>
              <VisceralFat measurement={lastBCMeasurement}/>
              <MetabolicAge measurement={lastBCMeasurement} user={userObject}/>
            </Row>
            <Row className="graphs">
              <BodyComposition measurements={lastFiveBCMeasurements} user={userObject}/>
              <GirthMeasurements measurements={lastFiveGirthMeasurements} user={userObject}/>
            </Row>
          </section>
        </Grid>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getUser, appointmentsUser, getUserBodyCompositionMeasurements, getUserGirthMeasurements} = state;
  return {
    error: common.error,
    loading: common.loading,
    userObject: getUser.userObject,
    userAppointmentCollection: appointmentsUser.userAppointmentCollection,
    userBodyCompositionMeasurementCollection: getUserBodyCompositionMeasurements.userBodyCompositionMeasurementCollection,
    userGirthMeasurementCollection: getUserGirthMeasurements.userGirthMeasurementCollection
  };
}

export default connect(mapStateToProps)(injectIntl(Performance));
