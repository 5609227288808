import React from "react";
import {FormattedMessage} from "react-intl";
import {Caption, Tile} from "../Tile";
import {Light} from "../Icons";
import {convertISODateTimeToJSDate} from "../../utils/DateTimeUtility";
import {getLanguage} from "../../utils/LanguageUtility";

/**
 * The {@code Credits} class represents the credits tile.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Credits extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {user} = this.props;

    let date = convertISODateTimeToJSDate(user.creditsExpiryDate);

    let icon = 'icon-success';
    if (user.credits <= 3 || date.setDate(date.getDate() + 3) <= new Date()) {
      icon = 'icon-danger';
    } else if (user.credits <= 5 || date.setDate(date.getDate() + 7) <= new Date()) {
      icon = 'icon-warning';
    }

    const expiryDate = new Date(user.creditsExpiryDate).toLocaleDateString(getLanguage());

    return (
      <Tile xs={12} md={4}>
        <FormattedMessage id="performance.page.tile.credits.title" tagName="h5"/>
        <Light icon="coins" classNames={icon}/>
        <Caption>
          <FormattedMessage id="performance.page.tile.credits.label.credits"
                            tagName="h5"
                            values={{
                              credits: user.credits
                            }}/>
          <FormattedMessage id="performance.page.tile.credits.label.expiry-date" values={{date: expiryDate}}/>
        </Caption>
      </Tile>
    );
  }
}

export default Credits;
