import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {injectIntl} from "react-intl";
import {
  About,
  Careers,
  ChangePassword,
  Contact,
  DashboardPage,
  Exception,
  Faq,
  ForgotPassword,
  Home,
  Login,
  Logout,
  Movies,
  Newsletter,
  PageNotFound,
  PersonalTraining,
  PolicyPage,
  Pricing,
  Register,
  ServicesPage,
  StoreLocator
} from "./";
import {SecuredRoute} from "../components/Router"
import URIKeys from "../constants/URIKeys";
import Application from "./info/Application";

/**
 * The {@code Page} class represents the application.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Page extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {formatMessage} = this.props.intl;

    return (
      <Switch>
        <Route exact path={formatMessage({id: URIKeys.HOME})} render={() => (window.location = formatMessage({id: URIKeys.HOME}))} />
        <Route path={formatMessage({id: URIKeys.ABOUT_US})} render={() => (window.location = formatMessage({id: URIKeys.ABOUT_US}))}/>
        <Route path={formatMessage({id: URIKeys.APPLICATION})} component={Application}/>
        <Route path={formatMessage({id: URIKeys.CAREERS})} component={Careers}/>
        <Route path={formatMessage({id: URIKeys.CONTACT})} render={() => (window.location = formatMessage({id: URIKeys.CONTACT}))}/>
        <Route path={formatMessage({id: URIKeys.NEWSLETTER_SIGN_UP})} component={Newsletter}/>
        <Route path={formatMessage({id: URIKeys.PERSONAL_TRAINING})} component={PersonalTraining}/>
        <Route path={formatMessage({id: URIKeys.STORE_LOCATOR})} render={() => (window.location = formatMessage({id: URIKeys.STORE_LOCATOR}))}/>
        <Route path={formatMessage({id: URIKeys.PRICING})} render={() => (window.location = formatMessage({id: URIKeys.PRICING}))}/>
        <Route path={formatMessage({id: URIKeys.FAQ})} render={() => (window.location = formatMessage({id: URIKeys.FAQ}))}/>

        <Route path={formatMessage({id: URIKeys.POLICIES})} component={PolicyPage}/>
        <Route path={formatMessage({id: URIKeys.SERVICES})} render={() => (window.location = formatMessage({id: URIKeys.SERVICES}))}/>
        <Route path={formatMessage({id: URIKeys.SCHEDULED_EMAIL})} component={Movies}/>

        <Route path={formatMessage({id: URIKeys.CHANGE_PASSWORD})} component={ChangePassword}/>
        <Route path={formatMessage({id: URIKeys.FORGOT_PASSWORD})} component={ForgotPassword}/>
        <Route path={formatMessage({id: URIKeys.LOGIN})} component={Login}/>
        <Route path={formatMessage({id: URIKeys.REGISTER})} component={Register}/>
        <SecuredRoute path={formatMessage({id: URIKeys.LOGOUT})} component={Logout}/>

        <SecuredRoute path={formatMessage({id: URIKeys.DASHBOARD})} component={DashboardPage}/>

        <Route path={formatMessage({id: URIKeys.EXCEPTION})} component={Exception}/>
        <Route path={formatMessage({id: URIKeys.PAGE_NOT_FOUND})} component={PageNotFound}/>
        <Route exact from="/" render={() => (window.location = formatMessage({id: URIKeys.HOME}))}/>
        <Redirect from="*" to={formatMessage({id: URIKeys.PAGE_NOT_FOUND})}/>
      </Switch>
    );
  }
}

export default injectIntl(Page);
