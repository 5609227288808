import React from "react";
import Light from "../Icons/Light";

/**
 * The {@code Ingredient} class represents an ingredient.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Ingredient extends React.Component {

    renderIngredient = () => {
        const {quantity, description} = this.props;

        let result;
        if (quantity) {
            result = quantity + ' ' + description;
        } else {
            result = description
        }

        return result;
    };

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <li className="ingredient">
                <Light icon="check-square"/>&#160;{this.renderIngredient()}
            </li>
        );
    }
}

export default Ingredient;
