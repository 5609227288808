import {getCookie, setCookie} from "./CookieUtility";
import {decode} from "./JWTUtility";
import BackendURLConstants from "../constants/BackendURLConstants";

/**
 * Checks whether the user is logged in.
 *
 * @returns {@code true} in case the user is logged in, otherwise {@code false}
 */
export function isLoggedIn() {
  let token = getToken();
  return !!token;
}

/**
 * Returns the access token.
 *
 * @returns {string} the access token
 */
export function getAccessToken() {
  let result = "";

  let expired = isExpired(getToken());
  expired = expired.result;

  if (!expired) {
    result = getToken()['access_token'];
  }

  return result;
}

export function getUsername() {
  let result = "";

  let token = getToken();
  if (token != null) {
    token = decode(token['access_token']);
    result = token['user_name'];
  }

  return result;
}


export const hasAuthorities = (authorities) => {
  const userAuthorities = getAuthorities();

  return authorities.some(authority => userAuthorities.includes(authority));
};

export function hasRole(role) {
  let token = getToken();
  if (token == null) {
    return false;
  }
  token = decode(token['access_token']);
  return token.authorities.includes(role);
}

export function attemptRefresh() {
  const token = getToken();
  if (!token) {
    // TODO: dirty fix, do this properly.
    window.location.replace('/uitloggen');
  }

  if (isExpired(token)) {
    const data = new URLSearchParams();
    data.append('grant_type', 'refresh_token');
    data.append('refresh_token', token['refresh_token']);

    return new Promise((resolve, reject) => {
      try {
        (async () => {
          const response = await fetch(BackendURLConstants.LOGIN, {
            method: 'POST',
            headers: {
              'Authorization': 'Basic Ym9keXJlc2V0OmVkMi1USmYtekZELTJyRQ==',
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            credentials: 'include',
            body: data
          });

          if (!response.ok) {
            throw new Error(response.status.toString());
          }

          const json = await response.json();

          let date = new Date();
          const refreshedToken = await setTokenExpiryDate(date, json);
          const rememberMe = getCookie('remember-me');

          let cookieExpiryDate = getCookieExpiryDate(date, rememberMe);

          setCookie('jwt', JSON.stringify(refreshedToken), cookieExpiryDate, false);
          setCookie('remember-me', rememberMe, cookieExpiryDate, false);

          resolve();
        })();
      } catch (error) {
        reject(error);
      }
    });
  }
  return Promise.resolve();
}

/**
 * Returns the JSON Web Token.
 *
 * @returns the JSON Web Token
 */
function getToken() {
  let result = getCookie('jwt');
  if (result) {
    result = JSON.parse(result);
  } else {
    result = null;
  }

  return result;
}

function getAuthorities() {
  let token = getToken();

  if (token) {
    token = decode(token['access_token']);
    return token['authorities'] || [];
  }

  return [];
}

/**
 * Checks whether the current JSON Web Token is expired.
 *
 * @param token
 * @returns {@code true} in case the JSON Web Token is expired, otherwise {@code false}
 */
function isExpired(token) {
  let result = true;
  if (token) {
    result = new Date(token['expires_in']).getTime() <= (new Date().getTime() - 30)
  }
  return result;
}

function getCookieExpiryDate(date, rememberMe) {
  let result = null;
  if (rememberMe) {
    let expiryDate = new Date();
    expiryDate.setDate(date.getDate() + 14);
    result = expiryDate.toUTCString();
  }
  return result;
}

function setTokenExpiryDate(date, json) {
  let result = json;
  date.setSeconds(date.getSeconds() + json['expires_in']);
  result['expires_in'] = date;
  return result;
}

