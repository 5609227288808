import React from "react";

/**
 * The {@code Address} class represents a points of service address.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Address extends React.Component {

    /**
     * Renders the component.
     *
     * @returns JSX.Element HTML representation of the component
     */
    render() {
        return (
            <div className="point-of-service-address">
                {this.props.children}
            </div>
        );
    }
}

export default Address;
