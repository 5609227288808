import React from "react";
import DashboardHeader from "../../../partials/DashboardHeader";
import {Button, ButtonGroup, Col, Grid, Row} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {getUsername} from "../../../utils/AuthenticationUtility";
import {getUser} from "../../../actions";
import {Redirect} from "react-router-dom";
import URIKeys from "../../../constants/URIKeys";
import {connect} from "react-redux";
import InactiveUserPage from "../../../components/InactiveUserPage/InactiveUserPage";
import Loading from "../../../components/Modals/Loading";
import FaqItem from "../../../components/Faq/FaqItem/FaqItem";
import {List, ListItem} from "../../../components/List";

/**
 * The {@code FoodTipsAndTricks} class represents the dashboard food tips & tricks page.
 Recipes
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class FoodTipsAndTricks extends React.Component {

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getUser(getUsername()));
  }

  /**
   * Creates a new instance of the {@link FoodTipsAndTricks} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      item: 0
    };
  }

  /**
   * Selects an item.
   */
  selectItem = (id) => {
    this.setState({
      item: id
    });
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, userObject} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !userObject) {
      return (
        <div id="dashboard-food-tips-and-tricks-page" className="dashboard-page">
          <DashboardHeader/>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    if (!userObject.active) {
      return (
        <div id="dashboard-food-tips-and-tricks-page" className="dashboard-page">
          <DashboardHeader/>
          <InactiveUserPage/>
        </div>
      );
    }

    return (
      <div id="dashboard-food-tips-and-tricks-page" className="dashboard-page">
        <DashboardHeader/>
        <Grid>
          <section>
            <Row>
              <Col xs={12}>
                <ButtonGroup>
                  {content.map(item => (
                    <Button key={item.id} onClick={() => this.selectItem(item.id)} active={item.id === this.state.item}>
                      {item.title}
                    </Button>
                  ))}
                </ButtonGroup>
              </Col>
            </Row>
          </section>
          <section>
            {content.map(item => (
              <Row key={item.id} className={item.id === this.state.item ? '' : 'hidden'}>
                <Col xs={12}>
                  {item.topics.map(topic => (
                    <FaqItem icon="check-square" key={topic.id} headerKey={topic.title}>
                      <FaqItem.Answer>
                        {topic.paragraphs && (
                          <List>
                            {topic.paragraphs.map((paragraph, i) => (
                              <ListItem key={i}>
                                {paragraph}
                              </ListItem>
                            ))}
                          </List>
                        )}
                        {topic.video && (
                          <video controls>
                            <source src={require('../../../videos/' + topic.video)} type="video/mp4"/>
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </FaqItem.Answer>
                    </FaqItem>
                  ))}
                </Col>
              </Row>
            ))}
          </section>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {common, getUser} = state;
  return {
    error: common.error,
    loading: common.loading,
    userObject: getUser.userObject
  };
}

const content = [
  {
    "id": 0,
    "title": "Bewust eten",
    "topics": [
      {
        "id": 0,
        "title": "Ontbijt",
        "paragraphs": [
          "Een evenwichtig en volwaardig ontbijt bestaat steeds uit 3 componenten, namelijk: fruit, zuivel (vb. melk, natuuryoghurt, kaas, ei, ...) en koolhydraten/vetten (vb. granola, volkoren brood, havermout, noten, ...).",
          "Indien je kiest voor yoghurt, geef dan altijd de voorkeur aan natuuryoghurt. Een natuuryoghurt bevat geen toegevoegde suikers. Een fruit- of vanilleyoghurt bevat 2 tot 3 klontjes suiker per potje! Wil je toch wat extra smaak toevoegen? Kies dan voor een stuk fruit, zo heb je deze vitaminenen en mineralen er nog bij.",
          "Wanneer je granola, muesli of ontbijtgranen eet, is het belangrijk dat deze arm zijn aan toegevoegde suikers. In de winkel kom je deze echter vaak tegen met zekere hoge suikergehaltes. In principe mag er niet meer dan 5 g suiker per 100 g granola/muesli/ontbijtgranen zitten.",
          "Het lijkt een cliché, maar het ontbijt is effectief de belangrijkste maaltijd van de dag. Een dag kan niet goed starten zonder de juiste energie."
        ]
      },
      {
        "id": 1,
        "title": "Tussendoortjes",
        "paragraphs": [
          "Een tussendoortje eet je best wanner er meer dan 4 uur tussen 2 hoofdmaaltijden zit. Zo voorkom je dat je uitgehongerd aan je volgende maaltijd begint en vermijd je energiedipjes.",
          "Om de goede trend van het ontbijt verder te zetten, is het belangrijk dat we bij de tussendoortjes ook kiezen voor voedingsmiddelen zonder toegevoegde suikers."
        ]
      },
      {
        "id": 2,
        "title": "Water drinken",
        "paragraphs": [
          "Het is belangrijk om elke dag opnieuw voldoende water te drinken. Dit om je fit en vitaal te voelen. Wanneer we doorheen de dag te weinig water drinken, kunnen klachten zoals vermoeidheid, hoofdpijn, loomheid, ... een gevolg zijn.",
          "Aanbevolen wordt om elke dag minstens 1,5 liter water te drinken. Ga hier zeker mee aan de slag! Het is een belangrijke naar gewichtsverlies toe, maar ook voor onze nodige portie alledaagse energie."
        ]
      },
      {
        "id": 3,
        "title": "Lunch",
        "paragraphs": [
          "Bij de lunch is het belangrijk steeds iets van groenten te voorzien. Groenten zijn de grootste bron van vitaminen, mineralen, vezels, vocht, ... en bevatten bovendien erg weinig kcal.",
          "Wist je trouwens dat elke kleur in de natuur een bepaalde vitamine het hoogst heeft? Hoe meer we variëren in kleur, hoe mooier onze inname aan talrijke vitaminen en mineralen. Je lichaam zal je dankbaar zijn."
        ]
      },
      {
        "id": 4,
        "title": "Warme maaltijd",
        "paragraphs": [
          "Bij de warme maaltijd is de indeling van het bord het belangrijkste.",
          "Minstens de helft van je bord moet uit groenten bestaan. Kies bij voorkeur twee verschillende groenten en/of kleuren. Dit zorgt voor een leuke en smakelijke afwisseling op je bord, zowel voor het oog als voor de smaakpapillen.",
          "Schep ALTIJD eerst je groenten op op je bord en zorg ervoor dat dit al de helft van je bord is. Je bord zal er automatisch anders uit zien. Dit kan je ook toepassen wanneer je op verplaatsing bent, bijvoorbeeld bij buffetvorm, BBQ, feestjes, ...",
          "100 - 150 g mager vlees, magere of vette vis, kaas of volwaardig vleesvervanger. Hierbij is 150 g echt een maximum.",
          "Laat gerust de koolhydraten eens achterwege of kies voor een kleine portie. Koolhydraten zijn onze brandstof en je kan deze het best vergelijken met een auto: Hoe meer kilometers een auto maakt, hoe meer deze brandstof zal moeten tanken en nodig heeft. Bij ons is dit net hetzelfde. Hoe meer kilometers wij afleggen, hoe meer brandstof (en dus koolhydraten) wij mogen tanken. Als je na de warme maaltijd (avondmaaltijd) niets meer van grote inspanning doet, is het eigenlijk niet nodig nog brandstof te gaan bijtanken en kan je de koolhydraten beter weglaten."
        ]
      },
      {
        "id": 5,
        "title": "Aandachtig eten",
        "paragraphs": [
          "Vaak eten we zonder dat we echt beseffen wat en hoeveel we aan het eten zijn. Dit doordat we vaak met onze gedachten ergens anders zitten of omdat we terwijl de krant lezen, tv kijken, op de gsm bezig zijn, ...",
          "Hierdoor overeten we ons vaak en eten we grotere porties. Dat vergroot ook de kans dat we weer sneller honger hebben omdat ons lichaam niet helemaal geregistreerd heeft dat we gegeten hebben."
        ]
      }
    ]
  },
  {
    "id": 1,
    "title": "Voedingstips",
    "topics": [
      {
        "id": 0,
        "title": "Voedingstip 1",
        "video": "scheduled-email-1.mp4"
      },
      {
        "id": 1,
        "title": "Voedingstip 2",
        "video": "scheduled-email-2.mp4"
      },
      {
        "id": 2,
        "title": "Voedingstip 3",
        "video": "scheduled-email-3.mp4"
      },
      {
        "id": 3,
        "title": "Voedingstip 4",
        "video": "scheduled-email-4.mp4"
      },
      {
        "id": 4,
        "title": "Voedingstip 5",
        "video": "scheduled-email-5.mp4"
      },
      {
        "id": 5,
        "title": "Voedingstip 6",
        "video": "scheduled-email-6.mp4"
      }
    ]
  },
  {
    "id": 2,
    "title": "Gezonde specerijen",
    "topics": [
      {
        "id": 0,
        "title": "Spaanse peper",
        "paragraphs": [
          "Spaanse pepers maken eten lekker pittig, zeker handig wanneer het buiten koud is. Maar ze kunnen ook onze stofwisseling harder laten werken. De reden hierachter is capsaïcine, de stof die ervoor zorgt dat specerijen zoals cayennepeper en Spaanse peper een duidelijke ‘kick’ geven aan ons eten.",
          "Onderzoek heeft aangetoond dat capsaïcine de stofwisseling van het lichaam verhoogd. Hierdoor gebruikt ons lichaam meer calorieën. Daarnaast wordt er gedacht dat capsaïcine bepaalde chemicaliën los maakt in de hersenen zodat we minder honger hebben.",
          "Capsaïcine kan ook zorgen voor een lager risico op het ontwikkelen van maagzweren door het vermogen van de maag cellen om infectie te weerstaan te verhogen. Daarnaast helpt het ons hart gezond te houden door ervoor te zorgen dat het slechte, LDL-cholesterol niet erger wordt."
        ]
      },
      {
        "id": 1,
        "title": "Gember",
        "paragraphs": [
          "Gember heeft een verdiende reputatie opgebouwd voor het verlichten van een onrustige maag. Onderzoek heeft aangetoond dat gember extracten kunnen helpen misselijkheid die wordt veroorzaakt door ochtendmisselijkheid of na een operatie of chemotherapie te kunnen verminderen.",
          "Echter, gember zit ook vol met ontstekingsremmende verbindingen zoals gingerols. Hiervan denken bepaalde deskundigen dat het zou kunnen helpen in de strijd tegen sommige vormen van kanker en dat het de pijn van artrose zou kunnen helpen verminderen. "
        ]
      },
      {
        "id": 2,
        "title": "Kaneel",
        "paragraphs": [
          "Kaneel maakt eten niet alleen lekkerder, maar enkele onderzoeken geven aan dat het eten van slechts één theelepel kaneel per dag (meestal gegeven in capsule vorm) mensen met type 2 diabetes kunnen helpen hun bloedsuikerspiegel beter onder controle te houden. ",
          "Naast de unieke etherische oliën, is kaneel een uitstekende bron van vezels en het sporenelement mangaan. Daarnaast is het ook een zeer goede bron van calcium. De combinatie van calcium en vezels in kaneel is belangrijk en kan nuttig zijn voor de preventie van verschillende gezondheidsproblemen. ",
          "Zowel calcium en vezels kunnen zich aan galzouten binden en helpen ze te verwijderen uit het lichaam. Door het verwijderen van gal helpen vezels om de schade die bepaalde galzouten kunnen veroorzaken te voorkomen, hierdoor kunnen ze het risico op het ontwikkelen van darmkanker verminderen. ",
          "Wanneer de gal wordt verwijderd door vezel moet het lichaam cholesterol afbreken om nieuwe gal te kunnen maken. Dit proces kan helpen om een te hoog cholesterolgehalte te verlagen, dat kan nuttig zijn bij het voorkomen van atherosclerose en hart -en vaatziekten."
        ]
      },
      {
        "id": 3,
        "title": "Kurkuma",
        "paragraphs": [
          "Deze specerij wordt veel gebruikt in India om wonden te helpen helen (het wordt dan vaak als een pasta aangebracht), het is ook veel gebruikt om een thee van te trekken tegen verkoudheid en om ademhalingsproblemen te verlichten. Er zijn ook een aantal gezondheidsvoordelen vastgesteld, deze worden het vaakst geassocieerd met curcumine. Dit is een stof in kurkuma dat bekend staat als een krachtige antioxidant en een stof die krachtige anti-inflammatoire eigenschappen heeft. ",
          "Van curcumine is aangetoond dat het helpt om artritispijn, pijn bij verwondingen en pijn bij tandheelkundige procedures te verlichten. Het is ook bestudeerd vanwege het mogelijk verminderen van hart- en vaatziekten, diabetes en de ziekte van Alzheimer. "
        ]
      },
      {
        "id": 4,
        "title": "Nootmuskaat",
        "paragraphs": [
          "Nootmuskaat is een populair kruid over de hele wereld vanwege de krachtige smaak die het aan gerechten kan toevoegen. Nootmuskaat is echter ook populair vanwege zijn vele voordelen voor de gezondheid. Nootmuskaat wordt al eeuwen gebruikt als een remedie voor diverse kwalen of gezondheidsproblemen. ",
          "Enkele van de voordelen voor de gezondheid van nootmuskaat zijn misschien wel interessant. De Romeinse en Griekse beschavingen gebruikten nootmuskaat als een soort van hersen tonic. De reden hierachter is omdat nootmuskaat effectief is bij het stimuleren van onze hersenen. ",
          "Hierdoor kan het ook helpen om vermoeidheid en stress te verminderen. Nootmuskaat is ook een effectief kalmerend middel. Nootmuskaat werd regelmatig gebruikt in de Chinese geneeskunde. De Chinezen gebruikten het kruid om ontstekingen en buikpijn te behandelen. ",
          "Daarnaast zorgt het voor een drastische vermindering van maagproblemen zoals diarree, obstipatie, een opgeblazen gevoel, winderigheid en ga zo maar door. Nootmuskaat olie verlicht buikpijn door overtollige lucht uit de darmen te helpen verwijderen. "
        ]
      },
      {
        "id": 5,
        "title": "Saffraan",
        "paragraphs": [
          "De kans is groot dat je wel eens vis met saffraan saus hebt gegeten. Saffraan wordt al lang gebruikt in de traditionele Perzische geneeskunde om mensen een beter humeur te geven. Vaak wordt saffraan gebruikt in een medicinale thee of wordt het gebruikt om rijst te bereiden. ",
          "Onderzoek van Iran’s Roozbeh psychiatrisch ziekenhuis bij de universiteit van Teheran van medische wetenschappen heeft geconstateerd dat saffraan kan helpen om de symptomen van het premenstrueel syndroom (PMS) en depressie te verlichten. In een onderzoek bleek dat 75% van de vrouwen met PMS die dagelijks saffraan capsules kregen melden dat hun PMS-symptomen (zoals stemmingswisselen en depressie) met minstens de helft daalden. Dit vergeleken met slechts 8% van de vrouwen die geen saffraan namen."
        ]
      },
      {
        "id": 6,
        "title": "Peterselie",
        "paragraphs": [
          "Universiteit van Missouri wetenschappers hebben vastgesteld dat dit kruid borstkanker-celgroei kan remmen. In het onderzoek kregen dieren apigenine, een verbinding die overvloedig aanwezig is in peterselie  (en ook in selderij). Hierdoor bleek dat het de weerstand stimuleerde tegen de ontwikkeling van kankergezwellen. "
        ]
      },
      {
        "id": 7,
        "title": "Salie",
        "paragraphs": [
          "Natuurgeneeskundigen raden het aan om een thee te trekken van salie wanneer mensen last hebben van een zere maag of keelpijn. Deze oplossing wordt ondersteund door een onderzoek waaruit bleek dat wanneer er een oplossing met salie in de keel werd gespoten het voor effectieve pijnbestrijding zorgde. ",
          "Voorlopig onderzoek suggereert dat salie sommige symptomen van de ziekte van Alzheimer in een vroeg stadium kan helpen verbeteren door het voorkomen dat een belangrijk enzym acetylcholine vernietigd. Dit is een stof in de hersenen die betrokken is bij ons geheugen en het leren van nieuwe informatie.",
          "In een ander onderzoek bleek dat studenten die salie extracten in capsulevorm namen duidelijk beter presteerden bij geheugentest en dat ze ook een beter humeur hadden. "
        ]
      },
      {
        "id": 8,
        "title": "Rozemarijn",
        "paragraphs": [
          "Uit recentelijk onderzoek bleek dat mensen beter presteerden bij geheugen- en alertheidtesten wanneer er nevelen van aromatische rozemarijn olie werden doorgegeven naar hun cabines waarin de test werd gedaan. Rozemarijn wordt vaak gebruikt in marinades voor vlees en gevogelte, en er is een wetenschappelijke reden achter die traditie: rozemarijnzuur en andere antioxydanten in de kruidentuin bestrijden bacteriën en voorkomen dat vlees snel kan bederven. ",
          "Ze kunnen zelfs vleeswaren gezonder maken. In maart 2010 vertelden Kansas State University onderzoekers dat het toevoegen van rozemarijn extracten aan rundergehakt de vorming van heterocyclische amines (HCA's) - kankerverwekkende stoffen geproduceerd als vlees worden gegrild, geroosterd of gebakken - hielp voorkomen."
        ]
      },
      {
        "id": 9,
        "title": "Peper",
        "paragraphs": [
          'Zwarte peper wordt soms ook wel eens "de koning van de kruiden" genoemd. Zwarte peper zorgt voor een groot aantal gezondheidsvoordelen en past bij bijna elke hartige schotel. Zwarte peper is afgeleid van de peperkorrel, dit is geen echte peper. ',
          "Zwarte peper werkt zowel als een antibioticum en een antioxidant. Het stimuleert vetafbraak, wat kan helpen bij gewichtsverlies, en onderzoek toont ook aan dat het kruid kan helpen de niveaus van het \"slechte\" LDL-cholesterol en triglyceriden in het bloed te verlagen. Daarnaast verhoogt het ons \"goede\" HDL-cholesterol. LDL-cholesterol kan zich ophopen in de slagaders als plaque, dit draagt bij aan de ontwikkeling van hart- en vaatziekten. "
        ]
      }
    ]
  },
  {
    "id": 3,
    "title": "Vlees ontdooien",
    "topics": [
      {
        "id": 0,
        "title": "In de koelkast",
        "paragraphs": [
          "Vlees ontdooien in de koelkast is het best geschikt voor dunnere stukjes vlees (steak, kalfslapjes, mignonnettes…).",
          "Zorg dat de temperatuur in je koelkast ongeveer 4 graden Celsius bedraagt. Leg je vlees op een bord of in een bakje en dek het af met folie, zo kan het niet lekken in je koelkast en op andere etenswaren. Plaats het op het onderste schap.",
          "Weet wel dat je zeker voor grotere stukken vaak een dag geduld zal moeten hebben.",
          "Maak het ontdooide vlees zo snel mogelijk klaar eens het is ontdooid."
        ]
      },
      {
        "id": 1,
        "title": "In de microgolfoven",
        "paragraphs": [
          "Vlees ontdooien in de microgolf is de snelste manier. Grote stukken vlees zijn minder handig om te ontdooien in de microgolf, omdat de buitenkant dan gaar wordt terwijl de binnenkant nog bevroren is.",
          "Leg het bevroren vlees op een bord of in een kom. Gebruik de ontdooifunctie om te voorkomen dat de randen van het vlees reeds gaar worden, terwijl de kern nog bevroren is.",
          "Wil je gemengd vlees zoals gehakt of varkensreepjes ontdooien? Roer het vlees regelmatig om zodat de warmte zich gelijkmatig verspreidt.",
          "Het vlees moet meteen na het ontdooien bereid worden,"
        ]
      },
      {
        "id": 2,
        "title": "In ijswater",
        "paragraphs": [
          "Vlees ontdooien in ijswater gaat veel sneller dan vlees ontdooien in de koelkast. Het vleesoppervlak blijft op een voedselveilige koude temperatuur terwijl de kern van het vlees toch vrij snel ontdooit.",
          "Dompel het luchtdicht verpakte vlees onder in een bakje of kom met ijswater (of zeer koud water). Plaats het bakje of de kom in de koelkast. Zo neemt het ijswater geen omgevingswarmte op. Bij grote stukken vlees moet je het ijswater om de 30 minuten verversen zodra de temperatuur van het water te hoog wordt (als het ijs volledig weggesmolten is).",
          "0,5 kg vlees kun je zo in minder dan een uur ontdooien. Bij grotere stukken vlees reken je best op een half uur per 0,5 kg vlees.",
          "Maak het ontdooide vlees zo snel mogelijk klaar eens het is ontdooid."
        ]
      },
      {
        "id": 3,
        "title": "Absoluut niet doen",
        "paragraphs": [
          "Vlees ontdooien in warm water",
          "Vlees ontdooien op kamertemperatuur"
        ]
      }
    ]
  }
];

export default connect(mapStateToProps)(injectIntl(FoodTipsAndTricks));
