import React from "react";

/**
 * The {@code List} class represents a list.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class List extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <ul className="list-checked">
                {this.props.children}
            </ul>
        );
    }
}

export default List;

