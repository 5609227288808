import React from "react";
import {Carousel, Col, Grid, Row} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {Waypoint} from "react-waypoint";
import ScrollableAnchor from 'react-scrollable-anchor'
import {BounceIn, FadeInUp} from "../../components/Animations/index";
import {Content as FeatureContent, FeatureBox, Icon} from "../../components/FeatureBox/index";
import {SectionHeader} from "../../components/SectionHeader/index";
import {ButtonWrapper, Content, VideoBackground} from "../../components/VideoBackground/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import {Image} from "../../components/Image/index";
import {List, ListItem} from "../../components/List/index";
import {Light} from "../../components/Icons/index";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code Home} class represents the home page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Home extends React.Component {

  /** 
   * The class constructor. 
   */
  constructor(props) {
    super(props);

    this.state = {
      renderFirstRow: false,
      renderSecondRow: false,
      renderMovement: false,
      renderFood: false,
      renderMotivation: false
    };
  }

  /**
   * Sets the flag that indicates whether the first row of features should be rendered.
   */
  setRenderFirstRow() {
    this.setState({
      renderFirstRow: true
    });
  };

  /**
   * Sets the flag that indicates whether the second row of features should be rendered.
   */
  setRenderSecondRow() {
    this.setState({
      renderSecondRow: true
    });
  };

  /**
   * Sets the flag that indicates whether the second row of features should be rendered.
   */
  setRenderMovement = () => {
    this.setState({
      renderMovement: true
    });
  };

  /**
   * Sets the flag that indicates whether the second row of features should be rendered.
   */
  setRenderFood() {
    this.setState({
      renderFood: true
    });
  };

  /**
   * Sets the flag that indicates whether the second row of features should be rendered.
   */
  setRenderMotivation() {
    this.setState({
      renderMotivation: true
    });
  };

  /**
   * Renders the first row of features if the appropriate flag is set.
   *
   * @returns {XML} the HTML representation of the first row
   */
  renderFirstRow() {
    if (this.state.renderFirstRow) {
      return (
        <Row>
          <Col xs={12} md={4}>
            <FeatureBox>
              <BounceIn>
                <Icon>
                  <Light icon="running"/>
                </Icon>
              </BounceIn>
              <FeatureContent>
                <FormattedMessage id="home.feature.accompaniment.title" tagName="h4"/>
                <FormattedHTMLMessage id="home.feature.accompaniment.paragraph" tagName="p"/>
              </FeatureContent>
            </FeatureBox>
          </Col>
          <Col xs={12} md={4}>
            <FeatureBox>
              <BounceIn delay="200">
                <Icon>
                  <Light icon="cauldron"/>
                </Icon>
              </BounceIn>
              <FeatureContent>
                <FormattedMessage id="home.feature.food.title" tagName="h4"/>
                <FormattedHTMLMessage id="home.feature.food.paragraph" tagName="p"/>
              </FeatureContent>
            </FeatureBox>
          </Col>
          <Col xs={12} md={4}>
            <FeatureBox>
              <BounceIn delay="400">
                <Icon>
                  <Light icon="badge-check"/>
                </Icon>
              </BounceIn>
              <FeatureContent>
                <FormattedMessage id="home.feature.motivation.title" tagName="h4"/>
                <FormattedHTMLMessage id="home.feature.motivation.paragraph" tagName="p"/>
              </FeatureContent>
            </FeatureBox>
          </Col>
        </Row>
      );
    }
  };

  /**
   * Renders the second row of features if the appropriate flag is set.
   *
   * @returns {XML} the HTML representation of the second row
   */
  renderSecondRow = () => {
    if (this.state.renderSecondRow) {
      return (
        <Row>
          <Col xs={12} md={4}>
            <FeatureBox>
              <BounceIn delay="600">
                <Icon>
                  <Light icon="spa"/>
                </Icon>
              </BounceIn>
              <FeatureContent>
                <FormattedMessage id="home.feature.sports-massage.title" tagName="h4"/>
                <FormattedHTMLMessage id="home.feature.sports-massage.paragraph" tagName="p"/>
              </FeatureContent>
            </FeatureBox>
          </Col>
          <Col xs={12} md={4}>
            <FeatureBox>
              <BounceIn delay="800">
                <Icon>
                  <Light icon="desktop"/>
                </Icon>
              </BounceIn>
              <FeatureContent>
                <FormattedMessage id="home.feature.connected.title" tagName="h4"/>
                <FormattedHTMLMessage id="home.feature.connected.paragraph" tagName="p"/>
              </FeatureContent>
            </FeatureBox>
          </Col>
          <Col xs={12} md={4}>
            <FeatureBox>
              <BounceIn delay="1000">
                <Icon>
                  <Light icon="map-signs"/>
                </Icon>
              </BounceIn>
              <FeatureContent>
                <FormattedMessage id="home.feature.accessible.title" tagName="h4"/>
                <FormattedHTMLMessage id="home.feature.accessible.paragraph" tagName="p"/>
              </FeatureContent>
            </FeatureBox>
          </Col>
        </Row>
      );
    }
  };

  /**
   * Renders the movement section if the appropriate flag is set.
   *
   * @returns {XML} the HTML representation of the second row
   */
  renderMovement = () => {
    if (this.state.renderMovement) {
      return (
        <Col xs={12} md={4}>
          <Image lowRes={require('../../images/pages/home/movement-tiny.jpeg')}
                 highRes={require('../../images/pages/home/movement.jpeg')}
                 classNames="img-responsive"/>
          <FadeInUp delay={200}>
            <FormattedMessage id="home.section.movement.title" tagName="h4"/>
          </FadeInUp>
          <List>
            <FadeInUp delay={400}>
              <ListItem>
                <FormattedMessage id="home.section.movement.benefit.one"/>
              </ListItem>
            </FadeInUp>
            <FadeInUp delay={600}>
              <ListItem>
                <FormattedMessage id="home.section.movement.benefit.two"/>
              </ListItem>
            </FadeInUp>
            <FadeInUp delay={800}>
              <ListItem>
                <FormattedMessage id="home.section.movement.benefit.three"/>
              </ListItem>
            </FadeInUp>
          </List>
          <FadeInUp delay={1000}>
            <LocalizedLink id={URIKeys.PERSONAL_TRAINING} className="btn btn-sm btn-primary">
              <Light icon="chevron-right"/>&#160;
              <FormattedMessage id="common.link.more"/>
            </LocalizedLink>
          </FadeInUp>
        </Col>
      );
    }
  };

  /**
   * Renders the food section if the appropriate flag is set.
   *
   * @returns {XML} the HTML representation of the second row
   */
  renderFood = () => {
    if (this.state.renderFood) {
      return (
        <Col xs={12} md={4}>
          <Image lowRes={require('../../images/pages/home/food.svg')}
                 highRes={require('../../images/pages/home/food.jpg')}
                 classNames="img-responsive"/>
          <FadeInUp delay={200}>
            <FormattedMessage id="home.section.food.title" tagName="h4"/>
          </FadeInUp>
          <List>
            <FadeInUp delay={400}>
              <ListItem>
                <FormattedMessage id="home.section.food.benefit.one"/>
              </ListItem>
            </FadeInUp>
            <FadeInUp delay={600}>
              <ListItem>
                <FormattedMessage id="home.section.food.benefit.two"/>
              </ListItem>
            </FadeInUp>
            <FadeInUp delay={800}>
              <ListItem>
                <FormattedMessage id="home.section.food.benefit.three"/>
              </ListItem>
            </FadeInUp>
          </List>
          <FadeInUp delay={1000}>
            <LocalizedLink id={URIKeys.FOOD_CONSULTANT} className="btn btn-sm btn-primary">
              <Light icon="chevron-right"/>&#160;
              <FormattedMessage id="common.link.more"/>
            </LocalizedLink>
          </FadeInUp>
        </Col>
      );
    }
  };

  /**
   * Renders the motivation section if the appropriate flag is set.
   *
   * @returns {XML} the HTML representation of the second row
   */
  renderMotivation = () => {
    if (this.state.renderMotivation) {
      return (
        <Col xs={12} md={4}>
          <Image lowRes={require('../../images/pages/home/motivation.svg')}
                 highRes={require('../../images/pages/home/motivation.jpg')}
                 classNames="img-responsive"/>
          <FadeInUp delay={200}>
            <FormattedMessage id="home.section.motivation.title" tagName="h4"/>
          </FadeInUp>
          <List>
            <FadeInUp delay={400}>
              <ListItem>
                <FormattedMessage id="home.section.motivation.benefit.one"/>
              </ListItem>
            </FadeInUp>
            <FadeInUp delay={600}>
              <ListItem>
                <FormattedMessage id="home.section.motivation.benefit.two"/>
              </ListItem>
            </FadeInUp>
            <FadeInUp delay={800}>
              <ListItem>
                <FormattedMessage id="home.section.motivation.benefit.three"/>
              </ListItem>
            </FadeInUp>
          </List>
          <FadeInUp delay={1000}>
            <LocalizedLink id={URIKeys.ABOUT_US} className="btn btn-sm btn-primary">
              <Light icon="chevron-right"/>&#160;
              <FormattedMessage id="common.link.more"/>
            </LocalizedLink>
          </FadeInUp>
        </Col>
      );
    }
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div id="home-page">
        <VideoBackground>
          <video poster={require('../../images/pages/home/background-movie.jpg')}
                 preload="auto"
                 className="video"
                 loop
                 autoPlay
                 muted>
            <source src={require('../../videos/background-movie.mp4')} type='video/mp4'/>
          </video>
          <Content>
            <FadeInUp>
              <FormattedMessage id="home.video-background.title" tagName="h1"/>
            </FadeInUp>
            <FadeInUp delay="200">
              <FormattedMessage id="home.video-background.subtitle" tagName="p"/>
            </FadeInUp>
            <ButtonWrapper>
              <FadeInUp delay="400">
                <a href="#method" className="btn btn-lg btn-transparent">
                  <Light icon="chevron-down"/>&#160;
                  <FormattedMessage id="home.video-background.button.read-how"/>
                </a>
              </FadeInUp>
              <FadeInUp delay="400">
                <LocalizedLink id={URIKeys.REGISTER} className="btn btn-lg btn-primary hidden-xs hidden-sm">
                  <FormattedMessage id="home.video-background.button.get-started"/>
                </LocalizedLink>
              </FadeInUp>
            </ButtonWrapper>
          </Content>
        </VideoBackground>
        <ScrollableAnchor id={'method'}>
          <section className="method">
            <Grid>
              <Row>
                <Waypoint onEnter={this.setRenderMovement.bind(this)}/>
                {this.renderMovement()}
                <Waypoint onEnter={this.setRenderFood.bind(this)}/>
                {this.renderFood()}
                <Waypoint onEnter={this.setRenderMotivation.bind(this)}/>
                {this.renderMotivation()}
              </Row>
            </Grid>
          </section>
        </ScrollableAnchor>
        <section className="testimonies hidden-xs hidden-sm">
          <Grid fluid>
            <Row>
              <Col md={6}>
                <div className="testimonies-wrapper">
                  <SectionHeader>
                    <FormattedMessage id="home.section.testimonies.title" tagName="h3"/>
                  </SectionHeader>
                  <Carousel controls={false}>
                    <Carousel.Item>
                      <Image lowRes={require('../../images/pages/home/testimony-one.svg')}
                             highRes={require('../../images/pages/home/testimony-one.jpg')}
                             classNames="img-circle"/>
                      <Carousel.Caption>
                        <FormattedMessage id="home.section.testimonies.testimony.one" tagName="h4"/>
                        <p>- Ilse Verdonck</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image lowRes={require('../../images/pages/home/testimony-two.svg')}
                             highRes={require('../../images/pages/home/testimony-two.jpg')}
                             classNames="img-circle"/>
                      <Carousel.Caption>
                        <FormattedMessage id="home.section.testimonies.testimony.two" tagName="h4"/>
                        <p>- Stefan Machiels</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image lowRes={require('../../images/pages/home/testimony-three.svg')}
                             highRes={require('../../images/pages/home/testimony-three.jpg')}
                             classNames="img-circle"/>
                      <Carousel.Caption>
                        <FormattedMessage id="home.section.testimonies.testimony.three" tagName="h4"/>
                        <p>- Stefanie Vanstipelen</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </Col>
              <Col md={6}>
                <Image lowRes={require('../../images/pages/home/testimonies.svg')}
                       highRes={require('../../images/pages/home/testimonies.jpg')}
                       classNames="img-responsive"/>
              </Col>
            </Row>
          </Grid>
        </section>
        <section className="quote">
          <Grid>
            <FormattedMessage id="home.section.quote.paragraph"/>
          </Grid>
        </section>
        <section className="features">
          <Grid>
            <Waypoint onEnter={this.setRenderFirstRow.bind(this)}/>
            {this.renderFirstRow()}
            <Waypoint onEnter={this.setRenderSecondRow.bind(this)}/>
            {this.renderSecondRow()}
          </Grid>
        </section>
      </div>
    );
  }
}

export default Home;
