import React from "react";
import {Grid} from "react-bootstrap";

/**
 * The {@code NutritionalValues} class represents the recipe's nutritional values.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class NutritionalValues extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <section className="nutritional-values">
                <Grid>
                    {this.props.children}
                </Grid>
            </section>
        );
    }
}

export default NutritionalValues;
