import React from "react";
import {Solid} from "../Icons";

/**
 * The {@code BasicInfoEntry} class represents a basic info entry.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class BasicInfoEntry extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <li>
                <Solid icon={this.props.icon}/>
                {this.props.children}
            </li>
        );
    }
}

export default BasicInfoEntry;
