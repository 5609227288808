import Actions from "../constants/Actions";
import {printJSDateInISODate} from "../utils/DateTimeUtility";
import {error} from "./Common";
import {getOne} from "../utils/FetchUtility";
import BackendURLConstants from "../constants/BackendURLConstants";

export function getCalendar(id, currentDate, numberOfDays) {
  return async dispatch => {
    try {
      let calendar = await getOne(
        BackendURLConstants.CALENDAR
          .replace('$id', id)
          .replace('$date', printJSDateInISODate(currentDate))
          .replace('$numberOfVisibleDays', numberOfDays),
        true
      );

      dispatch(getCalendarSuccess(calendar));
    } catch (e) {
      dispatch(error('error-message.calendar.get'));
    }
  };
}

function getCalendarSuccess(result) {
  return {
    type: Actions.GET_CALENDAR,
    result: result
  }
}
