import React from "react";
import {injectIntl} from "react-intl";
import {Modal} from "react-bootstrap";
import Light from "../../Icons/Light";

/**
 * The {@code AddCreditsModal} class represents the modal to add credits to a user.
 *
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class AddCreditsModal extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {show} = this.props;

    return (
      <Modal show={show} backdrop={true} bsSize="sm" className="info-modal text-center">
        <Modal.Header>
          <Light icon="coins"/>
        </Modal.Header>
        <Modal.Body>
          {this.props.children}
        </Modal.Body>
      </Modal>
    );
  }
}


export default injectIntl(AddCreditsModal);
