import React from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Col, ControlLabel, FormControl, FormGroup, Row} from "react-bootstrap";
import {selectFacility} from "../../actions";

/**
 * The {@code FacilitySelectForm} class represents the facility select form.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class FacilitySelectForm extends React.Component {

  /**
   * Handle the change event for the select input.
   *
   * @param e the event
   */
  handleFacilityChange = (e) => {
    const {dispatch} = this.props;

    const id = e.target.value;

    dispatch(selectFacility(id));
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {facilities, user, selectedFacility} = this.props;
    const {formatMessage} = this.props.intl;

    let filteredFacilities = [];
    for (let i = 0; i < facilities.length; i++) {
      if (!user.intakeCompleted) {
        filteredFacilities.push(facilities[i]);
      } else if (facilities[i].type !== 'INTAKE') {
        filteredFacilities.push(facilities[i]);
      }
    }

    const isMaasmechelen = !!user?.pointOfService?.name?.match(/maasmechelen/i)
    if (isMaasmechelen) {
      filteredFacilities = filteredFacilities.filter(f => !['INTAKE', 'INTAKE_E_FIT', 'SPINNING'].includes(f.type))
    }

    return (
      <form className="facility-selector">
        <Row>
          <Col xs={12}>
            <FormGroup controlId="facility">
              <ControlLabel>
                <FormattedMessage id="label.facility"/>
              </ControlLabel>
              <FormControl componentClass="select"
                           value={selectedFacility}
                           onChange={this.handleFacilityChange}>
                <option value="initial" disabled>
                  {formatMessage({id: 'form.placeholder.select'})}
                </option>
                {filteredFacilities.map(facility => (
                  <option value={facility.id} key={facility.id}>
                    {formatMessage({id: 'facilities.type.' + facility.type})}
                  </option>
                ))}
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
      </form>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {selectFacility} = state;
  return {
    selectedFacility: selectFacility.selectedFacility
  };
}

export default connect(mapStateToProps)(injectIntl(FacilitySelectForm));
