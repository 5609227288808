import React from "react";

/**
 * The {@code PricingBoxFeatureList} class represents a pricing box feature list.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PricingBoxFeatureList extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div className="pricing-box-feature-list">
                <ul className="list-unstyled">
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

export default PricingBoxFeatureList;
