import React from "react";
import {Modal} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import Light from "../Icons/Light";

/**
 * The {@code Success} class represents the content of the success modal component.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Success extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {show} = this.props;
    return (
      <Modal show={show} backdrop={true} className="success-modal text-center">
        <Modal.Header>
          <Light icon="check-circle"/>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="modal.success.title" tagName="h4"/>
          {this.props.children}
        </Modal.Body>
      </Modal>
    );
  }
}

export default Success;
