import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Field, Form, FormSpy} from "react-final-form";
import FormGroup from "./final-form/FormGroup/FormGroup";
import {Button, Col, ControlLabel, Row} from "react-bootstrap";
import Select from "./final-form/Select/Select";
import {connect} from "react-redux";
import {getFacilityAppointments, selectFacility} from "../../actions";

/**
 * The {@code PointOfServiceSelectForm} class represents the point of service select form.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PointOfServiceSelectForm extends React.Component {

  /**
   * Creates a new instance of the {@link PointOfServiceSelectForm} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    let pointOfService = this.props.pointsOfService && this.props.pointsOfService[0];

    this.state = {
      initialValues: {
        pointOfService: pointOfService && pointOfService.id,
        facility: undefined
      }
    };
  }

  /**
   * Handles the form on submit.
   */
  onSubmit = async (values) => {
    const {dispatch} = this.props;
    if (values.facility) {
      dispatch(selectFacility(values.facility));
      dispatch(getFacilityAppointments(values.facility, new Date(), undefined, true));
    }
  };

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}, pointsOfService} = this.props;
    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={this.state.initialValues}
        render={({handleSubmit, invalid}) => (
          <form onSubmit={handleSubmit}>
            <Row className="row-eq-height">
              <Col xs={12} sm={4}>
                <FormGroup controlId="pointOfService">
                  <ControlLabel>
                    <FormattedMessage id="label.point-of-service"/>
                  </ControlLabel>
                  <Field
                    name="pointOfService"
                    component={Select}
                    options={pointsOfService.map(pointOfService => ({
                      value: pointOfService.id,
                      label: pointOfService.name
                    }))}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={4}>
                <FormSpy
                  subscription={{values: true}}
                  render={({values: {pointOfService}}) => {
                    const result = pointsOfService.find(pos => pos.id == pointOfService); // Don't change this.

                    let options = [{
                      value: undefined,
                      label: formatMessage({id: 'label.facility'})
                    }];

                    if (result && result.facilities) {
                      result.facilities.map(facility => (
                        options.push({
                          value: facility.id,
                          label: formatMessage({id: 'facilities.type.' + facility.type})
                        })));
                    }

                    return (
                      <FormGroup controlId="facility">
                        <ControlLabel>
                          <FormattedMessage id="label.facility"/>
                        </ControlLabel>
                        <Field
                          name="facility"
                          component={Select}
                          options={options}
                        />
                      </FormGroup>
                    );
                  }}
                />
              </Col>
              <Col xs={12} sm={2}>
                <Button className="pull-right" bsStyle="primary" type="submit" disabled={invalid}>
                  <FormattedMessage id="common.button.select"/>
                </Button>
              </Col>
            </Row>
          </form>
        )}/>
    );
  }
}

export default connect()(injectIntl(PointOfServiceSelectForm));
