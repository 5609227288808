import React from "react";
import {Col} from "react-bootstrap";

/**
 * The {@code Steps} class represents the steps in the wizard.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Steps extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        activeStep: this.props.activeStep,
        toggleActiveStep: this.props.toggleActiveStep,
        toggleCancel: this.props.toggleCancel,
        pristine: this.props.pristine,
        invalid: this.props.invalid
      });
    });

    return (
      <Col xs={12} sm={9} className="wizard-steps">
        {children}
      </Col>
    );
  }
}

export default Steps;
