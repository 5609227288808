import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Bar} from "react-chartjs-2";
import {Tile} from "../Tile";
import {getLanguage} from "../../utils/LanguageUtility";

/**
 * The {@code BodyComposition} class represents the body composition tile.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class BodyComposition extends React.Component {

  /**
   * Renders the graph.
   *
   * @returns {*} the HTML representation of the graph
   */
  renderGraph = () => {
    const {measurements} = this.props;
    const {formatMessage} = this.props.intl;

    let labels = [];
    let boneMass = [];
    let fatMass = [];
    let muscleMass = [];
    let waterPercentage = [];

    if (measurements !== null && measurements.length > 0) {
      measurements.forEach(function (measurement) {
        labels.push(new Date(measurement.date).toLocaleDateString(getLanguage()));
        boneMass.push(measurement.boneMass);
        fatMass.push((measurement.weight / 100 * measurement.fatPercentage).toFixed(2));
        muscleMass.push(measurement.muscleMass);
        waterPercentage.push(measurement.waterPercentage);
      });
    }

    const data = {
      datasets: [
        {
          type: 'line',
          data: waterPercentage,
          label: formatMessage({id: 'performance.page.tile.body-composition.label.water-percentage'}),
          fill: false,
          borderWidth: 2,
          borderColor: '#007a9c',
          backgroundColor: '#007a9c',
          pointBorderColor: '#007a9c',
          pointBackgroundColor: '#007a9c',
          pointHoverBorderColor: '#004659',
          pointHoverBackgroundColor: '#004659',
          yAxisID: 'y-axis-line'
        },
        {
          data: fatMass,
          label: formatMessage({id: 'performance.page.tile.body-composition.label.fat-mass'}),
          borderColor: '#4cce73',
          backgroundColor: '#4cce73',
          hoverBorderColor: '#00ba38',
          hoverBackgroundColor: '#00ba38',
          yAxisID: 'y-axis-bar'
        },
        {
          data: boneMass,
          label: formatMessage({id: 'performance.page.tile.body-composition.label.bone-mass'}),
          fill: false,
          borderColor: '#f5f5f5',
          backgroundColor: '#f5f5f5',
          hoverBorderColor: '#ababab',
          hoverBackgroundColor: '#ababab',
          yAxisID: 'y-axis-bar'
        },
        {
          data: muscleMass,
          label: formatMessage({id: 'performance.page.tile.body-composition.label.muscular-mass'}),
          borderColor: '#4cc7e9',
          backgroundColor: '#4cc7e9',
          hoverBorderColor: '#00b1e0',
          hoverBackgroundColor: '#00b1e0',
          yAxisID: 'y-axis-bar'
        }
      ],

      labels: labels
    };

    const options = {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            stacked: true
          }
        ],
        yAxes: [
          {
            id: 'y-axis-bar',
            type: 'linear',
            position: 'left',
            gridLines: {
              display: false
            },
            stacked: true,
            ticks: {
              min: 0,
              suggestedMax: 80,
              stepSize: 10
            }
          },
          {
            id: 'y-axis-line',
            type: 'linear',
            gridLines: {
              display: false
            },
            position: 'right',
            ticks: {
              min: 30,
              max: 80,
              stepSize: 10
            }
          }
        ]
      }
    }

    return <Bar data={data}
                options={options}
                legend={{position: 'bottom', labels: {boxWidth: 12}}}/>;
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <Tile xs={12} md={6}>
        <FormattedMessage id="performance.page.tile.body-composition.title" tagName="h5"/>
        {this.renderGraph()}
      </Tile>
    );
  }
}

export default injectIntl(BodyComposition);
