/**
 * The {@code YES_NO} constant class contains the supported common yes no values usually for combo controls.
 *
 * @type {object}
 */
const YES_NO = [
    {
      key: 'label.select',
      value: 'NONE'
    },
    {
      key: 'common.yes',
      value: 'YES'
    },
    {
      key: 'common.no',
      value: 'NO'
    },
];


export default YES_NO;

