import {messagesNL} from "../i18n/messages";
import {getCookie, setCookie} from "./CookieUtility";
import LANGUAGES from "../constants/Languages";

/**
 * Returns the language.
 *
 * @returns the language
 */
export function getLanguage() {
  let language = getCookie('language');
  if (typeof language === 'undefined' || language === 'undefined') {
    language = navigator.language.substring(0, 2);
    let isValidLanguage = false;
    for (const supportedLanguage of LANGUAGES) {
      if (supportedLanguage.key === language) {
        isValidLanguage = true;
        break;
      }
    }

    if (!isValidLanguage) {
      language = 'nl';
    }

    setCookie('language', language, null, true);
  }
  return language;
}

/**
 * The localized messages.
 *
 * @param language the language
 * @returns the localized messages
 */
export function getMessages(language) {
  let result = messagesNL;
  switch (language) {
    case LANGUAGES.NL:
      break;
    default:
      break;
  }
  return result;
}
