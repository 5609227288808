import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {ParagraphHeader} from "../../components/ParagraphHeader/index";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code TermsAndConditions} class represents the terms and conditions page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class TermsAndConditions extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div id="terms-and-conditions-page">
                <PageHeader>
                    <PageHeaderTitle>
                        <FormattedMessage id="footer.link.terms-and-conditions" tagName="h3"/>
                        <FormattedMessage id="terms-and-conditions.page.subtitle" tagName="p"/>
                    </PageHeaderTitle>
                </PageHeader>
                <section>
                    <Grid>
                        <Row>
                            <Col xs={12} md={10} mdOffset={1}>
                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.introduction.title"
                                                      tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.introduction.paragraph.one"
                                                  tagName="p"
                                                  values={{
                                                    website: <a href="https://bodyreset.be">website</a>
                                                  }}/>
                                <FormattedMessage id="terms-and-conditions.section.introduction.paragraph.two"
                                                  tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.responsibility.title"
                                                      tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.responsibility.paragraph.one"
                                                  tagName="p"
                                                  values={{
                                                      email: <a
                                                          href="mailto:info&#64;bodyreset.be">info&#64;bodyreset.be</a>
                                                  }}/>
                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.other.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.other.paragraph.one" tagName="p"
                                                  values={{
                                                      privacyPolicy:
                                                          <LocalizedLink id={URIKeys.PRIVACY_POLICY}>
                                                              <FormattedMessage id="footer.link.privacy-policy"/>
                                                          </LocalizedLink>,
                                                      cookiePolicy:
                                                          <LocalizedLink id={URIKeys.COOKIE_POLICY}>
                                                              <FormattedMessage id="footer.link.cookie-policy"/>
                                                          </LocalizedLink>
                                                  }}/>


                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.purpose.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.purpose.paragraph.one" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.nature.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.nature.paragraph.one" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.liability.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.liability.paragraph.one"
                                                  tagName="p"/>
                                <FormattedMessage id="terms-and-conditions.section.liability.paragraph.two"
                                                  tagName="p"/>
                                <FormattedMessage id="terms-and-conditions.section.liability.paragraph.three"
                                                  tagName="p"/>
                                <FormattedMessage id="terms-and-conditions.section.liability.paragraph.four"
                                                  tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.accounts.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.accounts.paragraph.one" tagName="p"/>
                                <FormattedMessage id="terms-and-conditions.section.accounts.paragraph.two" tagName="p"/>
                                <FormattedMessage id="terms-and-conditions.section.accounts.paragraph.three"
                                                  tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.client-zone.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.client-zone.paragraph.one"
                                                  tagName="p"/>
                                <FormattedMessage id="terms-and-conditions.section.client-zone.paragraph.two"
                                                  tagName="p"
                                                  values={{
                                                      contactPage:
                                                          <LocalizedLink id={URIKeys.CONTACT}>
                                                              <FormattedMessage id="privacy-policy.link.contact-us"/>
                                                          </LocalizedLink>
                                                  }}/>
                                <FormattedMessage id="terms-and-conditions.section.client-zone.paragraph.three"
                                                  tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.property.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.property.paragraph.one" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.changes.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.changes.paragraph.one" tagName="p"/>
                                <FormattedMessage id="terms-and-conditions.section.changes.paragraph.two" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="terms-and-conditions.section.justice.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="terms-and-conditions.section.justice.paragraph.one" tagName="p"/>
                                <FormattedMessage id="terms-and-conditions.section.justice.paragraph.two" tagName="p"/>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </div>
        );
    }
}

export default TermsAndConditions;
