import React from "react";
import Ingredient from "./Ingredient";

/**
 * The {@code IngredientList} class represents a list of ingredients.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class IngredientList extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {ingredients} = this.props;

    if (ingredients == null || ingredients.length === 0) {
      return (
        <ul className="ingredient-list">
        </ul>
      );
    }

    return (
      <ul className="ingredient-list">
        {ingredients.map((ingredient, i) => (
          <Ingredient key={i}
                      quantity={ingredient.quantityAndUnit}
                      description={ingredient.description}/>
        ))}
      </ul>
    );
  }
}

export default IngredientList;
