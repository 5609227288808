import React from "react";
import {Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

/**
 * The {@code NutritionalValuesTitle} class represents the nutritional values title.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class NutritionalValuesTitle extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <Row className="nutritional-values-title">
                <Col xs={12} sm={6}>
                    <FormattedMessage id="recipe.page.section-three.title" tagName="h5"/>&#160;
                    (<FormattedMessage id="recipe.page.section-three.subtitle"/> {this.props.quantity} g)
                </Col>
                <Col xsHidden smHidden md={6}>
                    <FormattedMessage id="recipe.page.section-four.title" tagName="h5"/>
                </Col>
            </Row>
        );
    }
}

export default NutritionalValuesTitle;
