import React from "react";

/**
 * The {@code FeatureBox} class represents a feature box.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class FeatureBox extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div className="feature-box row">
                {this.props.children}
            </div>
        );
    }
}

export default FeatureBox;

