import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {injectIntl} from "react-intl";
import URIKeys from "../../constants/URIKeys";
import {CookiePolicy, PrivacyPolicy, TermsAndConditions} from "../index";

/**
 * The {@code PolicyPage} class represents the policy page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PolicyPage extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        const {formatMessage} = this.props.intl;

        return (
            <Switch>
                <Route path={formatMessage({id: URIKeys.COOKIE_POLICY})} component={CookiePolicy}/>
                <Route path={formatMessage({id: URIKeys.PRIVACY_POLICY})} component={PrivacyPolicy}/>
                <Route path={formatMessage({id: URIKeys.TERMS_AND_CONDITIONS})} component={TermsAndConditions}/>
                <Redirect from="*" to={formatMessage({id: URIKeys.PAGE_NOT_FOUND})}/>
            </Switch>
        );
    }
}

export default injectIntl(PolicyPage);
