import React from "react";
import {Col} from "react-bootstrap";

/**
 * The {@code Navigation} class represents the wizard navigation.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Navigation extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        activeStep: this.props.activeStep,
        toggleActiveStep: this.props.toggleActiveStep
      });
    });

    return (
      <Col xsHidden sm={3} className="wizard-navigation">
        <ul className="list-unstyled">
          {children}
        </ul>
      </Col>
    );
  }
}

export default Navigation;
