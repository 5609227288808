import React from "react";
import DashboardHeader from "../../../partials/DashboardHeader";
import {Button, Checkbox, Col, Grid, Radio, Row} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from "react-intl";
import {Redirect} from "react-router-dom";
import URIKeys from "../../../constants/URIKeys";
import {connect} from "react-redux";
import Loading from "../../../components/Modals/Loading";
import {getCart} from "../../../actions/Cart";
import {getLanguage} from "../../../utils/LanguageUtility";
import {getUsername} from "../../../utils/AuthenticationUtility";
import {error, getUser} from "../../../actions";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import LocalizedLink from "../../../components/Intl/LocalizedLink";
import {getOne, post, put} from "../../../utils/FetchUtility";
import BackendURLConstants from "../../../constants/BackendURLConstants";
import {isDayLightSavingsTime} from "../../../utils/DateTimeUtility";
import {Image} from "../../../components/Image";
import {InsufficientCreditsModal} from "../../../components/Modals";

let timer = null;
let poller = null;

/**
 * The {@code Checkout} class represents the dashboard checkout page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Checkout extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = ({
      step: 1,
      acceptedTermsAndConditions: false,
      submitted: false,
      paymentInformationObject: null,
      time: null,
      order: null
    });
  }

  componentDidMount() {
    const {dispatch} = this.props;

    let username = getUsername();

    dispatch(getUser(username));
    dispatch(getCart());
  }

  componentWillUnmount() {
    clearInterval(timer);
    clearInterval(poller);

    timer = null;
    poller = null;
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, userObject, cartObject} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !userObject || !cartObject) {
      return (
        <div id="dashboard-checkout-page" className="dashboard-page">
          <DashboardHeader/>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    if (cartObject.entries.length === 0) {
      return <Redirect to={formatMessage({id: URIKeys.DASHBOARD_CART})}/>
    }

    return (
      <div id="dashboard-checkout-page" className="dashboard-page">
        <DashboardHeader/>
        <Grid>
          <section className="checkout">
            <Row>
              <Col xs={12} sm={3}>
                {(this.state.step === 1 || this.state.step === 2) && this.renderShoppingCart()}
              </Col>
              <Col xs={12} sm={9}>
                {this.state.step === 1 && this.renderFirstStep()}
                {this.state.step === 2 && this.renderSecondStep()}
                {this.state.step === 3 && this.renderThirdStep()}
              </Col>
            </Row>
          </section>
        </Grid>
        <InsufficientCreditsModal show={this.state.showInsufficientCreditsModal}>
          <FormattedHTMLMessage id="modal.insufficient-credits.paragraph.order"
                                values={{
                                  balance: userObject.credits,
                                  price: cartObject.total
                                }}/>
          <Button bsStyle="danger" onClick={this.handleInsufficientCreditsCancelClick}>
            <FormattedMessage id="common.button.cancel"/>
          </Button>
        </InsufficientCreditsModal>
      </div>
    );
  }

  renderShoppingCart = () => {
    const {cartObject} = this.props;
    return (
      <div className="shopping-cart">
        <FormattedMessage id="nav-bar.item.dashboard.cart" tagName="h5"/>
        {cartObject.entries.map((entry) => (
          <div className="shopping-cart-entry" key={entry.id}>
            <Row>
              <Col xs={4} sm={2}>
                <span className="shopping-cart-entry-quantity">
                  {entry.quantity}
                </span>
              </Col>
              <Col xs={8} sm={10}>
                <span className="shopping-cart-entry-name">
                  {entry.product.name}
                </span>
              </Col>
            </Row>
          </div>
        ))}
        <div className="shopping-cart-totals">
          <Row>
            <Col xs={5}>
              <FormattedMessage id="label.total"/>
            </Col>
            <Col xs={7} className="text-right">
              {this.renderTotal(cartObject)}
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  renderFirstStep = () => {
    const {userObject, cartObject} = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <div className="checkout-step">
        <ProgressBar>
          <ProgressBar.Track/>
          <ProgressBar.Step active>
            <FormattedMessage id="page.checkout.step-one"/>
          </ProgressBar.Step>
          {cartObject.containsCreditBundles && (
            <ProgressBar.Step>
              <FormattedMessage id="page.checkout.step-two"/>
            </ProgressBar.Step>
          )}
          <ProgressBar.Step>
            <FormattedMessage id="page.checkout.step-three"/>
          </ProgressBar.Step>
        </ProgressBar>
        <FormattedMessage id="page.checkout.step-one.title" tagName="h3"/>
        <Row>
          <Col xs={6}>
            <FormattedMessage id="label.billing-address" tagName="h5"/>
            <address>
              {userObject.firstName + ' ' + userObject.lastName}<br/>
              {userObject.address.streetName + ' ' + userObject.address.streetNumber}<br/>
              {userObject.address.postalCode + ' ' + userObject.address.city}<br/>
              {formatMessage({id: 'countries.' + userObject.address.country})}
            </address>
          </Col>
          {cartObject.containsShipToProducts && (
            <Col xs={6}>
              <FormattedMessage id="label.shipping-address" tagName="h5"/>
              <address>
                {userObject.firstName + ' ' + userObject.lastName}<br/>
                {userObject.address.streetName + ' ' + userObject.address.streetNumber}<br/>
                {userObject.address.postalCode + ' ' + userObject.address.city}<br/>
                {formatMessage({id: 'countries.' + userObject.address.country})}
              </address>
            </Col>
          )}
        </Row>

        <FormattedMessage id="label.payment-method" tagName="h5"/>
        {cartObject.containsCreditBundles && (
          <Radio checked readOnly className="payment-method">
            <img className="img-responsive"
                 src={require('../../../images/pages/checkout/payconiq-horizontal-pos.png')}
                 alt="Payconiq"/>
          </Radio>
        )}
        {!cartObject.containsCreditBundles && (
          <Radio checked readOnly className="payment-method">
            <span className="payment-method-label">Credits</span>
          </Radio>
        )}
        <Checkbox className="terms-and-conditions" onChange={this.clickTermsAndConditions}
                  value={this.stateacceptedTermsAndConditions}>
          <FormattedMessage id="page.checkout.terms-and-conditions" tagName="p"
                            values={{
                              termsAndConditions:
                                <LocalizedLink id={URIKeys.TERMS_AND_CONDITIONS}>
                                  <FormattedMessage id="footer.link.terms-and-conditions"/>
                                </LocalizedLink>,
                            }}/>
        </Checkbox>
        <Button bsStyle="primary" disabled={this.state.submitted || !this.state.acceptedTermsAndConditions}
                onClick={() => this.proceedToPayment()}>
          {cartObject.containsCreditBundles && (
            <FormattedMessage id="page.checkout.button.pay-payconiq"/>
          )}
          {!cartObject.containsCreditBundles && (
            <FormattedMessage id="page.checkout.button.pay-credits"/>
          )}
        </Button>
      </div>
    );
  };

  clickTermsAndConditions = () => {
    this.setState({
      acceptedTermsAndConditions: !this.state.acceptedTermsAndConditions
    });
  };

  proceedToPayment = async () => {
    const {dispatch, cartObject, userObject} = this.props;


    this.setState({
      submitted: true
    });

    if (cartObject.containsCreditBundles) {
      try {
        const paymentInformation = await getOne(BackendURLConstants.PAYMENT_INFORMATION, true);
        const qrCode = paymentInformation._links.qrcode.href + '&s=M&f=PNG';

        this.startTimer(paymentInformation.expiresAt);
        this.startPolling();

        this.setState({
          step: ++this.state.step,
          paymentInformationObject: {
            paymentInformation: paymentInformation,
            qrCode: qrCode
          }
        });
      } catch (e) {
        dispatch(error('error-message.payment-information.get'));
      }
    } else {
      if (userObject.credits < cartObject.total) {
        this.setState({
          showInsufficientCreditsModal: true
        });
      } else {
        try {
          const order = await post(BackendURLConstants.ORDER.replace('$id', cartObject.id), undefined, 'application/json', true);

          this.setState({
            step: 3,
            order: order
          });
        } catch (e) {
          dispatch(error('error-message.place-order'));
        }
      }
    }
  };

  startTimer = (expiresAt) => {
    let expirationDate = new Date(expiresAt);
    if (!isDayLightSavingsTime(expirationDate)) {
      expirationDate.setHours(expirationDate.getHours() + 1);
    }

    this.setState({
      time: (expirationDate.getTime() - new Date().getTime()) / 1000
    });

    timer = setInterval(() => {
      this.setState({
        time: (expirationDate.getTime() - new Date().getTime()) / 1000
      });

      if (this.state.time <= 0) {
        clearInterval(timer);
        this.setState({
          paymentInformationObject: null
        });
      }
    }, 1000);
  };

  startPolling = async () => {
    const {intl: {formatMessage}, history, cartObject} = this.props;

    poller = setInterval(async () => {
      try {
        let order = await getOne(BackendURLConstants.ORDER_POLLING.replace('$id', cartObject.id), true);
        if (order) {
          clearInterval(poller);
          this.setState({
            step: ++this.state.step,
            paymentInformationObject: null,
            order: order
          });
        }
      } catch (e) {
        clearInterval(poller);
        history.push(formatMessage({id: URIKeys.DASHBOARD_CART}));
      }
    }, 5000);
  };

  cancel = async () => {
    const {intl: {formatMessage}, history, cartObject} = this.props;

    if (this.state.paymentInformationObject) {
      const data = {
        url: this.state.paymentInformationObject.paymentInformation._links.cancel.href,
        id: cartObject.id
      };

      try {
        put(BackendURLConstants.PAYMENT_INFORMATION, JSON.stringify(data), 'application/json', true);
      } catch (e) {
        // Do nothing
      }

      this.setState({
        paymentInformationObject: null
      });
    }

    history.push(formatMessage({id: URIKeys.DASHBOARD_CART}));
  };

  renderSecondStep = () => {
    const {intl: {formatMessage}, history} = this.props;
    if (!this.state.paymentInformationObject) {
      history.push(formatMessage({id: URIKeys.DASHBOARD_CART}));
    }

    return (
      <div className="checkout-step">
        <ProgressBar>
          <ProgressBar.Track/>
          <ProgressBar.Step complete>
            <FormattedMessage id="page.checkout.step-one"/>
          </ProgressBar.Step>
          <ProgressBar.Step active>
            <FormattedMessage id="page.checkout.step-two"/>
          </ProgressBar.Step>
          <ProgressBar.Step>
            <FormattedMessage id="page.checkout.step-three"/>
          </ProgressBar.Step>
        </ProgressBar>
        <FormattedMessage id="page.checkout.step-two.title" tagName="h3"/>
        <div className="payconiq">
          <Row>
            <Col xs={12}>
              <div className="payconiq-qr">
                <img className="img-response payconiq-qr-frame"
                     src={require('../../../images/pages/checkout/frame.png')}
                     alt="The Payconiq frame"/>
                {this.state.paymentInformationObject && this.renderQRCode()}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="payconiq-expiration-timer">
              {this.state.paymentInformationObject && this.renderExpiration()}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="payconiq-payment-information">
                <div className="payconiq-payment-information-wrapper">
                  <FormattedMessage id="page.checkout.payconiq.title" tagName="h5"/>
                  <Row>
                    <Col xs={4}>
                      <div className="payment-step">
                        <img className="img-responsive" src={require('../../../images/pages/checkout/step_1.png')}/>
                        <FormattedHTMLMessage id="page.checkout.payconiq.step-one"/>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="payment-step">
                        <img className="img-responsive" src={require('../../../images/pages/checkout/step_2.png')}/>
                        <FormattedHTMLMessage id="page.checkout.payconiq.step-two"/>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="payment-step">
                        <img className="img-responsive" src={require('../../../images/pages/checkout/step_3.png')}/>
                        <FormattedHTMLMessage id="page.checkout.payconiq.step-three"/>
                      </div>
                    </Col>
                  </Row>
                </div>
                <FormattedHTMLMessage id="page.checkout.payconiq.paragraph"/>
              </div>
            </Col>
          </Row>
        </div>
        <Button bsStyle="primary" onClick={() => this.cancel()}>
          <FormattedMessage id="common.button.cancel"/>
        </Button>
      </div>
    );
  };

  renderQRCode = () => {
    return (
      <img className="img-response payconiq-qr-code"
           src={this.state.paymentInformationObject.qrCode}
           alt="The Payconiq QR Code"/>
    );
  };

  renderExpiration = () => {
    const pad = function (num, size) {
      return ('000' + num).slice(size * -1);
    };

    const hours = Math.floor(this.state.time / 60 / 60);
    const minutes = Math.floor(this.state.time / 60) % 60;
    const seconds = Math.floor(this.state.time - minutes * 60);

    return (
      <FormattedMessage id="page.checkout.payconiq.timout.paragraph" values={{
        hours: pad(hours, 2),
        minutes: pad(minutes, 2),
        seconds: pad(seconds, 2)
      }}/>
    );
  };

  renderThirdStep = () => {
    const {intl: {formatMessage}, history, cartObject} = this.props;
    if (!this.state.order) {
      history.push(formatMessage({id: URIKeys.DASHBOARD_CART}));
    }

    let tax = 0;
    for (const taxEntry of this.state.order.taxes) {
      tax += taxEntry.value;
    }

    return (
      <div className="checkout-step">
        <ProgressBar>
          <ProgressBar.Track/>
          <ProgressBar.Step complete>
            <FormattedMessage id="page.checkout.step-one"/>
          </ProgressBar.Step>
          {cartObject.containsCreditBundles && (
            <ProgressBar.Step complete>
              <FormattedMessage id="page.checkout.step-two"/>
            </ProgressBar.Step>
          )}
          <ProgressBar.Step active>
            <FormattedMessage id="page.checkout.step-three"/>
          </ProgressBar.Step>
        </ProgressBar>
        <FormattedMessage id="page.checkout.step-three.title" tagName="h3"/>
        <Row>
          <Col xs={12} sm={6} className="order-confirmation-label">
            <FormattedMessage id="label.order-confirmation"/>
          </Col>
          <Col xs={12} sm={6}>
            {getUsername()}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="order-confirmation-label">
            <FormattedMessage id="label.order-no"/>
          </Col>
          <Col xs={12} sm={6}>
            {this.state.order.id}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="order-confirmation-label">
            <FormattedMessage id="label.order-date"/>
          </Col>
          <Col xs={12} sm={6}>
            {new Date(this.state.order.date).toLocaleDateString(getLanguage())}
          </Col>
        </Row>
        {this.state.order.entries.map((entry) => (
          <Row key={entry.id} className="order-entry">
            <Col xs={5}>
              <Row>
                <Col xs={2}>
                  {this.renderImage(entry.product)}
                </Col>
                <Col xs={10}>
                  <span className="order-entry-name">{entry.product.name}</span>
                  <span className="order-entry-sku">SKU: {entry.product.sku}</span>
                </Col>
              </Row>
            </Col>
            <Col xs={7}>
              <Row>
                <Col xs={4}>
                  <span className="order-entry-delivery-date">
                    {entry.deliveryDate && new Date(entry.deliveryDate).toLocaleDateString(getLanguage())}
                  </span>
                </Col>
                <Col xs={3}>
                  <span className="order-entry-quantity">
                       {entry.quantity.toLocaleString(getLanguage(), {
                         maximumFractionDigits: 0,
                         minimumFractionDigits: 0
                       })}
                  </span>
                </Col>
                <Col xs={5}>
                  {this.renderPrice(this.state.order, entry)}
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
        <div className="order-totals">
          <Row className="order-totals-subtotal">
            <Col xs={12}>
              <Row>
                <Col xs={6} sm={8}>
                  <FormattedMessage id="label.subtotal"/>
                </Col>
                <Col xs={6} sm={4}>
                  {this.renderSubtotal(this.state.order)}
                </Col>
              </Row>
            </Col>
          </Row>
          {this.renderTax(this.state.order, tax)}
          {this.renderDeliveryCosts(this.state.order)}
          <Row className="order-totals-total">
            <Col xs={12}>
              <Row>
                <Col xs={6} sm={8}>
                  <FormattedMessage id="label.total"/>
                </Col>
                <Col xs={6} sm={4}>
                  {this.renderTotal(this.state.order)}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Button bsStyle="primary" onClick={() => this.complete()}>
          <FormattedMessage id="modal.success.button.dashboard"/>
        </Button>
      </div>
    );
  };

  renderPrice = (cart, entry) => {
    if (cart.containsCreditBundles) {
      return (
        <span className="cart-entry-subtotal">
                        €{entry.subtotal.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
                      </span>
      );
    }

    return (
      <span className="cart-entry-subtotal">
        {entry.subtotal.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}&#160;credit(s)
      </span>
    );
  }

  renderSubtotal = (cart) => {
    if (cart.containsCreditBundles) {
      return (
        <div>
          €{cart.subtotal.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
        </div>
      )
    }

    return (
      <div>
        {cart.subtotal.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}&#160;credit(s)
      </div>
    )
  }

  renderTax = (cart, tax) => {
    if (cart.containsCreditBundles) {
      return (
        <Row className="shopping-cart-totals-tax">
          <Col xs={12}>
            <Row>
              <Col xs={6} sm={8}>
                <FormattedMessage id="label.tax"/>
              </Col>
              <Col xs={6} sm={4}>
                €{tax.toLocaleString(getLanguage(), {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return undefined;
  }

  renderDeliveryCosts = (order) => {
    if (order.containsShipToProducts) {
      return (
        <Row className="shopping-cart-totals-delivery-costs">
          <Col xs={12}>
            <Row>
              <Col xs={6} sm={8}>
                <FormattedMessage id="label.delivery-costs"/>
              </Col>
              <Col xs={6} sm={4}>
                {order.deliveryCosts.toLocaleString(getLanguage(), {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}&#160;credit(s)
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
    return undefined;
  }

  renderTotal = (cart) => {
    if (cart.containsCreditBundles) {
      return (
        <div>
          €{cart.total.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
        </div>
      );
    }

    return (
      <div>
        {cart.total.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}&#160;credit(s)
      </div>
    )
  }

  renderImage = (product) => {
    if (product.images && product.images[0]) {
      return (
        <img className="img-responsive" src={'data:image/jpg;base64,' + product.images[0].url}
             alt={product.images[0].name}/>
      );
    } else {
      return (
        <Image lowRes={require('../../../images/products/placeholder-tiny.jpeg')}
               highRes={require('../../../images/products/placeholder.jpeg')}
               classNames="img-responsive"/>
      );
    }
  }

  /**
   * Handles the click event on the cancel button.
   */
  handleInsufficientCreditsCancelClick = () => {
    this.setState({
      showInsufficientCreditsModal: false
    });
  };

  complete = async () => {
    const {intl: {formatMessage}, history} = this.props;
    this.setState({
      step: 0,
      acceptedTermsAndConditions: false,
      paymentInformationObject: null,
      time: null,
      order: null
    });

    history.push(formatMessage({id: URIKeys.DASHBOARD_PERFORMANCE}));
  };
}

function mapStateToProps(state) {
  const {common, getUser, getCart} = state;
  return {
    error: common.error,
    loading: common.loading,
    userObject: getUser.userObject,
    cartObject: getCart.cartObject,
  };
}

export default connect(mapStateToProps)(injectIntl(Checkout));
