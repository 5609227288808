import React from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, ControlLabel, Modal, Row, Table} from "react-bootstrap";
import Light from "../../Icons/Light";
import {Field, Form} from "react-final-form";
import arrayMutators from "final-form-arrays";
import Wizard from "../../Wizard/Wizard";
import Navigation from "../../Wizard/Navigation";
import NavigationNode from "../../Wizard/NavigationNode";
import Steps from "../../Wizard/Steps";
import FormGroup from "../../Forms/final-form/FormGroup/FormGroup";
import FormControl from "../../Forms/final-form/FormControl/FormControl";
import {FieldArray} from "react-final-form-arrays";
import RequiredAnnotation from "../../Forms/RequiredAnnotation";
import {compose, gtOrEqZero, maxLength, number, required} from "../../../utils/ValidationUtility";
import Select from "../../Forms/final-form/Select/Select";
import ALLERGENS from "../../../constants/Allergens";
import {getEmployeeRecipes} from "../../../actions";
import {getUsername} from "../../../utils/AuthenticationUtility";
import {deepCopy} from "../../../utils/DeepCopyUtiliy";
import BackendURLConstants from "../../../constants/BackendURLConstants";
import {patch, post, remove} from "../../../utils/FetchUtility";

/**
 * The {@code ManageRecipeModal} class represents the modal used to create or edit recipes.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ManageRecipeModal extends React.Component {

  /**
   * Creates a new instance of the {@link ManageRecipeModal} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      initialValues: this.getInitialValues(props.recipe)
    };
  }

  /**
   * Triggers when the component is updated.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.recipe !== this.props.recipe) {
      this.setState({
        initialValues: this.getInitialValues(this.props.recipe)
      });
    }
  }

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}, show} = this.props;
    return (
      <Modal show={show} backdrop={true} bsSize="lg" className="info-modal text-center" id="manage-recipe-modal">
        <Modal.Header>
          <Light icon="plus-square"/>
        </Modal.Header>
        <Modal.Body className="no-padding">
          <Form
            onSubmit={this.onSubmit}
            mutators={{
              ...arrayMutators
            }}
            initialValues={this.state.initialValues}
            render={({handleSubmit, pristine, invalid, form}) => (
              <form onSubmit={handleSubmit} id="recipe-form">
                <Wizard activeStep={1} toggleCancel={() => this.onCancel(form.reset)} pristine={pristine} invalid={invalid}>
                  <Navigation>
                    <NavigationNode step={1}>
                      <FormattedMessage id="documents.page.section.common.title" tagName="h5"/>
                    </NavigationNode>
                    <NavigationNode step={2}>
                      <FormattedMessage id="label.allergens" tagName="h5"/>
                    </NavigationNode>
                    <NavigationNode step={3}>
                      <FormattedMessage id="label.ingredients" tagName="h5"/>
                    </NavigationNode>
                    <NavigationNode step={4}>
                      <FormattedMessage id="label.instructions" tagName="h5"/>
                    </NavigationNode>
                  </Navigation>
                  <Steps>
                    <Wizard.Step step={1} first>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="label.common-information" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="name">
                            <ControlLabel>
                              <FormattedMessage id="label.name"/>
                              <RequiredAnnotation/>
                            </ControlLabel>
                            <Field
                              name="name"
                              component={FormControl}
                              validate={compose(required, maxLength(256))}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={6}>
                          <FormGroup controlId="meal">
                            <ControlLabel>
                              <FormattedMessage id="label.meal-type"/>
                              <RequiredAnnotation/>
                            </ControlLabel>
                            <Field
                              name="meal"
                              component={Select}
                              validate={required}
                              options={[
                                {
                                  value: undefined,
                                  label: formatMessage({id: 'label.select'}),
                                  disabled: true
                                },
                                {
                                  value: 'BREAKFAST',
                                  label: formatMessage({id: 'recipe.type.BREAKFAST'})
                                },
                                {
                                  value: 'LUNCH',
                                  label: formatMessage({id: 'recipe.type.LUNCH'})
                                },
                                {
                                  value: 'DINNER',
                                  label: formatMessage({id: 'recipe.type.DINNER'})
                                },
                                {
                                  value: 'SNACK',
                                  label: formatMessage({id: 'recipe.type.SNACK'})
                                }
                              ]}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <FormGroup controlId="preparationTime">
                            <ControlLabel>
                              <FormattedMessage id="label.preparation-time-in-min"/>
                              <RequiredAnnotation/>
                            </ControlLabel>
                            <Field
                              name="preparationTime"
                              component={FormControl}
                              validate={compose(required, number, gtOrEqZero)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={6}>
                          <FormGroup controlId="people">
                            <ControlLabel>
                              <FormattedMessage id="label.people"/>
                              <RequiredAnnotation/>
                            </ControlLabel>
                            <Field
                              name="people"
                              component={FormControl}
                              validate={compose(required, number, gtOrEqZero)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="description">
                            <ControlLabel>
                              <FormattedMessage id="label.description"/>
                            </ControlLabel>
                            <Field
                              name="description"
                              component={FormControl}
                              componentClass="textarea"
                              validate={maxLength(256)}
                              rows={3}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="tip">
                            <ControlLabel>
                              <FormattedMessage id="label.tip"/>
                            </ControlLabel>
                            <Field
                              name="tip"
                              component={FormControl}
                              componentClass="textarea"
                              validate={maxLength(256)}
                              rows={3}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Wizard.Step>
                    <Wizard.Step step={2}>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="label.allergens" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="allergens">
                            {ALLERGENS.map(allergen => (
                              <label className="checkbox-inline" key={allergen.key}>
                                <Field
                                  name="allergens"
                                  component="input"
                                  type="checkbox"
                                  value={allergen.key}
                                />{' '}
                                {formatMessage({id: 'allergen.type.' + allergen.key})}
                              </label>
                            ))}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Wizard.Step>
                    <Wizard.Step step={3}>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="label.ingredients" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FieldArray name="ingredients">
                            {({fields}) => (
                              <Table striped bordered condensed hover responsive>
                                <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="label.amount"/>
                                  </th>
                                  <th>
                                    <FormattedMessage id="label.ingredient"/>
                                    <RequiredAnnotation/>
                                  </th>
                                  <th>
                                    <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                                            onClick={() => fields.push('')}>
                                      <Light icon="plus-square"/>
                                    </Button>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((ingredient, index) => (
                                  <tr key={ingredient} className="ingredient">
                                    <td className="quantityAndUnit">
                                      <FormGroup controlId={`${ingredient}.quantityAndUnit`}>
                                        <Field name={`${ingredient}.quantityAndUnit`}
                                               component={FormControl}
                                               componentClass="input"/>
                                      </FormGroup>
                                    </td>
                                    <td className="description">
                                      <FormGroup controlId={`${ingredient}.description`}>
                                        <Field name={`${ingredient}.description`}
                                               component={FormControl}
                                               componentClass="input"
                                               validate={compose(required, maxLength(256))}/>
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <Button className="pull-right" bsStyle="danger" bsSize="small"
                                              onClick={() => fields.remove(index)}>
                                        <Light icon="trash-alt"/>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </Table>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                    </Wizard.Step>
                    <Wizard.Step step={4} last>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="label.instructions" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FieldArray name="instructions">
                            {({fields}) => (
                              <Table striped bordered condensed hover responsive>
                                <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="label.instruction"/>
                                    <RequiredAnnotation/>
                                  </th>
                                  <th>
                                    <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                                            onClick={() => fields.push('')}>
                                      <Light icon="plus-square"/>
                                    </Button>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((instruction, index) => (
                                  <tr key={instruction} className="instruction">
                                    <td className="description">
                                      <FormGroup controlId={`${instruction}.description`}>
                                        <Field name={`${instruction}.description`}
                                               component={FormControl}
                                               componentClass="textarea"
                                               validate={compose(required, maxLength(256))}
                                               rows={3}/>
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <Button className="pull-right" bsStyle="danger" bsSize="small"
                                              onClick={() => fields.remove(index)}>
                                        <Light icon="trash-alt"/>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </Table>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                    </Wizard.Step>
                  </Steps>
                </Wizard>
              </form>
            )}/>
        </Modal.Body>
      </Modal>
    );
  }

  /**
   * Closes the modal.
   */
  onCancel = (resetFn) => {
    resetFn();
    this.props.toggleManageRecipeModal();
  };

  /**
   * Handles the form on submit.
   */
  onSubmit = async (values) => {
    const {dispatch, recipe} = this.props;

    if (recipe) {
      await this.updateRecipe(values, recipe);
    } else {
      await this.createRecipe(values);
    }

    dispatch(getEmployeeRecipes(getUsername(), 10, 0));

    this.props.toggleManageRecipeModal();
  };

  /**
   * Creates the recipe for the given {@code values}.
   *
   * @param values the values
   * @returns the recipe
   */
  async createRecipe(values) {
    let ingredients = deepCopy(values.ingredients);
    let instructions = deepCopy(values.instructions);

    let recipe = {
      ...values,
      instructions: undefined,
      ingredients: undefined,
      employee: BackendURLConstants.EMPLOYEE.replace('$id', getUsername())
    };

    recipe = await post(BackendURLConstants.RECIPES, JSON.stringify(recipe), 'application/json', true);

    this.addSortOrder(ingredients);
    this.addSortOrder(instructions);

    await Promise.all(ingredients.map(async ingredient => await this.createOrUpdateIngredient(ingredient, recipe)));
    await Promise.all(instructions.map(async instruction => await this.createOrUpdateInstruction(instruction, recipe)));

    return recipe;
  }

  /**
   * Updates the recipe for the given {@code values}.
   *
   * @param values the values
   * @param recipe the recipe
   * @returns the recipe
   */
  async updateRecipe(values, recipe) {
    let ingredients = deepCopy(values.ingredients);
    let instructions = deepCopy(values.instructions);

    let data = {
      ...values,
      instructions: undefined,
      ingredients: undefined,
    };

    await patch(BackendURLConstants.RECIPE.replace('$id', recipe.id), JSON.stringify(data), 'application/json', true);

    this.addSortOrder(ingredients);
    this.addSortOrder(instructions);

    this.removeObsoleteIngredients(recipe.ingredients, ingredients);
    this.removeObsoleteInstructions(recipe.instructions, instructions);

    await Promise.all(ingredients.map(async ingredient => await this.createOrUpdateIngredient(ingredient, recipe)));
    await Promise.all(instructions.map(async instruction => await this.createOrUpdateInstruction(instruction, recipe)));
  }

  /**
   * Adds a sort order to the given {@code objects}.
   *
   * @param objects the objects
   */
  addSortOrder(objects) {
    for (let i = 0; i < objects.length; i++) {
      objects[i].order = i;
    }
  }

  /**
   * Removes the obsolete ingredients.
   *
   * @param existingIngredients the list of existing ingredients
   * @param ingredients the new list of ingredients
   */
  async removeObsoleteIngredients(existingIngredients, ingredients) {
    for (const existingIngredient of existingIngredients) {
      if (this.isObsolete(existingIngredient, ingredients)) {
        await remove(BackendURLConstants.INGREDIENT.replace('$id', existingIngredient.id), true);
      }
    }
  }

  /**
   * Removes the obsolete instructions.
   *
   * @param existingInstructions the list of existing instructions
   * @param instructions the new list of instructions
   */
  async removeObsoleteInstructions(existingInstructions, instructions) {
    for (const existingInstruction of existingInstructions) {
      if (this.isObsolete(existingInstruction, instructions)) {
        await remove(BackendURLConstants.INSTRUCTION.replace('$id', existingInstruction.id), true);
      }
    }
  }

  /**
   * Checks whether the given {@code existingItem} is obsolete.
   *
   * @param existingItem the existing slot
   * @param items the new items
   * @returns {boolean} {@code true} in case the existing item is obsolete, {@code false} otherwise
   */
  isObsolete(existingItem, items) {
    let flag = true;
    for (const item of items) {
      if (item.id && existingItem.id === item.id) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  /**
   * Creates or updates the ingredient for the given {@code recipe}.
   *
   * @param ingredient the ingredient
   * @param recipe the recipe
   */
  async createOrUpdateIngredient(ingredient, recipe) {
    ingredient.recipe = BackendURLConstants.RECIPE.replace('$id', recipe.id);

    if (ingredient.id) {
      await patch(BackendURLConstants.INGREDIENT.replace('$id', ingredient.id), JSON.stringify(ingredient), 'application/json', true);
    } else {
      await post(BackendURLConstants.INGREDIENTS, JSON.stringify(ingredient), 'application/json', true);
    }
  }

  /**
   * Creates or updates the instruction for the given {@code recipe}.
   *
   * @param instruction the instruction
   * @param recipe the recipe
   */
  async createOrUpdateInstruction(instruction, recipe) {
    instruction.recipe = BackendURLConstants.RECIPE.replace('$id', recipe.id);

    if (instruction.id) {
      await patch(BackendURLConstants.INSTRUCTION.replace('$id', instruction.id), JSON.stringify(instruction), 'application/json', true);
    } else {
      await post(BackendURLConstants.INSTRUCTIONS, JSON.stringify(instruction), 'application/json', true);
    }
  }

  /**
   * Returns the initial values for the form.
   * <p>
   * If the given {@code recipe} is not null, it's values will be used. Otherwise an empty form is created.
   *
   * @param recipe
   * @returns {{}} the initial values
   */
  getInitialValues(recipe) {
    let initialValues;
    if (recipe) {
      initialValues = {
        name: recipe.name,
        description: recipe.description,
        tip: recipe.tip,
        meal: recipe.meal,
        preparationTime: recipe.preparationTime,
        people: recipe.people,
        allergens: recipe.allergens,
        ingredients: recipe.ingredients,
        instructions: recipe.instructions,
        visible: recipe.visible
      }
    } else {
      initialValues = {
        name: undefined,
        description: undefined,
        tip: undefined,
        meal: undefined,
        preparationTime: undefined,
        people: undefined,
        allergens: [],
        ingredients: [
          {
            quantityAndUnit: undefined,
            description: undefined
          }
        ],
        instructions: [{
          description: undefined
        }],
        visible: false
      }
    }
    return initialValues;
  }
}

export default connect()(injectIntl(ManageRecipeModal));
