import React from "react";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code LocalizedLink} class represents a localized link.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class LocalizedLink extends React.Component {

  isExternalized(id) {
    return (id == URIKeys.HOME || id == URIKeys.ABOUT_US || id == URIKeys.SERVICES ||
            id === URIKeys.STORE_LOCATOR || id === URIKeys.PRICING || id == URIKeys.CONTACT ||
            id === URIKeys.FAQ);
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}, id, children, ...otherProps} = this.props;

    return (
      this.isExternalized(id) ? <a href={formatMessage({id: id})} {...otherProps}>{children}</a> :
      <Link {...otherProps} to={formatMessage({id: id})}>
        {children}
      </Link>
    );
  }
}

export default injectIntl(LocalizedLink);
