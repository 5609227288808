/**
 * The {@code ALLERGENS} constant class contains the allergens.
 *
 * @type {object}
 */
const ALLERGENS = [
  {
    key: 'GLUTEN'
  },
  {
    key: 'CRUSTACEANS'
  },
  {
    key: 'EGGS'
  },
  {
    key: 'FISH'
  },
  {
    key: 'PEANUTS'
  },
  {
    key: 'SOY'
  },
  {
    key: 'MILK'
  },
  {
    key: 'NUTS'
  },
  {
    key: 'CELERY'
  },
  {
    key: 'MUSTARD'
  },
  {
    key: 'SESAME'
  },
  {
    key: 'SULFITES'
  },
  {
    key: 'LUPINS'
  },
  {
    key: 'MOLLUSCS'
  }
];

export default ALLERGENS;

