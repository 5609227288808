import React from "react";
import {Redirect} from "react-router-dom";
import {injectIntl} from "react-intl";
import {Col, Grid, Row} from "react-bootstrap";
import {ChangePasswordForm} from "../../components/Forms/index";
import {isLoggedIn} from "../../utils/AuthenticationUtility";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code ChangePassword} class represents the change password page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ChangePassword extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        const {formatMessage} = this.props.intl;
        if (isLoggedIn()) {
            return <Redirect to={formatMessage({id: URIKeys.DASHBOARD_PERFORMANCE})}/>
        }

        return (
            <div id="change-password-page">
                <Grid>
                    <Row>
                        <Col xs={10} xsOffset={1} md={4} mdOffset={4} className="col-change-password-form">
                            <div className="change-password-form-wrapper">
                                <ChangePasswordForm username={this.props.match.params.username}
                                                    token={this.props.match.params.token}/>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default injectIntl(ChangePassword);
