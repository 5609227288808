import React from "react";
import Light from "../Icons/Light";

/**
 * The {@code Document} class represents an document.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Document extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {fileName, label} = this.props;
    return (
      <li className="document">
        <Light icon="file-alt" classNames="icon-info"/>
        <a href={require('../../documents/' + fileName)} download>{label}</a>
      </li>
    );
  }
}

export default Document;
