import {combineReducers} from "redux";
import {calendar} from "./Calendar";
import {common} from "./Common";
import {postEmail} from "./Email";
import {getFacilityAppointments, selectFacility} from "./Facilities";
import {getPointOfService, getPointsOfService, storeLocator} from "./PointsOfService";
import {changePassword, login, logout, resetPassword} from "./Security"
import {getRecipe, getRecipes} from "./Recipes"
import {postSubscription} from "./Subscription";
import {employee, employeeRecipes} from "./Employee";
import {
  getUser,
  getUserAppointments,
  getUserBodyCompositionMeasurements,
  getUserGirthMeasurements,
  getUserMealSchedule,
  getUserMealSchedules,
  getUsers
} from "./Users";
import {getCategories, getProduct, getProducts} from "./Products";
import {appointmentsUser} from "./Appointments";
import Actions from "../constants/Actions";
import {intakeForms} from "./IntakeForm";
import {getCart} from "./Cart";

export default (state, action) => {
  if (action.type === Actions.LOGOUT) {
    state = undefined
  }

  return appReducer(state, action);
};

const appReducer = combineReducers({
  // Appointments
  appointmentsUser,

  // Calendar
  calendar,

  // Cart
  getCart,

  // Categories
  getCategories,

  // Common
  common,

  // Emails
  postEmail,

  // Employee
  employee,
  employeeRecipes,

  // Facilities
  selectFacility,
  getFacilityAppointments,

  // Points of service
  getPointsOfService,
  getPointOfService,
  storeLocator,

  // Products
  getProduct,
  getProducts,

  // Security
  changePassword,
  login,
  logout,
  resetPassword,

  // Recipes
  getRecipe,
  getRecipes,

  // Subscriptions
  postSubscription,

  // Users
  getUser,
  getUserAppointments,
  getUserBodyCompositionMeasurements,
  getUserGirthMeasurements,
  getUserMealSchedules,
  getUserMealSchedule,
  getUsers,

  // Intake Forms
  intakeForms,
});
