import React from "react";
import {Redirect} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, Grid, Row, Table} from "react-bootstrap";
import {Pagination} from "@react-bootstrap/pagination";
import DashboardHeader from "../../../partials/DashboardHeader";
import Loading from "../../../components/Modals/Loading";
import URIKeys from "../../../constants/URIKeys";
import {PageHeader, PageHeaderTitle} from "../../../components/PageHeader";
import {Light} from "../../../components/Icons";
import {getPointsOfService, getUsers} from "../../../actions";
import {connect} from "react-redux";
import {hasRole} from "../../../utils/AuthenticationUtility";
import {remove} from "../../../utils/FetchUtility";
import BackendURLConstants from "../../../constants/BackendURLConstants";
import UserGeneralInformationForm from "../../../components/Forms/UserGeneralInformationForm";
import AddEntityModal from "../../../components/Modals/AddEntityModal/AddEntityModal";
import DeleteEntityModal from "../../../components/Modals/DeleteEntityModal/DeleteEntityModal";
import {convertISODateToJSDate} from "../../../utils/DateTimeUtility";
import {NUTRITIONIST_CONSULTANCY_STATUS} from "../../../constants/NutritionistConsultancyStatus";
import Solid from "../../../components/Icons/Solid";
import {getLanguage} from "../../../utils/LanguageUtility";
import USER_ROLES from "../../../constants/UserRoles";

/**
 * The {@code Users} class represents the dashboard users listing page.
 *
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Users extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showDeleteUserModal: false,
      showCreateUserModal: false,
      user: undefined
    };
  }

  /**
   * Gets the users.
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getPointsOfService(undefined, undefined, undefined, true));
    dispatch(getUsers(undefined, 0, 'firstName,asc&sort=lastName,asc'));
  }

  togglePage = (page) => {
    const {dispatch} = this.props;
    dispatch(getUsers(undefined, --page, 'firstName,asc&sort=lastName,asc'));
  };

  /**
   * Renders the delete button.
   *
   * @returns {*} the delete button
   */
  renderDeleteButton = (user) => {
    let result = null;
    if (hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN)) {
      result = (
        <Button className="pull-right" bsStyle="danger" bsSize="small"
                onClick={() => this.toggleDeleteUserModal(user, true)}>
          <Light icon="trash-alt"/>
        </Button>
      );
    }
    return result;
  };

  /**
   * Renders the create button.
   *
   * @returns {*} the create button
   */
  renderCreateButton = () => {
    let result = null;
    if (hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN)) {
      result = (
        <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                onClick={() => this.toggleCreateUserModal(true)}>
          <Light icon="plus-square"/>
        </Button>
      );
    }
    return result;
  };

  /**
   * Toggles the delete confirmation modal.
   *
   * @param user the user
   * @param value the value
   */
  toggleDeleteUserModal = (user, value = true) => {
    this.setState({
      showDeleteUserModal: value,
      user: user
    });
  };

  /**
   * Toggles the create user modal.
   *
   * @param value the value
   */
  toggleCreateUserModal = (value = true) => {
    this.setState({
      showCreateUserModal: value
    });
  };

  /**
   * Removes the user.
   */
  onDeleteUser = async () => {
    const {dispatch} = this.props;
    const {user} = this.state;

    await remove(BackendURLConstants.USER.replace('$id', user.username), true);
    await dispatch(getUsers(undefined, 0, undefined));

    this.toggleDeleteUserModal(null, false);
  };

  /**
   * Edits the user.
   */
  onEditUser = (id) => {
    const {intl: {formatMessage}, history} = this.props;
    history.push(formatMessage({id: URIKeys.DASHBOARD_USER}).replace(':id', id));
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}, error, loading, userCollection, pointOfServiceCollection} = this.props;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !userCollection || !pointOfServiceCollection) {
      return (
        <div id="dashboard-users-page" className="dashboard-page">
          <DashboardHeader/>
          <PageHeader>
            <PageHeaderTitle>
              <FormattedMessage id="users.page.title" tagName="h3"/>
              <FormattedMessage id="users.page.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <section className="users">
            <Loading/>
          </section>
        </div>
      )
    }

    return (
      <div id="dashboard-users-page" className="dashboard-page">
        <DashboardHeader/>
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="users.page.title" tagName="h3"/>
            <FormattedMessage id="users.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="users">
          <Grid>
            <Row>
              <Col xs={12}>
                {this.renderCreateButton()}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Table striped bordered condensed hover responsive>
                  <thead>
                  <tr>
                    <th><FormattedMessage id="label.first-name"/></th>
                    <th><FormattedMessage id="label.last-name"/></th>
                    <th><FormattedMessage id="label.username"/></th>
                    <th><FormattedMessage id="label.credits"/></th>
                    <th><FormattedMessage id="label.credits-expiry-date"/></th>
                    <th><FormattedMessage id="label.godparent"/></th>
                    <th><FormattedMessage id="label.active"/></th>
                    <th><FormattedMessage id="label.additional-info"/></th>
                    <th><FormattedMessage id="label.newsletter.abbreviation"/></th>
                    <th><FormattedMessage id="label.motivation.abbreviation"/></th>
                    <th>&#160;</th>
                  </tr>
                  </thead>
                  <tbody>
                  {userCollection.users.map((user) => (
                    <tr key={user.username}>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.username}</td>
                      <td>{user.credits}</td>
                      <td>{convertISODateToJSDate(user.creditsExpiryDate).toLocaleDateString(getLanguage())}</td>
                      <td>{user.godparent && user.godparent.firstName} {user.godparent && user.godparent.lastName}</td>
                      <td>{user.active ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'})}</td>
                      <td className="text-center">
                        {(user.nutritionistConsultancyStatus === NUTRITIONIST_CONSULTANCY_STATUS.INITIAL_APPOINTMENT_PLANNED
                          || user.nutritionistConsultancyStatus === NUTRITIONIST_CONSULTANCY_STATUS.FOLLOWUP_APPOINTMENT_OPEN) && (
                          <Solid icon="utensils-alt" classNames="icon-success"/>
                        )}
                      </td>
                      <td>{this._isUserSubscribed(user, 'NEWSLETTER')}</td>
                      <td>{this._isUserSubscribed(user, 'MOTIVATION')}</td>
                      <td className="text-center">
                        <Button bsStyle="primary" bsSize="small" onClick={() => this.onEditUser(user.username)}>
                          <Light icon="edit"/>
                        </Button>
                        {this.renderDeleteButton(user)}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Grid>
        </section>
        <section className="pagination-links">
          <Grid>
            <Row>
              <Col xs={12}>
                <Pagination bsSize="medium"
                            items={(userCollection.page.totalPages)}
                            activePage={userCollection.page.number + 1}
                            onSelect={this.togglePage}
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={5}/>
              </Col>
            </Row>
          </Grid>
        </section>
        <DeleteEntityModal show={this.state.showDeleteUserModal}>
          <FormattedMessage id="user.page.modal.delete-confirmation.title" tagName="h4"/>
          <FormattedMessage id="users.page.modal.delete-confirmation.body"
                            values={{
                              firstName: this.state.user && this.state.user.firstName,
                              lastName: this.state.user && this.state.user.lastName
                            }}
                            tagName="p"/>
          <Button bsStyle="primary" onClick={() => this.onDeleteUser()}>
            <FormattedMessage id="common.button.confirm"/>
          </Button>
          <Button bsStyle="danger" onClick={() => this.toggleDeleteUserModal(null, false)}>
            <FormattedMessage id="common.button.cancel"/>
          </Button>
        </DeleteEntityModal>
        <AddEntityModal show={this.state.showCreateUserModal}>
          <FormattedMessage id="users.page.modal.create.title" tagName="h4"/>
          <UserGeneralInformationForm create={true} edit={true} user={null}/>
        </AddEntityModal>
      </div>
    );
  }

  /**
   * Returns the value for the newsletter field.
   *
   * @param user the user
   * @param type the type of subscription
   * @private
   */
  _isUserSubscribed(user, type) {
    const {intl: {formatMessage}} = this.props;
    let result = formatMessage({id: 'common.no'});
    if (user.subscriptions) {
      for (const subscription of user.subscriptions) {
        if (type === subscription.type) {
          result = formatMessage({id: 'common.yes'});
          break;
        }
      }
    }
    return result;
  }
}


/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getUsers, getPointsOfService} = state;
  return {
    error: common.error,
    loading: common.loading,
    userCollection: getUsers.userCollection,
    pointOfServiceCollection: getPointsOfService.pointOfServiceCollection
  };
}

export default connect(mapStateToProps)(injectIntl(Users));
