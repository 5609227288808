/**
 * Converts the given {@code value} to a localized date time object.
 *
 * @param value the date value
 * @returns {Date} the date time object
 */
export function convertISODateToJSDate(value) {
  return new Date(value + 'T00:00:00Z');
}

/**
 * Converts the given {@code value} to a localized date time object.
 *
 * @param value the time value
 * @returns {Date} the date time object
 */
export function convertISOTimeToJSDate(value) {
  let today = new Date();
  let date = new Date(today.getFullYear()
    + '-' + prefix(today.getMonth() + 1)
    + '-' + prefix(today.getDate())
    + 'T' + value + 'Z');
  if (!isDayLightSavingsTime(date)) {
    date.setHours(date.getHours() - 1);
  }
  return date;
}

/**
 * Converts the given {@code value} to a localized date time object.
 *
 * @param value the date and time value
 * @returns {Date} the date time object
 */
export function convertISODateTimeToJSDate(value) {
  let date = new Date(value + 'Z');
  if (!isDayLightSavingsTime(date)) {
    date.setHours(date.getHours() - 1);
  }
  return date;
}

/**
 * Converts the given {@code value} to the value suitable for a date input field.
 *
 * @param value the date
 * @returns {string} the date object
 */
export function convertJSDateToDateInput(value) {
  return prefix(value.getDate()) + "-" + prefix(value.getMonth() + 1) + "-" + value.getFullYear();
}

/**
 * Converts the given {@code value} to the value suitable for a date input field.
 *
 * @param value the date
 * @returns {string} the date object
 */
export function convertISODateToDateInput(value) {
  let date = convertISODateToJSDate(value);
  return prefix(date.getDate()) + "-" + prefix(date.getMonth() + 1) + "-" + date.getFullYear();
}

/**
 * Converts the given {@code date} to a time string.
 *
 * @param value the date and time
 * @returns {string} the date time string
 */
export function convertJSDateToISODateTime(value) {
  value = convertToUTC(value);
  return printJSDateInISODateTime(value);
}

/**
 * Converts the given {@code value} to a UTC date time string
 *
 * @param value the value
 * @returns {string} the date time string
 */
export function convertInputTimeToISODateTime(value) {
  let split = value.split(':');

  const date = new Date();
  date.setHours(split[0]);
  date.setMinutes(split[1]);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return convertJSDateToISODateTime(date);
}

/**
 * Prints the date in the ISO string with the time zone information.
 *
 * @returns {string} the date string in ISO 8601 format
 */
export function printInputDateInISODateTime(value) {
  const split = value.split('-');
  return split[2]
    + '-' + split[1]
    + '-' + split[0]
    + 'T00:00:00';
}

/**
 * Prints the date in the ISO String.
 *
 * @returns {string} the date string in ISO 8601 format
 */
export function printJSDateInISODate(value) {
  return value.getFullYear()
    + '-' + prefix(value.getMonth() + 1)
    + '-' + prefix(value.getDate());
}

/**
 * Prints the date in the ISO string with the time zone information.
 *
 * @returns {string} the date string in ISO 8601 format
 */
export function printJSDateInISODateTime(value) {
  return printJSDateInISODate(value)
    + 'T' + prefix(value.getHours())
    + ':' + prefix(value.getMinutes())
    + ':' + prefix(value.getSeconds());
}

export function formatDate(jsDate) {
  return `${jsDate.getDate()}-${jsDate.getMonth() + 1}-${jsDate.getFullYear()}`
}

/**
 * Adds a prefix {@code 0} to the given {@code value} if necessary.
 *
 * @param value the value
 * @returns {string} the prefixed value
 */
function prefix(value) {
  let norm = Math.floor(Math.abs(value));
  return (norm < 10 ? '0' : '') + norm;
}

/**
 * Converts the given {@code date} to the UTC time zone.
 *
 * @param date the date to convert
 * @returns {Date} the converted date
 */
function convertToUTC(date) {
  return new Date(date.getTime() + getDateOffset(date) * 60000);
}

/**
 * Returns the timezone offset to use for date conversion of the given {@code date}.
 * <p>
 * The backend stores all dates in UTC. To avoid issues regarding the Daylight Saving Time, we have to adjust the offset
 * manually.
 *
 * @param the date
 * @returns {number} the timezone offset
 */
function getDateOffset(date) {
    // Based on Current Deployment : Client is in Northern Hemisphere.
    let janurayTimeOffset = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    let juneTimeOffset = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(janurayTimeOffset, juneTimeOffset);
}

/**
 * Checks whether the given {@code date} is in DST.
 *
 * @param date the date
 * @returns {boolean} {@code true} in case the given {@code date} is in DST, {@code false} otherwise
 */
export function isDayLightSavingsTime(date) {
  return date.getTimezoneOffset() === getDateOffset(date);
}
