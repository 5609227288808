import React from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, Grid, Row, Table} from "react-bootstrap";
import {getUsername} from "../../../utils/AuthenticationUtility";
import DashboardHeader from "../../../partials/DashboardHeader";
import Loading from "../../../components/Modals/Loading";
import URIKeys from "../../../constants/URIKeys";
import {PageHeader, PageHeaderTitle} from "../../../components/PageHeader";
import {Light} from "../../../components/Icons";
import {getEmployee} from "../../../actions";

/**
 * The {@code PointsOfService} class represents the dashboard points of service page.
 *
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PointsOfService extends React.Component {

  /**
   * Gets emplyee points of service. 
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getEmployee(getUsername()));
  }

  goToDetail = (posId) => {
    const {intl: {formatMessage}, history} = this.props;

    history.push(formatMessage({id: URIKeys.DASHBOARD_POINT_OF_SERVICE}).replace(':id', posId));
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}, error, loading, employee} = this.props;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !employee) {
      return (
        <div id="dashboard-points-of-service-page" className="dashboard-page">
          <DashboardHeader/>
          <PageHeader>
            <PageHeaderTitle>
              <FormattedMessage id="points-of-service.page.title" tagName="h3"/>
              <FormattedMessage id="points-of-service.page.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    return (
      <div id="dashboard-points-of-service-page" className="dashboard-page">
        <DashboardHeader/>
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="points-of-service.page.title" tagName="h3"/>
            <FormattedMessage id="points-of-service.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="points-of-service">
          <Grid>
            <Row>
              <Col md={12}>
                <Table responsive hover bordered striped condensed>
                  <thead>
                  <tr>
                    <th><FormattedMessage id="point-of-service.name"/></th>
                    <th><FormattedMessage id="address.phone-number"/></th>
                    <th><FormattedMessage id="address.city"/></th>
                    <th><FormattedMessage id="address.state"/></th>
                    <th><FormattedMessage id="address.country"/></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {employee.pointsOfService.map((pointOfService) => (
                    <tr key={pointOfService.id}>
                      <td>{pointOfService.name}</td>
                      <td>{pointOfService.address ? pointOfService.address.phoneNumber : ''}</td>
                      <td>{pointOfService.address ? pointOfService.address.city : ''}</td>
                      <td>{pointOfService.address ? formatMessage({id: `countries.${pointOfService.address.country}.state.${pointOfService.address.state}`}) : ''}</td>
                      <td>{pointOfService.address ? formatMessage({id: `countries.${pointOfService.address.country}`}) : ''}</td>
                      <td className="text-center">
                        <Button bsStyle="primary" onClick={() => this.goToDetail(pointOfService.id)} bsSize="small">
                          <Light icon="edit"/>
                        </Button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, employee} = state;

  return {
    error: common.error,
    loading: common.loading,
    employee: employee.employee
  };
}

export default connect(mapStateToProps)(injectIntl(PointsOfService));
