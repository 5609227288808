/**
 * The {@code BUNDLES} constant class contains the supported bundles.
 *
 * @type {object}
 */
const BUNDLES = [
  {
    key: 'trial',
    sku: '00000001'
  }, {
    key: 'small',
    sku: '00000002'
  },
  {
    key: 'medium',
    sku: '00000003'
  }, {
    key: 'large',
    sku: '00000004'
  }, {
    key: 'home',
    sku: '00000005'
  }
];

export default BUNDLES;

