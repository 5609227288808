import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, Form} from "react-final-form";
import {FieldArray} from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays';
import {Button, Col, ControlLabel, FormGroup, Modal, Row, Table} from "react-bootstrap";
import Light from "../../Icons/Light";
import OPENING_DAY_TYPES from "../../../constants/OpeningDayType";
import FormControl from "../../Forms/final-form/FormControl/FormControl";
import {compose, date, required} from "../../../utils/ValidationUtility";
import Checkbox from "../../Forms/final-form/Checkbox/Checkbox";
import {convertISODateToDateInput} from "../../../utils/DateTimeUtility";
import {getLanguage} from "../../../utils/LanguageUtility";

/**
 * The {@code AddCreditsModal} class represents the modal to add credits to a user.
 *
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class FacilityOpeningDayModal extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {show, onSubmit, onCancel, openingDay, openingSchedule} = this.props;

    let submitFn;
    let pushFn;

    const isSpecial = !openingDay || openingDay.type === OPENING_DAY_TYPES.SPECIAL;

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{
          id: openingDay && openingDay.id,
          date: openingDay && openingDay.date && convertISODateToDateInput(openingDay.date),
          closed: openingDay && openingDay.closed,
          openingSchedule: openingSchedule && openingSchedule.id,
          type: openingDay && openingDay.type,
          dayOfWeek: openingDay && openingDay.dayOfWeek,
          openingHours: (openingDay && openingDay.openingHours && openingDay.openingHours
            .map(openingHour => ({
              id: openingHour.id,
              startTime: openingHour.startTime.toLocaleTimeString(getLanguage(), {
                hour: '2-digit',
                minute: '2-digit'
              }),
              endTime: openingHour.endTime.toLocaleTimeString(getLanguage(), {hour: '2-digit', minute: '2-digit'}),
              appointmentsPerSlot: openingHour.appointmentsPerSlot
            }))) || []
        }}
        mutators={{
          ...arrayMutators
        }}
        render={({handleSubmit, invalid}) => {
          submitFn = handleSubmit;

          return (
            <Modal id="modal-facility-opening-day" bsSize="lg" show={show} backdrop={true}
                   className="info-modal text-center">
              <Modal.Header>
                <Light icon="exclamation-circle"/>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xs={12}>
                    <FormattedMessage
                      id={`modal.${openingDay && openingDay.id ? 'update' : 'create'}-facility-opening-day.title`}
                      tagName="h4"/>

                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <input name="id" type="hidden"/>
                  </Col>
                </Row>

                {isSpecial && (
                  <Row>
                    <Col xs={12}>
                      <FormGroup controlId="date">
                        <ControlLabel>
                          <FormattedMessage id="label.date"/>
                        </ControlLabel>
                        <Field name="date" component={FormControl} validate={compose(required, date)}/>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xs={12}>
                    <FormGroup controlId="closed">
                      <Field name="closed" component={Checkbox} type="checkbox">
                        <FormattedMessage id="label.closed"/>
                      </Field>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="actions">
                  <Col xs={12}>
                    <Button bsStyle="primary" bsSize="small" className="pull-right" onClick={() => pushFn({})}>
                      <Light icon="plus-square"/>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Table responsive hover bordered striped condensed>
                      <thead>
                      <tr>
                        <th><FormattedMessage id="opening-hour.start-time"/></th>
                        <th><FormattedMessage id="opening-hour.end-time"/></th>
                        <th><FormattedMessage id="opening-hour.appointments"/></th>
                        <th></th>
                      </tr>
                      </thead>

                      <FieldArray name="openingHours">
                        {({fields}) => {
                          pushFn = fields.push;

                          return (
                            <tbody>
                            {fields.map((name, index) => {

                              return (
                                <tr key={name}>
                                  <td>
                                    <Field name={`${name}.startTime`} component="input"/>
                                  </td>
                                  <td>
                                    <Field name={`${name}.endTime`} component="input"/>
                                  </td>
                                  <td>
                                    <Field name={`${name}.appointmentsPerSlot`} component="input"/>
                                  </td>

                                  <td className="table-actions">
                                    <Button bsStyle="danger" bsSize="small" onClick={() => fields.remove(index)}>
                                      <Light icon="trash-alt"/>
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                            </tbody>
                          );
                        }}
                      </FieldArray>
                    </Table>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={onCancel}>
                  <FormattedMessage id="common.button.cancel"/>
                </Button>
                <Button bsStyle="primary" onClick={submitFn} disabled={invalid}>
                  <FormattedMessage id="common.button.confirm"/>
                </Button>
              </Modal.Footer>
            </Modal>
          );
        }}/>
    );
  }
}

export default FacilityOpeningDayModal;
