import React from "react";
import {FormattedMessage} from "react-intl";
import Allergen from "./Allergen";

/**
 * The {@code AllergenList} class represents a list of allergens.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class AllergenList extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        const {allergens} = this.props;

        if (!allergens || allergens.length === 0) {
            return (
                <ul className="allergen-list">
                </ul>
            );
        }

        return (
            <ul className="allergen-list">
                {allergens.map(allergen => (
                    <Allergen key={allergen}>
                        <FormattedMessage id={'allergen.type.' + allergen}/>
                    </Allergen>
                ))}
            </ul>
        );
    }
}

export default AllergenList;
