import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {ParagraphHeader} from "../../components/ParagraphHeader/index";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code PrivacyPolicy} class represents the privacy policy page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PrivacyPolicy extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div
                id="privacy-policy-page">
                <PageHeader>
                    <PageHeaderTitle>
                        <FormattedMessage id="footer.link.privacy-policy" tagName="h3"/>
                        <FormattedMessage id="privacy-policy.page.subtitle" tagName="p"/>
                    </PageHeaderTitle>
                </PageHeader>
                <section>
                    <Grid>
                        <Row>
                            <Col xs={12} md={10} mdOffset={1}>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.introduction.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.introduction.paragraph.one" tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.introduction.paragraph.two" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.application.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.application.paragraph.one" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.other.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.other.paragraph.one" tagName="p"
                                                  values={{
                                                      cookiePolicy:
                                                          <LocalizedLink id={URIKeys.COOKIE_POLICY}>
                                                              <FormattedMessage id="footer.link.cookie-policy"/>
                                                          </LocalizedLink>,
                                                      termsAndConditions:
                                                          <LocalizedLink id={URIKeys.TERMS_AND_CONDITIONS}>
                                                              <FormattedMessage id="footer.link.terms-and-conditions"/>
                                                          </LocalizedLink>
                                                  }}/>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.processing.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.processing.item.one.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.one.paragraph.one"
                                                  tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.two.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.two.paragraph.one"
                                                  tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.three.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.three.paragraph.one"
                                                  tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.four.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.four.paragraph.one"
                                                  tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.four.paragraph.two"
                                                  tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.five.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.five.paragraph.one"
                                                  tagName="p"
                                                  values={{
                                                      contactPage:
                                                          <LocalizedLink id={URIKeys.CONTACT}>
                                                              <FormattedMessage id="privacy-policy.link.contact-us"/>
                                                          </LocalizedLink>
                                                  }}/>
                                <FormattedMessage id="privacy-policy.section.processing.item.six.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.processing.item.six.paragraph.one"
                                                  tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.details.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.details.item.one.subtitle" tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.details.item.one.paragraph.one"
                                                  tagName="p"
                                                  values={{
                                                      contactPage:
                                                          <LocalizedLink id={URIKeys.CONTACT}>
                                                              <FormattedMessage id="privacy-policy.link.contact-us"/>
                                                          </LocalizedLink>
                                                  }}/>
                                <FormattedMessage id="privacy-policy.section.details.item.two.subtitle" tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.details.item.two.paragraph.one"
                                                  tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.security.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.security.paragraph.one" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.sharing.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.sharing.paragraph.one" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.validation.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.validation.paragraph.one" tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.one.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.one.paragraph.one"
                                                  tagName="p"
                                                  values={{
                                                      contactPage:
                                                          <LocalizedLink id={URIKeys.CONTACT}>
                                                              <FormattedMessage id="privacy-policy.link.contact-us"/>
                                                          </LocalizedLink>
                                                  }}/>
                                <FormattedMessage id="privacy-policy.section.validation.item.two.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.two.paragraph.one"
                                                  tagName="p"
                                                  values={{
                                                      contactPage:
                                                          <LocalizedLink id={URIKeys.CONTACT}>
                                                              <FormattedMessage id="privacy-policy.link.contact-us"/>
                                                          </LocalizedLink>
                                                  }}/>
                                <FormattedMessage id="privacy-policy.section.validation.item.three.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.three.paragraph.one"
                                                  tagName="p"
                                                  values={{
                                                      contactPage:
                                                          <LocalizedLink id={URIKeys.CONTACT}>
                                                              <FormattedMessage id="privacy-policy.link.contact-us"/>
                                                          </LocalizedLink>
                                                  }}/>
                                <FormattedMessage id="privacy-policy.section.validation.item.three.paragraph.two"
                                                  tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.four.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.four.paragraph.one"
                                                  tagName="p"
                                                  values={{
                                                      contactPage:
                                                          <LocalizedLink id={URIKeys.CONTACT}>
                                                              <FormattedMessage id="privacy-policy.link.contact-us"/>
                                                          </LocalizedLink>
                                                  }}/>
                                <FormattedMessage id="privacy-policy.section.validation.item.five.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.five.paragraph.one"
                                                  tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.six.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.six.paragraph.one"
                                                  tagName="p"
                                                  values={{
                                                      cookiePolicy:
                                                          <LocalizedLink id={URIKeys.COOKIE_POLICY}>
                                                              <FormattedMessage id="footer.link.cookie-policy"/>
                                                          </LocalizedLink>
                                                  }}/>
                                <FormattedMessage id="privacy-policy.section.validation.item.seven.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.seven.paragraph.one"
                                                  tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.eight.subtitle"
                                                  tagName="h6"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.eight.paragraph.one"
                                                  tagName="p"/>
                                <FormattedMessage id="privacy-policy.section.validation.item.eight.paragraph.two"
                                                  tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.duration.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.duration.paragraph.one" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.contact.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.contact.paragraph.one" tagName="p"/>
                                <FormattedHTMLMessage id="privacy-policy.section.contact.paragraph.two"
                                                      tagName="address"
                                />
                                <FormattedMessage id="privacy-policy.section.contact.paragraph.three" tagName="p"
                                                  values={{
                                                      email: <a
                                                          href="mailto:info&#64;bodyreset.be">info&#64;bodyreset.be</a>
                                                  }}/>
                                <ParagraphHeader>
                                    <FormattedMessage id="privacy-policy.section.changes.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="privacy-policy.section.changes.paragraph.one" tagName="p"/>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </div>
        );
    }
}

export default PrivacyPolicy;
