import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {ParagraphHeader} from "../../components/ParagraphHeader/index";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import {List, ListItem} from "../../components/List/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code CookiePolicy} class represents the legal page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class CookiePolicy extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div id="cookie-policy-page">
                <PageHeader>
                    <PageHeaderTitle>
                        <FormattedMessage id="footer.link.cookie-policy" tagName="h3"/>
                        <FormattedMessage id="cookie-policy.page.subtitle" tagName="p"/>
                    </PageHeaderTitle>
                </PageHeader>
                <section>
                    <Grid>
                        <Row>
                            <Col xs={12} md={10} mdOffset={1}>
                                <ParagraphHeader>
                                    <FormattedMessage id="cookie-policy.section.responsibility.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="cookie-policy.section.responsibility.paragraph.one" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="cookie-policy.section.application.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="cookie-policy.section.application.paragraph.one" tagName="p"
                                                  values={{
                                                      website: <a href="https://www.bodyreset.be">website</a>
                                                  }}/>
                                <ParagraphHeader>
                                    <FormattedMessage id="cookie-policy.section.definition.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="cookie-policy.section.definition.paragraph.one" tagName="p"/>
                                <FormattedMessage id="cookie-policy.section.definition.paragraph.two" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="cookie-policy.section.reason.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="cookie-policy.section.reason.paragraph.one" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="cookie-policy.section.usage.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="cookie-policy.section.usage.paragraph.one" tagName="p"/>
                                <List>
                                    <ListItem>
                                        <FormattedMessage id="cookie-policy.section.usage.item.one"/>
                                    </ListItem>
                                    <ListItem>
                                        <FormattedMessage id="cookie-policy.section.usage.item.two"/>
                                    </ListItem>
                                </List>
                                <FormattedMessage id="cookie-policy.section.usage.paragraph.two" tagName="p"/>
                                <ParagraphHeader>
                                    <FormattedMessage id="cookie-policy.section.duration.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="cookie-policy.section.duration.paragraph.one" tagName="p"/>
                                <List>
                                    <ListItem>
                                        <FormattedHTMLMessage id="cookie-policy.section.duration.item.one"/>
                                    </ListItem>
                                    <ListItem>
                                        <FormattedHTMLMessage id="cookie-policy.section.duration.item.two"/>
                                    </ListItem>
                                </List>
                                <ParagraphHeader>
                                    <FormattedMessage id="cookie-policy.section.manage.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="cookie-policy.section.manage.paragraph.one" tagName="p"
                                                  values={{
                                                      website: <a href="http://www.allaboutcookies.org" target="_blank"
                                                                  rel="noopener noreferrer">www.allaboutcookies.org</a>
                                                  }}/>
                                <ParagraphHeader>
                                    <FormattedMessage id="cookie-policy.section.other.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="cookie-policy.section.other.paragraph.one" tagName="p"
                                                  values={{
                                                      privacyPolicy:
                                                          <LocalizedLink id={URIKeys.PRIVACY_POLICY}>
                                                              <FormattedMessage id="footer.link.privacy-policy"/>
                                                          </LocalizedLink>
                                                  }}/>
                                <ParagraphHeader>
                                    <FormattedMessage id="cookie-policy.section.changes.title" tagName="h4"/>
                                </ParagraphHeader>
                                <FormattedMessage id="cookie-policy.section.changes.paragraph.one" tagName="p"/>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </div>
        );
    }
}

export default CookiePolicy;
