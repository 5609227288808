/**
 * The {@code Actions} constant class contains the different actions.
 *
 * @type {int}
 */
const Actions = {
  // Common
  DONE: 'DONE',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  RESET: 'RESET',

  // Appointments
  CREATE_APPOINTMENT: 'CREATE_APPOINTMENT',
  DELETE_APPOINTMENT: 'DELETE_APPOINTMENT',
  GET_APPOINTMENTS_USER: 'GET_APPOINTMENTS_USER',

  // Calendar
  GET_CALENDAR: 'GET_CALENDAR',
  GET_CALENDAR_SLOT: 'GET_CALENDAR_SLOT',

  // Cart
  GET_CART: 'GET_CART',
  GET_PAYMENT_INFORMATION: 'GET_PAYMENT_INFORMATION',

  // Categories
  GET_CATEGORIES: 'GET_CATEGORIES',

  // Emails
  POST_EMAIL: 'POST_EMAIL',
  POST_EMAIL_FAILED: 'POST_EMAIL_FAILED',
  POST_EMAIL_RESET: 'POST_EMAIL_RESET',
  POST_EMAIL_SUCCESS: 'POST_EMAIL_SUCCESS',

  // EMPLOYEE
  GET_EMPLOYEE: 'GET_EMPLOYEE',
  GET_EMPLOYEE_RECIPES: 'GET_EMPLOYEE_RECIPES',

  // Facilities
  SELECT_FACILITY: 'SELECT_FACILITY',
  GET_FACILITY_APPOINTMENTS: 'GET_FACILITY_APPOINTMENTS',

  // Points of Service
  GET_POINTS_OF_SERVICE: 'GET_POINTS_OF_SERVICE',
  GET_POINTS_OF_SERVICE_STORE_LOCATOR: 'GET_POINTS_OF_SERVICE_STORE_LOCATOR',
  GET_POINT_OF_SERVICE: 'GET_POINT_OF_SERVICE',

  // Recipes
  GET_RECIPE: 'GET_RECIPE',
  GET_RECIPES: 'GET_RECIPES',

  // Products
  GET_PRODUCT: 'GET_PRODUCT',
  GET_PRODUCTS: 'GET_PRODUCTS',

  // Security
  LOGIN: 'LOGIN',
  LOGIN_RESET: 'LOGIN_RESET',
  LOGIN_WRONG_CREDENTIALS: 'LOGIN_WRONG_CREDENTIALS',

  LOGOUT_RESET: 'LOGOUT_RESET',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_RESET: 'RESET_PASSWORD_RESET',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_RESET: 'CHANGE_PASSWORD_RESET',

  // Subscriptions
  POST_SUBSCRIPTION: 'POST_SUBSCRIPTION',
  POST_SUBSCRIPTION_FAILED: 'POST_SUBSCRIPTION_FAILED',
  POST_SUBSCRIPTION_RESET: 'POST_SUBSCRIPTION_RESET',
  POST_SUBSCRIPTION_SUCCESS: 'POST_SUBSCRIPTION_SUCCESS',

  // User
  GET_USERS: 'GET_USERS',
  GET_USER: 'GET_USER',
  GET_USER_POINT_OF_SERVICE: 'GET_USER_POINT_OF_SERVICE',
  GET_USER_GODPARENT: 'GET_USER_GODPARENT',
  GET_USER_APPOINTMENTS: 'GET_USER_APPOINTMENTS',
  GET_USER_BODY_COMPOSITION_MEASUREMENTS: 'GET_USER_BODY_COMPOSITION_MEASUREMENTS',
  GET_USER_GIRTH_MEASUREMENTS: 'GET_USER_GIRTH_MEASUREMENTS',
  GET_USER_MEAL_SCHEDULES: 'GET_USER_MEAL_SCHEDULES',
  GET_USER_MEAL_SCHEDULE: 'GET_USER_MEAL_SCHEDULE',

  // Intake Form
  GET_INTAKE_FORMS: 'GET_INTAKE_FORMS',
  GET_INTAKE_FORM: 'GET_INTAKE_FORM',
};

export default Actions;
