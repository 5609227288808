import React from "react";
import Track from "./Track";
import Step from "./Step";

/**
 * The {@code ProgressBar} class represents a progress bar.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ProgressBar extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div className="step-progress-bar">
        {this.props.children}
      </div>
    );
  }
}

ProgressBar.Track = Track;
ProgressBar.Step = Step;

export default ProgressBar;
