import React from "react";

/**
 * The {@code Street} class represents the street in a points of service address.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Street extends React.Component {

    /**
     * Renders the component.
     *
     * @returns JSX.Element HTML representation of the component
     */
    render() {
        return (
            <span className="point-of-service-address-street">
                {this.props.children}
            </span>
        );
    }
}

export default Street;
