/**
 * Checks whether the user is logged in.
 *
 * @returns {@code true} in case the user is logged in, otherwise {@code false}
 */
export function decode(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url
        .replace('-', '+')
        .replace('_', '/');
    return JSON.parse(window.atob(base64));
}
