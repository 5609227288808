import React from "react";
import {Button, Col, ControlLabel, Modal, Row, Table} from "react-bootstrap";
import Light from "../../Icons/Light";
import Wizard from "../../Wizard/Wizard";
import {Field, Form} from "react-final-form";
import {FormattedMessage, injectIntl} from "react-intl";
import {convertJSDateToDateInput, printInputDateInISODateTime} from "../../../utils/DateTimeUtility";
import FormControl from "../../Forms/final-form/FormControl/FormControl";
import FormGroup from "../../Forms/final-form/FormGroup/FormGroup";
import {FieldArray} from "react-final-form-arrays";
import {required} from "../../../utils/ValidationUtility";
import arrayMutators from "final-form-arrays";
import SearchableSelect from "../../Forms/final-form/SearchableSelect/SearchableSelect";
import Navigation from "../../Wizard/Navigation";
import Steps from "../../Wizard/Steps";
import NavigationNode from "../../Wizard/NavigationNode";
import RequiredAnnotation from "../../Forms/RequiredAnnotation";
import BackendURLConstants from "../../../constants/BackendURLConstants";
import {patch, post, remove} from "../../../utils/FetchUtility";
import {getUserMealSchedules} from "../../../actions";
import {deepCopy} from "../../../utils/DeepCopyUtiliy";
import {connect} from "react-redux";
import {getUsername} from "../../../utils/AuthenticationUtility";

/**
 * The {@code ManageMealScheduleModal} class represents the modal used to create or edit meal schedules.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ManageMealScheduleModal extends React.Component {

  /**
   * Creates a new instance of the {@link ManageMealScheduleModal} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);


    this.state = {
      initialValues: this.getInitialValues(props.mealSchedule)
    };
  }

  /**
   * Triggers when the component is updated.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.mealSchedule !== this.props.mealSchedule) {
      this.setState({
        initialValues: this.getInitialValues(this.props.mealSchedule)
      });
    }
  }

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}, show, recipes} = this.props;
    return (
      <Modal show={show} backdrop={true} bsSize="lg" className="info-modal text-center" id="manage-meal-schedule-modal">
        <Modal.Header>
          <Light icon="plus-square"/>
        </Modal.Header>
        <Modal.Body className="no-padding">
          <Form
            onSubmit={this.onSubmit}
            mutators={{
              ...arrayMutators
            }}
            initialValues={this.state.initialValues}
            render={({handleSubmit, pristine, invalid, form}) => (
              <form onSubmit={handleSubmit} id="meal-schedule-form">
                <Wizard activeStep={1} toggleCancel={() => this.onCancel(form.reset)} pristine={pristine} invalid={invalid}>
                  <Navigation>
                    <NavigationNode step={1}>
                      <FormattedMessage id="documents.page.section.common.title" tagName="h5"/>
                    </NavigationNode>
                    <NavigationNode step={2}>
                      <FormattedMessage id="date.day.0" tagName="h5"/>
                    </NavigationNode>
                    <NavigationNode step={3}>
                      <FormattedMessage id="date.day.1" tagName="h5"/>
                    </NavigationNode>
                    <NavigationNode step={4}>
                      <FormattedMessage id="date.day.2" tagName="h5"/>
                    </NavigationNode>
                    <NavigationNode step={5}>
                      <FormattedMessage id="date.day.3" tagName="h5"/>
                    </NavigationNode>
                    <NavigationNode step={6}>
                      <FormattedMessage id="date.day.4" tagName="h5"/>
                    </NavigationNode>
                    <NavigationNode step={7}>
                      <FormattedMessage id="date.day.5" tagName="h5"/>
                    </NavigationNode>
                    <NavigationNode step={8}>
                      <FormattedMessage id="date.day.6" tagName="h5"/>
                    </NavigationNode>
                  </Navigation>
                  <Steps>
                    <Wizard.Step step={1} first>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="label.common-information" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={6}>
                          <FormGroup controlId="date">
                            <ControlLabel>
                              <FormattedMessage id="label.date"/>
                            </ControlLabel>
                            <Field
                              name="date"
                              component={FormControl}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <FormGroup controlId="user">
                            <ControlLabel>
                              <FormattedMessage id="label.user"/>
                            </ControlLabel>
                            <Field
                              name="user"
                              component={FormControl}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="comment">
                            <ControlLabel>
                              <FormattedMessage id="label.comment"/>
                            </ControlLabel>
                            <Field
                              name="comment"
                              component={FormControl}
                              componentClass="textarea"
                              rows={8}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="feedback">
                            <ControlLabel>
                              <FormattedMessage id="label.feedback"/>
                            </ControlLabel>
                            <Field
                              name="feedback"
                              component={FormControl}
                              componentClass="textarea"
                              rows={8}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Wizard.Step>
                    <Wizard.Step step={2}>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="date.day.0" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="days[0].comment">
                            <ControlLabel>
                              <FormattedMessage id="label.comment"/>
                            </ControlLabel>
                            <Field
                              name="days[0].comment"
                              component={FormControl}
                              componentClass="textarea"
                              rows={8}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FieldArray name="days[0].slots">
                            {({fields}) => (
                              <Table striped bordered condensed hover responsive>
                                <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="label.recipe"/>
                                    <RequiredAnnotation/>
                                  </th>
                                  <th>
                                    <FormattedMessage id="label.comment"/>
                                  </th>
                                  <th>
                                    <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                                            onClick={() => fields.push('')}>
                                      <Light icon="plus-square"/>
                                    </Button>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((slot, index) => (
                                  <tr key={slot} className="slot">
                                    <td className="recipe">
                                      <Field name={`${slot}.recipe`}
                                             component={SearchableSelect}
                                             options={recipes && recipes.map(recipe => ({
                                               value: recipe.id,
                                               label: '[' + formatMessage({id: 'recipe.type.' + recipe.meal}) + '] ' + recipe.name
                                             }))}
                                             value={slot.recipe ? slot.recipe : undefined}
                                             placeholder={formatMessage({id: 'form.placeholder.select'})}
                                             validate={required}/>
                                    </td>
                                    <td className="comment">
                                      <FormGroup controlId={`${slot}.comment`}>
                                        <Field
                                          name={`${slot}.comment`}
                                          component={FormControl}
                                          componentClass="textarea"
                                          rows={3}
                                        />
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <Button className="pull-right" bsStyle="danger" bsSize="small"
                                              onClick={() => fields.remove(index)}>
                                        <Light icon="trash-alt"/>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </Table>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                    </Wizard.Step>
                    <Wizard.Step step={3}>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="date.day.1" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="days[1].comment">
                            <ControlLabel>
                              <FormattedMessage id="label.comment"/>
                            </ControlLabel>
                            <Field
                              name="days[1].comment"
                              component={FormControl}
                              componentClass="textarea"
                              rows={8}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FieldArray name="days[1].slots">
                            {({fields}) => (
                              <Table striped bordered condensed hover responsive>
                                <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="label.recipe"/>
                                    <RequiredAnnotation/>
                                  </th>
                                  <th>
                                    <FormattedMessage id="label.comment"/>
                                  </th>
                                  <th>
                                    <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                                            onClick={() => fields.push('')}>
                                      <Light icon="plus-square"/>
                                    </Button>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((slot, index) => (
                                  <tr key={slot} className="slot">
                                    <td className="recipe">
                                      <Field name={`${slot}.recipe`}
                                             component={SearchableSelect}
                                             options={recipes && recipes.map(recipe => ({
                                               value: recipe.id,
                                               label: '[' + formatMessage({id: 'recipe.type.' + recipe.meal}) + '] ' + recipe.name
                                             }))}
                                             placeholder={formatMessage({id: 'form.placeholder.select'})}
                                             size={5}
                                             validate={required}/>
                                    </td>
                                    <td className="comment">
                                      <FormGroup controlId={`${slot}.comment`}>
                                        <Field
                                          name={`${slot}.comment`}
                                          component={FormControl}
                                          componentClass="textarea"
                                          rows={3}
                                        />
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <Button className="pull-right" bsStyle="danger" bsSize="small"
                                              onClick={() => fields.remove(index)}>
                                        <Light icon="trash-alt"/>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </Table>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                    </Wizard.Step>
                    <Wizard.Step step={4}>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="date.day.2" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="days[2].comment">
                            <ControlLabel>
                              <FormattedMessage id="label.comment"/>
                            </ControlLabel>
                            <Field
                              name="days[2].comment"
                              component={FormControl}
                              componentClass="textarea"
                              rows={8}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FieldArray name="days[2].slots">
                            {({fields}) => (
                              <Table striped bordered condensed hover responsive>
                                <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="label.recipe"/>
                                    <RequiredAnnotation/>
                                  </th>
                                  <th>
                                    <FormattedMessage id="label.comment"/>
                                  </th>
                                  <th>
                                    <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                                            onClick={() => fields.push('')}>
                                      <Light icon="plus-square"/>
                                    </Button>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((slot, index) => (
                                  <tr key={slot} className="slot">
                                    <td className="recipe">
                                      <Field name={`${slot}.recipe`}
                                             component={SearchableSelect}
                                             options={recipes && recipes.map(recipe => ({
                                               value: recipe.id,
                                               label: '[' + formatMessage({id: 'recipe.type.' + recipe.meal}) + '] ' + recipe.name
                                             }))}
                                             placeholder={formatMessage({id: 'form.placeholder.select'})}
                                             size={5}
                                             validate={required}/>
                                    </td>
                                    <td className="comment">
                                      <FormGroup controlId={`${slot}.comment`}>
                                        <Field
                                          name={`${slot}.comment`}
                                          component={FormControl}
                                          componentClass="textarea"
                                          rows={3}
                                        />
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <Button className="pull-right" bsStyle="danger" bsSize="small"
                                              onClick={() => fields.remove(index)}>
                                        <Light icon="trash-alt"/>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </Table>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                    </Wizard.Step>
                    <Wizard.Step step={5}>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="date.day.3" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="days[3].comment">
                            <ControlLabel>
                              <FormattedMessage id="label.comment"/>
                            </ControlLabel>
                            <Field
                              name="days[3].comment"
                              component={FormControl}
                              componentClass="textarea"
                              rows={8}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FieldArray name="days[3].slots">
                            {({fields}) => (
                              <Table striped bordered condensed hover responsive>
                                <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="label.recipe"/>
                                    <RequiredAnnotation/>
                                  </th>
                                  <th>
                                    <FormattedMessage id="label.comment"/>
                                  </th>
                                  <th>
                                    <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                                            onClick={() => fields.push('')}>
                                      <Light icon="plus-square"/>
                                    </Button>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((slot, index) => (
                                  <tr key={slot} className="slot">
                                    <td className="recipe">
                                      <Field name={`${slot}.recipe`}
                                             component={SearchableSelect}
                                             options={recipes && recipes.map(recipe => ({
                                               value: recipe.id,
                                               label: '[' + formatMessage({id: 'recipe.type.' + recipe.meal}) + '] ' + recipe.name
                                             }))}
                                             placeholder={formatMessage({id: 'form.placeholder.select'})}
                                             size={5}
                                             validate={required}/>
                                    </td>
                                    <td className="comment">
                                      <FormGroup controlId={`${slot}.comment`}>
                                        <Field
                                          name={`${slot}.comment`}
                                          component={FormControl}
                                          componentClass="textarea"
                                          rows={3}
                                        />
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <Button className="pull-right" bsStyle="danger" bsSize="small"
                                              onClick={() => fields.remove(index)}>
                                        <Light icon="trash-alt"/>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </Table>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                    </Wizard.Step>
                    <Wizard.Step step={6}>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="date.day.4" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="days[4].comment">
                            <ControlLabel>
                              <FormattedMessage id="label.comment"/>
                            </ControlLabel>
                            <Field
                              name="days[4].comment"
                              component={FormControl}
                              componentClass="textarea"
                              rows={8}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FieldArray name="days[4].slots">
                            {({fields}) => (
                              <Table striped bordered condensed hover responsive>
                                <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="label.recipe"/>
                                    <RequiredAnnotation/>
                                  </th>
                                  <th>
                                    <FormattedMessage id="label.comment"/>
                                  </th>
                                  <th>
                                    <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                                            onClick={() => fields.push('')}>
                                      <Light icon="plus-square"/>
                                    </Button>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((slot, index) => (
                                  <tr key={slot} className="slot">
                                    <td className="recipe">
                                      <Field name={`${slot}.recipe`}
                                             component={SearchableSelect}
                                             options={recipes && recipes.map(recipe => ({
                                               value: recipe.id,
                                               label: '[' + formatMessage({id: 'recipe.type.' + recipe.meal}) + '] ' + recipe.name
                                             }))}
                                             placeholder={formatMessage({id: 'form.placeholder.select'})}
                                             size={5}
                                             validate={required}/>
                                    </td>
                                    <td className="comment">
                                      <FormGroup controlId={`${slot}.comment`}>
                                        <Field
                                          name={`${slot}.comment`}
                                          component={FormControl}
                                          componentClass="textarea"
                                          rows={3}
                                        />
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <Button className="pull-right" bsStyle="danger" bsSize="small"
                                              onClick={() => fields.remove(index)}>
                                        <Light icon="trash-alt"/>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </Table>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                    </Wizard.Step>
                    <Wizard.Step step={7}>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="date.day.5" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="days[5].comment">
                            <ControlLabel>
                              <FormattedMessage id="label.comment"/>
                            </ControlLabel>
                            <Field
                              name="days[5].comment"
                              component={FormControl}
                              componentClass="textarea"
                              rows={8}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FieldArray name="days[5].slots">
                            {({fields}) => (
                              <Table striped bordered condensed hover responsive>
                                <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="label.recipe"/>
                                    <RequiredAnnotation/>
                                  </th>
                                  <th>
                                    <FormattedMessage id="label.comment"/>
                                  </th>
                                  <th>
                                    <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                                            onClick={() => fields.push('')}>
                                      <Light icon="plus-square"/>
                                    </Button>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((slot, index) => (
                                  <tr key={slot} className="slot">
                                    <td className="recipe">
                                      <Field name={`${slot}.recipe`}
                                             component={SearchableSelect}
                                             options={recipes && recipes.map(recipe => ({
                                               value: recipe.id,
                                               label: '[' + formatMessage({id: 'recipe.type.' + recipe.meal}) + '] ' + recipe.name
                                             }))}
                                             placeholder={formatMessage({id: 'form.placeholder.select'})}
                                             size={5}
                                             validate={required}/>
                                    </td>
                                    <td className="comment">
                                      <FormGroup controlId={`${slot}.comment`}>
                                        <Field
                                          name={`${slot}.comment`}
                                          component={FormControl}
                                          componentClass="textarea"
                                          rows={3}
                                        />
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <Button className="pull-right" bsStyle="danger" bsSize="small"
                                              onClick={() => fields.remove(index)}>
                                        <Light icon="trash-alt"/>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </Table>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                    </Wizard.Step>
                    <Wizard.Step step={8} last>
                      <Row>
                        <Col xs={12}>
                          <FormattedMessage id="date.day.6" tagName="h4"/>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup controlId="days[6].comment">
                            <ControlLabel>
                              <FormattedMessage id="label.comment"/>
                            </ControlLabel>
                            <Field
                              name="days[6].comment"
                              component={FormControl}
                              componentClass="textarea"
                              rows={8}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FieldArray name="days[6].slots">
                            {({fields}) => (
                              <Table striped bordered condensed hover responsive>
                                <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="label.recipe"/>
                                    <RequiredAnnotation/>
                                  </th>
                                  <th>
                                    <FormattedMessage id="label.comment"/>
                                  </th>
                                  <th>
                                    <Button className="btn-create pull-right" bsStyle="primary" bsSize="small"
                                            onClick={() => fields.push('')}>
                                      <Light icon="plus-square"/>
                                    </Button>
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                {fields.map((slot, index) => (
                                  <tr key={slot} className="slot">
                                    <td className="recipe">
                                      <Field name={`${slot}.recipe`}
                                             component={SearchableSelect}
                                             options={recipes && recipes.map(recipe => ({
                                               value: recipe.id,
                                               label: '[' + formatMessage({id: 'recipe.type.' + recipe.meal}) + '] ' + recipe.name
                                             }))}
                                             placeholder={formatMessage({id: 'form.placeholder.select'})}
                                             size={5}
                                             validate={required}/>
                                    </td>
                                    <td className="comment">
                                      <FormGroup controlId={`${slot}.comment`}>
                                        <Field
                                          name={`${slot}.comment`}
                                          component={FormControl}
                                          componentClass="textarea"
                                          rows={3}
                                        />
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <Button className="pull-right" bsStyle="danger" bsSize="small"
                                              onClick={() => fields.remove(index)}>
                                        <Light icon="trash-alt"/>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </Table>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                    </Wizard.Step>
                  </Steps>
                </Wizard>
              </form>
            )}/>
        </Modal.Body>
      </Modal>
    );
  }

  /**
   * Closes the modal.
   */
  onCancel = (resetFn) => {
    resetFn();
    this.props.toggleManageMealScheduleModal();
  };

  /**
   * Handles the form on submit.
   */
  onSubmit = async (values) => {
    const {dispatch, mealSchedule} = this.props;

    if (mealSchedule) {
      await this.updateMealSchedule(values, mealSchedule);
    } else {
      await this.createMealSchedule(values, this.props.user.username);
    }

    await dispatch(getUserMealSchedules(this.props.user.username, 10, 0));

    this.props.toggleManageMealScheduleModal();
  };

  /**
   * Creates the meal schedule for the given {@code values}.
   *
   * @param values the values
   * @param userID the ID of the user
   * @returns the meal schedule
   */
  async createMealSchedule(values, userID) {
    let days = deepCopy(values.days);

    let mealSchedule = {
      ...values,
      days: undefined,
      date: printInputDateInISODateTime(values.date),
      user: BackendURLConstants.USER.replace('$id', userID),
      employee: BackendURLConstants.EMPLOYEE.replace('$id', values.employee)
    };

    mealSchedule = await post(BackendURLConstants.MEAL_SCHEDULES, JSON.stringify(mealSchedule), 'application/json', true);
    await Promise.all(days.map(async day => await this.createOrUpdateDay(day, mealSchedule)));

    return mealSchedule;
  }

  /**
   * Updates the meal schedule for the given {@code values}.
   *
   * @param values the values
   * @param mealSchedule the meal schedule
   */
  async updateMealSchedule(values, mealSchedule) {
    let days = deepCopy(values.days);

    let data = {
      ...values,
      days: undefined,
      date: printInputDateInISODateTime(values.date),
      user: BackendURLConstants.USER.replace('$id', mealSchedule.user.username),
      employee: BackendURLConstants.EMPLOYEE.replace('$id', mealSchedule.employee.username)
    };

    if (!data.comment) {
      data.comment = '';
    }

    await patch(BackendURLConstants.MEAL_SCHEDULE.replace('$id', mealSchedule.id), JSON.stringify(data), 'application/json', true);
    await Promise.all(days.map(async day => await this.createOrUpdateDay(day, mealSchedule)));
  }

  /**
   * Creates or updates the meal schedule day for the given {@code mealSchedule}.
   *
   * @param day the meal schedule day
   * @param mealSchedule the meal schedule
   * @returns the meal schedule day
   */
  async createOrUpdateDay(day, mealSchedule) {
    let slots = deepCopy(day.slots);

    day.slots = undefined;
    day.mealSchedule = BackendURLConstants.MEAL_SCHEDULE.replace('$id', mealSchedule.id);

    if (!day.comment) {
      day.comment = '';
    }

    if (day.id) {
      await patch(BackendURLConstants.MEAL_SCHEDULE_DAY.replace('$id', day.id), JSON.stringify(day), 'application/json', true);
    } else {
      day = await post(BackendURLConstants.MEAL_SCHEDULE_DAYS, JSON.stringify(day), 'application/json', true);
    }

    this.addSortOrderToSlots(slots);

    if (mealSchedule.days) {
      await this.removeObsoleteSlots(mealSchedule.days[day.order].slots, slots);
    }

    await Promise.all(slots.map(async slot => await this.createOrUpdateSlot(slot, day)));

    return day;
  }

  /**
   * Adds a sort order to the given {@code slots}.
   *
   * @param slots the slots
   */
  addSortOrderToSlots(slots) {
    for (let i = 0; i < slots.length; i++) {
      slots[i].order = i;
    }
  }

  /**
   * Removes the obsolete slots.
   *
   * @param existingSlots the list of existing slots
   * @param slots the new list of slots
   */
  async removeObsoleteSlots(existingSlots, slots) {
    for (const existingSlot of existingSlots) {
      if (this.isObsolete(existingSlot, slots)) {
        await remove(BackendURLConstants.MEAL_SCHEDULE_SLOT.replace('$id', existingSlot.id), true);
      }
    }
  }

  /**
   * Checks whether the given {@code existingSlot} is obsolete.
   *
   * @param existingSlot the existing slot
   * @param slots the new slots
   * @returns {boolean} {@code true} in case the existing slot is obsolete, {@code false} otherwise
   */
  isObsolete(existingSlot, slots) {
    let flag = true;
    for (const slot of slots) {
      if (slot.id && existingSlot.id === slot.id) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  /**
   * Creates or updates the meal schedule slot for the given {@code day}.
   *
   * @param slot the meal schedule slot
   * @param day the meal schedule day
   */
  async createOrUpdateSlot(slot, day) {
    slot.day = BackendURLConstants.MEAL_SCHEDULE_DAY.replace('$id', day.id);
    slot.recipe = BackendURLConstants.RECIPE.replace('$id', slot.recipe.value);

    if (!slot.comment) {
      slot.comment = '';
    }

    if (slot.id) {
      await patch(BackendURLConstants.MEAL_SCHEDULE_SLOT.replace('$id', slot.id), JSON.stringify(slot), 'application/json', true);
    } else {
      await post(BackendURLConstants.MEAL_SCHEDULE_SLOTS, JSON.stringify(slot), 'application/json', true);
    }
  }

  /**
   * Returns the initial values for the form.
   * <p>
   * If the given {@code mealSchedule} is not null, it's values will be used. Otherwise an empty form is created.
   *
   * @param mealSchedule
   * @returns {{}} the initial values
   */
  getInitialValues = (mealSchedule) => {
    const {intl: {formatMessage}} = this.props;

    let initialValues;
    if (mealSchedule) {
      // Fix the recipe to work with react-select
      for (const day of mealSchedule.days) {
        for (const slot of day.slots) {
          if (slot.recipe.id && slot.recipe.meal && slot.recipe.name) {
            slot.recipe = {
              value: slot.recipe.id,
              label: '[' + formatMessage({id: 'recipe.type.' + slot.recipe.meal}) + '] ' + slot.recipe.name
            };
          }
        }
      }

      initialValues = {
        date: convertJSDateToDateInput(mealSchedule.date),
        user: mealSchedule.user.firstName + ' ' + mealSchedule.user.lastName,
        employee: mealSchedule.employee.username,
        comment: mealSchedule.comment,
        days: mealSchedule.days
      }
    } else {
      initialValues = {
        date: convertJSDateToDateInput(new Date()),
        user: this.props.user.firstName + ' ' + this.props.user.lastName,
        employee: getUsername(),
        comment: undefined,
        days: [
          {
            order: 0,
            comment: undefined,
            slots: [
              {
                recipe: undefined,
                comment: undefined
              }
            ]
          },
          {
            order: 1,
            comment: undefined,
            slots: [
              {
                recipe: undefined,
                comment: undefined
              }
            ]
          },
          {
            order: 2,
            comment: undefined,
            slots: [
              {
                recipe: undefined,
                comment: undefined
              }
            ]
          },
          {
            order: 3,
            comment: undefined,
            slots: [
              {
                recipe: undefined,
                comment: undefined
              }
            ]
          },
          {
            order: 4,
            comment: undefined,
            slots: [
              {
                recipe: undefined,
                comment: undefined
              }
            ]
          },
          {
            order: 5,
            comment: undefined,
            slots: [
              {
                recipe: undefined,
                comment: undefined
              }
            ]
          },
          {
            order: 6,
            comment: undefined,
            slots: [
              {
                recipe: undefined,
                comment: undefined
              }
            ]
          },
        ]
      }
    }
    return initialValues;
  };

}

export default connect()(injectIntl(ManageMealScheduleModal));
