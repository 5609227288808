import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {BackLink, PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import {Image, ParallaxImage} from "../../components/Image/index";
import {Light} from "../../components/Icons/index";
import URIKeys from "../../constants/URIKeys";
import {List, ListItem} from "../../components/List";

/**
 * The {@code Companies} class represents the companies service page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Companies extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div id="companies-page">
        <ParallaxImage lowRes={require('../../images/pages/companies/page-header.svg')}
                       highRes={require('../../images/pages/companies/page-header.jpg')}/>
        <PageHeader>
          <BackLink>
            <LocalizedLink id={URIKeys.SERVICES}>
              <Light icon="chevron-left"/>&#160;
              <FormattedMessage id="nav-bar.item.services"/>
            </LocalizedLink>
          </BackLink>
          <PageHeaderTitle>
            <FormattedMessage id="services.section.companies.title" tagName="h3"/>
            <FormattedMessage id="companies.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section>
          <Grid>
            <Row>
              <Col xsHidden sm={4}>
                <Image lowRes={require('../../images/pages/companies/image-1.svg')}
                       highRes={require('../../images/pages/companies/image-1.png')}
                       classNames="img-responsive"/>
                <Image lowRes={require('../../images/pages/companies/image-2.svg')}
                       highRes={require('../../images/pages/companies/image-2.png')}
                       classNames="img-responsive"/>
              </Col>
              <Col xs={12} sm={8}>
                <FormattedMessage id="companies.paragraph.one" tagName="p"/>
                <FormattedMessage id="companies.paragraph.two" tagName="p"/>
                <List>
                  <ListItem><FormattedMessage id="companies.li.benefit.one"/></ListItem>
                  <ListItem><FormattedMessage id="companies.li.benefit.two"/></ListItem>
                  <ListItem><FormattedMessage id="companies.li.benefit.three"/></ListItem>
                  <ListItem><FormattedMessage id="companies.li.benefit.four"/></ListItem>
                </List>
                <FormattedMessage id="companies.paragraph.three" tagName="p"/>
                <List>
                  <ListItem><b><FormattedMessage id="companies.li.excuse.one.q"/></b><br/><FormattedMessage
                    id="companies.li.excuse.one.a"/></ListItem>
                  <ListItem><b><FormattedMessage id="companies.li.excuse.two.q"/></b><br/><FormattedMessage
                    id="companies.li.excuse.two.a"/></ListItem>
                  <ListItem><b><FormattedMessage id="companies.li.excuse.three.q"/></b><br/><FormattedMessage
                    id="companies.li.excuse.three.a"/></ListItem>
                  <ListItem><b><FormattedMessage id="companies.li.excuse.four.q"/></b><br/><FormattedMessage
                    id="companies.li.excuse.four.a"/></ListItem>
                  <ListItem><b><FormattedMessage id="companies.li.excuse.five.q"/></b><br/><FormattedMessage
                    id="companies.li.excuse.five.a"/></ListItem>
                </List>
                <LocalizedLink id={URIKeys.SERVICES} className="btn btn-sm btn-info">
                  <Light icon="chevron-left"/>&#160;
                  <FormattedMessage id="common.button.back"/>
                </LocalizedLink>
                <LocalizedLink id={URIKeys.CONTACT} className="btn btn-sm btn-success">
                  <FormattedMessage id="companies.button.one"/>
                </LocalizedLink>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

export default Companies;
