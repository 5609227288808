import React from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Col, Grid, Row} from "react-bootstrap";
import {getUsername} from "../../../utils/AuthenticationUtility";
import DashboardHeader from "../../../partials/DashboardHeader";
import Loading from "../../../components/Modals/Loading";
import {getAppointmentsUser, getUser} from "../../../actions";
import URIKeys from "../../../constants/URIKeys";
import {AppointmentTable, Calendar} from "../../../components/Appointments";
import FacilitySelectForm from "../../../components/Forms/FacilitySelectForm";
import InactiveUserPage from "../../../components/InactiveUserPage/InactiveUserPage";
import {FACILITIES} from "../../../constants/Facilities";
import MealScheduleRequest from "../../../components/MealScheduleRequest/MealScheduleRequest";
import {createIntakeForm} from "../../../actions/IntakeForms";
import {NUTRITIONIST_CONSULTANCY_STATUS} from "../../../constants/NutritionistConsultancyStatus";
import {getCalendar} from "../../../actions/Calendar";
import {isMobile} from "../../../utils/DeviceUtility";


/**
 * The {@code Appointments} class represents the dashboard appointments page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Appointments extends React.Component {

  /**
   * Gets the user. 
   */
  componentDidMount() {
    const {dispatch} = this.props;

    let username = getUsername();

    dispatch(getUser(username));
    dispatch(getAppointmentsUser(username));
  }

  /**
   * Renders the calendar.
   */
  renderCalendar = () => {
    const {dispatch, selectedFacility, userObject} = this.props;
    let result;

    if (!userObject || selectedFacility === 'initial') {
      result = <table className="calendar hidden"></table>;
    } else {
      let facility = null;
      userObject.pointOfService.facilities.forEach(function (fac) {
        if (facility === null && selectedFacility === fac.id.toString()) {
          facility = fac;
        }
      });

      let numberOfDays = isMobile() ? 1 : 7;
      let date = new Date();
      if (numberOfDays > 1) {
        date = new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)));
      }
      dispatch(getCalendar(facility.id, date, numberOfDays));


      if (this.isIntakeRequired(facility.type) && !userObject.intakeCompleted) {
        result = (
          result = <FormattedMessage id="label.intake-not-completed" tagName="p"/>
        )
      } else {
        result = <Calendar facility={facility} user={userObject}/>
      }
    }

    return result;
  };

  isIntakeRequired(facilityType) {
    return 'PERSONAL_TRAINING_CENTRE' === facilityType
      || 'PERSONAL_TRAINING_ON_SITE' === facilityType
      || 'SPINNING' === facilityType || 'E_FIT' === facilityType
  }

  renderNutritionistPracticefacility = () => {
    const {selectedFacility, userObject} = this.props;

    if (userObject.nutritionistConsultancyStatus === NUTRITIONIST_CONSULTANCY_STATUS.INITIAL_APPOINTMENT_OPEN || userObject.nutritionistConsultancyStatus === NUTRITIONIST_CONSULTANCY_STATUS.FOLLOWUP_APPOINTMENT_OPEN) {
      return this.renderCalendar();
    } else {
      let facility = null;
      userObject.pointOfService.facilities.forEach(function (fac) {
        if (facility === null && selectedFacility === fac.id.toString()) {
          facility = fac;
        }
      });

      return (
        <MealScheduleRequest user={userObject} facility={facility} onSubmit={this.onSubmitNutritionScheduleIntakeForm}/>
      );
    }
  };

  onSubmitNutritionScheduleIntakeForm = async (values) => {
    const {dispatch, userObject, selectedFacility} = this.props;

    await dispatch(createIntakeForm(
      userObject['_links'].self.href,
      selectedFacility,
      values.entries,
    ));

    let username = getUsername();
    dispatch(getUser(username));
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, userObject, userAppointmentCollection, selectedFacility} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !userObject || !userAppointmentCollection) {
      return (
        <div id="dashboard-performance-page" className="dashboard-page">
          <DashboardHeader/>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    if (!userObject.active) {
      return (
        <div id="dashboard-performance-page" className="dashboard-page">
          <DashboardHeader/>
          <InactiveUserPage/>
        </div>
      );
    }

    const selectedFacilityObj = selectedFacility && userObject.pointOfService.facilities.find(facility => facility.id == selectedFacility);

    return (
      <div id="dashboard-appointments-page" className="dashboard-page">
        <DashboardHeader/>
        <Grid>
          <section className="upcoming-appointments">
            <Row>
              <Col xs={12}>
                <FormattedMessage id="appointments.page.section.upcoming-appointments.title"
                                  tagName="h5"/>
                <AppointmentTable appointments={userAppointmentCollection.appointments}/>
              </Col>
            </Row>
          </section>
          <section className="create-appointment">
            <Row>
              <Col xs={12}>
                <FormattedMessage id="appointments.page.section.create-appointment.title" tagName="h5"/>
              </Col>
            </Row>
            <FacilitySelectForm facilities={userObject.pointOfService.facilities} user={userObject}/>

            {selectedFacilityObj && selectedFacilityObj.type === FACILITIES.NUTRITIONIST_PRACTICE ?
              this.renderNutritionistPracticefacility() : this.renderCalendar()}
          </section>
        </Grid>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getUser, appointmentsUser, selectFacility} = state;
  return {
    error: common.error,
    loading: common.loading,
    userObject: getUser.userObject,
    userAppointmentCollection: appointmentsUser.userAppointmentCollection,
    selectedFacility: selectFacility.selectedFacility
  };
}

export default connect(mapStateToProps)(injectIntl(Appointments));
