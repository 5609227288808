import React from "react";

/**
 * The {@code ButtonWrapper} class represents the button wrapper for the video background component.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ButtonWrapper extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div className="button-wrapper">{this.props.children}</div>
        );
    }
}

export default ButtonWrapper;
