import React from "react";
import {withRouter} from "react-router-dom";

/**
 * The {@code ScrollToTop} class make sure the page is scroll to the top when the route changes.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ScrollToTop extends React.Component {

    /**
     * Scrolls to the top of the page.
     *
     * @param prevProps the previous properties
     */
    componentDidUpdate(prevProps) {
        if (this.props.location.hash === "" && this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);
