import React from "react";
import {Col, Row} from "react-bootstrap";

/**
 * The {@code Title} class represents a page header title.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Title extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <Row>
                <Col xs={12}>
                    <div className="page-header-title">{this.props.children}</div>
                </Col>
            </Row>
        );
    }
}

export default Title;
