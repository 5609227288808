import React from "react";
import {Modal, ProgressBar} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

/**
 * The {@code Loading} class represents the content of the loading modal component.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Loading extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <Modal show={true} bsSize="sm" backdrop={true} className="loading-modal">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="modal.loading.title"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProgressBar active now={100}/>
                </Modal.Body>
            </Modal>
        );
    }
}

export default Loading;
