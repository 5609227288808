import React from "react";
import {connect} from "react-redux";
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from "react-intl";
import {Button, ButtonGroup, Col, Row, Table} from "react-bootstrap";
import {InsufficientCreditsModal, Loading} from "../Modals";
import {createAppointment} from "../../actions"
import {
  convertISODateTimeToJSDate,
  convertISODateToJSDate,
  convertJSDateToDateInput,
  convertJSDateToISODateTime
} from "../../utils/DateTimeUtility";
import SlotStatus from "../../constants/SlotStatus";
import BackendURLConstants from "../../constants/BackendURLConstants";
import {Light} from "../Icons";
import AddEntityModal from "../Modals/AddEntityModal/AddEntityModal";
import OPENING_DAY_TYPES from "../../constants/OpeningDayType";
import {isMobile} from "../../utils/DeviceUtility";
import {getLanguage} from "../../utils/LanguageUtility";
import {getCalendar} from "../../actions/Calendar";
import {forceHttps, getHeaders} from "../../utils/FetchUtility";
import {attemptRefresh} from "../../utils/AuthenticationUtility";
import InvalidSlotModal from "../Modals/InvalidSlotModal/InvalidSlotModal";

/**
 * The {@code Calendar} class represents the calendar.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Calendar extends React.Component {

  /** 
   * The class constructor. 
   */
  constructor(props, context) {
    super(props, context);

    let numberOfDays = isMobile() ? 1 : 7;

    let date = new Date();
    if (numberOfDays > 1) {
      date = new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)));
    }

    this.state = {
      date: date,
      numberOfVisibleDays: numberOfDays,
      showCreateAppointmentModal: false,
      showInsufficientCreditsModal: false,
      createAppointmentButtonsDisabled: false,
      showInvalidSlotModal: false,
      appointment: {
        date: null
      }
    };
  }

  /**
   * Renders and returns the calendar body.
   *
   * @returns {*} the calendar body
   */
  renderBody = () => {
    const {calendar} = this.props;
    const {numberOfVisibleDays} = this.state;

    let rows = [];
    for (let i = 0; i < calendar.days[0].slots.length; i++) {

      let slots = [];
      for (let j = 0; j < numberOfVisibleDays; j++) {
        slots.push(
          this.renderSlot(j, calendar.days[j].slots[i])
        );
      }

      rows.push(
        <tr key={i}>
          {slots}
        </tr>
      );
    }
    return (
      <tbody>
      {rows}
      </tbody>
    );
  };

  /**
   * Renders and returns the calendar slot.
   *
   * @returns {*} the calendar slot
   */
  renderSlot = (key, slot) => {
    const {user, facility} = this.props;

    let result = <td key={key} className="no-border">&#160;</td>;
    if (slot) {

      let startDate = convertISODateTimeToJSDate(slot.startDate);
      let endDate = convertISODateTimeToJSDate(slot.endDate);

      result = <td key={key}>
        <div className={'slot slot-' + slot.status}
             onClick={() => this.handleSlotClick(convertISODateTimeToJSDate(slot.startDate), slot.status, user, facility)}>
          <h5>
            {startDate.toLocaleTimeString(getLanguage(), {hour: '2-digit', minute: '2-digit'})
            + ' - '
            + endDate.toLocaleTimeString(getLanguage(), {hour: '2-digit', minute: '2-digit'})}
          </h5>
        </div>
      </td>;
    }
    return result;
  };

  /**
   * Handles the click event on a slot.
   *
   * @param date the date of the slot
   * @param status the slot status
   * @param user the user
   * @param facility the facility
   */
  handleSlotClick = async (date, status, user, facility) => {
    let valid = await this.validateSlot(facility, date);

    if (!valid) {
      this.setState({
        showInvalidSlotModal: true
      });
    } else if (user.credits < facility.appointmentPrice) {
      this.setState({
        showInsufficientCreditsModal: true
      });
    } else if (SlotStatus.UNAVAILABLE !== status && SlotStatus.ALREADY_BOOKED !== status) {
      this.setState({
        showCreateAppointmentModal: true,
        appointment: {
          date: date,
        }
      });
    }
  };

  async validateSlot(facility, date) {
    let url = forceHttps(BackendURLConstants.CALENDAR_SLOT
      .replace('$id', facility.id)
      .replace('$date', convertJSDateToISODateTime(date)));

    await attemptRefresh();

    try {
      let response = await fetch(url, {
        method: 'GET',
        headers: getHeaders(null, true),
        credentials: 'include'
      });
      return response.ok;
    } catch (e) {
      return false;
    }
  }

  /**
   * Handles the click event on the confirm button.
   */
  handleCreateAppointmentConfirmClick = async () => {
    const {dispatch, user, facility} = this.props;
    const {appointment, numberOfVisibleDays} = this.state;

    this.setState({
      createAppointmentButtonsDisabled: true,
    });

    let facilityDTO = {
      id: facility.id
    };

    const data = {
      date: convertJSDateToISODateTime(appointment.date),
      user: BackendURLConstants.USER.replace('$id', user.username),
      pointOfService: BackendURLConstants.POINT_OF_SERVICE.replace('$id', user.pointOfService.id),
      facility: facilityDTO
    };

    await dispatch(createAppointment(JSON.stringify(data)));
    dispatch(getCalendar(facility.id, appointment.date, numberOfVisibleDays));

    await this.setState({
      date: appointment.date,
      createAppointmentButtonsDisabled: false,
      showCreateAppointmentModal: false,
      appointment: {
        date: null,
        username: null
      }
    });
  };

  /**
   * Handles the click event on the cancel button.
   */
  handleCreateAppointmentCancelClick = () => {
    this.setState({
      createAppointmentButtonsDisabled: false,
      showCreateAppointmentModal: false,
      appointment: {
        date: null,
        username: null
      }
    });
  };

  /**
   * Handles the click event on the cancel button.
   */
  handleInsufficientCreditsCancelClick = () => {
    this.setState({
      showInsufficientCreditsModal: false
    });
  };

  handleCreateInvalidAppointmentClick = () => {
    const {dispatch, facility} = this.props;
    const {numberOfVisibleDays, date} = this.state;

    dispatch(getCalendar(facility.id, date, numberOfVisibleDays));

    this.setState({
      showInvalidSlotModal: false
    });
  };

  /**
   * Renders the month and year of the first of the selected week.
   *
   * @returns {*} the month and year
   */
  renderMonthAndYear = () => {
    const {calendar} = this.props;

    const firstDayOfTheWeek = convertISODateToJSDate(calendar.days[0].date);
    return <h3>{firstDayOfTheWeek.toLocaleString(getLanguage(), {month: 'long'}) + ' ' + firstDayOfTheWeek.getFullYear()}</h3>;
  };

  /**
   * Renders the navigation buttons.
   *
   * @returns {*} the navigation buttons
   */
  renderButtons = () => {
    const {numberOfVisibleDays, date} = this.state;

    const firstDayOfTheSelectedWeek = date;
    const currentDate = new Date();

    const firstDayOfTheCurrentWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1)));
    firstDayOfTheCurrentWeek.setHours(0);
    firstDayOfTheCurrentWeek.setMinutes(0);
    firstDayOfTheCurrentWeek.setSeconds(0);
    firstDayOfTheCurrentWeek.setMilliseconds(0);

    const currentDay = new Date();
    currentDay.setHours(0);
    currentDay.setMinutes(0);
    currentDay.setSeconds(0);
    currentDay.setMilliseconds(0);

    let disabled = false;
    if ((numberOfVisibleDays === 7 && firstDayOfTheCurrentWeek.getTime() === firstDayOfTheSelectedWeek.getTime())
      || (currentDay.getTime() === firstDayOfTheSelectedWeek.getTime())) {
      disabled = true;
    }

    return (
      <ButtonGroup>
        <Button disabled={disabled} onClick={() => this.handlePreviousWeekClick()}>
          <Light icon="chevron-left"/>
        </Button>
        <Button disabled={disabled} onClick={() => this.handleTodayClick(disabled)}>
          <FormattedMessage id="common.button.today"/>
        </Button>
        <Button onClick={() => this.handleNextWeekClick(disabled)}>
          <Light icon="chevron-right"/>
        </Button>
      </ButtonGroup>
    );
  };

  /**
   * Handles the click event for the next week button.
   */
  handleNextWeekClick = () => {
    const {dispatch, facility} = this.props;
    const {numberOfVisibleDays, date} = this.state;

    date.setDate(date.getDate() + numberOfVisibleDays);

    this.setState({
      date: date
    });

    dispatch(getCalendar(facility.id, date, numberOfVisibleDays));
  };

  /**
   * Handles the click event for the next week button.
   *
   * @param disabled indicates whether the button is disabled, or not.
   */
  handlePreviousWeekClick = (disabled) => {
    if (!disabled) {
      const {dispatch, facility} = this.props;
      const {numberOfVisibleDays, date} = this.state;

      date.setDate(date.getDate() - numberOfVisibleDays);

      this.setState({
        date: date
      });

      dispatch(getCalendar(facility.id, date, numberOfVisibleDays));
    }
  };

  /**
   * Handles the click event for the today button.
   *
   * @param disabled indicates whether the button is disabled, or not.
   */
  handleTodayClick = (disabled) => {
    if (!disabled) {
      const {dispatch, facility} = this.props;
      const {numberOfVisibleDays} = this.state;

      dispatch(getCalendar(facility.id, new Date(), numberOfVisibleDays));
    }
  };

  _getSpecialOpeningDayForDate(facility, date) {
    const isoDate = convertJSDateToDateInput(date);

    if (facility.openingSchedule && facility.openingSchedule.openingDays) {
      const specialOpeningDay = facility.openingSchedule.openingDays
        .filter(openingDay => openingDay.type === OPENING_DAY_TYPES.SPECIAL)
        .find(openingDay => convertJSDateToDateInput(new Date(openingDay.date)) === isoDate);

      if (specialOpeningDay) {
        return specialOpeningDay
      }
    }
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {calendar, facility, user} = this.props;
    const {formatMessage} = this.props.intl;

    if (!calendar) {
      return <Loading/>;
    }

    let appointment = {
      date: '',
      time: '',
      credits: ''
    };

    if (this.state.appointment.date !== null && this.state.appointment.credits !== null) {
      appointment = {
        date: this.state.appointment.date.toLocaleDateString(getLanguage()),
        time: this.state.appointment.date.toLocaleTimeString(getLanguage(), {hour: '2-digit', minute: '2-digit'}),
        credits: facility.appointmentPrice
      };
    }

    return (
      <div className="calendar">
        <Row className="calendar-description">
          <Col xs={12}>
            <FormattedMessage id={'facilities.type.' + facility.type + '.description'}
                              values={{
                                credit:
                                facility.appointmentPrice
                              }}/>
          </Col>
        </Row>
        <Row className="calendar-legend">
          <Col xs={12}>
            <ul className="list-inline">
              <li><span className="legend-icon legend-icon-success">&nbsp;</span><FormattedMessage
                id={'calendar.legend.success'}/></li>
              <li><span className="legend-icon legend-icon-warning">&nbsp;</span><FormattedMessage
                id={'calendar.legend.warning'}/></li>
              <li><span className="legend-icon legend-icon-danger">&nbsp;</span><FormattedMessage
                id={'calendar.legend.danger'}/></li>
              <li><span className="legend-icon legend-icon-info">&nbsp;</span><FormattedMessage
                id={'calendar.legend.info'}/></li>
            </ul>
          </Col>
        </Row>
        <Row className="calendar-navigation">
          <Col xs={12} md={6} className="calendar-navigation-year">
            {this.renderMonthAndYear()}
          </Col>
          <Col xs={12} md={6} className="calendar-navigation-buttons">
            {this.renderButtons()}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Table className="calendar-table" bordered condensed responsive>
              <thead>
              <tr>
                {calendar.days.map((day, i) => (
                  <th key={i} className="text-center">
                    <div>{convertISODateToJSDate(day.date).getDate()}</div>
                    <div className="day-long">
                      <FormattedMessage
                        id={`date.day.${(convertISODateToJSDate(day.date).getDay() === 0 ? 6 : convertISODateToJSDate(day.date).getDay() - 1)}`}/>
                    </div>
                    <div className="day-short">
                      <FormattedMessage
                        id={`date.day.${(convertISODateToJSDate(day.date).getDay() === 0 ? 6 : convertISODateToJSDate(day.date).getDay() - 1)}.abbreviation`}/>
                    </div>
                  </th>
                ))}
              </tr>
              </thead>
              {this.renderBody()}
            </Table>
            <AddEntityModal show={this.state.showCreateAppointmentModal}>
              <FormattedMessage id="modal.create-appointment.title" tagName="h4"/>
              <FormattedHTMLMessage
                id={facility.type === 'NUTRITIONIST_PRACTICE' ? 'modal.create-appointment.paragraph-two' : 'modal.create-appointment.paragraph-one'}
                values={{
                  time: appointment.time,
                  date: appointment.date,
                  credits: appointment.credits
                }}/>
              <Button bsStyle="primary" onClick={this.handleCreateAppointmentConfirmClick}
                      disabled={this.state.createAppointmentButtonsDisabled}>
                <FormattedMessage id="common.button.confirm"/>
              </Button>
              <Button bsStyle="danger" onClick={this.handleCreateAppointmentCancelClick}
                      disabled={this.state.createAppointmentButtonsDisabled}>
                <FormattedMessage id="common.button.cancel"/>
              </Button>
            </AddEntityModal>
            <InsufficientCreditsModal show={this.state.showInsufficientCreditsModal}>
              <FormattedHTMLMessage id="modal.insufficient-credits.paragraph"
                                    values={{
                                      balance: user.credits,
                                      price: facility.appointmentPrice
                                    }}/>
              <Button bsStyle="danger" onClick={this.handleInsufficientCreditsCancelClick}>
                <FormattedMessage id="common.button.cancel"/>
              </Button>
            </InsufficientCreditsModal>
            <InvalidSlotModal show={this.state.showInvalidSlotModal}>
              <FormattedMessage id="modal.invalid-slot.paragraph" tagName="p"/>
              <Button bsStyle="danger" onClick={this.handleCreateInvalidAppointmentClick}>
                <FormattedMessage id="modal.invalid-slot.button"/>
              </Button>
            </InvalidSlotModal>
          </Col>
        </Row>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {calendar} = state;

  return {
    calendar: calendar.calendar
  };
}

export default connect(mapStateToProps)(injectIntl(Calendar));
