import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {Image, ParallaxImage} from "../../components/Image/index";
import {BackLink, PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import {Light} from "../../components/Icons/index";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code SchoolActivities} class represents the school activities service page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class SchoolActivities extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div id="service-page">
        <ParallaxImage lowRes={require('../../images/pages/school-activities/page-header.svg')}
                       highRes={require('../../images/pages/school-activities/page-header.jpg')}/>
        <PageHeader>
          <BackLink>
            <LocalizedLink id={URIKeys.SERVICES}>
              <Light icon="chevron-left"/>&#160;
              <FormattedMessage id="nav-bar.item.services"/>
            </LocalizedLink>
          </BackLink>
          <PageHeaderTitle>
            <FormattedMessage id="services.section.school-activities.title" tagName="h3"/>
            <FormattedMessage id="school-activities.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section>
          <Grid>
            <Row>
              <Col xsHidden sm={5}>
                <Image lowRes={require('../../images/pages/school-activities/image.svg')}
                       highRes={require('../../images/pages/school-activities/image.jpeg')}
                       classNames="img-responsive"/>
              </Col>
              <Col xs={12} sm={7}>
                <FormattedMessage id="school-activities.paragraph.one" tagName="p"/>
                <FormattedMessage id="school-activities.paragraph.two" tagName="p"/>
                <FormattedMessage id="school-activities.paragraph.three" tagName="p"/>
                <FormattedMessage id="school-activities.paragraph.four" tagName="p"/>
                <br/>
                <LocalizedLink id={URIKeys.SERVICES} className="btn btn-sm btn-info">
                  <Light icon="chevron-left"/>&#160;
                  <FormattedMessage id="common.button.back"/>
                </LocalizedLink>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

export default SchoolActivities;
