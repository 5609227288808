import React from "react";
import {connect} from "react-redux";
import {Col, FormControl, Grid, Image, Row} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from "react-intl";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import URIKeys from "../../constants/URIKeys";
import PricingBox from "../../components/PricingBox/PricingBox";
import PricingBoxTitle from "../../components/PricingBox/PricingBoxTitle";
import {
  PricingBoxAction,
  PricingBoxFeatureList,
  PricingBoxPrice,
  PricingBoxPriceUnit,
  PricingBoxPriceValue
} from "../../components/PricingBox/index";
import {Solid} from "../../components/Icons/index";
import {getPointsOfService} from "../../actions";
import {getCreditBundles} from "../../actions/Products";
import {Redirect} from "react-router-dom";
import Loading from "../../components/Modals/Loading";
import {getLanguage} from "../../utils/LanguageUtility";

/**
 * The {@code Pricing} class represents the pricing page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Pricing extends React.Component {

  state = {
    selectedPointOfService: undefined
  };

  async componentDidMount() {
    const {dispatch} = this.props;

    const pointsOfService = await dispatch(getPointsOfService(undefined, undefined, undefined, false));
    dispatch(getCreditBundles(4, 0));

    if (pointsOfService && pointsOfService.length > 0) {
      this.setPointOfServiceState(pointsOfService[0]);
    }
  }

  onChangePointOfService = (event) => {
    const {pointOfServiceCollection} = this.props;

    if (pointOfServiceCollection && pointOfServiceCollection.pointsOfService) {
      const pointOfServiceId = event.target.value;
      const pointOfService = pointOfServiceCollection.pointsOfService.find(pointOfService => pointOfService.id == pointOfServiceId);

      if (pointOfService) {
        this.setPointOfServiceState(pointOfService);
      }
    }
  };

  setPointOfServiceState = (pointOfService) => {
    this.setState({
      selectedPointOfService: pointOfService
    });
  };

  renderPaymentInformation = (pointOfService) => {
    const {intl: {formatMessage}} = this.props;
    return (
      <div className="payment-information">
        <ul className="list-unstyled">
          <li><b>{pointOfService.bankInformation.iban}</b></li>
          <li>{pointOfService.bankInformation.bic}</li>
          <li>&#160;</li>
          <li><b>{pointOfService.name}</b></li>
          <li>{pointOfService.address ? (pointOfService.address.streetName + ' ' + pointOfService.address.streetNumber) : ''}</li>
          <li>{pointOfService.address ? (pointOfService.address.postalCode + ' ' + pointOfService.address.city) : ''} </li>
          <li>{pointOfService.address ? formatMessage({id: `countries.${pointOfService.address.country}`}) : ''}</li>
          <li>&#160;</li>
          <FormattedHTMLMessage id="page.pricing.payment.paragraph.two" tagName="li"/>
        </ul>
      </div>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, pointOfServiceCollection, productCollection} = this.props;
    const {formatMessage} = this.props.intl;
    const {selectedPointOfService} = this.state;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !pointOfServiceCollection || !productCollection) {
      return (
        <div id="pricing-page">
          <PageHeader>
            <PageHeaderTitle>
              <FormattedMessage id="page.pricing.title" tagName="h3"/>
              <FormattedMessage id="page.pricing.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    return (
      <div id="pricing-page">
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="page.pricing.title" tagName="h3"/>
            <FormattedMessage id="page.pricing.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="info">
          <Grid>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <FormattedMessage id="page.pricing.paragraph.one" tagName="p"/>
                <FormattedMessage id="page.pricing.paragraph.two" tagName="p"/>
              </Col>
            </Row>
          </Grid>
        </section>
        <section className="credit-bundles">
          <Grid>
            <Row>
              <div className="section-header">
                <FormattedMessage id="page.pricing.credit-bundles.title" tagName="h3"/>
              </div>
              {productCollection.products && productCollection.products.map((product, i) => (
                <PricingBox md={3} highlight={product.highlighted} key={product.sku}>
                  <PricingBoxTitle>
                    <h4>{product.name.split(" ")[0]}</h4>
                    {product.highlighted && (
                      <FormattedMessage id="page.pricing.pricing-box.three.subtitle"/>
                    )}
                  </PricingBoxTitle>
                  <PricingBoxPrice>
                    <PricingBoxPriceUnit>
                      €
                    </PricingBoxPriceUnit>
                    <PricingBoxPriceValue>
                      {product.price.toLocaleString(getLanguage(), {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0
                      })}
                    </PricingBoxPriceValue>
                  </PricingBoxPrice>
                  <PricingBoxFeatureList>
                    {product.specs.map((specification, i) => (
                      <li key={i}>
                        {specification.key} {specification.value}
                      </li>
                    ))}
                    {product.highlighted && (
                      <li>
                        <Solid icon="star"/>
                        <Solid icon="star"/>
                        <Solid icon="star"/>
                        <Solid icon="star"/>
                        <Solid icon="star"/>
                      </li>
                    )}
                    <li>&nbsp;</li>
                  </PricingBoxFeatureList>
                  <PricingBoxAction>
                    <LocalizedLink id={URIKeys.REGISTER}
                                   className={product.highlighted ? 'btn btn-success' : 'btn btn-primary'}>
                      <FormattedMessage id="page.pricing.pricing-box.button.subscribe"/>
                    </LocalizedLink>
                  </PricingBoxAction>
                </PricingBox>
              ))}
            </Row>
          </Grid>
        </section>
        <section className="buy">
          <div className="section-header">
            <FormattedMessage id="page.pricing.how-to.header" tagName="h3"/>
          </div>
          <Grid>
            <Row>
              <Col xs={12} md={8} mdOffset={1}>
                <FormattedMessage id="page.pricing.payconiq" tagName="p"/>
              </Col>
              <Col xs={12} md={2}>
                <Image responsive className="payconiq-logo" src={require('../../images/pages/prices/payconiq.png')}/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <FormattedMessage id="page.pricing.bank-transfer" tagName="p"/>
              </Col>
            </Row>
            {pointOfServiceCollection.pointsOfService && pointOfServiceCollection.pointsOfService.length > 0 && (
              <div>
                <Row>
                  <Col xs={12} md={5} mdOffset={1}>
                    <FormControl
                      name="pointOfService"
                      componentClass="select"
                      onChange={this.onChangePointOfService}
                    >
                      {pointOfServiceCollection.pointsOfService.map(pointOfService => (
                        <option key={pointOfService.id} value={pointOfService.id}>{pointOfService.name}</option>
                      ))}}
                    </FormControl>
                  </Col>
                </Row>
                <Row className="pricing-how-to-info">
                  <Col xs={12} md={10} mdOffset={1}>
                    {selectedPointOfService && (
                      this.renderPaymentInformation(selectedPointOfService)
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </Grid>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {common, getPointsOfService, getProducts} = state;

  return {
    error: common.error,
    loading: common.loading,
    pointOfServiceCollection: getPointsOfService.pointOfServiceCollection,
    productCollection: getProducts.productCollection
  };
}

export default connect(mapStateToProps)(injectIntl(Pricing));
