import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Alert, Col, Grid, Row} from "react-bootstrap";
import DashboardHeader from "../../../partials/DashboardHeader";
import {BackLink, PageHeaderTitle} from "../../../components/PageHeader";
import {Light} from "../../../components/Icons";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {Redirect} from "react-router";
import URIKeys from "../../../constants/URIKeys";
import Loading from "../../../components/Modals/Loading";
import {connect} from "react-redux";
import NewsletterForm from "../../../components/Forms/NewsletterForm";
import {getPointsOfService} from "../../../actions";

/**
 * The {@code Newsletter} class represents the dashboard page to send newsletters.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Newsletter extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      show: true
    };
  }

  handleDismiss = () => {
    this.setState({show: false});
  };

  /**
   * Gets the points of service.
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getPointsOfService(undefined, undefined, undefined, false));
  }

  renderWarning = () => {
    let warning = undefined;
    if (this.state.show) {
      warning = (
        <Alert bsStyle="warning" onDismiss={() => this.handleDismiss()}>
          <FormattedMessage id="common.title.warning" tagName="h4"/>
          <FormattedMessage id="page.newsletter.paragraph.one" tagName="p"/>
          <FormattedMessage id="page.newsletter.paragraph.two" tagName="p"/>
        </Alert>
      );
    }
    return warning;
  };

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {intl: {formatMessage}, history, error, loading, pointOfServiceCollection} = this.props;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !pointOfServiceCollection) {
      return (
        <div id="newsletter-page" className="dashboard-page">
          <DashboardHeader/>
          <PageHeader>
            <PageHeaderTitle>
              <FormattedMessage id="page.newsletter.title" tagName="h3"/>
              <FormattedMessage id="page.newsletter.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    return (
      <div id="newsletter-page" className="dashboard-page">
        <DashboardHeader/>
        <PageHeader>
          <BackLink>
            <a onClick={history.goBack}>
              <Light icon="chevron-left"/>&#160;
              <FormattedMessage id="common.button.back-to-previous"/>
            </a>
          </BackLink>
          <PageHeaderTitle>
            <FormattedMessage id="page.newsletter.title" tagName="h3"/>
            <FormattedMessage id="page.newsletter.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="newsletter-form">
          <Grid>
            <Row>
              <Col xs={12}>
                {this.renderWarning()}
                <NewsletterForm pointsOfService={pointOfServiceCollection.pointsOfService}/>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getPointsOfService} = state;
  return {
    error: common.error,
    loading: common.loading,
    pointOfServiceCollection: getPointsOfService.pointOfServiceCollection
  };
}

export default connect(mapStateToProps)(injectIntl(Newsletter));
