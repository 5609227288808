import React from "react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {Col, Grid, Row} from "react-bootstrap";
import {Marker} from "google-maps-react"
import {FormattedMessage, injectIntl} from "react-intl";
import {
  Address,
  City,
  OpeningDay,
  OpeningHour,
  OpeningSchedule,
  OpeningStatus,
  PointOfService,
  PointOfServiceList,
  Street
} from "../../components/PointsOfServiceList";
import {MapContainer} from '../../components/Map';
import {Loading} from "../../components/Modals";
import Logo from "../../images/logos/logo-small.svg";
import {getPointsOfServiceForStoreLocator} from "../../actions";
import URIKeys from "../../constants/URIKeys";
import {getLanguage} from "../../utils/LanguageUtility";

const initialZoom = 10; // todo: move to config
const initialLatitude = 51.0167;
const initialLongitude = 5.75;

const weekdays = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"]; // todo: move to constant

/**
 * The {@code StoreLocator} class represents the store locator page.
 *
 * @author Christiaan Janssen
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class StoreLocator extends React.Component {

  /** 
   * The class constructor. 
   */
  constructor(props) {
    super(props);

    this.state = { // todo: use class fields anr remove empty constructor
      selectedPointOfService: 0,
      zoom: initialZoom,
      latitude: initialLatitude,
      longitude: initialLongitude
    };
  }

  /**
   * Gets the points of service.
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getPointsOfServiceForStoreLocator());
  }

  /**
   * Renders the opening schedule for the given {@code pointOfService}.
   *
   * @param pointOfService the point of service
   * @returns {*} the HTML representation of the opening schedule
   */
  renderOpeningSchedule(pointOfService) {
    if (pointOfService.facilities) {
      return pointOfService.facilities
        .filter(facility => facility.type === 'PERSONAL_TRAINING_CENTRE') // todo: move facility type to constant
        .map(facility => (
          <OpeningSchedule key={facility} visible={this.state.selectedPointOfService === pointOfService.id}>
            {facility.openingSchedule.openingDays
              .filter(openingDay => openingDay.type === 'regular')
              .map(openingDay => (
                <OpeningDay day={weekdays.indexOf(openingDay.dayOfWeek)} key={openingDay.id}>
                  {this.renderOpeningHours(openingDay.openingHours)}
                </OpeningDay>
              ))}
          </OpeningSchedule>
        ));
    }
  }

  /**
   * Renders the given {@code openingHours}.
   *
   * @param openingHours the opening hours
   * @returns {*} the HTML representation of the opening hours
   */
  renderOpeningHours(openingHours) {
    let result = (<OpeningHour>
      <FormattedMessage id="points-of-service.closed"/>
    </OpeningHour>);

    if (openingHours.length > 0) {
      result = (openingHours.map(openingHour => (
        <OpeningHour key={openingHour.id}>
          {openingHour.startTime.toLocaleTimeString(getLanguage(), {hour: '2-digit', minute: '2-digit'})} -
          {openingHour.endTime.toLocaleTimeString(getLanguage(), {hour: '2-digit', minute: '2-digit'})}
        </OpeningHour>
      )));
    }
    return result;
  }

  /**
   * Renders the opening status for the given {@code pointOfService}.
   *
   * @param pointOfService the point of service
   * @returns {*} the HTML representation of the opening schedule
   */
  renderOpeningStatus(pointOfService) {
    // todo: move time logic to util
    const date = new Date();
    const currentDay = weekdays[new Date().getDay()];

    if (pointOfService.facilities) {
      return pointOfService.facilities
        .filter(facility => facility.type === 'PERSONAL_TRAINING_CENTRE') // todo: move to constant
        .map(facility => {
          return facility.openingSchedule.openingDays.map(openingDay => {
            if (openingDay.dayOfWeek === currentDay) {
              let isOpen = false;
              let willOpen = false;
              let endTime;
              let startTime;
              for (let k = 0; k < openingDay.openingHours.length; k++) { // todo: use forEach
                let openingHour = openingDay.openingHours[k];
                if (openingHour.startTime <= date && openingHour.endTime > date) {
                  isOpen = true;
                  endTime = openingHour.endTime.toLocaleTimeString(getLanguage(), {
                    hour: '2-digit',
                    minute: '2-digit'
                  });
                  break;
                } else if (openingHour.startTime > date) {
                  willOpen = true;
                  startTime = openingHour.startTime.toLocaleTimeString(getLanguage(), {
                    hour: '2-digit',
                    minute: '2-digit'
                  });
                  endTime = openingHour.endTime.toLocaleTimeString(getLanguage(), {
                    hour: '2-digit',
                    minute: '2-digit'
                  });
                  break;
                }
              }

              if (isOpen) {
                return (
                  <OpeningStatus>
                    <FormattedMessage id="points-of-service.status.is-open"
                                      values={{
                                        endTime: endTime
                                      }}/>
                  </OpeningStatus>
                );
              } else if (willOpen) {
                return (
                  <OpeningStatus>
                    <FormattedMessage id="points-of-service.status.will-open"
                                      values={{
                                        startTime: startTime,
                                        endTime: endTime
                                      }}/>
                  </OpeningStatus>
                );

              } else {
                return (
                  <OpeningStatus>
                    <FormattedMessage id="points-of-service.status.is-closed"/>
                  </OpeningStatus>
                );
              }
            }
          });
        });
    }
  }

  /**
   * Stores the ID of the selected point of service in the component's local state.
   * <p>
   * If the selected point of service is clicked again, the component's local state will be cleared.
   */
  handleShowDetails(pointOfService, e) {
    e.preventDefault();

    this.setState(prevState => ({
      selectedPointOfService: prevState.selectedPointOfService === pointOfService.id ? 0 : pointOfService.id,
      zoom: prevState.selectedPointOfService === pointOfService.id ? initialZoom : 15,
      latitude: prevState.selectedPointOfService === pointOfService.id ? initialLatitude : pointOfService.latitude,
      longitude: prevState.selectedPointOfService === pointOfService.id ? initialLongitude : pointOfService.longitude
    }));
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, pointsOfService} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || pointsOfService === null) {
      return (
        <div id="store-locator-page">
          <Grid fluid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    const icon = {
      url: Logo,
      height: 50 + 'px'
    };

    return (
      <div id="store-locator-page">
        <Grid fluid>
          <Col xs={12} sm={3}>
            <section className="sidebar">
              <PointOfServiceList>
                {pointsOfService.map(pointOfService => (
                  <PointOfService data={pointOfService} key={pointOfService.id}>
                    <Address>
                      <Street>
                          <span onClick={(e) => this.handleShowDetails(pointOfService, e)}>
                              {pointOfService.address.streetName + ' ' + pointOfService.address.streetNumber}
                          </span>
                      </Street>
                      <City>{pointOfService.address.city}</City>
                    </Address>
                    {this.renderOpeningStatus(pointOfService)}
                    {this.renderOpeningSchedule(pointOfService)}
                  </PointOfService>
                ))}
              </PointOfServiceList>
            </section>
          </Col>
          <Col sm={9} xsHidden>
            <section className="map">
              <MapContainer zoom={this.state.zoom}
                            initialCenter={{lat: initialLatitude, lng: initialLongitude}}
                            center={{lat: this.state.latitude, lng: this.state.longitude}}>
                {pointsOfService.map(pointOfService => (
                  <Marker name={pointOfService.name} key={pointOfService.id}
                          position={{lat: pointOfService.latitude, lng: pointOfService.longitude}}
                          icon={icon}/>
                ))}
              </MapContainer>
            </section>
          </Col>
        </Grid>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, storeLocator} = state;
  return {
    error: common.error,
    loading: common.loading,
    pointsOfService: storeLocator.pointsOfService
  };
}

export default connect(mapStateToProps)(injectIntl(StoreLocator));
