import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader";
import {FormattedMessage} from "react-intl";
import connect from "react-redux/es/connect/connect";
import URIKeys from "../../constants/URIKeys";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import {reset} from "../../actions";

/**
 * The {@code Exception} class represents the 500 page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Exception extends React.Component {

    /**
     * Resets the state.
     */
    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(reset());
    }

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        let message = <FormattedMessage id='error-message.common' tagName="h4"/>;
        if (this.props.message != null) {
            message = <FormattedMessage id={this.props.message} tagName="h4"/>
        }

        return (
            <div id="500-page" className="error-page">
                <PageHeader>
                    <PageHeaderTitle>
                        <FormattedMessage id="500.page.title" tagName="h3"/>
                        <FormattedMessage id="500.page.subtitle" tagName="p"/>
                    </PageHeaderTitle>
                </PageHeader>
                <section>
                    <Grid>
                        <Row>
                            <Col xsHidden sm={6}>
                                <p className="error-page-code">500</p>
                            </Col>
                            <Col xs={12} sm={6}>
                                {message}
                                <FormattedMessage id='500.page.paragraph' tagName="p"/>
                                <LocalizedLink id={URIKeys.HOME} className="btn btn-primary">
                                    <FormattedMessage id="500.page.button"/>
                                </LocalizedLink>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </div>
        );
    }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
    const {common} = state;

    const {
        message
    } = common || {
        message: null
    };

    return {
        message
    };
}

export default connect(mapStateToProps)(Exception);
