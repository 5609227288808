import React from "react";
import {Grid} from "react-bootstrap";

/**
 * The {@code PageHeader} class represents a page header.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PageHeader extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div className="page-header">
                <Grid>
                    {this.props.children}
                </Grid>
            </div>
        );
    }
}

export default PageHeader;
