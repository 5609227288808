import React from "react";
import {connect} from "react-redux";
import {Button, Table} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from "react-intl";
import {deleteAppointment} from "../../actions";
import {convertISODateTimeToJSDate} from "../../utils/DateTimeUtility";
import DeleteEntityModal from "../Modals/DeleteEntityModal/DeleteEntityModal";
import Light from "../Icons/Light";
import {FACILITIES} from "../../constants/Facilities";
import {getLanguage} from "../../utils/LanguageUtility";


/**
 * The {@code AppointmentTable} class represents the appointment table.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class AppointmentTable extends React.Component {

  /** 
   * The class constructor. 
   */
  constructor() {
    super();

    this.state = {
      showDeleteAppointmentModal: false,
      deleteAppointmentButtonsDisabled: false,
      appointment: {
        id: null,
      }
    };
  }

  /**
   * Renders the appointment price.
   *
   * @param price the price
   * @returns {*} the price
   */
  renderPrice = (price) => {
    let result = price;

    if (price === 1) {
      result += ' credit';
    } else {
      result += ' credits';
    }

    return result;
  };

  /**
   * Renders the start and end time of the appointment.
   *
   * @param appointment the appointment
   * @returns {*} the start and end time
   */
  renderAppointmentTime = (appointment) => {
    let result = '';

    let date = convertISODateTimeToJSDate(appointment.date);

    result += date.toLocaleTimeString(getLanguage(), {hour: '2-digit', minute: '2-digit'});
    result += ' - ';

    date.setMinutes(date.getMinutes() + appointment.facility.appointmentDuration);

    result += date.toLocaleTimeString(getLanguage(), {hour: '2-digit', minute: '2-digit'});

    return result;
  };

  /**
   * Renders the cancellation button.
   *
   * @param appointment the appointment
   * @returns {*} the cancellation button
   */
  renderCancellationButton = (appointment) => {
    let date = new Date();
    if ('PERSONAL_TRAINING_ON_SITE' === appointment.facility.type) {
      date.setDate(date.getDate() + 2);
    } else {
      date.setDate(date.getDate() + 1);
    }

    let disabled = true;
    if (date < convertISODateTimeToJSDate(appointment.date)) {
      disabled = false;
    }

    return (
      <Button bsStyle="danger" bsSize="xsmall" block disabled={disabled}
              onClick={() => this.handleCancelAppointment(appointment.id, disabled)}>
        <Light icon="trash-alt"/>
      </Button>
    );
  };

  /**
   * Handles the cancel appointment click.
   */
  handleCancelAppointment(id, disabled) {
    if (!disabled) {
      this.setState({
        showDeleteAppointmentModal: true,
        appointment: {
          id: id,
        }
      });
    }
  };

  /**
   * Handles the click event on the confirm button.
   */
  handleDeleteAppointmentConfirmClick = async () => {
    const {dispatch} = this.props;
    const {appointment} = this.state;

    this.setState({
      deleteAppointmentButtonsDisabled: true,
    });

    await dispatch(deleteAppointment(appointment.id));

    await this.setState({
      showDeleteAppointmentModal: false,
      deleteAppointmentButtonsDisabled: false,
      appointment: {
        id: null,
      }
    });
  };

  /**
   * Handles the click event on the cancel button.
   */
  handleDeleteAppointmentCancelClick = () => {
    this.setState({
      showDeleteAppointmentModal: false,
      deleteAppointmentButtonsDisabled: false,
      appointment: {
        id: null,
      }
    });
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  renderBody() {
    let {appointments} = this.props;

    let result = '';
    let displayMessage = false;

    if (appointments.length > 0) {
      if (appointments.length > 0) {
        appointments.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));

        result = <tbody>
        {appointments.map(appointment => {
          const showPrice = appointment.facility.type !== FACILITIES.NUTRITIONIST_PRACTICE

          return (
            <tr key={appointment.id}>
              <td>{convertISODateTimeToJSDate(appointment.date).toLocaleDateString(getLanguage())}</td>
              <td>{this.renderAppointmentTime(appointment)}</td>
              <FormattedMessage id={'facilities.type.' + appointment.facility.type} tagName="td"/>
              <td>{showPrice && this.renderPrice(appointment.facility.appointmentPrice)}</td>
              <td>{this.renderCancellationButton(appointment)}</td>
            </tr>
          );
        })}
        </tbody>
      } else {
        displayMessage = true;
      }
    } else {
      displayMessage = true;
    }

    if (displayMessage) {
      result = <tbody>
      <tr>
        <td colSpan={5}>
          <FormattedMessage id="appointments.page.section.upcoming-appointments.table.body.no-appointments"/>
        </td>
      </tr>
      </tbody>
    }

    return result;
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {appointment} = this.state;

    return (
      <div>
        <Table striped bordered condensed hover responsive>
          <thead>
          <tr>
            <FormattedMessage id="appointments.page.section.upcoming-appointments.table.header.date"
                              tagName="th"/>
            <FormattedMessage id="appointments.page.section.upcoming-appointments.table.header.time"
                              tagName="th"/>
            <FormattedMessage id="appointments.page.section.upcoming-appointments.table.header.facility"
                              tagName="th"/>
            <FormattedMessage id="appointments.page.section.upcoming-appointments.table.header.price"
                              tagName="th"/>
            <th>&nbsp;</th>
          </tr>
          </thead>
          {this.renderBody()}
        </Table>
        <DeleteEntityModal show={this.state.showDeleteAppointmentModal}>
          <FormattedMessage id="modal.delete-appointment.title" tagName="h4"/>
          <FormattedHTMLMessage id="modal.delete-appointment.paragraph"
                                values={{
                                  time: appointment.time,
                                  date: appointment.date
                                }}/>
          <Button bsStyle="primary" onClick={this.handleDeleteAppointmentConfirmClick}
                  disabled={this.state.deleteAppointmentButtonsDisabled}>
            <FormattedMessage id="common.button.confirm"/>
          </Button>
          <Button bsStyle="danger" onClick={this.handleDeleteAppointmentCancelClick}
                  disabled={this.state.deleteAppointmentButtonsDisabled}>
            <FormattedMessage id="common.button.cancel"/>
          </Button>
        </DeleteEntityModal>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, appointmentsUser} = state;
  return {
    error: common.error,
    loading: common.loading,
    userAppointmentCollection: appointmentsUser.userAppointmentCollection
  };
}

export default connect(mapStateToProps)(injectIntl(AppointmentTable));
