import React from "react";
import {FormattedMessage} from "react-intl";
import {Caption, Tile} from "../Tile";
import {Light} from "../Icons";

/**
 * The {@code MetabolicAge} class represents the metabolic age tile.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class MetabolicAge extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        const {measurement, user} = this.props;

      let color = 'icon-gray';
        let icon = 'walking';
        let metabolicAge = '-';

        if (measurement !== null) {
            const age = Math.abs(new Date(Date.now() - new Date(user.dayOfBirth)).getUTCFullYear() - 1970);
            metabolicAge = measurement.metabolicAge;

            const difference = age - metabolicAge;

          color = 'icon-success';
            if (difference < 0 && difference >= -5) {
              color = 'icon-warning';
                icon = 'blind';
            } else if (difference < -5) {
              color = 'icon-danger';
                icon = 'wheelchair';
            }
        }

        return (
            <Tile xs={12} md={4}>
                <FormattedMessage id="performance.page.tile.metabolic-age.title" tagName="h5"/>
              <Light icon={icon} classNames={color}/>
                <Caption>
                    <FormattedMessage id="performance.page.tile.metabolic-age.label.age"
                                      tagName="h5"
                                      values={{
                                          age: metabolicAge
                                      }}/>
                </Caption>
            </Tile>
        );
    }
}

export default MetabolicAge;
