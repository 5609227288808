import React from "react";
import {Grid} from "react-bootstrap";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import FaqItem from "../../components/Faq/FaqItem/FaqItem";
import {List, ListItem} from "../../components/List";

/**
 * The {@code Faq} class represents the faq page.
 *
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Faq extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div id="faq-page">
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="faq.page.title" tagName="h3"/>
            <FormattedMessage id="faq.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="faq">
          <Grid>
            <FaqItem icon="check-square" headerKey="page.faq.login.question">
              <FaqItem.Answer>
                <List>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.login.answer.li-one"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.login.answer.li-two"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.login.answer.li-three"/>
                  </ListItem>
                </List>
              </FaqItem.Answer>
            </FaqItem>
            <FaqItem icon="check-square" headerKey="page.faq.logout.question">
              <FaqItem.Answer>
                <List>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.logout.answer.li-one"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.logout.answer.li-two"/>
                  </ListItem>
                </List>
              </FaqItem.Answer>
            </FaqItem>
            <FaqItem icon="check-square" headerKey="page.faq.change-password.question">
              <FaqItem.Answer>
                <List>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.change-password.answer.li-one"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.change-password.answer.li-two"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.change-password.answer.li-three"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.change-password.answer.li-four"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.change-password.answer.li-five"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.change-password.answer.li-six"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.change-password.answer.li-seven"/>
                  </ListItem>
                </List>
              </FaqItem.Answer>
            </FaqItem>
            <FaqItem icon="check-square" headerKey="page.faq.update-information.question">
              <FaqItem.Answer>
                <List>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.update-information.answer.li-one"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.update-information.answer.li-two"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.update-information.answer.li-three"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.update-information.answer.li-four"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.update-information.answer.li-five"/>
                  </ListItem>
                </List>
              </FaqItem.Answer>
            </FaqItem>
            <FaqItem icon="check-square" headerKey="page.faq.create-appointment.question">
              <FaqItem.Answer>
                <List>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.create-appointment.answer.li-one"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.create-appointment.answer.li-two"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.create-appointment.answer.li-three"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.create-appointment.answer.li-four"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.create-appointment.answer.li-five"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.create-appointment.answer.li-six"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.create-appointment.answer.li-seven"/>
                  </ListItem>
                </List>
              </FaqItem.Answer>
            </FaqItem>
            <FaqItem icon="check-square" headerKey="page.faq.cancel-appointment.question">
              <FaqItem.Answer>
                <List>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.cancel-appointment.answer.li-one"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.cancel-appointment.answer.li-two"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.cancel-appointment.answer.li-three"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.cancel-appointment.answer.li-four"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.cancel-appointment.answer.li-five"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.cancel-appointment.answer.li-six"/>
                  </ListItem>
                </List>
              </FaqItem.Answer>
            </FaqItem>
            <FaqItem icon="check-square" headerKey="page.faq.buy-credits.question">
              <FaqItem.Answer>
                <List>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.buy-credits.answer.li-one"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.buy-credits.answer.li-two"/>
                  </ListItem>
                  <ListItem>
                    <FormattedHTMLMessage id="page.faq.buy-credits.answer.li-three"/>
                  </ListItem>
                </List>
              </FaqItem.Answer>
            </FaqItem>
          </Grid>
        </section>
      </div>
    );
  }
}

export default Faq;
