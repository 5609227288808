import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader";
import UserGeneralInformationForm from "../../components/Forms/UserGeneralInformationForm";
import {connect} from "react-redux";
import {getPointsOfService} from "../../actions";
import {Redirect} from "react-router-dom";
import URIKeys from "../../constants/URIKeys";
import Loading from "../../components/Modals/Loading";

/**
 * The {@code Register} class represents the registration page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Register extends React.Component {

  componentDidMount() {
    const {dispatch} = this.props;

    dispatch(getPointsOfService(undefined, undefined, undefined, false));
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, pointOfServiceCollection} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !pointOfServiceCollection) {
      return (
        <div id="registration-page">
          <PageHeader>
            <PageHeaderTitle>
              <FormattedMessage id="page.registration.title" tagName="h3"/>
              <FormattedMessage id="page.registration.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    return (
      <div id="registration-page">
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="page.registration.title" tagName="h3"/>
            <FormattedMessage id="page.registration.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section className="form">
          <Grid>
            <Row>
              <Col xs={12}>
                <UserGeneralInformationForm create={true} edit={true} user={null}/>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {common, getPointsOfService} = state;

  return {
    error: common.error,
    loading: common.loading,
    pointOfServiceCollection: getPointsOfService.pointOfServiceCollection
  };
}

export default connect(mapStateToProps)(injectIntl(Register));
