import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {BackLink, PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import {Image, ParallaxImage} from "../../components/Image/index";
import {Light} from "../../components/Icons/index";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code Academy} class represents the academy service page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Academy extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div id="service-page">
        <ParallaxImage lowRes={require('../../images/pages/academy/page-header.svg')}
                       highRes={require('../../images/pages/academy/page-header.jpeg')}/>
        <PageHeader>
          <BackLink>
            <LocalizedLink id={URIKeys.SERVICES}>
              <Light icon="chevron-left"/>&#160;
              <FormattedMessage id="nav-bar.item.services"/>
            </LocalizedLink>
          </BackLink>
          <PageHeaderTitle>
            <FormattedMessage id="services.section.academy.title" tagName="h3"/>
            <FormattedMessage id="academy.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section>
          <Grid>
            <Row>
              <Col xsHidden sm={5}>
                <Image lowRes={require('../../images/pages/academy/image.svg')}
                       highRes={require('../../images/pages/academy/image.jpeg')}
                       classNames="img-responsive"/>
              </Col>

              <Col xs={12} sm={7}>
                <FormattedMessage id="academy.paragraph.one" tagName="p"/>
                <FormattedMessage id="academy.paragraph.two" tagName="p"/>
                <p>
                  <FormattedMessage id="academy.paragraph.three"/>&#160;
                  <LocalizedLink id={URIKeys.CAREERS}>
                    <FormattedMessage id="academy.link.careers"/>
                  </LocalizedLink>
                </p>
                <br/>
                <LocalizedLink id={URIKeys.SERVICES} className="btn btn-sm btn-info">
                  <Light icon="chevron-left"/>&#160;
                  <FormattedMessage id="common.button.back"/>
                </LocalizedLink>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

export default Academy;
