import React from "react";

/**
 * The {@code ParallaxImage} class represents a parallax image.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ParallaxImage extends React.Component {

    /**
     * The class constructor.
     */
    constructor() {
        super();

        this.state = {
            loaded: false
        };
    }

    /**
     * Gets the HD image.
     */
    componentDidMount() {
        const image = new Image();
        image.src = this.props.highRes;
        image.onload = () => {
            let div = document.getElementById('parallax-image-high-res');
            if (div) {
                div.style.backgroundImage = 'url(' + image.src + ')';

                this.setState({
                    loaded: true
                });
            }
        }
    }

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        let lowResClass = '';
        let highResClass = ' parallax-image-hidden';

        if (this.state.loaded) {
            lowResClass = ' parallax-image-hidden';
            highResClass = '';
        }

        return (
            <div className="parallax-image">
                <div className={"parallax-image-low-res" + lowResClass}
                     style={{backgroundImage: 'url(' + this.props.lowRes + ')'}}>
                </div>
                <div id="parallax-image-high-res" className={"parallax-image-high-res" + highResClass}>
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default ParallaxImage;
