import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {injectIntl} from "react-intl";
import URIKeys from "../../constants/URIKeys";
import {
  Academy,
  Companies,
  FoodConsultant,
  PersonalTraining,
  PersonalTrainingAtHome,
  Recipe,
  SchoolActivities,
  Services,
  Spinning,
  SportMassage
} from "../index";
import Energizers from "./Energizers";

/**
 * The {@code ServicesPage} class represents the services page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ServicesPage extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {formatMessage} = this.props.intl;

    return (
      <Switch>
        <Route exact path={formatMessage({id: URIKeys.SERVICES})} component={Services}/>
        <Route path={formatMessage({id: URIKeys.ACADEMY})} component={Academy}/>
        <Route path={formatMessage({id: URIKeys.COMPANIES})} component={Companies}/>
        <Route path={formatMessage({id: URIKeys.ENERGIZERS})} component={Energizers}/>
        <Route exact path={formatMessage({id: URIKeys.RECIPE})} component={Recipe}/>
        <Route path={formatMessage({id: URIKeys.FOOD_CONSULTANT})} component={FoodConsultant}/>
        <Route path={formatMessage({id: URIKeys.PERSONAL_TRAINING})} component={PersonalTraining}/>
        <Route path={formatMessage({id: URIKeys.PERSONAL_TRAINING_AT_HOME})}
               component={PersonalTrainingAtHome}/>
        <Route path={formatMessage({id: URIKeys.SCHOOL_ACTIVITIES})} component={SchoolActivities}/>
        <Route path={formatMessage({id: URIKeys.SPINNING})} component={Spinning}/>
        <Route path={formatMessage({id: URIKeys.SPORT_MASSAGE})} component={SportMassage}/>
        <Redirect from="*" to={formatMessage({id: URIKeys.PAGE_NOT_FOUND})}/>
      </Switch>
    );
  }
}

export default injectIntl(ServicesPage);
