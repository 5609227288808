import React from "react";

/**
 * The {@code Badge} class represents an image badge.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Badge extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    return (
      <span className="badge badge-transparent">
          {this.props.children}
      </span>
    );
  }
}

export default Badge;

