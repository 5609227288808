import React from "react";
import {Col} from "react-bootstrap";

/**
 * The {@code Icon} class represents the icon of the feature box component.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Icon extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <Col xs={2}>
                {this.props.children}
            </Col>
        );
    }
}

export default Icon;

