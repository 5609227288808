/**
 * The {@code URI_KEYS} constant class contains the different URI keys.
 *
 * @type {string}
 */
const URI_KEYS = {
  // Dashboard
  DASHBOARD: 'uri.dashboard',
  DASHBOARD_APPOINTMENTS: 'uri.dashboard.appointments',
  DASHBOARD_CART: 'uri.dashboard.cart',
  DASHBOARD_CHECKOUT: 'uri.dashboard.checkout',
  DASHBOARD_DOCUMENTS: 'uri.dashboard.documents',
  DASHBOARD_FOOD_TIPS_AND_TRICKS: 'uri.dashboard.food-tips-and-tricks',
  DASHBOARD_PERFORMANCE: 'uri.dashboard.performance',
  DASHBOARD_MEAL_SCHEDULES: 'uri.dashboard.meal-schedules',
  DASHBOARD_MEAL_SCHEDULE: 'uri.dashboard.meal-schedule',
  DASHBOARD_INTAKE_FORMS: 'uri.dashboard.intake-forms',
  DASHBOARD_INTAKE_FORM: 'uri.dashboard.intake-form',
  DASHBOARD_MANAGE_RECIPES: 'uri.dashboard.manage-recipes',
  DASHBOARD_MANAGE_MEAL_SCHEDULES: 'uri.dashboard.manage-meal-schedules',
  DASHBOARD_OVERVIEW: 'uri.dashboard.overview',
  DASHBOARD_USER: 'uri.dashboard.user',
  DASHBOARD_USERS: 'uri.dashboard.users',
  DASHBOARD_POINTS_OF_SERVICE: 'uri.dashboard.points-of-service',
  DASHBOARD_POINT_OF_SERVICE: 'uri.dashboard.point-of-service',
  DASHBOARD_NEWSLETTER: 'uri.dashboard.newsletter',
  DASHBOARD_SHOP: 'uri.dashboard.shop',
  DASHBOARD_PRODUCT_DETAIL_PAGE: 'uri.dashboard.pdp',
  DASHBOARD_TECHNICAL_EMAIL: 'uri.dashboard.technical-email',

  // Error
  EXCEPTION: 'uri.error.exception',
  PAGE_NOT_FOUND: 'uri.error.page-not-found',

  // Info
  ABOUT_US: 'uri.about-us',
  APPLICATION: 'uri.application',
  CAREERS: 'uri.careers',
  CONTACT: 'uri.contact',
  HOME: 'uri.home',
  NEWSLETTER_SIGN_UP: 'uri.newsletter-sign-up',
  STORE_LOCATOR: 'uri.store-locator',
  PRICING: 'uri.pricing',
  FAQ: 'uri.faq',

  // Policies
  COOKIE_POLICY: 'uri.policies.cookie-policy',
  POLICIES: 'uri.policies',
  PRIVACY_POLICY: 'uri.policies.privacy-policy',
  TERMS_AND_CONDITIONS: 'uri.policies.terms-and-conditions',

  // Scheduled Email
  SCHEDULED_EMAIL: 'uri.scheduled-email',

  // Security
  CHANGE_PASSWORD: 'uri.change-password',
  FORGOT_PASSWORD: 'uri.forgot-password',
  LOGIN: 'uri.login',
  LOGOUT: 'uri.logout',
  REGISTER: 'uri.register',

  // Services
  ACADEMY: 'uri.services.academy',
  COMPANIES: 'uri.services.companies',
  ENERGIZERS: 'uri.services.energizers',
  FOOD_CONSULTANT: 'uri.services.food-consultant',
  PERSONAL_TRAINING: 'uri.services.personal-training',
  PERSONAL_TRAINING_AT_HOME: 'uri.services.personal-training-at-home',
  RECIPE: 'uri.services.recipe',
  SCHOOL_ACTIVITIES: 'uri.services.school-activities',
  SERVICES: 'uri.services',
  SPINNING: 'uri.services.spinning',
  SPORT_MASSAGE: 'uri.services.sport-massage',

  // Redirect
  REGISTER_REDIRECT: 'uri.register.redirect'
};

export default URI_KEYS;
