// Dashboard
export {default as Appointments} from "./dashboard/user/Appointments";
export {default as DashboardPage} from "./dashboard/DashboardPage";
export {default as Documents} from "./dashboard/user/Documents";
export {default as Performance} from "./dashboard/user/Performance";
export {default as MealSchedules} from "./dashboard/user/MealSchedules";
export {default as MealSchedule} from "./dashboard/user/MealSchedule";
export {default as Overview} from "./dashboard/employee/Overview";
export {default as Users} from "./dashboard/employee/Users";
export {default as User} from "./dashboard/employee/User";
export {default as ManageRecipes} from "./dashboard/employee/Recipes";
export {default as PointsOfService} from "./dashboard/employee/PointsOfService";
export {default as PointOfService} from "./dashboard/employee/PointOfService";

// Error
export {default as Exception} from "./error/Exception";
export {default as PageNotFound} from "./error/PageNotFound";

// Info
export {default as About} from "./info/About";
export {default as Careers} from "./info/Careers";
export {default as Contact} from "./info/Contact";
export {default as Home} from "./info/Home";
export {default as Newsletter} from "./info/Newsletter";
export {default as StoreLocator} from "./info/StoreLocator";
export {default as Pricing} from "./info/Pricing";
export {default as Faq} from "./info/Faq";

// Policies
export {default as CookiePolicy} from "./policies/CookiePolicy";
export {default as PolicyPage} from "./policies/PolicyPage";
export {default as PrivacyPolicy} from "./policies/PrivacyPolicy";
export {default as TermsAndConditions} from "./policies/TermsAndConditions";

// Security
export {default as ChangePassword} from "./security/ChangePassword";
export {default as ForgotPassword} from "./security/ForgotPassword";
export {default as Login} from "./security/Login";
export {default as Logout} from "./security/Logout";
export {default as Register} from "./security/Register";

// Scheduled Email
export {default as Movies} from "./movies/Movies";

// Services
export {default as Academy} from "./services/Academy";
export {default as Companies} from "./services/Companies";
export {default as FoodConsultant} from "./services/FoodConsultant";
export {default as PersonalTraining} from "./services/PersonalTraining";
export {default as PersonalTrainingAtHome} from "./services/PersonalTrainingAtHome";
export {default as Recipe} from "./services/Recipe";
export {default as SchoolActivities} from "./services/SchoolActivities";
export {default as Services} from "./services/Services";
export {default as ServicesPage} from "./services/ServicesPage";
export {default as Spinning} from "./services/Spinning";
export {default as SportMassage} from "./services/SportMassage";


