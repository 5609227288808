import {expireCookie, setCookie} from "../utils/CookieUtility";
import {done, error, start} from "./Common";
import Actions from "../constants/Actions";
import BackendURLConstants from "../constants/BackendURLConstants";

export function login(data, rememberMe) {
  return async dispatch => {
    try {
      dispatch(start());

      let date = new Date();

      let json = await post(BackendURLConstants.LOGIN, data);

      json = setTokenExpiryDate(date, json);

      setCookie('jwt', JSON.stringify(json), getCookieExpiryDate(date, rememberMe), false);
      setCookie('remember-me', rememberMe);

      dispatch(loginSuccess());
      dispatch(logoutReset());
      dispatch(done());
    } catch (e) {
      if ('400' === e.message) {
        dispatch(loginWrongCredentials());
      } else {
        dispatch(error('error-message.login.post'));
      }

      dispatch(done());
    }
  }
}

function loginSuccess() {
  return {
    type: Actions.LOGIN
  }
}

function loginWrongCredentials() {
  return {
    type: Actions.LOGIN_WRONG_CREDENTIALS,
    message: 'form.validation-message.invalid-credentials'
  }
}

export function resetLoginForm() {
  return {
    type: Actions.LOGIN_RESET
  }
}

export function logout() {
  return dispatch => {
    dispatch(start());

    expireCookie('jwt');
    expireCookie('remember-me');

    dispatch(logoutSuccess());
    dispatch(done());
  }
}

function logoutReset() {
  return {
    type: Actions.LOGOUT_RESET
  }
}

function logoutSuccess() {
  return {
    type: Actions.LOGOUT_SUCCESS
  }
}

export function resetPassword(username) {
  return async dispatch => {
    try {
      dispatch(start());

      let response = await fetch(BackendURLConstants.RESET_PASSWORD.replace('$username', username), {
        method: 'POST',
        headers: {
          'Authorization': 'Basic Ym9keXJlc2V0OmVkMi1USmYtekZELTJyRQ=='
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(response.status);
      }

      await dispatch(resetPasswordSuccess());
      await dispatch(done());
    } catch (e) {
      dispatch(error('error-message.reset-password.post'));
    }
  }
}

function resetPasswordSuccess() {
  return {
    type: Actions.RESET_PASSWORD
  }
}

export function resetResetPasswordForm() {
  return {
    type: Actions.RESET_PASSWORD_RESET
  }
}

export function changePassword(username, token, data) {
  return async dispatch => {
    try {
      dispatch(start());

      let response = await fetch(BackendURLConstants.CHANGE_PASSWORD
        .replace('$username', username)
        .replace('$token', token), {
        method: 'POST',
        headers: {
          'Authorization': 'Basic Ym9keXJlc2V0OmVkMi1USmYtekZELTJyRQ==',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: data
      });

      if (!response.ok) {
        throw new Error(response.status);
      }

      await dispatch(postChangePasswordSuccess());
      await dispatch(done());
    } catch (e) {
      dispatch(error('error-message.change-password.post'));
    }
  }
}

function postChangePasswordSuccess() {
  return {
    type: Actions.CHANGE_PASSWORD
  }
}

export function resetChangePasswordForm() {
  return {
    type: Actions.CHANGE_PASSWORD_RESET
  }
}

async function post(url, data) {
  let response = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': 'Basic Ym9keXJlc2V0OmVkMi1USmYtekZELTJyRQ==',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    credentials: 'include',
    body: data
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  return await response.json();
}

function getCookieExpiryDate(date, rememberMe) {
  let result = null;
  if (rememberMe) {
    let expiryDate = new Date();
    expiryDate.setDate(date.getDate() + 14);
    result = expiryDate.toUTCString();
  }
  return result; // todo: add expiry date that matches server refresh token validity time.
}

function setTokenExpiryDate(date, json) {
  let result = json;
  date.setSeconds(date.getSeconds() + json['expires_in']);
  result['expires_in'] = date;
  return result;
}
