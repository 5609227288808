import React from "react";

/**
 * The {@code BasicInfo} class represents the basic recipe info.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class BasicInfo extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <ul className="basic-info">
                {this.props.children}
            </ul>
        );
    }
}

export default BasicInfo;
