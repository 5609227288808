import React from "react";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {Light} from "../components/Icons";
import URIKeys from "../constants/URIKeys";
import {hasRole} from "../utils/AuthenticationUtility";
import USER_ROLES from "../constants/UserRoles";

/**
 * The {@code DashboardHeader} class represents the dashboard header.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class DashboardHeader extends React.Component {

  renderUserLinks = () => {
    const {intl: {formatMessage}} = this.props;

    return [
      <LinkContainer key="dashboard-performance" to={formatMessage({id: URIKeys.DASHBOARD_PERFORMANCE})}>
        <NavItem eventKey={1}>
          <Light icon="chart-line"/>
          <FormattedMessage id="nav-bar.item.dashboard.performance"/>
        </NavItem>
      </LinkContainer>,
      <LinkContainer key="dashboard-appointments" to={formatMessage({id: URIKeys.DASHBOARD_APPOINTMENTS})}>
        <NavItem eventKey={2}>
          <Light icon="calendar-alt"/>
          <FormattedMessage id="nav-bar.item.dashboard.appointments"/>
        </NavItem>
      </LinkContainer>,
      <LinkContainer key="dashboard-meal-schedules" to={formatMessage({id: URIKeys.DASHBOARD_MEAL_SCHEDULES})}>
        <NavItem eventKey={4}>
          <Light icon="utensils-alt"/>
          <FormattedMessage id="nav-bar.item.dashboard.meal-schedules"/>
        </NavItem>
      </LinkContainer>,
      <LinkContainer key="dashboard-documents" to={formatMessage({id: URIKeys.DASHBOARD_DOCUMENTS})}>
        <NavItem eventKey={5}>
          <Light icon="books"/>
          <FormattedMessage id="nav-bar.item.dashboard.documents"/>
        </NavItem>
      </LinkContainer>,
      <LinkContainer key="dashboard-shop" to={formatMessage({id: URIKeys.DASHBOARD_SHOP})}>
        <NavItem eventKey={6}>
          <Light icon="store-alt"/>
          <FormattedMessage id="nav-bar.item.dashboard.shop"/>
        </NavItem>
       </LinkContainer>
    ];
  };

  renderShoppingCartLink = () => {
    const {intl: {formatMessage}} = this.props;

    return (
      <LinkContainer key="dashboard-shopping-cart" to={formatMessage({id: URIKeys.DASHBOARD_CART})}>
        <NavItem eventKey={7}>
          <Light icon="shopping-cart"/>
          <FormattedMessage id="nav-bar.item.dashboard.cart"/>
        </NavItem>
      </LinkContainer>
    );
  };

  renderTrainerLinks = () => {
    const {intl: {formatMessage}} = this.props;

    return [
      <LinkContainer key="dashboard-overview" to={formatMessage({id: URIKeys.DASHBOARD_OVERVIEW})}>
        <NavItem eventKey={1}>
          <Light icon="calendar-alt"/>
          <FormattedMessage id="nav-bar.item.dashboard.overview"/>
        </NavItem>
      </LinkContainer>,
      <LinkContainer key="dashboard-users" to={formatMessage({id: URIKeys.DASHBOARD_USERS})}>
        <NavItem eventKey={2}>
          <Light icon="users"/>
          <FormattedMessage id="nav-bar.item.dashboard.users"/>
        </NavItem>
      </LinkContainer>,
    ];
  };

  renderNutritionistLinks = () => {
    const {intl: {formatMessage}} = this.props;

    return [
      <LinkContainer key="dashboard-recipes" to={formatMessage({id: URIKeys.DASHBOARD_MANAGE_RECIPES})}>
        <NavItem eventKey={4}>
          <Light icon="cauldron"/>
          <FormattedMessage id="nav-bar.item.dashboard.recipes"/>
        </NavItem>
      </LinkContainer>,
      <LinkContainer key="dashboard-intake-forms" to={formatMessage({id: URIKeys.DASHBOARD_INTAKE_FORMS})}>
        <NavItem eventKey={5}>
          <Light icon="clipboard-list-check"/>
          <FormattedMessage id="nav-bar.item.dashboard.intake-forms"/>
        </NavItem>
      </LinkContainer>
    ];
  };

  renderManagerLinks = () => {
    const {intl: {formatMessage}} = this.props;

    return [
      <LinkContainer key="dashboard-points-of-service" to={formatMessage({id: URIKeys.DASHBOARD_POINTS_OF_SERVICE})}>
        <NavItem eventKey={3}>
          <Light icon="store-alt"/>
          <FormattedMessage id="nav-bar.item.dashboard.points-of-service"/>
        </NavItem>
      </LinkContainer>,
      <LinkContainer key="dashboard-newsletter" to={formatMessage({id: URIKeys.DASHBOARD_NEWSLETTER})}>
        <NavItem eventKey={6}>
          <Light icon="mail-bulk"/>
          <FormattedMessage id="nav-bar.item.dashboard.newsletter"/>
        </NavItem>
      </LinkContainer>,
    ];
  };

  renderAdminLinks = () => {
    const {intl: {formatMessage}} = this.props;

    return [
      <LinkContainer key="dashboard-newsletter" to={formatMessage({id: URIKeys.DASHBOARD_TECHNICAL_EMAIL})}>
        <NavItem eventKey={7}>
          <Light icon="info-circle"/>
          <FormattedMessage id="nav-bar.item.dashboard.technical-email"/>
        </NavItem>
      </LinkContainer>
    ];
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <Navbar inverse collapseOnSelect>
        <Navbar.Header>
          <Navbar.Toggle>
            <FormattedMessage id="nav-bar.item.dashboard"/>
            <Light icon="chevron-down"/>
          </Navbar.Toggle>
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            {hasRole(USER_ROLES.USER) && this.renderUserLinks()}
            {(hasRole(USER_ROLES.TRAINER) || hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.NUTRITIONIST) || hasRole(USER_ROLES.ADMIN)) && this.renderTrainerLinks()}
            {(hasRole(USER_ROLES.NUTRITIONIST) || hasRole(USER_ROLES.ADMIN)) && this.renderNutritionistLinks()}
            {(hasRole(USER_ROLES.MANAGER) || hasRole(USER_ROLES.ADMIN)) && this.renderManagerLinks()}
            {hasRole(USER_ROLES.ADMIN) && this.renderAdminLinks()}
          </Nav>
          <Nav pullRight>
            {hasRole(USER_ROLES.USER) && this.renderShoppingCartLink()}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

}

export default injectIntl(DashboardHeader);
