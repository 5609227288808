import {BASE_URL} from "../config/Config";

/**
 * The {@code BackendURLConstants} constant class contains the different URL of the backend application.
 *
 * @type {String}
 */
const BackendURLConstants = {
  APPOINTMENTS: BASE_URL + "/appointments",
  APPOINTMENT: BASE_URL + "/appointments/$id",
  APPOINTMENT_EMPLOYEE: BASE_URL + "/appointments/$id/employee",
  APPOINTMENT_USER: BASE_URL + "/appointments/search/user?username=$id",

  CALENDAR: BASE_URL + "/facilities/$id/calendar?date=$date&numberOfVisibleDays=$numberOfVisibleDays",
  CALENDAR_SLOT: BASE_URL + "/facilities/$id/calendar/slots?date=$date",

  CART: BASE_URL + "/carts/current",
  CART_ENTRIES: BASE_URL + "/carts/current/entries",
  CART_ENTRY: BASE_URL + "/carts/current/entries/$id",

  CATEGORIES: BASE_URL + "/categories",
  CATEGORY_PRODUCTS: BASE_URL + "/categories/$ids/products",

  PAYMENT_INFORMATION: BASE_URL + "/checkout/payment-information",

  APPLICATION_EMAIL: BASE_URL + "/emails/application",
  CONTACT_EMAIL: BASE_URL + "/emails/contact",
  NEWSLETTER_EMAIL: BASE_URL + "/emails/newsletter",
  TECHNICAL_EMAIL: BASE_URL + "/emails/technical",

  CHANGE_PASSWORD: BASE_URL + "/user-details/$username/tokens/$token",
  RESET_PASSWORD: BASE_URL + "/user-details/$username/tokens",
  LOGIN: BASE_URL + "/oauth/token",

  EMPLOYEE: BASE_URL + "/employees/$id",
  EMPLOYEE_POINT_OF_SERVICES: BASE_URL + "/employees/$id/points-of-service",
  EMPLOYEE_GODCHILDREN: BASE_URL + "/employees/$id/godchildren",
  EMPLOYEE_RECIPES: BASE_URL + "/employees/$id/recipes",

  FACILITY: BASE_URL + '/facilities/$id',
  FACILITY_APPOINTMENTS: BASE_URL + '/facilities/$id/appointments?startDate=$startDate&endDate=$endDate',
  FACILITY_OPENING_SCHEDULE: BASE_URL + '/facilities/$id/opening-schedule',

  INGREDIENTS: BASE_URL + '/ingredients',
  INGREDIENT: BASE_URL + '/ingredients/$id',

  INSTRUCTIONS: BASE_URL + '/instructions',
  INSTRUCTION: BASE_URL + '/instructions/$id',

  MEAL_SCHEDULES: BASE_URL + "/meal-schedules",
  MEAL_SCHEDULE: BASE_URL + "/meal-schedules/$id",
  MEAL_SCHEDULE_EMPLOYEE: BASE_URL + "/meal-schedules/$id/employee",
  MEAL_SCHEDULE_USER: BASE_URL + "/meal-schedules/$id/user",
  MEAL_SCHEDULE_MEAL_SCHEDULE_DAYS: BASE_URL + "/meal-schedules/$id/days",

  MEAL_SCHEDULE_DAYS: BASE_URL + "/meal-schedule-days",
  MEAL_SCHEDULE_DAY: BASE_URL + "/meal-schedule-days/$id",
  MEAL_SCHEDULE_DAY_SLOTS: BASE_URL + "/meal-schedule-days/$id/slots",

  MEAL_SCHEDULE_SLOTS: BASE_URL + "/meal-schedule-slots",
  MEAL_SCHEDULE_SLOT: BASE_URL + "/meal-schedule-slots/$id",
  MEAL_SCHEDULE_SLOT_RECIPE: BASE_URL + "/meal-schedule-slots/$id/recipe",

  POINTS_OF_SERVICE: BASE_URL + "/points-of-service",
  POINT_OF_SERVICE: BASE_URL + "/points-of-service/$id",
  POINTS_OF_SERVICE_EMPLOYEES: BASE_URL + "/points-of-service/$id/employees",
  POINT_OF_SERVICE_FACILITIES: BASE_URL + "/points-of-service/$id/facilities",
  STORE_LOCATOR: BASE_URL + "/points-of-service/search/store-locator",

  OPENING_DAYS: BASE_URL + '/opening-days',
  OPENING_DAY: BASE_URL + '/opening-days/$id',
  OPENING_DAY_OPENING_HOURS: BASE_URL + '/opening-days/$id/opening-hours',

  OPENING_SCHEDULE_OPENING_DAYS: BASE_URL + '/opening-schedules/$id/opening-days',

  OPENING_HOURS: BASE_URL + '/opening-hours',
  OPENING_HOUR: BASE_URL + '/opening-hours/$id',

  ORDER: BASE_URL + '/orders/$id',
  ORDER_POLLING: BASE_URL + '/orders/polling/$id',

  PRODUCTS: BASE_URL + "/products",
  CREDIT_BUNDLES: BASE_URL + "/products/credit-bundles",
  PRODUCT: BASE_URL + "/products/$id",

  RECIPES: BASE_URL + "/recipes",
  RECIPE: BASE_URL + "/recipes/$id",
  RECIPES_COOKBOOK: BASE_URL + "/recipes/search/cookbook",
  RECIPE_INGREDIENTS: BASE_URL + "/recipes/$id/ingredients",
  RECIPE_INSTRUCTIONS: BASE_URL + "/recipes/$id/instructions",

  SUBSCRIPTIONS: BASE_URL + "/subscriptions",
  SUBSCRIPTION: BASE_URL + "/subscriptions/$id",
  SUBSCRIPTIONS_BY_USER: BASE_URL + "/subscriptions/search/user?username=$id",

  USERS: BASE_URL + "/users",
  USER: BASE_URL + "/users/$id",
  USER_VALIDATE: BASE_URL + "/users/$id/validate",
  USER_POINT_OF_SERVICE: BASE_URL + "/users/$id/point-of-service",
  USER_APPOINTMENTS: BASE_URL + "/users/$id/appointments",
  USER_BODY_COMPOSITION_MEASUREMENTS: BASE_URL + "/users/$id/body-composition-measurements",
  USER_GIRTH_MEASUREMENTS: BASE_URL + "/users/$id/girth-measurements",
  USER_GODPARENT: BASE_URL + "/users/$id/godparent",
  USER_CREDITS: BASE_URL + "/users/$id/credits",
  USER_MEAL_SCHEDULES: BASE_URL + "/users/$id/meal-schedules",
  USER_MEAL_SCHEDULE: BASE_URL + "/meal-schedules/$mealScheduleId",

  BODY_COMPOSITION_MEASUREMENTS: BASE_URL + "/body-composition-measurements",
  GIRTH_MEASUREMENTS: BASE_URL + "/girth-measurements",

  INTAKE_FORMS: BASE_URL + "/intake-forms",
  INTAKE_FORM: BASE_URL + "/intake-forms/$intakeFormId",
  INTAKE_FORM_ENTRIES: BASE_URL + "/intake-form-entries",
  INTAKE_FORM_INTAKE_FORM_ENTRIES: BASE_URL + "/intake-forms/$intakeFormId/entries",
  EMPLOYEE_INTAKE_FORMS: BASE_URL + "/employees/$employeeId/intake-forms",
};

export default BackendURLConstants;
