import React from "react";

/**
 * The {@code Caption} class represents the tile caption.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Caption extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div className="caption">
                {this.props.children}
            </div>
        );
    }
}

export default Caption;
