import React from "react";
import {Col} from "react-bootstrap";
import Badge from "./Badge";
import ImageWrapper from "./ImageWrapper";
import Name from "./Name";

/**
 * The {@code FeatureBox} class represents a feature box.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ImageBox extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    return (
      <Col xs={this.props.xs} sm={this.props.sm} lg={this.props.lg} className="image-box">
        {this.props.children}
      </Col>
    );
  }
}

ImageBox.Badge = Badge;
ImageBox.ImageWrapper = ImageWrapper;
ImageBox.Name = Name;

export default ImageBox;
