import React from "react";
import {Redirect} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {resetPassword, resetResetPasswordForm} from "../../actions";
import RequiredAnnotation from "./RequiredAnnotation";
import {Loading} from "../../components/Modals";
import {getValidationState, validate} from "../../utils/ValidationUtility";
import URIKeys from "../../constants/URIKeys";
import ValidationTypes from "../../constants/ValidationTypes";


/**
 * The {@code ForgotPasswordForm} class represents a forgot password form.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ForgotPasswordForm extends React.Component {

    /** 
     * The class constructor. 
     */
    constructor() {
        super();

        this.state = {
            emailAddress: {
                value: '',
                state: undefined,
                message: undefined
            }
        };
    }

    /**
     * Resets the form.
     */
    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(resetResetPasswordForm());
    }

    /**
     * Handle the change event for the email address input.
     *
     * @param e the event
     */
    handleEmailAddressChange = (e) => {
        this.validateEmailAddress(e.target.value);
    };

    /**
     * Validates the given {@code emailAddress}.
     *
     * @param emailAddress the email address
     */
    validateEmailAddress(emailAddress) {
        let message = validate(emailAddress, [ValidationTypes.REQUIRED, ValidationTypes.EMAIL_ADDRESS]);
        let state = getValidationState(message);
        this.setState({
            emailAddress: {
                value: emailAddress,
                state: state,
                message: message,
            }
        });
    };

    /**
     * Handles the form on submit.
     */
    handleSubmit = (e) => {
        e.preventDefault();

        if (this.isValid()) {
            const {dispatch} = this.props;
            dispatch(resetPassword(this.state.emailAddress.value));
        }
    };

    /**
     * Checks whether the form is valid.
     *
     * @returns {boolean} {@code true} in case the form is valid, otherwise {@code false}
     */
    isValid() {
        this.validateEmailAddress(this.state.emailAddress.value);

        return this.state.emailAddress.state === null;
    }

    /**
     * Renders the component.
     *
     * @returns {*} the HTML representation of the component
     */
    render() {
        const {error, loading, success} = this.props;
        const {formatMessage} = this.props.intl;

        if (error) {
            return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
        }

        let loadingModal;
        if (loading > 0) {
            loadingModal = <Loading/>;
        }

        if (success) {
            window.location.href = formatMessage({id: URIKeys.HOME});
        }

        return (
            <form
                id="forgot-password-form"
                onSubmit={this.handleSubmit}
                noValidate="noValidate">
                <Row>
                    <Col xs={12}>
                        <h4>
                            <FormattedMessage id="forgot-password.page.title"/>
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="forgot-password.page.paragraph" tagName="p"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup controlId="emailAddress" validationState={this.state.emailAddress.state}>
                            <ControlLabel>
                              <FormattedMessage id="label.email-address"/>
                                <RequiredAnnotation/>
                            </ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.emailAddress.value}
                                onChange={this.handleEmailAddressChange}/>
                          <HelpBlock>{this.state.emailAddress.message}</HelpBlock>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button type="submit" bsStyle="primary">
                            <FormattedMessage id="form.button.reset-password"/>
                        </Button>
                    </Col>
                </Row>
                {loadingModal}
            </form>
        );
    }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
    const {common, resetPassword} = state;
    return {
        error: common.error,
        loading: common.loading,
        success: resetPassword.success
    };
}

export default connect(mapStateToProps)(injectIntl(ForgotPasswordForm));
