import React from "react";
import ReactDOM from "react-dom";
import {applyMiddleware, createStore} from "redux";
import {Provider} from "react-redux";
import thunkMiddleware from "redux-thunk";
import {IntlProvider} from "react-intl";
import {BrowserRouter} from "react-router-dom";
import rootReducer from "./reducers";
import App from "./components/App";
import ScrollToTop from "./components/Misc/ScrollToTop";
import {getLanguage, getMessages} from "./utils/LanguageUtility";
import "./styles/app.css";
import {composeWithDevTools} from "redux-devtools-extension";

// All modern browsers, except `Safari`, have implemented the `ECMAScript Internationalization API`. For that we need to patch in on runtime.
if (!global.Intl) {
  global.intl = import('intl');
}

// TODO: cookie melding (GDPR)

const language = getLanguage();
const messages = getMessages(language);

const store = createStore(
  rootReducer, composeWithDevTools(
    applyMiddleware(
      thunkMiddleware
    )
  )
);

ReactDOM.render((
        <Provider store={store}>
            <IntlProvider locale={language} messages={messages}>
                <BrowserRouter>
                    <ScrollToTop>
                        <App/>
                    </ScrollToTop>
                </BrowserRouter>
            </IntlProvider>
        </Provider>
    ),
    document.getElementById('root')
);
