import React from "react";
import {Light} from "../../components/Icons";

/**
 * The {@code ListItem} class represents a list item.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ListItem extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <li className="list-item">
                <div className="list-item-icon">
                    <Light icon="check"/>
                </div>
                <div className="list-item-content">
                    {this.props.children}
                </div>
            </li>
        );
    }
}

export default ListItem;

