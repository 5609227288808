import {getAll, getOne} from "../utils/FetchUtility";
import {done, error, start} from "./Common";
import Actions from "../constants/Actions";
import BackendURLConstants from "../constants/BackendURLConstants";

export function getEmployee(id) {
  return async dispatch => {
    try {
      dispatch(start());

      let employee = await getOne(BackendURLConstants.EMPLOYEE.replace('$id', id), true);

      await _fetchPointOfService(employee);
      await _fetchGodchildren(employee);

      await dispatch(getEmployeeSuccess(employee));
      await dispatch(done());
    } catch (e) {
      dispatch(error('error-message.user.get'));
    }
  }
}

function getEmployeeSuccess(result) {
  return {
    type: Actions.GET_EMPLOYEE,
    result: result
  }
}

async function _fetchPointOfService(employee) {
  let {_embedded: {pointOfServices}} = await getAll(BackendURLConstants.EMPLOYEE_POINT_OF_SERVICES.replace('$id', employee.username), undefined, undefined, undefined, undefined, true);

  pointOfServices.map(pos => {
    pos.facilities = pos.facilities.filter(function (facility) {
      return facility.appointmentsEnabled;
    });
  });

  employee.pointsOfService = pointOfServices;
}

async function _fetchGodchildren(employee) {
  let {_embedded: {users}} = await getAll(BackendURLConstants.EMPLOYEE_GODCHILDREN.replace('$id', employee.username), undefined, undefined, undefined, undefined, true);

  employee.godchildren = [...users]
    .sort((a, b) => a.firstName < b.firstName ? -1 : (a.firstName > b.firstName ? 1 : (a.lastName < b.lastName ? -1 : (a.lastName > b.lastName ? 1 : (0)))));
}

export function getEmployeeRecipes(id, pageSize, pageNumber) {
  return async dispatch => {
    try {
      dispatch(start());

      let json = await getAll(BackendURLConstants.EMPLOYEE_RECIPES.replace('$id', id), pageSize, pageNumber, undefined, undefined, true);

      let links = await json._links;
      let recipes = json._embedded && json._embedded.recipeDToes ? json._embedded.recipeDToes : [];
      let page = await json.page;

      await Promise.all(recipes.map(_fetchIngredients));
      await Promise.all(recipes.map(_fetchInstructions));

      await dispatch(getEmployeeRecipesSuccess({
        recipes: recipes,
        links: links,
        page: page
      }));
      await dispatch(done());
    } catch (e) {
      dispatch(error('error-message.recipes.get'));
    }
  }
}

function getEmployeeRecipesSuccess(result) {
  return {
    type: Actions.GET_EMPLOYEE_RECIPES,
    result: result
  }
}

async function _fetchIngredients(recipe) {
  let {_embedded: {ingredients}} = await getAll(BackendURLConstants.RECIPE_INGREDIENTS.replace('$id', recipe.id), undefined, undefined, false, undefined, true);
  recipe.ingredients = [...ingredients].sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
}

async function _fetchInstructions(recipe) {
  let {_embedded: {instructions}} = await getAll(BackendURLConstants.RECIPE_INSTRUCTIONS.replace('$id', recipe.id), undefined, undefined, false, undefined, true);
  recipe.instructions = [...instructions].sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
}
