import React from "react";
import {Col} from "react-bootstrap";

/**
 * The {@code Tile} class represents a tile.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Tile extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <Col xs={this.props.xs} md={this.props.md}>
                <div className="tile">
                    {this.props.children}
                </div>
            </Col>
        );
    }
}

export default Tile;
