import React from "react";

/**
 * The {@code Answer} class represents an answer.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Answer extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div className="faq-item__content">
        {this.props.children}
      </div>
    );
  }
}

export default Answer;
