import React from "react";

/**
 * The {@code PointOfServiceList} class represents a list containing points of service.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PointOfServiceList extends React.Component {

    /**
     * Renders the component.
     *
     * @returns JSX.Element HTML representation of the component
     */
    render() {
        return (
            <ul className="list-unstyled points-of-service-list">
                {this.props.children}
            </ul>
        );
    }
}

export default PointOfServiceList;
