import React from "react";
import {Field, Form} from "react-final-form";
import {Button, Col, ControlLabel, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import FormControl from "./final-form/FormControl/FormControl";
import FormGroup from "./final-form/FormGroup/FormGroup";
import {compose, date, required} from "../../utils/ValidationUtility";
import {convertJSDateToDateInput, printInputDateInISODateTime} from "../../utils/DateTimeUtility";
import {post} from "../../utils/FetchUtility";
import BackendURLConstants from "../../constants/BackendURLConstants";
import {connect} from "react-redux";
import {getUserBodyCompositionMeasurements} from "../../actions";

/**
 * The {@code BodyCompositionMeasurementForm} class represents the form to edit/create a body composition measurement.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class BodyCompositionMeasurementForm extends React.Component {

  /**
   * Creates a new instance of the {@link BodyCompositionMeasurementForm} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      initialValues: {
        date: convertJSDateToDateInput(new Date()),
        height: undefined,
        weight: undefined,
        boneMass: undefined,
        muscleMass: undefined,
        fatPercentage: undefined,
        waterPercentage: undefined,
        metabolicAge: undefined,
        visceralFat: undefined,
      }
    };
  }

  /**
   * Renders the submit button.
   *
   * @returns {*} the submit button
   */
  renderSubmitButton = (pristine, invalid) => {
    return (
      <Button className="pull-right" bsStyle="primary" type="submit" disabled={pristine || invalid}>
        <FormattedMessage id="common.button.confirm"/>
      </Button>
    );
  };

  /**
   * Renders the cancel button.
   *
   * @returns {*} the cancel button
   */
  renderCancelButton = () => {
    return (
      <Button className="pull-right" bsStyle="danger" onClick={this.props.toggleCreateBodyCompositionMeasurementModal}>
        <FormattedMessage id="common.button.cancel"/>
      </Button>
    );
  };

  /**
   * Handles the form on submit.
   */
  onSubmit = async (values) => {
    const {user, dispatch} = this.props;

    const data = {
      ...values,
      date: printInputDateInISODateTime(values.date),
      user: BackendURLConstants.USER.replace('$id', user.username)
    };

    await post(BackendURLConstants.BODY_COMPOSITION_MEASUREMENTS, JSON.stringify(data), 'application/json', true);

    dispatch(getUserBodyCompositionMeasurements(user.username));
    this.props.toggleCreateBodyCompositionMeasurementModal();
  };

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    return (
      <div>
        <Form
          onSubmit={this.onSubmit}
          initialValues={this.state.initialValues}
          render={({handleSubmit, pristine, invalid}) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="date">
                    <ControlLabel>
                      <FormattedMessage id="label.date"/>
                    </ControlLabel>
                    <Field name="date" component={FormControl} validate={compose(required, date)}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="height">
                    <ControlLabel>
                      <FormattedMessage id="label.height"/>
                    </ControlLabel>
                    <Field name="height" component={FormControl} type="number" min="0" step="any" validate={required}/>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="weight">
                    <ControlLabel>
                      <FormattedMessage id="label.weight"/>
                    </ControlLabel>
                    <Field name="weight" component={FormControl} type="number" min="0" step="any" validate={required}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="fatPercentage">
                    <ControlLabel>
                      <FormattedMessage id="label.fat-percentage"/>
                    </ControlLabel>
                    <Field name="fatPercentage" component={FormControl} type="number" min="0" step="any"
                           validate={required}/>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="muscleMass">
                    <ControlLabel>
                      <FormattedMessage id="label.muscle-mass"/>
                    </ControlLabel>
                    <Field name="muscleMass" component={FormControl} type="number" min="0" step="any"
                           validate={required}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="boneMass">
                    <ControlLabel>
                      <FormattedMessage id="label.bone-mass"/>
                    </ControlLabel>
                    <Field name="boneMass" component={FormControl} type="number" min="0" step="any"
                           validate={required}/>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="metabolicAge">
                    <ControlLabel>
                      <FormattedMessage id="label.metabolic-age"/>
                    </ControlLabel>
                    <Field name="metabolicAge" component={FormControl} type="number" min="0" step="any"
                           validate={required}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="waterPercentage">
                    <ControlLabel>
                      <FormattedMessage id="label.water-percentage"/>
                    </ControlLabel>
                    <Field name="waterPercentage" component={FormControl} type="number" min="0" step="any"
                           validate={required}/>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup controlId="visceralFat">
                    <ControlLabel>
                      <FormattedMessage id="label.visceral-fat"/>
                    </ControlLabel>
                    <Field name="visceralFat" component={FormControl} type="number" min="0" step="any"
                           validate={required}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {this.renderCancelButton()}
                  {this.renderSubmitButton(pristine, invalid)}
                </Col>
              </Row>
            </form>
          )}/>
      </div>
    );
  }
}

export default connect()(injectIntl(BodyCompositionMeasurementForm));
