import React from "react";

/**
 * The {@code DocumentList} class represents a list of documents.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class DocumentList extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <ul className="list-unstyled document-list">
        {this.props.children}
      </ul>
    );
  }
}

export default DocumentList;
