import React from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux";
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, FormControl, Grid, Row, Table} from "react-bootstrap";
import {getUsername} from "../../../utils/AuthenticationUtility";
import DashboardHeader from "../../../partials/DashboardHeader";
import Loading from "../../../components/Modals/Loading";
import {getUser} from "../../../actions";
import URIKeys from "../../../constants/URIKeys";
import {getProduct} from "../../../actions/Products";
import {Image} from "../../../components/Image";
import {getLanguage} from "../../../utils/LanguageUtility";
import {Light} from "../../../components/Icons";
import {post} from "../../../utils/FetchUtility";
import BackendURLConstants from "../../../constants/BackendURLConstants";
import AddToCartModal from "../../../components/Modals/AddToCartModal/AddToCartModal";
import {getCart} from "../../../actions/Cart";
import InvalidCartModal from "../../../components/Modals/InvalidCartModal/InvalidCartModal";

/**
 * The {@code ProductDetailPage} class represents the dashboard product detail page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ProductDetailPage extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      showConfirmationModal: false,
      showWarningModal: false,
      warningMessage: undefined,
      addedEntry: undefined,
      variant: undefined
    };
  }

  componentDidMount() {
    const {dispatch} = this.props;

    let username = getUsername();

    dispatch(getUser(username));
    dispatch(getProduct(this.props.match.params.id));
    dispatch(getCart());
  }

  addToCart = async (product) => {
    const data = {
      quantity: 1,
      product: {
        id: product.id,
        sku: product.sku
      }
    };

    try {
      const entry = await post(BackendURLConstants.CART_ENTRIES, JSON.stringify(data), 'application/json', true);

      this.setState({
        showConfirmationModal: true,
        showWarningModal: false,
        addedEntry: entry
      });
    } catch (e) {
      let errorMessage = 'modal.invalid-cart.generic';
      if ('INCOMPATIBLE' === e.message) {
        errorMessage = 'modal.invalid-cart.incompatible';
      } else if ('NO_STOCK' === e.message) {
        errorMessage = 'modal.invalid-cart.no-stock';
      }

      this.setState({
        showConfirmationModal: false,
        showWarningModal: true,
        warningMessage: errorMessage,
        addedEntry: undefined
      });
    }
  };

  checkout = () => {
    const {intl: {formatMessage}, history} = this.props;
    this.setState({
      showConfirmationModal: false,
      showWarningModal: false,
      addedEntry: undefined
    });
    history.push(formatMessage({id: URIKeys.DASHBOARD_CART}));
  };

  shop = () => {
    const {intl: {formatMessage}, history} = this.props;
    this.setState({
      showConfirmationModal: false,
      showWarningModal: false,
      addedEntry: undefined
    });
    history.push(formatMessage({id: URIKeys.DASHBOARD_SHOP}));
  };

  cancel = () => {
    this.setState({
      showConfirmationModal: false,
      showWarningModal: false,
      warningMessage: 'modal.invalid-cart.generic',
      addedEntry: undefined
    });
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, userObject, productObject, cartObject} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0
      || !userObject
      || !productObject
      || !cartObject) {
      return (
        <div id="dashboard-product-detail-page" className="dashboard-page">
          <DashboardHeader/>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    let product = productObject;
    if (this.state.variant) {
      product = this.state.variant;
    } else if (productObject.variants && productObject.variants.length > 0) {
      product = productObject.variants[0];
    }

    const descriptions = product.description.split('\n');

    return (
      <div id="dashboard-product-detail-page" className="dashboard-page">
        <DashboardHeader/>
        <Grid>
          <section className="basic-information">
            <Row>
              <Col xs={12} sm={6} md={5}>
                {this.renderImages(product)}
              </Col>
              <Col xs={12} sm={6} md={7}>
                <h3 className="basic-information-name">
                  {productObject.name}
                </h3>
                {this.renderDropdown(productObject)}
                <p className="basic-information-sku">
                  SKU: {product.sku}
                </p>
                <div className="basic-information-description">
                  {descriptions[0]}
                </div>
                {this.renderPrice(product)}
                <div className="basic-information-button">
                  <Button bsStyle="primary" onClick={() => this.addToCart(product)}
                          disabled={'NO_STOCK' === product.stockIndication}>
                    <Light icon="plus-square"/>&#160;In Mijn Winkelmandje
                  </Button>
                </div>
                {'SHIP_TO' === product.type && (
                  <p className="basic-information-stock">
                    <FormattedMessage id={'label.' + product.stockIndication}/>
                  </p>
                )}
                {'TAKE_AWAY' === product.type && product.availability && product.availability.length > 0 && (
                  <ul className="list-inline basic-information-availability">
                    <FormattedMessage id='label.availability' tagName='li'/>
                    {product.availability.map((availability, i) => (
                      <li key={i}>
                        &nbsp;<FormattedMessage id={'label.' + availability}/>
                        {(i + 3) === product.availability.length && <span>,</span>}
                        {(i + 2) === product.availability.length && <FormattedMessage id='label.and'/>}
                      </li>
                    ))}
                  </ul>
                )}
              </Col>
            </Row>
          </section>
          <section className="details">
            <Row>
              <Col xs={12} sm={6} className="details-description">
                <h5>Omschrijving</h5>
                {descriptions.map((description, i) => (
                  <p key={i}>
                    {description}
                  </p>
                ))}
              </Col>
              <Col xs={12} sm={6} className="details-specifications">
                <h5>Kenmerken</h5>
                <Table striped bordered condensed hover responsive>
                  <tbody>
                  {product.specs.map((specification, i) => (
                    <tr key={i}>
                      {specification.key ? <td>{specification.key}</td> : null}
                      <td>{specification.value}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </section>
        </Grid>
        <AddToCartModal show={this.state.showConfirmationModal}>
          <FormattedMessage id="modal.add-to-cart.title" tagName="h4"/>
          <FormattedHTMLMessage id="modal.add-to-cart.paragraph"
                                values={{
                                  name: this.state.addedEntry ? this.state.addedEntry.product.name : ''
                                }}
                                tagName="p"/>
          <Row>
            <Col xs={12}>
              <Button className="pull-right" bsStyle="default" onClick={() => this.checkout()}>
                <FormattedMessage id="modal.add-to-cart.button.checkout"/>
              </Button>
              <Button className="pull-right" bsStyle="primary" onClick={() => this.shop()}>
                <FormattedMessage id="modal.add-to-cart.button.show-cart"/>
              </Button>
            </Col>
          </Row>
        </AddToCartModal>
        <InvalidCartModal show={this.state.showWarningModal}>
          <FormattedMessage id={this.state.warningMessage} tagName="p"/>
          <Button bsStyle="warning" onClick={() => this.cancel()}>
            <FormattedMessage id="common.button.cancel"/>
          </Button>
        </InvalidCartModal>
      </div>
    );
  }

  renderImages = (product) => {
    if (product.images && product.images[0]) {
      //  TODO: CAROUSEL
      return (
        <img className="img-responsive" src={'data:image/jpg;base64,' + product.images[0].url}
             alt={product.images[0].name}/>
      );
    } else {
      return (
        <Image lowRes={require('../../../images/products/placeholder-tiny.jpeg')}
               highRes={require('../../../images/products/placeholder.jpeg')}
               classNames="img-responsive"/>
      );
    }
  }

  renderDropdown = (product) => {
    if (product.variants && product.variants.length > 0) {
      return (
        <FormControl componentClass="select" className="basic-information-variants" onChange={this.toggleVariant}>
          {product.variants.map(variant => (
            <option key={variant.id} value={variant.id}>{variant.name}</option>
          ))}
        </FormControl>
      );
    }
    return null;
  }

  toggleVariant = (event) => {
    const {productObject} = this.props;
    const id = event.target.value;

    let selectedVariant = productObject.variants[0];
    for (const variant of productObject.variants) {
      if (variant.id == id) {
        selectedVariant = variant;
        break;
      }
    }

    this.setState({
      variant: selectedVariant
    });
  };

  renderPrice = (product) => {
    if (product.type && product.type === 'CREDIT_BUNDLE') {
      return (
        <div className="basic-information-price">
          €{product.price.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
        </div>
      );
    } else {
      return (
        <div className="basic-information-price">
          {product.price.toLocaleString(getLanguage(), {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1
          })}&nbsp;credit(s)
        </div>
      );
    }
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getUser, getProduct, getCart} = state;
  return {
    error: common.error,
    loading: common.loading,
    userObject: getUser.userObject,
    productObject: getProduct.productObject,
    cartObject: getCart.cartObject
  };
}

export default connect(mapStateToProps)(injectIntl(ProductDetailPage));
