import React from "react";
import {FormattedMessage} from "react-intl";
import {Caption, Tile} from "../Tile";
import {Light} from "../Icons";

/**
 * The {@code Weight} class represents the weight tile.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Weight extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        const {measurement} = this.props;

      let color = 'icon-gray';
        let icon = 'tachometer-slowest';
        let weight = '-';

        if (measurement !== null) {
            // Convert cm to m
            const height = measurement.height / 100;
            weight = measurement.weight;

            // Calculate BMI
            const bmi = weight / (height * height);

          color = 'icon-success';
            if (bmi < 16.5 || bmi >= 30) {
              color = 'icon-danger';
                icon = 'tachometer-fast';
            } else if (bmi < 18.5 || bmi >= 25) {
              color = 'icon-warning';
                icon = 'tachometer-slow';
            }
        }

        return (
            <Tile xs={12} md={4}>
                <FormattedMessage id="performance.page.tile.weight.title" tagName="h5"/>
              <Light icon={icon} classNames={color}/>
                <Caption>
                    <h5>{weight} kg</h5>
                </Caption>
            </Tile>
        );
    }
}

export default Weight;
