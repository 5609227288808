import React from "react";
import Instruction from "./Instruction";

/**
 * The {@code InstructionList} class represents a list of instructions.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class InstructionList extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {instructions} = this.props;

    if (instructions == null || instructions.length === 0) {
      return (
        <ol className="instruction-list">
        </ol>
      );
    }

    return (
      <ol className="instruction-list">
        {instructions.map((instruction, i) => (
          <Instruction key={i}
                       description={instruction.description}/>
        ))}
      </ol>
    );
  }
}

export default InstructionList;
