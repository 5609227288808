import React from "react";
import {Col} from "react-bootstrap";

/**
 * The {@code PricingBox} class represents a pricing box.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PricingBox extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    return (
      <div className="pricing-box">
        <Col xs={12} md={this.props.md} mdOffset={this.props.mdOffset}>
          <div className={"pricing-box-content" + (this.props.highlight ? ' highlight' : '')}>
            {this.props.children}
          </div>
        </Col>
      </div>
    );
  }
}

export default PricingBox;
