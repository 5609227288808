import {remove, post} from "./FetchUtility";
import BackendURLConstants from "../constants/BackendURLConstants";

/**
   * Returns the value for the newsletter field.
   *
   * @param user the user
   * @param type the type of subscription
   * @private
   */
  export function isUserSubscribed(user, type) {
    let result = false;
    if (user.subscriptions) {
      for (const subscription of user.subscriptions) {
        if (type === subscription.type) {
          result = true;
          break;
        }
      }
    }
    return result;
  }

  export async function updateSubscription(user, value, type) {
    if (value) {
      try {
        let subscription = {
          firstName: user.firstName,
          lastName: user.lastName,
          emailAddress: user.username,
          type: type,
          user: BackendURLConstants.USER.replace('$id', user.username)
        };

        await post(BackendURLConstants.SUBSCRIPTIONS, JSON.stringify(subscription), 'application/json', false);
      } catch (e) {
        // This might fail if the user is already subscribed.
      }
    } else {
      try {
        await remove(BackendURLConstants.SUBSCRIPTION.replace('$id', getSubscriptionID(user, type)), false);
      } catch (e) {
        // This might fail if there is no subscription exists.
      }
    }
  };

  export function getSubscriptionID(user, type) {
    let id;
    if (user.subscriptions) {
      for (const subscription of user.subscriptions) {
        if (type === subscription.type) {
          id = subscription.id;
          break;
        }
      }
    }
    return id;
  };
