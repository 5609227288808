import Actions from "../constants/Actions";

export function login(state = {
  success: false,
  message: null
}, action) {
  switch (action.type) {
    case Actions.LOGIN:
      return {
        ...state,
        success: true,
        message: null
      };
    case Actions.LOGIN_RESET:
      return {
        ...state,
        success: false,
        message: null
      };
    case Actions.LOGIN_WRONG_CREDENTIALS:
      return {
        ...state,
        success: false,
        message: action.message
      };
    default:
      return state;
  }
}

export function logout(state = {
  success: false
}, action) {
  if (action.type === Actions.LOGOUT_RESET) {
    return {
      ...state,
      success: false,
    };
  } else if (action.type === Actions.LOGOUT_SUCCESS) {
    return {
      ...state,
      success: true,
    };
  } else {
    return state;
  }
}

export function resetPassword(state = {
  success: false
}, action) {
  switch (action.type) {
    case Actions.RESET_PASSWORD:
      return Object.assign({}, state, {
        success: true
      });
    case Actions.RESET_PASSWORD_RESET:
      return Object.assign({}, state, {
        success: false
      });
    default:
      return state;
  }
}

export function changePassword(state = {
  success: false
}, action) {
  switch (action.type) {
    case Actions.CHANGE_PASSWORD:
      return Object.assign({}, state, {
        success: true
      });
    case Actions.CHANGE_PASSWORD_RESET:
      return Object.assign({}, state, {
        success: false
      });
    default:
      return state;
  }
}
