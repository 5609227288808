import React from "react";
import {Field, Form} from "react-final-form";
import {Button, Col, ControlLabel, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import FormControl from "./final-form/FormControl/FormControl";
import FormGroup from "./final-form/FormGroup/FormGroup";
import Select from "./final-form/Select/Select";
import {compose, email, maxLength, phoneNumber, required} from "../../utils/ValidationUtility";
import RequiredAnnotation from "./RequiredAnnotation";
import {post} from "../../utils/FetchUtility";
import BackendURLConstants from "../../constants/BackendURLConstants";
import LocalizedLink from "../Intl/LocalizedLink";
import URIKeys from "../../constants/URIKeys";
import {Success} from "../Modals";

/**
 * The {@code ManagerApplication} class represents the form to apply for the manager position.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PersonalTrainerApplication extends React.Component {

  /**
   * Creates a new instance of the {@link ManagerApplication} class.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      showSuccessModal: false,
      initialValues: {
        firstName: undefined,
        lastName: undefined,
        phoneNumber: undefined,
        emailAddress: undefined,
        car: undefined,
        freelancer: undefined,
        accountant: undefined,
        job: undefined,
        education: undefined,
        experience: undefined,
        location: undefined,
        flexibility: undefined,
        motivation: undefined,
        manager: undefined,
        movementCoach: undefined
      }
    };
  }

  /**
   * Returns the component
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {formatMessage} = this.props.intl;

    return <Form
      onSubmit={this.onSubmit}
      initialValues={this.state.initialValues}
      render={({handleSubmit, pristine, invalid, form}) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FormattedMessage id="form.application.personal-trainer.paragraph.one" tagName="p"/>
              <FormattedMessage id="form.application.personal-trainer.paragraph.two" tagName="p"/>
            </Col>
          </Row>
          <fieldset>
            <FormattedMessage id="form.application.contact-information" tagName="legend"/>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup controlId="firstName">
                  <ControlLabel>
                    <FormattedMessage id="label.first-name"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="firstName"
                    component={FormControl}
                    validate={compose(required, maxLength(256))}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup controlId="lastName">
                  <ControlLabel>
                    <FormattedMessage id="label.last-name"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="lastName"
                    component={FormControl}
                    validate={compose(required, maxLength(256))}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup controlId="phoneNumber">
                  <ControlLabel>
                    <FormattedMessage id="label.phone-number"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="phoneNumber"
                    placeholder="+32123456789"
                    component={FormControl}
                    validate={compose(required, phoneNumber, maxLength(256))}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup controlId="emailAddress">
                  <ControlLabel>
                    <FormattedMessage id="label.email-address"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="emailAddress"
                    component={FormControl}
                    validate={compose(required, email, maxLength(256))}
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
          <fieldset>
            <FormattedMessage id="form.application.education-and-experience" tagName="legend"/>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup controlId="car">
                  <ControlLabel>
                    <FormattedMessage id="label.car"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="car"
                    component={Select}
                    validate={required}
                    options={[
                      {
                        value: undefined,
                        label: formatMessage({id: 'label.select'}),
                        disabled: true
                      },
                      {
                        value: 'Nee',
                        label: formatMessage({id: 'label.no'})
                      },
                      {
                        value: 'Ja',
                        label: formatMessage({id: 'label.yes'})
                      }
                    ]}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup controlId="freelancer">
                  <ControlLabel>
                    <FormattedMessage id="label.freelancer"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="freelancer"
                    component={Select}
                    validate={required}
                    options={[
                      {
                        value: undefined,
                        label: formatMessage({id: 'label.select'}),
                        disabled: true
                      },
                      {
                        value: 'Nee',
                        label: formatMessage({id: 'label.no'})
                      },
                      {
                        value: 'Ja',
                        label: formatMessage({id: 'label.yes'})
                      }
                    ]}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup controlId="accountant">
                  <ControlLabel>
                    <FormattedMessage id="label.accountant"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="accountant"
                    component={Select}
                    validate={required}
                    options={[
                      {
                        value: undefined,
                        label: formatMessage({id: 'label.select'}),
                        disabled: true
                      },
                      {
                        value: 'Nee',
                        label: formatMessage({id: 'label.no'})
                      },
                      {
                        value: 'Ja',
                        label: formatMessage({id: 'label.yes'})
                      }
                    ]}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup controlId="job">
                  <ControlLabel>
                    <FormattedMessage id="label.job"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="job"
                    component={FormControl}
                    componentClass="textarea"
                    validate={compose(required, maxLength(256))}
                    rows={5}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup controlId="experience">
                  <ControlLabel>
                    <FormattedMessage id="label.experience"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="experience"
                    component={FormControl}
                    componentClass="textarea"
                    validate={compose(maxLength(256))}
                    rows={5}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup controlId="education">
                  <ControlLabel>
                    <FormattedMessage id="label.education"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="education"
                    component={FormControl}
                    componentClass="textarea"
                    validate={required}
                    rows={5}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup controlId="location">
                  <ControlLabel>
                    <FormattedMessage id="label.personal-trainer-location"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="location"
                    component={FormControl}
                    componentClass="textarea"
                    validate={required}
                    rows={5}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup controlId="flexibility">
                  <ControlLabel>
                    <FormattedMessage id="label.flexibility"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="flexibility"
                    component={FormControl}
                    componentClass="textarea"
                    validate={required}
                    rows={5}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup controlId="motivation">
                  <ControlLabel>
                    <FormattedMessage id="label.personal-trainer-motivation"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="motivation"
                    component={FormControl}
                    componentClass="textarea"
                    validate={required}
                    rows={5}
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
          <fieldset>
            <FormattedMessage id="form.application.more" tagName="legend"/>
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup controlId="manager">
                  <ControlLabel>
                    <FormattedMessage id="label.entrepreneur"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="manager"
                    component={Select}
                    validate={required}
                    options={[
                      {
                        value: undefined,
                        label: formatMessage({id: 'label.select'}),
                        disabled: true
                      },
                      {
                        value: 'Nee',
                        label: formatMessage({id: 'label.no'})
                      },
                      {
                        value: 'Ja',
                        label: formatMessage({id: 'label.yes'})
                      }
                    ]}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup controlId="movementCoach">
                  <ControlLabel>
                    <FormattedMessage id="label.movement-coach"/>
                    <RequiredAnnotation/>
                  </ControlLabel>
                  <Field
                    name="movementCoach"
                    component={Select}
                    validate={required}
                    options={[
                      {
                        value: undefined,
                        label: formatMessage({id: 'label.select'}),
                        disabled: true
                      },
                      {
                        value: 'Nee',
                        label: formatMessage({id: 'label.no'})
                      },
                      {
                        value: 'Ja',
                        label: formatMessage({id: 'label.yes'})
                      }
                    ]}
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
          <Row className="form-buttons">
            <Col xs={12}>
              <Button className="pull-right" bsStyle="danger" onClick={form.reset}>
                <FormattedMessage id="common.button.cancel"/>
              </Button>
              <Button className="pull-right" bsStyle="primary" type="submit" disabled={pristine || invalid}>
                <FormattedMessage id="common.button.confirm"/>
              </Button>
            </Col>
          </Row>
          <Success show={this.state.showSuccessModal}>
            <FormattedMessage id="success-message.email.post" tagName="p"/>
            <LocalizedLink id={URIKeys.HOME} className="btn btn-success">
              <FormattedMessage id="modal.success.button.home"/>
            </LocalizedLink>
          </Success>
        </form>
      )}/>
  }

  /**
   * Handles the form submit.
   */
  onSubmit = async (values) => {
    const data = {
      ...values,
      'function': 'Personal Trainer'
    };

    post(BackendURLConstants.APPLICATION_EMAIL, JSON.stringify(data), 'application/json', false);

    this.setState({
      showSuccessModal: true
    });
  };
}

export default injectIntl(PersonalTrainerApplication);
