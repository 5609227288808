import React from "react";
import {Col, Grid, Row} from "react-bootstrap";

/**
 * The {@code MobileRecipeInfo} class represents the mobile recipe info.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class MobileRecipeInfo extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <section className="mobile-recipe-info">
                <Grid>
                    <Row>
                        <Col xs={12}>
                            {this.props.children}
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

export default MobileRecipeInfo;
