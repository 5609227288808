import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {ParallaxImage} from "../../components/Image/index";
import {BackLink, PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import {Light} from "../../components/Icons/index";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code SportMassage} class represents the sport massage service page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class SportMassage extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div id="service-page">
                <ParallaxImage lowRes={require('../../images/pages/sport-massage/page-header.svg')}
                               highRes={require('../../images/pages/sport-massage/page-header.jpg')}/>
                <PageHeader>
                    <BackLink>
                        <LocalizedLink id={URIKeys.SERVICES}>
                            <Light icon="chevron-left"/>&#160;
                            <FormattedMessage id="nav-bar.item.services"/>
                        </LocalizedLink>
                    </BackLink>
                    <PageHeaderTitle>
                        <FormattedMessage id="services.section.sport-massage.title" tagName="h3"/>
                        <FormattedMessage id="sport-massage.page.subtitle" tagName="p"/>
                    </PageHeaderTitle>
                </PageHeader>
                <section>
                    <Grid>
                        <Row>
                            <Col xs={12} md={10} mdOffset={1}>
                                <FormattedMessage id="sport-massage.paragraph.one" tagName="p"/>
                                <FormattedMessage id="sport-massage.paragraph.two" tagName="p"/>
                                <FormattedMessage id="sport-massage.paragraph.three" tagName="p"/>
                              <FormattedMessage id="sport-massage.paragraph.four" tagName="p"/>
                              <br/>
                                <LocalizedLink id={URIKeys.SERVICES} className="btn btn-sm btn-info">
                                    <Light icon="chevron-left"/>&#160;
                                    <FormattedMessage
                                        id="common.button.back"/>
                                </LocalizedLink>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </div>
        );
    }
}

export default SportMassage;
