import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import {ParallaxImage} from "../../components/Image/index";

/**
 * The {@code Movies} class represents the about us page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Movies extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    return (
      <div id="movies-page">
        <ParallaxImage lowRes={require('../../images/pages/about-us/page-header-tiny.jpeg')}
                       highRes={require('../../images/pages/about-us/page-header.jpeg')}/>
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="page.movies.title" tagName="h3"/>
            <FormattedMessage id={'page.movies.subtitle-' + this.props.match.params.id} tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section>
          <Grid>
            <Row>
              <Col xs={12}>
                <video controls>
                  <source src={require('../../videos/scheduled-email-' + this.props.match.params.id + '.mp4')}
                          type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

export default Movies;
