import React from "react";
import {Link} from "react-router-dom";
import {Image} from "../Image/";

/**
 * The {@code ImageWrapper} class represents the image wrapper.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ImageWrapper extends React.Component {

  renderImage = () => {
    const {lowRes, highRes, src} = this.props;
    if (src) {
      return (
        <div className="image-box-image-wrapper">
          <img className="img-responsive" src={'data:image/jpg;base64,' + src.url} alt={src.name}/>
          {this.props.children}
        </div>
      );
    } else if (lowRes && highRes) {
      return (
        <div className="image-box-image-wrapper">
          <Image lowRes={this.props.lowRes} highRes={this.props.highRes} classNames="img-responsive"/>
          {this.props.children}
        </div>
      );
    }
  };

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    return (
      <Link to={this.props.url}>
        {this.renderImage()}
      </Link>
    );
  }
}

export default ImageWrapper;
