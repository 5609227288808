import React from "react";
import {MenuItem, Nav, Navbar, NavDropdown, NavItem} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {Light, Solid} from "../components/Icons"
import {getUsername, hasRole, isLoggedIn} from "../utils/AuthenticationUtility";
import ScreenSizes from "../constants/ScreenSizes";
import URIKeys from "../constants/URIKeys";
import Logo from "../images/logos/logo.svg";
import {connect} from "react-redux";
import USER_ROLES from "../constants/UserRoles";

const navBarDefaultHeight = '60px';
const navBarInflatedHeight = '100px';

/**
 * The {@code Header} class represents the page header.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Header extends React.Component {

  /** 
   * The class constructor. 
   */
  constructor(props) {
    super(props);

    this.state = {
      pageYOffset: -1,
      classes: ''
    };
  }

  /** 
   * Adds the event listeners when the component is added to the DOM. 
   */
  componentDidMount() {
    this.updateNavBar();
    window.addEventListener('scroll', this.updateNavBar);
    window.addEventListener('resize', this.resetOffset);
    window.addEventListener('resize', this.updateNavBar);
  }

  /** 
   * Removes the event listeners when the component is removed from the DOM. 
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateNavBar);
    window.removeEventListener('resize', this.resetOffset);
    window.removeEventListener('resize', this.updateNavBar);
  }

  /**
   * Resets the offset after the screen has been resized.
   */
  resetOffset = () => {
    this.setState({
        pageYOffset: -1
      }
    );
  };

  /** 
   * Determines if the page is been scrolled down. If this is the case, the state of the the stickyClass is updated according to the current device. 
   */
  updateNavBar = () => {
    const pageYOffset = window.pageYOffset;

    if (this.state.pageYOffset !== pageYOffset) {
      let classes;

      if (window.innerWidth < ScreenSizes.SM_MAX || pageYOffset > 0) {
        classes = '';
        document.body.style.paddingTop = navBarDefaultHeight;
      } else {
        classes = 'navbar-inflated';
        document.body.style.paddingTop = navBarInflatedHeight;
      }

      this.setState({
        pageYOffset: pageYOffset,
        classes: classes
      });
    }
  };

  /**
   * Renders the dashboard link for either the user or the employee that is currently logged in.
   *
   * @returns {*} the HTML representation of the link
   */
  renderDashboardLink = () => {
    const {formatMessage} = this.props.intl;
    if (hasRole(USER_ROLES.USER)) {
      return (
        <LinkContainer to={formatMessage({id: URIKeys.DASHBOARD_PERFORMANCE})}>
          <MenuItem eventKey={7.1}>
            <Light icon="user-chart"/>
            <FormattedMessage id="nav-bar.item.dashboard"/>
          </MenuItem>
        </LinkContainer>
      );
    }

    return (
      <LinkContainer to={formatMessage({id: URIKeys.DASHBOARD_OVERVIEW})}>
        <MenuItem eventKey={7.1}>
          <Light icon="user-chart"/>
          <FormattedMessage id="nav-bar.item.dashboard"/>
        </MenuItem>
      </LinkContainer>
    );
  };

  /**
   * Renders the edit link for either the user or the employee that is currently logged in.
   *
   * @returns {*} the HTML representation of the link
   */
  renderEditLink = () => {
    const {formatMessage} = this.props.intl;
    if (hasRole(USER_ROLES.USER)) {
      return (
        <LinkContainer to={formatMessage({id: URIKeys.DASHBOARD_USER}).replace(':id', getUsername())}>
          <MenuItem eventKey={7.2}>
            <Light icon="user-edit"/>
            <FormattedMessage id="nav-bar.item.edit"/>
          </MenuItem>
        </LinkContainer>
      );
    }
    return null;
  };

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {formatMessage} = this.props.intl;

    return (
      <Navbar fixedTop fluid collapseOnSelect className={this.state.classes}>
        <Navbar.Header>
          <Navbar.Brand>
            <img src={Logo} alt="logo"/>
          </Navbar.Brand>
          <Navbar.Toggle>
            <Light icon="bars"/>
          </Navbar.Toggle>
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
              <NavItem eventKey={1} href={formatMessage({id: URIKeys.HOME})} >
                <FormattedMessage id="nav-bar.item.home"/>
              </NavItem>
              <NavItem eventKey={2} href={formatMessage({id: URIKeys.ABOUT_US})}>
                <FormattedMessage id="nav-bar.item.about-us"/>
              </NavItem>
              <NavItem eventKey={3} href={formatMessage({id: URIKeys.SERVICES})}>
                <FormattedMessage id="nav-bar.item.services"/>
              </NavItem>
              <NavItem eventKey={4} href={formatMessage({id: URIKeys.STORE_LOCATOR})}>
                <FormattedMessage id="nav-bar.item.store-locator"/>
              </NavItem>
              <NavItem eventKey={5} href={formatMessage({id: URIKeys.PRICING})}>
                <FormattedMessage id="nav-bar.item.pricing"/>
              </NavItem>
            {
              !isLoggedIn() &&
              <LinkContainer to={formatMessage({id: URIKeys.LOGIN})}>
                <NavItem eventKey={6}>
                  <FormattedMessage id="nav-bar.item.login"/>
                </NavItem>
              </LinkContainer>
            }
            {
              isLoggedIn() &&
              <NavDropdown eventKey={7} id="basic-nav-dropdown"
                           title={<div className="dropdown-title"><Solid icon="user"/><FormattedMessage tagName="span"
                                                                                                        id="nav-bar.item.account"/>
                           </div>} noCaret={true}>
                {this.renderDashboardLink()}
                {this.renderEditLink()}
                <MenuItem divider/>
                <LinkContainer to={formatMessage({id: URIKeys.LOGOUT})}>
                  <MenuItem eventKey={7.3}>
                    <Light icon="sign-out"/>
                    <FormattedMessage id="nav-bar.item.logout"/>
                  </MenuItem>
                </LinkContainer>
              </NavDropdown>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}


const mapStateToProps = (state) => ({
  login: state.login.success,
  logout: state.logout.success,
});

export default connect(mapStateToProps)(injectIntl(Header));
