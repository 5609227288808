import React from "react";
import Steps from "./Steps";
import {Row} from "react-bootstrap";
import Navigation from "./Navigation";
import Step from "./Step";
import NavigationNode from "./NavigationNode";

/**
 * The {@code Wizard} class represents a wizard.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Wizard extends React.Component {

  /**
   * Creates a new instance of the {@link Wizard} component
   *
   * @param props the properties
   */
  constructor(props) {
    super(props);

    this.state = {
      activeStep: this.props.activeStep
    };
  }

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        activeStep: this.state.activeStep,
        toggleActiveStep: this.toggleActiveStep,
        toggleCancel: this.props.toggleCancel,
        pristine: this.props.pristine,
        invalid: this.props.invalid
      });
    });

    return (
      <Row className="wizard row-eq-height">
        {children}
      </Row>
    );
  }

  /**
   * Toggles the active step.
   *
   * @param step the step
   */
  toggleActiveStep = (step) => {
    this.setState({
      activeStep: step
    });
  }
}

Wizard.Navigation = Navigation;
Wizard.NavigationNode = NavigationNode;
Wizard.Steps = Steps;
Wizard.Step = Step;

export default Wizard;
