import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {getPointsOfService} from "../../actions/index";
import {Loading} from "../../components/Modals/index";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import {ContactForm} from "../../components/Forms/index";
import {ParallaxImage} from "../../components/Image/index";
import {Redirect} from "react-router-dom";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code Contact} class represents the contact page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Contact extends React.Component {

  /**
   * Gets the points of service.
   */
  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getPointsOfService(undefined, undefined, undefined, false));
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, pointOfServiceCollection} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !pointOfServiceCollection) {
      return (
        <div id="contact-page">
          <ParallaxImage lowRes={require('../../images/pages/contact/page-header.svg')}
                         highRes={require('../../images/pages/contact/page-header.jpg')}/>
          <PageHeader>
            <PageHeaderTitle>
              <FormattedMessage id="contact.page.title" tagName="h3"/>
              <FormattedMessage id="contact.page.subtitle" tagName="p"/>
            </PageHeaderTitle>
          </PageHeader>
          <section>
            <Grid>
              <Row>
                <Loading/>
              </Row>
            </Grid>
          </section>
        </div>
      )
    }

    return (
      <div id="contact-page">
        <ParallaxImage lowRes={require('../../images/pages/contact/page-header.svg')}
                       highRes={require('../../images/pages/contact/page-header.jpg')}/>
        <PageHeader>
          <PageHeaderTitle>
            <FormattedMessage id="contact.page.title" tagName="h3"/>
            <FormattedMessage id="contact.page.subtitle" tagName="p"/>
          </PageHeaderTitle>
        </PageHeader>
        <section>
          <Grid>
            <Row>
              <Col xs={12} md={10} mdOffset={1}>
                <ContactForm pointsOfService={pointOfServiceCollection.pointsOfService}/>
              </Col>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
  const {common, getPointsOfService} = state;
  return {
    error: common.error,
    loading: common.loading,
    pointOfServiceCollection: getPointsOfService.pointOfServiceCollection
  };
}

export default connect(mapStateToProps)(injectIntl(Contact));
