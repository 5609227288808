import React from "react";

/**
 * The {@code SectionHeader} class represents a section heading.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class SectionHeader extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div className="section-header">{this.props.children}</div>
        );
    }
}

export default SectionHeader;
