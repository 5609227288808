import React from "react";
import {Light} from "../Icons";

/**
 * The {@code NavigationNode} class represents a node in the wizard navigation.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class NavigationNode extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    return (
      <li
        className={this.props.step === this.props.activeStep ? 'wizard-navigation-node wizard-navigation-node-active' : 'wizard-navigation-node'}
        onClick={() => this.props.toggleActiveStep(this.props.step)}>
        {this.props.children}
        &#160;<Light icon="chevron-right"/>
      </li>
    );
  }
}

export default NavigationNode;
