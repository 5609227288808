import React from "react";
import {Field, Form} from "react-final-form";
import {Button, Col, ControlLabel, FormGroup, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import FormControl from "./final-form/FormControl/FormControl";
import {compose, maxLength, required} from "../../utils/ValidationUtility";
import {post} from "../../utils/FetchUtility";
import BackendURLConstants from "../../constants/BackendURLConstants";
import LocalizedLink from "../Intl/LocalizedLink";
import URIKeys from "../../constants/URIKeys";
import {Success} from "../Modals";

/**
 * The {@code TechnicalEmailForm} class represents the form to send technical emails.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class TechnicalEmailForm extends React.Component {

  /**
   * Creates a new instance of the {@link TechnicalEmailForm} component.
   *
   * @param props the properties
   * @param context the context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      showSuccessModal: false,
      initialValues: {
        subject: undefined,
        message: undefined,
        callToAction: {
          text: undefined,
          url: undefined
        }
      }
    };
  }

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    return (
      <div>
        <Form
          onSubmit={this.onSubmit}
          initialValues={this.state.initialValues}
          render={({handleSubmit, form}) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="form.newsletter.message" tagName="h4"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="subject">
                    <ControlLabel>
                      <FormattedMessage id="label.subject"/>
                    </ControlLabel>
                    <Field
                      name="subject"
                      component={FormControl}
                      validate={compose(required, maxLength(256))}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="message">
                    <ControlLabel>
                      <FormattedMessage id="label.message"/>
                    </ControlLabel>
                    <Field
                      name="message"
                      component={FormControl}
                      componentClass="textarea"
                      validate={compose(required)}
                      rows={20}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormattedMessage id="form.newsletter.call-to-action" tagName="h4"/>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="callToAction.text">
                    <ControlLabel>
                      <FormattedMessage id="label.call-to-action.text"/>
                    </ControlLabel>
                    <Field
                      name="callToAction.text"
                      component={FormControl}
                      validate={compose(maxLength(256))}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup controlId="callToAction.url">
                    <ControlLabel>
                      <FormattedMessage id="label.call-to-action.url"/>
                    </ControlLabel>
                    <Field
                      name="callToAction.url"
                      component={FormControl}
                      validate={compose(maxLength(256))}
                      placeholder="https://www.bodyreset.be/prijzen"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button className="pull-right" bsStyle="danger" onClick={form.reset}>
                    <FormattedMessage id="common.button.cancel"/>
                  </Button>
                  <Button className="pull-right" bsStyle="primary" type="submit">
                    <FormattedMessage id="common.button.confirm"/>
                  </Button>
                </Col>
              </Row>
              <Success show={this.state.showSuccessModal}>
                <FormattedMessage id="success-message.technical-email.post" tagName="p"/>
                <LocalizedLink id={URIKeys.DASHBOARD_OVERVIEW} className="btn btn-success">
                  <FormattedMessage id="modal.success.button.dashboard"/>
                </LocalizedLink>
              </Success>
            </form>
          )}/>
      </div>
    );
  }

  /**
   * Handles the form on submit.
   */
  onSubmit = async (values) => {
    const data = {
      ...values,
    };

    this.setState({
      initialValues: {
        subject: undefined,
        message: undefined,
        callToAction: {
          text: undefined,
          url: undefined
        }
      }
    });

    post(BackendURLConstants.TECHNICAL_EMAIL, JSON.stringify(data), 'application/json', true);

    this.setState({
      showSuccessModal: true
    });
  };
}

export default TechnicalEmailForm;
