import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";

/**
 * The {@code Step} class represents a step in the wizard.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Step extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    return (
      <div className={this.props.step === this.props.activeStep ? 'wizard-step' : 'wizard-step hidden'}>
        {this.props.children}
        <Row>
          <Col xs={4}>
            <Button bsStyle="danger" className="pull-left" onClick={this.props.toggleCancel}>
              <FormattedMessage id="common.button.cancel"/>
            </Button>
          </Col>
          {this.renderNavigation()}
        </Row>
      </div>
    );
  }

  /**
   * Renders the navigation to go through the wizard.
   *
   * @returns {*} the navigation
   */
  renderNavigation = () => {
    let result;
    if (this.props.first) {
      result = (
        <Col xs={8}>
          <Button bsStyle="info" className="pull-right"
                  onClick={() => this.props.toggleActiveStep(this.props.step + 1)}>
            <FormattedMessage id="common.button.next"/>
          </Button>
        </Col>
      );
    } else if (this.props.last) {
      result = (
        <Col xs={8}>
          <Button bsStyle="info" className="pull-right" type="submit"
                  disabled={this.props.pristine || this.props.invalid}>
            <FormattedMessage id="common.button.confirm"/>
          </Button>
          <Button bsStyle="info" className="pull-right"
                  onClick={() => this.props.toggleActiveStep(this.props.step - 1)}>
            <FormattedMessage id="common.button.previous"/>
          </Button>
        </Col>
      );
    } else {
      result = (
        <Col xs={8}>
          <Button bsStyle="info" className="pull-right"
                  onClick={() => this.props.toggleActiveStep(this.props.step + 1)}>
            <FormattedMessage id="common.button.next"/>
          </Button>
          <Button bsStyle="info" className="pull-right"
                  onClick={() => this.props.toggleActiveStep(this.props.step - 1)}>
            <FormattedMessage id="common.button.previous"/>
          </Button>
        </Col>
      );
    }
    return result;
  }
}

export default Step;
