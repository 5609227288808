import React from "react";
import {Grid} from "react-bootstrap";

/**
 * The {@code RecipeInfoWrapper} class represents a recipe info wrapper.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class RecipeInfoWrapper extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <Grid className="recipe-info-wrapper">
                {this.props.children}
            </Grid>
        );
    }
}

export default RecipeInfoWrapper;
