import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Caption, Tile} from "../Tile";
import {Light} from "../Icons";
import {convertISODateTimeToJSDate} from "../../utils/DateTimeUtility";
import {getLanguage} from "../../utils/LanguageUtility";

/**
 * The {@code Appointment} class represents the appointment tile.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Appointment extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {appointment} = this.props;
    const {formatMessage} = this.props.intl;

    let icon = 'icon-gray';
    let appointmentDate = '-';
    let appointmentType = '-';

    if (appointment !== null) {
      let date = convertISODateTimeToJSDate(appointment.date);

      icon = 'icon-success';
      appointmentDate = date.toLocaleDateString(getLanguage())
        + " - "
        + date.toLocaleTimeString(getLanguage(), {hour: '2-digit', minute: '2-digit'});
      appointmentType = formatMessage({id: 'facilities.type.' + appointment.facility.type});
    }

    return (
      <Tile xs={12} md={4}>
        <FormattedMessage id="performance.page.tile.appointment.title" tagName="h5"/>
        <Light icon="calendar-check" classNames={icon}/>
        <Caption>
          <h5>{appointmentDate}</h5>
          <span>{appointmentType}</span>
        </Caption>
      </Tile>
    );
  }
}

export default injectIntl(Appointment);
