import React from "react";

/**
 * The {@code RequiredAnnotation} class represents asterisk that is used to indicate an input field as required.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class RequiredAnnotation extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <span className="required-annotation">&nbsp;*</span>
        );
    }
}

export default RequiredAnnotation;
