import React from "react";
import {FormattedMessage} from "react-intl";
import {Modal} from "react-bootstrap";
import Light from "../../Icons/Light";

/**
 * The {@code DeleteUserModal} class represents the modal to provide a confirmation before deleting a user.
 *
 * @author Cornel Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class DeleteOpeningDayModal extends React.Component {

  /**
   * Renders the component.
   *
   * @returns {*} the HTML representation of the component
   */
  render() {
    const {show} = this.props;

    return (
      <Modal show={show} backdrop={true} className="danger-modal text-center">
        <Modal.Header>
          <Light icon="exclamation-circle"/>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="point-of-service.page.modal.delete-confirmation.title" tagName="h4"/>
          {this.props.children}
        </Modal.Body>
      </Modal>
    );
  }
}

export default DeleteOpeningDayModal;
