/**
 * The {@code COUNTRIES} constant class contains the supported countries.
 * todo: move to endpoint
 *
 * @type {object}
 */
const COUNTRIES = [
  {
    key: 'countries.BE',
    isoCode: 'BE',
    states: [
      {
        key: 'countries.BE.state.BE-VAN',
        isoCode: 'BE-VAN'
      },
      {
        key: 'countries.BE.state.BE-VLI',
        isoCode: 'BE-VLI'
      },
      {
        key: 'countries.BE.state.BE-VOV',
        isoCode: 'BE-VOV'
      },
      {
        key: 'countries.BE.state.BE-VBR',
        isoCode: 'BE-VBR'
      },
      {
        key: 'countries.BE.state.BE-VWV',
        isoCode: 'BE-VWV'
      },
      {
        key: 'countries.BE.state.BE-WBR',
        isoCode: 'BE-WBR'
      },
      {
        key: 'countries.BE.state.BE-WHT',
        isoCode: 'BE-WHT'
      },
      {
        key: 'countries.BE.state.BE-WLG',
        isoCode: 'BE-WLG'
      },
      {
        key: 'countries.BE.state.BE-WLX',
        isoCode: 'BE-WLX'
      },
      {
        key: 'countries.BE.state.BE-WNA',
        isoCode: 'BE-WNA'
      },
    ]
  },
  {
    key: 'countries.NL',
    isoCode: 'NL',
    states: [
      {
        key: 'countries.NL.state.NL-DR',
        isoCode: 'NL-DR'
      },
      {
        key: 'countries.NL.state.NL-FL',
        isoCode: 'NL-FL'
      },
      {
        key: 'countries.NL.state.NL-FR',
        isoCode: 'NL-FR'
      },
      {
        key: 'countries.NL.state.NL-GE',
        isoCode: 'NL-GE'
      },
      {
        key: 'countries.NL.state.NL-GR',
        isoCode: 'NL-GR'
      },
      {
        key: 'countries.NL.state.NL-LI',
        isoCode: 'NL-LI'
      },
      {
        key: 'countries.NL.state.NL-NB',
        isoCode: 'NL-NB'
      },
      {
        key: 'countries.NL.state.NL-NH',
        isoCode: 'NL-NH'
      },
      {
        key: 'countries.NL.state.NL-OV',
        isoCode: 'NL-OV'
      },
      {
        key: 'countries.NL.state.NL-UT',
        isoCode: 'NL-UT'
      },
      {
        key: 'countries.NL.state.NL-ZE',
        isoCode: 'NL-ZE'
      },
      {
        key: 'countries.NL.state.NL-ZH',
        isoCode: 'NL-ZH'
      },
    ]
  }
];


export default COUNTRIES;

