/**
 * The {@code OPENING_DAY_TYPES} constant class contains the available opening day types.
 *
 * @type {string}
 */
const OPENING_DAY_TYPES = {
    REGULAR: 'regular',
    SPECIAL: 'special',
};

export default OPENING_DAY_TYPES;
