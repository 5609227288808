import React from "react";
import DashboardHeader from "../../../partials/DashboardHeader";
import {Checkbox, Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import {getUsername} from "../../../utils/AuthenticationUtility";
import {getUser} from "../../../actions";
import {Redirect} from "react-router-dom";
import URIKeys from "../../../constants/URIKeys";
import {connect} from "react-redux";
import Loading from "../../../components/Modals/Loading";
import {getCategories, getCategoryProducts, getProducts} from "../../../actions/Products";
import {ImageBox} from "../../../components/ImageBox";
import {getLanguage} from "../../../utils/LanguageUtility";
import {Pagination} from "@react-bootstrap/pagination";

/**
 * The {@code Shop} class represents the dashboard shop page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Shop extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: new Set()
    };
  }

  componentDidMount() {
    const {dispatch} = this.props;

    let username = getUsername();

    dispatch(getUser(username));
    dispatch(getCategories());
    dispatch(getProducts(12, 0));
  }

  /**
   * Renders the component.
   *
   * @returns {XML} the HTML representation of the component
   */
  render() {
    const {error, loading, userObject, productCollection, categoryCollection} = this.props;
    const {formatMessage} = this.props.intl;

    if (error) {
      return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
    }

    if (loading > 0 || !userObject || !productCollection || !categoryCollection) {
      return (
        <div id="dashboard-shop-page" className="dashboard-page">
          <DashboardHeader/>
          <Grid>
            <Row>
              <Loading/>
            </Row>
          </Grid>
        </div>
      )
    }

    return (
      <div id="dashboard-shop-page" className="dashboard-page">
        <DashboardHeader/>
        <Grid>
          <section className="products">
            <Row>
              <Col xs={4} sm={3}>
                <Row>
                  <Col xs={12}>
                    <h4>Categorieëen</h4>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    {categoryCollection.categories.map((category) => (
                      <Checkbox value={category.id} key={category.id}
                                checked={this.state.selectedCategories.has(category.id)}
                                onChange={() => this.selectCategory(category.id)}>
                        {category.name}
                      </Checkbox>
                    ))}
                  </Col>
                </Row>
              </Col>
              <Col xs={8} sm={9}>
                {this.renderProducts(productCollection.products)}
              </Col>
            </Row>
          </section>
          {productCollection && productCollection.products.length > 0 && this.renderPagination(productCollection.page)}
        </Grid>
      </div>
    );
  };

  renderProducts = (products) => {
    const {formatMessage} = this.props.intl;

    if (!products || products.length === 0) {
      return (
        <Row>
          <FormattedMessage id="page.shop.no-results.title" tagName="h4"/>
        </Row>
      )
    }

    return (
      <Row>
        {products.map((product) => (
          <ImageBox key={product.id} xs={12} sm={3}>
            {this.renderImage(product)}
            <ImageBox.Name
              url={formatMessage({id: URIKeys.DASHBOARD_PRODUCT_DETAIL_PAGE}).replace(':id', product.id)}>
              {product.name}
            </ImageBox.Name>
          </ImageBox>
        ))}
      </Row>
    )
  };

  renderImage = (product) => {
    const {formatMessage} = this.props.intl;

    let variant = product;
    if (product.variants && product.variants.length > 0) {
      variant = product.variants[0];
    }

    return (
      <ImageBox.ImageWrapper
        url={formatMessage({id: URIKeys.DASHBOARD_PRODUCT_DETAIL_PAGE}).replace(':id', product.id)}
        src={variant.images && variant.images[0]}
        lowRes={require('../../../images/products/placeholder-tiny.jpeg')}
        highRes={require('../../../images/products/placeholder.jpeg')}>
        {this.renderPrice(variant)}
      </ImageBox.ImageWrapper>
    )
  }

  renderPrice = (product) => {
    if (product.type && product.type === 'CREDIT_BUNDLE') {
      return (
        <ImageBox.Badge>
          €{product.price.toLocaleString(getLanguage(), {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
        </ImageBox.Badge>
      );
    } else {
      return (
        <ImageBox.Badge>
          {product.price.toLocaleString(getLanguage(), {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1
          })}&nbsp;credit(s)
        </ImageBox.Badge>
      );
    }
  };

  /**
   * Renders the pagination links
   *
   * @param page the page information
   * @returns {undefined|*} the pagination links
   */
  renderPagination(page) {
    let result = undefined;
    if (page) {
      return (<section className="pagination-links">
        <Grid>
          <Row>
            <Col xs={12}>
              <Pagination bsSize="medium"
                          items={page.totalPages}
                          activePage={page.number + 1}
                          onSelect={this.togglePage}
                          prev
                          next
                          first
                          last
                          ellipsis
                          boundaryLinks
                          maxButtons={5}/>
            </Col>
          </Row>
        </Grid>
      </section>);
    }
    return result;
  }

  /**
   * Toggles a page in the paginated list.
   *
   * @param page the page
   */
  togglePage = (page) => {
    const {dispatch} = this.props;
    var selectedCategories = this.state.selectedCategories;
    if (selectedCategories.size === 0) {
      dispatch(getProducts(12, --page));
    } else {
      dispatch(getCategoryProducts(Array.from(selectedCategories).join(), 12, --page));
    }
  };

  selectCategory = id => {
    const {dispatch} = this.props;

    var selectedCategories = this.state.selectedCategories;

    if (selectedCategories.has(id)) {
      selectedCategories.delete(id);
    } else {
      selectedCategories.add(id);
    }

    this.setState({
      selectedCategories: selectedCategories
    });

    if (selectedCategories.size === 0) {
      dispatch(getProducts(12, 0));
    } else {
      dispatch(getCategoryProducts(Array.from(selectedCategories).join(), 12, 0));
    }
  }
}

function mapStateToProps(state) {
  const {common, getUser, getProducts, getCategories} = state;
  return {
    error: common.error,
    loading: common.loading,
    userObject: getUser.userObject,
    productCollection: getProducts.productCollection,
    categoryCollection: getCategories.categoryCollection
  };
}


export default connect(mapStateToProps)(injectIntl(Shop));
