import React from "react";
import {configureAnchors} from 'react-scrollable-anchor';
import {Footer, Header} from "../partials";
import Page from "../pages/Page";

configureAnchors({offset: -60});

/**
 * The {@code App} class represents the application.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class App extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div>
                <Header/>
                <Page/>
                <Footer/>
            </div>
        );
    }
}

export default App;
