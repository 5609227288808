import Actions from "../constants/Actions";

const initialState = {
  intakeFormsCollection: undefined,
  intakeFormObj: undefined,
};

export function intakeForms(state = initialState, action) {
  if (action.type === Actions.GET_INTAKE_FORMS) {
    return {
      ...state,
      intakeFormsCollection: action.result
    };
  } else if (action.type === Actions.GET_INTAKE_FORM) {
    return {
      ...state,
      intakeFormObj: action.result
    };
  } else {
    return state;
  }
}
