import React from "react";
import {Col, Grid, Row} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {ParallaxImage} from "../../components/Image/index";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader/index";
import {NewsletterSignUpForm} from "../../components/Forms/index";

/**
 * The {@code Newsletter} class represents the newsletter signup page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class Newsletter extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div id="newsletter-sign-up-page">
                <ParallaxImage lowRes={require('../../images/pages/newsletter-signup/page-header.svg')}
                               highRes={require('../../images/pages/newsletter-signup/page-header.jpg')}/>
                <PageHeader>
                    <PageHeaderTitle>
                        <FormattedMessage id="newsletter-sign-up.page.title" tagName="h3"/>
                        <FormattedMessage id="newsletter-sign-up.page.subtitle" tagName="p"/>
                    </PageHeaderTitle>
                </PageHeader>
                <section>
                    <Grid>
                        <Row>
                            <Col xs={12} md={10} mdOffset={1}>
                                <NewsletterSignUpForm/>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </div>
        );
    }
}

export default Newsletter;
