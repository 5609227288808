import React from "react";
import {PageHeader, PageHeaderTitle} from "../../components/PageHeader";
import {FormattedMessage} from "react-intl";
import {Col, Grid, Row} from "react-bootstrap";
import LocalizedLink from "../../components/Intl/LocalizedLink";
import URIKeys from "../../constants/URIKeys";

/**
 * The {@code PageNotFound} class represents the 404 page.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class PageNotFound extends React.Component {

    /**
     * Renders the component.
     *
     * @returns {XML} the HTML representation of the component
     */
    render() {
        return (
            <div id="404-page" className="error-page">
                <PageHeader>
                    <PageHeaderTitle>
                        <FormattedMessage id="404.page.title" tagName="h3"/>
                        <FormattedMessage id="404.page.subtitle" tagName="p"/>
                    </PageHeaderTitle>
                </PageHeader>
                <section>
                    <Grid>
                        <Row>
                            <Col xsHidden sm={6}>
                                <p className="error-page-code">404</p>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormattedMessage id='404.page.header' tagName="h4"/>
                                <FormattedMessage id='404.page.paragraph' tagName="p"/>
                                <LocalizedLink id={URIKeys.HOME} className="btn btn-primary">
                                    <FormattedMessage id="404.page.button"/>
                                </LocalizedLink>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </div>
        );
    }
}

export default PageNotFound;
