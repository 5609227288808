/**
 * Sets a cookie for the given {@code key} and {@code value}.
 *
 * @param key the key
 * @param value the value
 * @param date the expiry date
 * @param neverExpires indicates whether the cookie should expire or not
 */
export function setCookie(key, value, date, neverExpires) {
  let expiryDate = '';
  if (neverExpires) {
    expiryDate = '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
  } else if (date != null) {
    expiryDate = '; expires=' + date;
  }

  document.cookie = key + '=' + value + expiryDate + '; path=/';
}

/**
 * Returns the value for the cookie with the given {@code key}.
 * <p>
 * If there is no cookie present for the given {@code key}, an empty string is returned.o
 *
 * @param key the key
 * @returns the value
 */
export function getCookie(key) {
  let result = undefined;
  key += "=";
  if (document.cookie) {
    const cookies = document.cookie.split(';');
    if (cookies) {
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];

        // Remove leading spaces
        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
        }

        if (cookie.indexOf(key) === 0) {
          result = cookie.substring(key.length, cookie.length);
        }
      }
    }
  }
  return result;
}

/**
 * Expires the cookie for the given {@code key}.
 *
 * @param key the key
 */
export function expireCookie(key) {
  document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
}
