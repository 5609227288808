import {attemptRefresh, getAccessToken} from "./AuthenticationUtility";

/**
 * Performs a GET request and returns the promise.
 *
 * @param url the URL
 * @param pageSize the page size
 * @param pageNumber the page number that you wnt to fetch
 * @param sort the sort query
 * @param query the filter query
 * @param authorized the flag to indicate whether the authorization header should be added
 * @returns {Promise<any>} the promise
 */
export async function getAll(url, pageSize, pageNumber = 0, sort, query, authorized) {
  url = forceHttps(url);

  let queryParams = '';

  let pageSizeRequestParams = getPageSizeRequestParameter(pageSize);
  if (pageSizeRequestParams !== '') {
    queryParams += '?' + pageSizeRequestParams;
  }

  let pageNumberRequestParams = getPageNumberRequestParameter(pageNumber);
  if (pageNumberRequestParams !== '') {
    if (queryParams === '') {
      queryParams += '?' + pageNumberRequestParams;
    } else {
      queryParams += '&' + pageNumberRequestParams;
    }
  }

  let sortRequestParams = getSortRequestParameter(sort);
  if (sortRequestParams !== '') {
    if (queryParams === '') {
      queryParams += '?' + sortRequestParams;
    } else {
      queryParams += '&' + sortRequestParams;
    }
  }

  let searchRequestParams = getFilterRequestParameter(query);
  if (searchRequestParams !== '') {
    if (queryParams === '') {
      queryParams += '?' + searchRequestParams;
    } else {
      queryParams += '&' + searchRequestParams;
    }
  }

  if (authorized) {
    await attemptRefresh();
  }

  let response = await fetch(url + queryParams, {
    method: 'GET',
    headers: getHeaders(null, authorized),
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  return await response.json();
}

/**
 * Performs a GET request and returns the promise.
 *
 * @param url the URL
 * @param authorized the flag to indicate whether the authorization header should be added
 * @returns {Promise<any>} the promise
 */
export async function getOne(url, authorized) {
  url = forceHttps(url);

  if (authorized) {
    await attemptRefresh();
  }

  let response = await fetch(url, {
    method: 'GET',
    headers: getHeaders(null, authorized),
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  if (response.status === 204) {
    return Promise.resolve();
  }
  return await response.json();
}

/**
 * Performs a POST request and returns the promise.
 *
 * @param url the URL
 * @param data the data
 * @param contentType the content type
 * @param authorized the flag to indicate whether the authorization header should be added
 * @returns {Promise<any>} the promise
 */
export async function post(url, data, contentType, authorized) {
  url = forceHttps(url);

  if (authorized) {
    await attemptRefresh();
  }

  let response = await fetch(url, {
    method: 'POST',
    headers: getHeaders(contentType, authorized),
    credentials: 'include',
    body: data
  });

  if (!response.ok) {
    throw { // todo: extend error class here...
      status: response.status.toString(),
      message: await response.text()
    };
  }

  return await response.json();
}

/**
 * Performs a PATCH request and returns the promise.
 *
 * @param url the URL
 * @param data the data
 * @param contentType the content type
 * @param authorized the flag to indicate whether the authorization header should be added
 * @returns {Promise<any>} the promise
 */
export async function patch(url, data, contentType, authorized) {
  url = forceHttps(url);

  if (authorized) {
    await attemptRefresh();
  }

  let response = await fetch(url, {
    method: 'PATCH',
    headers: getHeaders(contentType, authorized),
    credentials: 'include',
    body: data
  });

  if (!response.ok) {
    return response.text().then(Promise.reject.bind(Promise));
  }

  return await response.json();
}

/**
 * Performs a PATCH request and returns the promise.
 *
 * @param url the URL
 * @param data the data
 * @param contentType the content type
 * @param authorized the flag to indicate whether the authorization header should be added
 * @returns {Promise<any>} the promise
 */
export async function put(url, data, contentType, authorized) {
  url = forceHttps(url);

  if (authorized) {
    await attemptRefresh();
  }

  let response = await fetch(url, {
    method: 'PUT',
    headers: getHeaders(contentType, authorized),
    credentials: 'include',
    body: data
  });

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  return Promise.resolve();
}

/**
 * Performs a DELETE request and returns the promise.
 *
 * @param url the URL
 * @param authorized the flag to indicate whether the authorization header should be added
 * @returns {Promise<any>} the promise
 */
export async function remove(url, authorized) {
  url = forceHttps(url);

  if (authorized) {
    await attemptRefresh();
  }

  let response = await fetch(url, {
    method: 'DELETE',
    headers: getHeaders(null, authorized),
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error(response.status.toString());
  }
}

/**
 * Returns the request parameter to set the page size.
 *
 * @param pageSize the page size
 * @returns {string} the request parameter to set the page size
 */
function getPageSizeRequestParameter(pageSize) {
  let result = '';
  if (pageSize) {
    result = "size=" + pageSize;
  }
  return result;
}

/**
 * Returns the request parameter to set the page number.
 *
 * @param pageNumber the page number
 * @returns {string} the request parameter to set the page number
 */
function getPageNumberRequestParameter(pageNumber) {
  let result = '';
  if (pageNumber) {
    result = "page=" + pageNumber;
  }
  return result;
}

/**
 * Returns the request parameter to sort the result.
 *
 * @param sort the field on which to sort
 * @returns {string} the request parameter to sort the result
 */
function getSortRequestParameter(sort) {
  let result = '';
  if (sort) {
    result = `sort=${sort}`;
  }
  return result
}

/**
 * Returns the request parameter to filter the result.
 *
 * @param query Object container the different queries.
 * @returns {string} the request parameter to sort the result
 */
function getFilterRequestParameter(query) {
  let result = '';

  if (query) {
    const filterString = Object.keys(query)
      .map(key => `${key}:${query[key]}`).join();
    result = `search=${filterString}`;

  }
  return result
}

/**
 * Returns the HTTP headers.
 *
 * @param contentType the content type
 * @param authorized the flag to indicate whether the authorization header should be added
 * @returns the HTTP headers
 */
export function getHeaders(contentType, authorized) {
  let headers = {};

  if (authorized !== null && authorized) {
    headers['Authorization'] = 'Bearer ' + getAccessToken();
  }

  if (contentType !== null) {
    headers['Content-Type'] = contentType;
  }

  return headers;
}

export function forceHttps(url) {
  if (window && window.location && window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
    url = url.replace('http://backend.bodyreset.be:10000/', 'https://backend.bodyreset.be/');
    url = url.replace('http://brtest.webvantage.me:10002/', 'https://brtest.webvantage.me/');
  }
  return url;
}
