import React from "react";

/**
 * The {@code OpeningStatus} class represents an opening status.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class OpeningStatus extends React.Component {

    /**
     * Renders the component.
     *
     * @returns JSX.Element HTML representation of the component
     */
    render() {
        return (
            <span className="point-of-service-opening-status">
                {this.props.children}
            </span>
        );
    }
}

export default OpeningStatus;
