import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {changePassword, resetChangePasswordForm} from "../../actions";
import RequiredAnnotation from "./RequiredAnnotation";
import {Loading} from "../../components/Modals";
import {getValidationState, validate, validatePasswords} from "../../utils/ValidationUtility";
import ValidationTypes from "../../constants/ValidationTypes";
import URIKeys from "../../constants/URIKeys";
import {Redirect} from "react-router-dom";

/**
 * The {@code ChangePasswordForm} class represents a change password form.
 *
 * @author Christiaan Janssen
 * @version %I%, %G%
 * @since 1.0.0
 */
class ChangePasswordForm extends React.Component {

    /** 
     * The class constructor. 
     */
    constructor(props) {
        super(props);

        this.state = {
            password: {
                value: '',
                state: undefined,
                message: undefined
            },
            passwordConfirmation: {
                value: '',
                state: undefined,
                message: undefined
            }
        };
    }

    /**
     * Resets the form.
     */
    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(resetChangePasswordForm());
    }

    /**
     * Handle the change event for the email address input.
     *
     * @param e the event
     */
    handlePasswordChange = (e) => {
        this.validatePassword(e.target.value);
    };

    /**
     * Validates the given {@code password}.
     *
     * @param password the password
     */
    validatePassword(password) {
        let message = validate(password, [ValidationTypes.REQUIRED, ValidationTypes.PASSWORD]);
        let state = getValidationState(message);
        this.setState({
            password: {
                value: password,
                state: state,
                message: message,
            }
        });
    };

    /**
     * Handle the change event for the email address input.
     *
     * @param e the event
     */
    handlePasswordConfirmationChange = (e) => {
        this.validatePasswordConfirmation(e.target.value);
    };

    /**
     * Validates the given {@code passwordConfirmation}.
     *
     * @param passwordConfirmation the password confirmation
     */
    validatePasswordConfirmation(passwordConfirmation) {
        let message = validate(passwordConfirmation, [ValidationTypes.REQUIRED, ValidationTypes.PASSWORD]);
        if (message == null) {
            message = validatePasswords(this.state.password.value, passwordConfirmation)
        }
        let state = getValidationState(message);
        this.setState({
            passwordConfirmation: {
                value: passwordConfirmation,
                state: state,
                message: message,
            }
        });
    };

    /**
     * Handles the form on submit.
     */
    handleSubmit = (e) => {
        e.preventDefault();

        if (this.isValid()) {
            const {dispatch} = this.props;

            let data = [];
            data.push(encodeURIComponent('password') + '=' + encodeURIComponent(this.state.password.value));

            dispatch(changePassword(this.props.username, this.props.token, data));
        }
    };

    /**
     * Checks whether the form is valid.
     *
     * @returns {boolean} {@code true} in case the form is valid, otherwise {@code false}
     */
    isValid() {
        this.validatePassword(this.state.password.value);
        this.validatePasswordConfirmation(this.state.passwordConfirmation.value);

        return this.state.password.state === null && this.state.passwordConfirmation.state === null;
    }

    /**
     * Renders the component.
     *
     * @returns {*} the HTML representation of the component
     */
    render() {
        const {error, loading, success} = this.props;
        const {formatMessage} = this.props.intl;

        if (error) {
            return <Redirect to={formatMessage({id: URIKeys.EXCEPTION})}/>
        }

        let loadingModal;
        if (loading > 0) {
            loadingModal = <Loading/>;
        }

        if (success) {
            return <Redirect to={formatMessage({id: URIKeys.LOGIN})}/>
        }

        return (
            <form
                id="change-password-form"
                onSubmit={this.handleSubmit}
                noValidate="noValidate">
                <Row>
                    <Col xs={12}>
                        <h4>
                            <FormattedMessage id="change-password.page.title"/>
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="change-password.page.paragraph" tagName="p"/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup controlId="password" validationState={this.state.password.state}>
                            <ControlLabel>
                              <FormattedMessage id="label.password"/>
                                <RequiredAnnotation/>
                            </ControlLabel>
                            <FormControl
                                type="password"
                                value={this.state.password.value}
                                onChange={this.handlePasswordChange}/>
                            <HelpBlock>{this.state.password.message}
                            </HelpBlock>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup controlId="passwordConfirmation"
                                   validationState={this.state.passwordConfirmation.state}>
                            <ControlLabel>
                              <FormattedMessage id="label.password-confirmation"/>
                                <RequiredAnnotation/>
                            </ControlLabel>
                            <FormControl
                                type="password"
                                value={this.state.passwordConfirmation.value}
                                onChange={this.handlePasswordConfirmationChange}/>
                            <HelpBlock>{this.state.passwordConfirmation.message}
                            </HelpBlock>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button type="submit" bsStyle="primary">
                            <FormattedMessage id="form.button.change-password"/>
                        </Button>
                    </Col>
                </Row>
                {loadingModal}
            </form>
        );
    }
}

/**
 * Maps the Redux state to the component properties.
 *
 * @param state the Redux state
 * @returns the component properties
 */
function mapStateToProps(state) {
    const {common, changePassword} = state;
    return {
        error: common.error,
        loading: common.loading,
        success: changePassword.success
    };
}

export default connect(mapStateToProps)(injectIntl(ChangePasswordForm));
