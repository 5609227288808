import Actions from "../constants/Actions";

export function createAppointment(state = {
  success: false
}, action) {
  if (action.type === Actions.CREATE_APPOINTMENT) {
    return Object.assign({}, state, {
      success: true
    });
  } else {
    return state;
  }
}

export function deleteAppointment(state = {
  success: false
}, action) {
  if (action.type === Actions.DELETE_APPOINTMENT) {
    return Object.assign({}, state, {
      success: true
    });
  } else {
    return state;
  }
}

export function appointmentsUser(state = {
  userAppointmentCollection: null
}, action) {
  if (action.type === Actions.GET_APPOINTMENTS_USER) {
    return Object.assign({}, state, {
      userAppointmentCollection: action.result
    });
  } else {
    return state;
  }
}
